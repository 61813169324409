import { gql } from '@apollo/client';
import { User } from 'types/common';

export const areasQuery = gql`
  query GetAreas {
    getAreas {
      name
      _id
    }
  }
`;

export type GetCustomersQueryResponse = {
  getCustomers: { _id: string; fname: string; lname: string }[];
};

export const customersName = gql`
  query GetCustomers($filter: CustomerFilter) {
    getCustomers(filter: $filter) {
      _id
      fname
      lname
      referenceId
    }
  }
`;

export type UserNamesQueryResponse = {
  getUsers: User[];
};

export type UserNamesQueryVariables = {
  filter: {
    department?: string;
    email?: string;
    fname?: string;
    lname?: string;
    mobile?: string;
    status?: string;
    type?: string;
    roles?: string[];
  };
};

export const USERS_NAMES_QUERY = gql`
  query GetUsers($filter: UserFilter) {
    getUsers(filter: $filter) {
      _id
      fname
      lname
      empId
    }
  }
`;
