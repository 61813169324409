import { gql } from '@apollo/client';
import { AutoCompleteOption } from 'types/common';

export type LHASubCategoriesQueryResponse = {
  getLhaProducts: {
    subCategory: string;
  }[];
};

export const LHA_SUB_CATEGORIES_QUERY = gql`
  query GetLhaProducts {
    getLhaProducts {
      subCategory
    }
  }
`;

export type LHAGroupNamesQueryResponse = {
  getLhaProducts: {
    group: string;
  }[];
};

export type LHAGroupNamesQueryVariables = {
  filter: {
    subCategory: string;
  };
};

export const LHA_GROUP_NAMES_QUERY = gql`
  query GetLhaProducts($filter: LhaProductFilter) {
    getLhaProducts(filter: $filter) {
      group
    }
  }
`;

export type LHAApplicationsQueryResponse = {
  getLhaProducts: {
    application: string;
  }[];
};

export type LHAApplicationsQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
  };
};

export const LHA_APPLICATIONS_QUERY = gql`
  query GetLhaProducts($filter: LhaProductFilter) {
    getLhaProducts(filter: $filter) {
      application
    }
  }
`;

export type LHAWattagesQueryResponse = {
  getLhaProducts: {
    wattage: string;
  }[];
};

export type LHAWattagesQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    application: string;
  };
};

export const LHA_WATTAGES_QUERY = gql`
  query GetLhaProducts($filter: LhaProductFilter) {
    getLhaProducts(filter: $filter) {
      wattage
    }
  }
`;

export type LHABrandNamesQueryResponse = {
  getLhaProducts: {
    _id: string;
    price: number;
    brand: AutoCompleteOption;
  }[];
};

export type LHABrandNamesQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    size: string;
    unitOfMeasure: string;
  };
};

export const LHA_BRAND_NAMES_QUERY = gql`
  query GetLhaProducts($filter: LhaProductFilter) {
    getLhaProducts(filter: $filter) {
      _id
      price
      brand {
        _id
        name
      }
    }
  }
`;
