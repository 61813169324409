import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { toast } from 'react-toastify';

import { getToken, logout } from 'utils/auth';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_BACKEND,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions, message }) => {
      toast.error(message);
      if (extensions && extensions.code === 'UNAUTHENTICATED') logout();
    });
  if (networkError) toast.error(`Network Error! ${networkError.message}`);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: getToken(),
    },
  };
});

const cache = new InMemoryCache();

export const client = new ApolloClient({
  // @ts-ignore
  link: from([errorLink, authLink.concat(httpLink)]),
  connectToDevTools: true,
  cache,
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export const removeUserFromCache = userId => {
  cache.evict({
    id: cache.identify({ id: userId, __typename: 'UserType' }),
  });
  cache.gc();
};
