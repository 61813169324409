import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { toast } from 'react-toastify';

import Transition from 'components/Transition';

const DELETE_LEAD_PRODUCT = gql`
  mutation DeleteLeadProduct($id: ID!) {
    deleteLeadProduct(_id: $id)
  }
`;

const DeletePopup = ({
  open,
  onClose,
  id,
  onDelete,
  loading,
}: {
  open: boolean;
  onClose: () => void;
  id: string;
  onDelete: () => void;
  loading: boolean;
}) => {
  const [deleteLeadProduct, { loading: deletingLeadProduct }] = useMutation(DELETE_LEAD_PRODUCT);

  const handleDelete = () => {
    deleteLeadProduct({
      variables: {
        id,
      },
      onCompleted: _ => {
        onDelete();
        toast.info('Product Deleted Successfully!');
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle sx={{ fontWeight: 600, fontSize: '1.1rem', pb: 1.1 }}>
        Do you really want to delete this
      </DialogTitle>
      <DialogActions>
        <LoadingButton
          fullWidth
          onClick={handleDelete}
          variant="contained"
          loading={deletingLeadProduct || loading}
        >
          Delete
        </LoadingButton>
        <Button fullWidth onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePopup;
