import { NetworkStatus, gql, useLazyQuery, useMutation } from '@apollo/client';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, Grid, GridProps, InputAdornment, Typography } from '@mui/material';
import { COMPETITION_CREATE_SUCCESS, COMPETITION_UPDATE_SUCCESS } from 'data/notificationsConst';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CompetitionType, Lead } from 'types/common';

import GroupedAccordion from 'components/Accordion/GroupedAccordion';
import Fieldset from 'components/Fieldset';
import Selection from 'components/Inputs/Selection';
import TextField from 'components/Inputs/TextField';
import UploadInput from 'components/Inputs/UploadInput';

import { deepEqual } from 'utils/common';

import CompetitionDeletePopup from './CompetitionDeletePopup';

type CompetitionProps = {
  initialData:
    | {
        competitions: {
          _id: string;
          competitionName: string;
          competitionValue: number;
          competitionQuoteImage: string[];
        }[];
        underCompetition: boolean;
      }
    | Record<string, any>;
};

type UpdateLeadMutationResponse = {
  updateLead: Lead;
};

type UpdateLeadMutationVariables = {
  input: {
    _id: string;
    underCompetition: boolean;
  };
};

type CreateLeadCompetitionMutationResponse = {
  createLeadCompetition: CompetitionType;
};

const CREATE_LEAD_COMPETITION_MUTATION = gql`
  mutation CreateLeadCompetition($input: CreateLeadCompetitionInput!) {
    createLeadCompetition(input: $input) {
      _id
      competitionName
      competitionValue
      competitionQuoteImage
    }
  }
`;

const GET_LEAD_COMPETITIONS_QUERY = gql`
  query GetLeadById($id: ID!) {
    getLeadById(_id: $id) {
      _id
      competitions {
        _id
        competitionName
        competitionValue
        competitionQuoteImage
      }
    }
  }
`;

const UPDATE_LEAD_COMPETITION_MUTATION = gql`
  mutation UpdateLeadCompetition($input: UpdateLeadCompetitionInput!) {
    updateLeadCompetition(input: $input) {
      _id
      competitionName
      competitionValue
      competitionQuoteImage
    }
  }
`;

const UPDATE_LEAD_MUTATION = gql`
  mutation UpdateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      _id
      underCompetition
      competitions {
        _id
        competitionName
        competitionValue
        competitionQuoteImage
      }
    }
  }
`;

const Competition: React.FC<CompetitionProps> = ({ initialData = {} }) => {
  const [formState, setFormState] = useState<Record<string, any>>(initialData);

  const [competitions, setCompetitions] = useState(initialData.competitions);
  const [currOpenCompetition, setCurrOpenCompetition] = useState<number | string>(0);
  const [enableNewCompetition, toggleNewCompetition] = useState(false);
  const [enableDeletePopup, setEnableDeletePopup] = useState({ id: '', status: false });

  const { leadId = '' } = useParams<{ leadId: string }>();

  const [updateLead, { loading: updatingLead }] = useMutation<
    UpdateLeadMutationResponse,
    UpdateLeadMutationVariables
  >(UPDATE_LEAD_MUTATION);

  const [getCompetitions, { networkStatus: leadCompetitionsStatus }] = useLazyQuery(
    GET_LEAD_COMPETITIONS_QUERY,
    {
      variables: {
        id: leadId,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({ ...prev, [fieldName]: val }));
  };

  const handleSubmit = () => {
    if (formState.underCompetition === false) {
      updateLead({
        variables: {
          input: {
            _id: leadId,
            underCompetition: false,
          },
        },
        onCompleted: data => {
          const {
            updateLead: { competitions, underCompetition },
          } = data;

          setFormState({
            underCompetition,
            competitions,
          });

          toast.success(COMPETITION_UPDATE_SUCCESS);
        },
      });
    }
  };

  return (
    <Grid container columnSpacing={2} mt={3}>
      <Grid item xs={12}>
        <Grid mb={1.5} px={0.5}>
          <Divider>
            <Typography variant="subtitle1" fontWeight={600}>
              Competition Details
            </Typography>
          </Divider>
        </Grid>
      </Grid>
      <Grid item xs={12} columnSpacing={2}>
        <Fieldset label="Under Competition">
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Selection
                activeId={formState.underCompetition}
                id={true}
                deSelectable
                label="YES"
                handleChange={value => handleChange('underCompetition', value[0])}
              />
            </Grid>
            <Grid item xs={6}>
              <Selection
                activeId={formState.underCompetition}
                id={false}
                deSelectable
                label="NO"
                handleChange={value => handleChange('underCompetition', value[0])}
              />
            </Grid>
          </Grid>
        </Fieldset>
      </Grid>
      {formState.underCompetition && (
        <Grid item container flexDirection="column">
          {competitions.map((comp: CompetitionType, idx) => (
            <GroupedAccordion
              key={comp._id}
              activeIndex={currOpenCompetition}
              currIndex={idx}
              setActiveIndex={setCurrOpenCompetition}
              actions={[
                <DeleteForeverIcon
                  color="error"
                  onClick={() => setEnableDeletePopup({ id: comp._id, status: true })}
                />,
              ]}
              title={comp.competitionName}
            >
              <Grid px={1} py={2.5}>
                <CompetitionDetails competition={comp} />
              </Grid>
            </GroupedAccordion>
          ))}
          {!enableNewCompetition && (
            <Grid item justifySelf={'flex-start'} mt={2}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  toggleNewCompetition(true);
                  setCurrOpenCompetition(100);
                }}
              >
                Add New Competition
              </Button>
            </Grid>
          )}
          {enableNewCompetition && (
            <GroupedAccordion
              activeIndex={currOpenCompetition}
              currIndex={100}
              setActiveIndex={setCurrOpenCompetition}
              actions={[
                <DeleteForeverIcon color="error" onClick={() => toggleNewCompetition(false)} />,
              ]}
              title="Add New Competition"
            >
              <Grid px={1} py={2.5}>
                <CompetitionDetails
                  onSubmit={newComp => {
                    setCompetitions(prev => [...prev, newComp]);
                    toggleNewCompetition(false);
                  }}
                />
              </Grid>
            </GroupedAccordion>
          )}
        </Grid>
      )}
      {!!!formState.underCompetition && (
        <Grid item container mt={2} direction={'column'}>
          <LoadingButton
            variant="contained"
            size="small"
            disabled={
              formState.underCompetition === initialData.underCompetition ||
              formState.underCompetition != false
            }
            loading={updatingLead}
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </Grid>
      )}
      <CompetitionDeletePopup
        id={enableDeletePopup.id}
        loading={
          leadCompetitionsStatus === NetworkStatus.refetch ||
          leadCompetitionsStatus === NetworkStatus.loading
        }
        onClose={() => setEnableDeletePopup({ id: '', status: false })}
        onDelete={() => {
          setCompetitions(prev => prev.filter(comp => comp._id !== enableDeletePopup.id));
          getCompetitions().then(() => setEnableDeletePopup({ id: '', status: false }));
        }}
        open={enableDeletePopup.status}
      />
    </Grid>
  );
};

type CompetitionDetailsProps = {
  competition?: CompetitionType;
  onSubmit?: (arg: CompetitionType) => void;
} & GridProps;

const CompetitionDetails: React.FC<CompetitionDetailsProps> = ({
  competition,
  onSubmit,
  ...props
}) => {
  const [formState, setFormState] = useState(
    competition ?? {
      _id: '',
      competitionName: '',
      competitionValue: '',
      competitionQuoteImage: [],
    }
  );
  const [disableSubmit, setDisableSubmit] = useState(true);

  const { leadId = '' } = useParams<{ leadId: string }>();

  useEffect(() => {
    const shouldDisableSubmit = () => {
      let flag = false;
      if (deepEqual(competition, formState)) flag = true;
      else if (Number(formState.competitionValue) === competition?.competitionValue) flag = true;
      else if (!formState.competitionName) flag = true;
      else if (!formState.competitionValue) flag = true;
      else if (!formState.competitionQuoteImage.length) flag = true;

      setDisableSubmit(flag);
    };
    shouldDisableSubmit();
  }, [formState]);

  const [createLeadCompetition, { loading: creatingLeadCompetition }] =
    useMutation<CreateLeadCompetitionMutationResponse>(CREATE_LEAD_COMPETITION_MUTATION);

  const [updateLeadCompetition, { loading: updatingLeadCompetition }] = useMutation(
    UPDATE_LEAD_COMPETITION_MUTATION
  );

  const [updateLead, { loading: updatingLead }] = useMutation(UPDATE_LEAD_MUTATION);

  const handleChange = (e: any) => {
    const val = e.target.value;

    setFormState(prev => ({
      ...prev,
      [e.target.name]: val,
    }));
  };

  const handleSubmit = () => {
    if (!!!formState._id) {
      createLeadCompetition({
        variables: {
          input: {
            lead: leadId,
            competitionName: formState.competitionName,
            competitionValue: +formState.competitionValue,
            competitionQuoteImage: formState.competitionQuoteImage,
          },
        },
        onCompleted: newComp => {
          updateLead({
            variables: {
              input: {
                _id: leadId,
                underCompetition: true,
              },
            },
          }).then(() => {
            if (onSubmit) onSubmit(newComp.createLeadCompetition);
          });

          toast.success(COMPETITION_CREATE_SUCCESS);
        },
      });
    } else {
      updateLeadCompetition({
        variables: {
          input: {
            _id: formState._id,
            competitionName: formState.competitionName,
            competitionValue: +formState.competitionValue,
            competitionQuoteImage: formState.competitionQuoteImage,
          },
        },
        onCompleted: _ => {
          toast.success(COMPETITION_UPDATE_SUCCESS);
        },
      });
    }
  };

  return (
    <Grid item container spacing={2} {...props}>
      <Grid item xs={6}>
        <TextField
          label="Competition Name"
          variant="outlined"
          name="competitionName"
          value={formState.competitionName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Competition Quote Value"
          variant="outlined"
          type="number"
          name="competitionValue"
          value={formState.competitionValue}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <b>₹</b>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <UploadInput
          onChange={val =>
            setFormState(prev => ({
              ...prev,
              competitionQuoteImage: val,
            }))
          }
          value={formState.competitionQuoteImage}
          label="Competition Image"
          multiple
          required
        />
      </Grid>

      <Grid item container justifyContent={'space-between'}>
        <LoadingButton
          variant="outlined"
          disabled={disableSubmit}
          loading={creatingLeadCompetition || updatingLead || updatingLeadCompetition}
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default Competition;
