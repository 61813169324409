import { useLazyQuery } from '@apollo/client';
import { FormControl, Grid, TextField } from '@mui/material';
import { areasQuery } from 'graphql/query/area';
import { SiteStatusEnum, StatusEnum } from 'types/common';

import DateRange from 'components/Inputs/DateRange';
import { MultiSelect, Select } from 'components/Inputs/Select';

const SiteFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const handleChange = (fieldName: string, val: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const [getAreas, { data: areas, loading: areasLoading }] = useLazyQuery(areasQuery);

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            value={state.searchTerm ?? ''}
            label="Site Search"
            variant="outlined"
            onChange={e => handleChange('searchTerm', e.target.value)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <DateRange
          value={state.expectedCompletionDateRange ?? [null, null]}
          onChange={val => handleChange('expectedCompletionDateRange', val)}
          title="Expected Completion Date"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Select
          label="Status"
          onChange={val => handleChange('status', val)}
          options={Object.values(StatusEnum).map(o => ({ label: o, value: o }))}
          value={state.status ?? []}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Site Mapping"
          handleChange={val => handleChange('siteMapping', val)}
          options={[
            { label: 'YES', value: true },
            { label: 'NO', value: false },
          ]}
          value={state.siteMapping ?? []}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Site Status"
          handleChange={val => handleChange('siteStatus', val)}
          options={Object.values(SiteStatusEnum).map(o => ({ label: o, value: o }))}
          value={state.siteStatus ?? []}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Area"
          handleChange={val => handleChange('area', val)}
          options={
            areas?.getAreas.map(o => ({
              label: o.name,
              value: o._id,
            })) ?? []
          }
          value={state.area ?? []}
          fetchConfig={{
            fetchFn: getAreas,
            variables: {},
            loading: areasLoading,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SiteFilters;
