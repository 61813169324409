import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import EastIcon from '@mui/icons-material/East';
import { Grid, GridProps, Typography } from '@mui/material';
import { FIELDVISIT_UPDATE_SUCCESS } from 'data/notificationsConst';
import dayjs from 'dayjs';
import {
  GET_USERS_FOR_FIELD_VISIT_ASSIGNMENT,
  GetUsersForFieldVisitAssignmentQueryResponse,
} from 'graphql/query/common';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FieldVisit,
  FieldVisitOutcomeEnum,
  FieldVisitStatusEnum,
  FieldVisitTypeEnum,
  Lead,
  PreferredSlotEnum,
  preferredSlotMap,
} from 'types/common';

import GroupedAccordion from 'components/Accordion/GroupedAccordion';
import { FormInput, FormPanel } from 'components/FormPanel';
import GoBackButton from 'components/GoBackButton';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

import { removeEmptyFields } from 'utils/common';
import { convertLeadsToAutoCompleteOptions, transformCustomerNames } from 'utils/transformFn';

type GetFieldVisitQueryResponse = {
  getFieldVisitById: FieldVisit;
};
type GetFieldVisitQueryVariables = {
  id: string;
};

const GET_FIELD_VISIT_QUERY = gql`
  query GetFieldVisitById($id: ID!) {
    getFieldVisitById(_id: $id) {
      _id
      referenceId
      createdBy {
        _id
        empId
        fname
        lname
      }
      assignedTo {
        _id
        empId
        fname
        lname
      }
      fieldVisitType
      fieldVisitDate
      preferredSlot
      comments
      status
      brand {
        _id
        name
      }
      rescheduledCounter
      nextFieldVisitDate
      fieldVisitOutcome
      lead {
        _id
        referenceId
        customer {
          _id
          referenceId
          fname
          lname
        }
        area {
          _id
          name
        }
      }
    }
  }
`;

type UpdateFieldVisitMutationResponse = {
  updateFieldVisit: FieldVisit;
};

type UpdateFieldVisitMutationVariables = {
  input: {
    _id: string;
    lead: string;
    assignedTo?: string;
    fieldVisitType?: FieldVisitTypeEnum;
    fieldVisitDate?: string;
    preferredSlot?: PreferredSlotEnum;
    comments?: string;
    rescheduledCounter?: number;
    brand?: string;
    fieldVisitOutcome?: FieldVisitOutcomeEnum;
    status?: FieldVisitStatusEnum;
  };
};

const UPDATE_FIELD_VISIT_MUTATION = gql`
  mutation UpdateFieldVisit($input: UpdateFieldVisitInput!) {
    updateFieldVisit(input: $input) {
      _id
      referenceId
      createdBy {
        _id
        empId
        fname
        lname
      }
      assignedTo {
        _id
        empId
        fname
        lname
      }
      fieldVisitType
      fieldVisitDate
      preferredSlot
      comments
      status
      brand {
        _id
        name
      }
      rescheduledCounter
      nextFieldVisitDate
      fieldVisitOutcome
      lead {
        _id
        referenceId
        customer {
          _id
          referenceId
          fname
          lname
        }
        area {
          _id
          name
        }
      }
    }
  }
`;

type GetLeadsQueryResponse = {
  getLeads: Lead[];
};

const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      customer {
        _id
        fname
        lname
      }
      referenceId
    }
  }
`;

const FieldVisitPage = () => {
  const { fieldVisitId = '' } = useParams<{ fieldVisitId: string }>();
  const [activeIndex, setActiveIndex] = useState<number | string>(0);

  const { data: fieldVisit, loading: loadingFieldVisit } = useQuery<
    GetFieldVisitQueryResponse,
    GetFieldVisitQueryVariables
  >(GET_FIELD_VISIT_QUERY, {
    variables: {
      id: fieldVisitId,
    },
    errorPolicy: 'all',
  });
  return (
    <Navbar>
      <GoBackButton title="Field Visit Details " />
      {loadingFieldVisit || !!!fieldVisit ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid container flexDirection="column" pb={4}>
          <GroupedAccordion
            activeIndex={activeIndex}
            currIndex={0}
            setActiveIndex={setActiveIndex}
            title="Field Visit"
          >
            <Grid item xs={12} px={1} py={2}>
              <FieldVisitDetailsForm
                fieldVisitId={fieldVisitId}
                initialData={fieldVisit.getFieldVisitById}
              />
            </Grid>
          </GroupedAccordion>
          <Grid
            container
            flexDirection="column"
            rowGap={1.5}
            my={2}
            p={2}
            border={1}
            borderColor="#d0d0d0"
            borderRadius={2}
          >
            <NextPageLink
              title="Product Details"
              linkTo={`/leads/${fieldVisit.getFieldVisitById.lead._id}/products`}
            />
            <NextPageLink
              title="Lead Status"
              linkTo={`/leads/${fieldVisit.getFieldVisitById.lead._id}/lead-status`}
            />
            <NextPageLink
              title="Customer"
              linkTo={`/customers/${fieldVisit.getFieldVisitById.lead.customer._id}`}
            />
            {/* <NextPageLink title="Previous Field Visit" linkTo="/field-visits" /> */}
          </Grid>
        </Grid>
      )}
    </Navbar>
  );
};

const FieldVisitDetailsForm: React.FC<{ fieldVisitId: string; initialData: FieldVisit }> = ({
  fieldVisitId,
  initialData,
}) => {
  const [getLeads, { loading: loadingLeads, data: leads }] =
    useLazyQuery<GetLeadsQueryResponse>(GET_LEADS_QUERY);

  const [fetchUsers, { data: users, loading: loadingUsers }] =
    useLazyQuery<GetUsersForFieldVisitAssignmentQueryResponse>(
      GET_USERS_FOR_FIELD_VISIT_ASSIGNMENT
    );

  const [updateFieldVisit, { loading: updatingFieldVisit, error }] = useMutation<
    UpdateFieldVisitMutationResponse,
    UpdateFieldVisitMutationVariables
  >(UPDATE_FIELD_VISIT_MUTATION);

  const isFieldVisitClosed = initialData.status === FieldVisitStatusEnum.CLOSED;

  const handleSubmit = (data: any) => {
    const refinedData = removeEmptyFields(data);

    delete refinedData.referenceId;
    delete refinedData.createdBy;

    if (data.fieldVisitDate !== initialData.fieldVisitDate) {
      refinedData.fieldVisitDate = dayjs(data.fieldVisitDate).format('YYYY-MM-DD');
    }

    updateFieldVisit({
      variables: {
        input: {
          _id: fieldVisitId,
          ...refinedData,
        },
      },
      onCompleted: _ => {
        toast.success(FIELDVISIT_UPDATE_SUCCESS);
      },
    });
  };

  return (
    <FormPanel
      error={error}
      loading={updatingFieldVisit}
      onSubmit={handleSubmit}
      submitButtonLabel="Update"
    >
      <FormInput
        fieldName="lead"
        label="Lead"
        type="auto_complete_with_fetch"
        defaultValue={convertLeadsToAutoCompleteOptions([initialData.lead])[0]}
        fullWidth
        autoCompleteConfig={{
          fetchOptionsFn: getLeads,
          loading: loadingLeads,
          options: convertLeadsToAutoCompleteOptions(leads?.getLeads),
          labelWithId: true,
        }}
        validators={{
          required: true,
        }}
        disabled
      />
      <FormInput
        fieldName="referenceId"
        label="Field Visit ID"
        type="string"
        defaultValue={initialData.referenceId}
        disabled
      />
      <FormInput
        fieldName="fieldVisitType"
        label="Field Visit Type"
        type="select"
        defaultValue={initialData.fieldVisitType}
        disabled
        options={Object.values(FieldVisitTypeEnum).map(o => ({ label: o, value: o }))}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="fieldVisitDate"
        label="Field Visit Date"
        type="date"
        dateConfig={{
          disablePast: true,
        }}
        disabled
        defaultValue={!!initialData.fieldVisitDate ? dayjs(initialData.fieldVisitDate) : null}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="preferredSlot"
        label="Preffered Slot"
        type="select"
        disabled
        defaultValue={initialData.preferredSlot}
        options={Object.values(PreferredSlotEnum).map(o => ({
          label: preferredSlotMap[o],
          value: o,
        }))}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="status"
        label="Status"
        type="string"
        defaultValue={initialData.status}
        validators={{
          required: true,
        }}
        disabled
      />
      <FormInput
        fieldName="createdBy"
        label="Created By"
        type="auto_complete_with_fetch"
        defaultValue={transformCustomerNames([initialData.createdBy])[0]}
        disabled
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="assignedTo"
        label="Assigned to"
        type="auto_complete_with_fetch"
        fullWidth
        defaultValue={transformCustomerNames([initialData.assignedTo])[0]}
        autoCompleteConfig={{
          options: transformCustomerNames(users?.fetchUsersForFieldVisitAssignment),
          loading: loadingUsers,
          fetchOptionsFn: fetchUsers,
          labelWithId: true,
        }}
        disabled={isFieldVisitClosed}
      />
      <FormInput
        fieldName="fieldVisitOutcome"
        label="Field Visit Outcome"
        type="select"
        fullWidth
        defaultValue={initialData.fieldVisitOutcome ?? ''}
        options={Object.values(FieldVisitOutcomeEnum).map(o => ({ label: o, value: o }))}
        validators={{
          required: true,
        }}
        disabled={isFieldVisitClosed}
      />
      <FormInput
        fieldName="comments"
        label="Comments"
        type="textarea"
        multiple
        fullWidth
        placeholder="Field Visit Notes"
        defaultValue={initialData.comments ?? ''}
        validators={{
          required: true,
        }}
        disabled={isFieldVisitClosed}
      />
    </FormPanel>
  );
};

type NextPageLinkProps = {
  title: string;
  linkTo: string;
} & GridProps;

const NextPageLink: React.FC<NextPageLinkProps> = ({ title, linkTo, ...props }) => {
  const navigate = useNavigate();
  return (
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(linkTo)}
      {...props}
    >
      <Typography variant="body2" fontSize={16} fontWeight={700}>
        {title}
      </Typography>
      <EastIcon color="primary" fontSize="medium" />
    </Grid>
  );
};

export default FieldVisitPage;
