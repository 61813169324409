import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid, useMediaQuery } from '@mui/material';
import { customersName } from 'graphql/query/area';
import {
  GET_CRM_USERS_QUERY,
  GET_FIELD_SALES_USERS,
  GET_STORE_ASSOCIATE_USERS,
  GetCRMUsersQueryResponse,
  GetFieldSalesUsersQueryResponse,
  GetStoreAssociateUsersQueryResponse,
} from 'graphql/query/common';
import {
  GET_STORE_DETAILS_BY_STORE_ASSOCIATE,
  GetStoreDetailsByStoreAssociateResponse,
  GetStoreDetailsByStoreAssociateVariables,
  LEAD_SOURCE_NAMES_QUERY,
  LeadSourceNamesQueryResponse,
} from 'graphql/query/lead';
import {
  PROJECT_NAMES_QUERY,
  ProjectNamesQueryResponse,
  ProjectNamesQueryVariables,
} from 'graphql/query/project';
import { useEffect, useMemo, useState } from 'react';
import { AutoCompleteOption, LeadCategoryEnum, RoleEnum } from 'types/common';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';

import { transformCustomerNames } from 'utils/transformFn';

type CreateLeadFormProps = {
  handleSubmit: (data: any) => void;
  initialData: Record<string, string>;
  loading: boolean;
};

type FormStateProps = {
  productCategory: LeadCategoryEnum | '';
  source: AutoCompleteOption;
  customer: AutoCompleteOption;
  project?: AutoCompleteOption;
  callCenterOwner?: AutoCompleteOption;
  store?: AutoCompleteOption;
  storeLead?: AutoCompleteOption;
  storeAssociate?: AutoCompleteOption;
  fieldSalesAssociate?: AutoCompleteOption;
};

const CreateLeadForm: React.FC<CreateLeadFormProps> = ({ handleSubmit, loading, initialData }) => {
  const [formState, setFormState] = useState<FormStateProps>({
    productCategory: '',
    source: { _id: '', name: '', referenceId: '' },
    customer: { _id: '', name: '', referenceId: '' },
    callCenterOwner: { _id: '', name: '', referenceId: '' },
  });

  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const [getCustomers, { data: customers, loading: customersLoading }] =
    useLazyQuery(customersName);

  const [getProjects, { data: projects, loading: projectsLoading }] = useLazyQuery<
    ProjectNamesQueryResponse,
    ProjectNamesQueryVariables
  >(PROJECT_NAMES_QUERY);

  const [getStoresDetails, { data: storesDetails, loading: loadingStoreDetails }] = useLazyQuery<
    GetStoreDetailsByStoreAssociateResponse,
    GetStoreDetailsByStoreAssociateVariables
  >(GET_STORE_DETAILS_BY_STORE_ASSOCIATE);

  const [getCRMUsers, { data: crmUsers, loading: loadingCrmUsers }] =
    useLazyQuery<GetCRMUsersQueryResponse>(GET_CRM_USERS_QUERY);

  const [getStoreAssociates, { data: storeAssociates, loading: loadingStoreAssociates }] =
    useLazyQuery<GetStoreAssociateUsersQueryResponse>(GET_STORE_ASSOCIATE_USERS);

  const [
    getFieldSalesAssociates,
    { data: fieldSalesAssociates, loading: loadingFieldSalesAssociates },
  ] = useLazyQuery<GetFieldSalesUsersQueryResponse>(GET_FIELD_SALES_USERS);

  const [getLeadSources, { data: leadSources, loading: leadSourcesLoading }] =
    useLazyQuery<LeadSourceNamesQueryResponse>(LEAD_SOURCE_NAMES_QUERY);

  useEffect(() => {
    const extractInitialData = () => {
      const res = {};
      Object.keys(initialData).forEach(item => {
        res[item] = initialData[item];
      });

      setFormState(prev => ({
        ...prev,
        ...res,
      }));
    };

    extractInitialData();
  }, []);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const selectedStore = useMemo(() => {
    if (storesDetails?.getStoreDetailsByStoreAssociate) {
      const { store, storeLead } = storesDetails.getStoreDetailsByStoreAssociate;
      return {
        store: store ? `${store.name} (${store.referenceId})` : '',
        storeLead: storeLead
          ? `${storeLead.fname} ${storeLead?.lname ?? ''} (${storeLead.empId})`
          : '',
      };
    }
    return {
      store: '',
      storeLead: '',
    };
  }, [storesDetails]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(formState);
      }}
    >
      <Grid container mt={1.5} mb={4} columnSpacing={1.5} rowGap={2}>
        <Grid item xs={6}>
          <Select
            label="Lead Category"
            fullWidth
            required
            value={formState.productCategory}
            onChange={val => handleChange('productCategory', val)}
            options={Object.values(LeadCategoryEnum).map(o => ({ label: o, value: o }))}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            fetch={getLeadSources}
            handleChange={val => handleChange('source', val)}
            label="Source"
            loading={leadSourcesLoading}
            options={leadSources?.getLeadSources ?? []}
            value={formState.source}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteWithFetch
            fetch={getCustomers}
            handleChange={val => {
              handleChange('customer', val);
              handleChange('project', null);
            }}
            label="Customer"
            loading={customersLoading}
            labelWithId
            options={transformCustomerNames(customers?.getCustomers) ?? []}
            value={formState.customer}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            fetch={getProjects}
            handleChange={val => handleChange('project', val)}
            label="Project"
            loading={projectsLoading}
            options={projects?.getProjects ?? []}
            disabled={!!!formState.customer.referenceId}
            labelWithId
            value={formState.project ?? { _id: '', name: '', referenceId: '' }}
            variables={{
              filter: {
                searchTerm: formState.customer.referenceId,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            fetch={getCRMUsers}
            handleChange={val => handleChange('callCenterOwner', val)}
            label="CRM"
            loading={loadingCrmUsers}
            options={transformCustomerNames(crmUsers?.fetchCRMUsers) ?? []}
            value={formState.callCenterOwner ?? { _id: '', name: '', referenceId: '' }}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            fetch={getFieldSalesAssociates}
            handleChange={val => handleChange('fieldSalesAssociate', val)}
            label="Field Sales Associates"
            loading={loadingFieldSalesAssociates}
            options={transformCustomerNames(fieldSalesAssociates?.fetchFieldSalesUsers) ?? []}
            value={formState.fieldSalesAssociate ?? { _id: '', name: '', referenceId: '' }}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            fetch={getStoreAssociates}
            label="Store Associate"
            loading={loadingStoreAssociates}
            options={transformCustomerNames(storeAssociates?.fetchStoreAssociateUsers) ?? []}
            value={formState.storeAssociate ?? { _id: '', name: '', referenceId: '' }}
            handleChange={val => {
              handleChange('storeAssociate', val);
              !!val?._id &&
                getStoresDetails({
                  variables: {
                    id: val._id,
                  },
                });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            value={!!formState.storeAssociate?._id ? selectedStore.store : ''}
            label="Store"
            loading={loadingStoreDetails}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            value={!!formState.storeAssociate?._id ? selectedStore.storeLead : ''}
            label="Store Lead"
            loading={loadingStoreDetails}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="end">
          <LoadingButton
            type="submit"
            fullWidth={isMobileScreen}
            loading={loading}
            disabled={loading}
            size={isMobileScreen ? 'medium' : 'large'}
            variant="outlined"
          >
            Next
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateLeadForm;
