import { Box } from '@mui/material';
import { FC } from 'react';

import Sidebar from 'components/Sidebar';

// import banners from 'media/icons/Banner.svg';
// import cheque from 'media/icons/Cheque_pen.svg';
// import FAQ from 'media/icons/FAQ.svg';
// import addPincode from 'media/icons/add-pincode.svg';
// import bagHandleOutline from 'media/icons/bag-handle-outline.svg';
// import blogIcon from 'media/icons/blog-outline.svg';
// import blogs from 'media/icons/blogs.svg';
// import brandsIcon from 'media/icons/brands.svg';
// import bulkCreateProducts from 'media/icons/bulk-create-products.svg';
import sitesIcon from 'media/icons/construction.svg';
import fieldVisit from 'media/icons/detective-agent.svg';
// import creditApproval from 'media/icons/collection-approval.svg';
// import coupons from 'media/icons/coupons.svg';
// import createOrder from 'media/icons/createorders.svg';
// import createProduct from 'media/icons/createproducts.svg';
// import createQuotation from 'media/icons/createquotation.svg';
// import cubeOutline from 'media/icons/cube-outline.svg';
// import dealsOfTheDay from 'media/icons/dealsoftheday.svg';
// import featuredBrands from 'media/icons/featuredbrands.svg';
import homeOutline from 'media/icons/home-outline.svg';
// import masterOrder from 'media/icons/masterorder.svg';
// import orderB2B from 'media/icons/orderb2b.svg';
// import orderB2C from 'media/icons/orderb2c.svg';
import peopleOutline from 'media/icons/people-outline.svg';
import projectIcon from 'media/icons/project.svg';
// import personAddOutline from 'media/icons/person-add-outline.svg';
import leadsIcon from 'media/icons/salesFunnel.svg';
// import ribbonOutline from 'media/icons/ribbon-outline.svg';
import settingsIcon from 'media/icons/settings.svg';
import followups from 'media/icons/stairs.svg';

// import storefrontOutline from 'media/icons/storefront-outline.svg';
// import uploadRequests from 'media/icons/uploadrequest.svg';

// import getSidebarTabsForRole from 'utils/sidebarTabs';

const tabs = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    icon: homeOutline,
    route: '/',
  },
  {
    key: 'customers',
    label: 'Customers',
    icon: peopleOutline,
    route: '/customers',
  },
  {
    key: 'leads',
    label: 'Leads',
    icon: leadsIcon,
    route: '/leads',
  },
  {
    key: 'projects',
    label: 'Projects',
    icon: projectIcon,
    route: '/projects',
  },
  {
    key: 'sites',
    label: 'Sites',
    icon: sitesIcon,
    route: '/sites',
  },
  {
    key: 'followups',
    label: 'Followups',
    icon: followups,
    route: '/followups',
  },
  {
    key: 'field-visits',
    label: 'Field Visits',
    icon: fieldVisit,
    route: '/field-visits',
  },
  {
    key: 'settings',
    label: 'Settings',
    icon: settingsIcon,
    route: '/settings',
  },
];

const ResponsiveSidebarLayout: FC<{ children: any }> = props => {
  const renderContent = () => {
    return (
      <>
        <Sidebar tabs={tabs} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: 2,
            pt: 1,
            width: '100%',
            height: '100%',
            overflowX: 'hidden',
          }}
        >
          <div>{props.children}</div>
        </Box>
      </>
    );
  };

  return <Box sx={{ display: 'flex' }}>{renderContent()}</Box>;
};

export default ResponsiveSidebarLayout;
