import { useLazyQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  MKWCriteriasQueryResponse,
  MKWCriteriasQueryVariables,
  MKWPropertyStatusQueryResponse,
  MKWPropertyStatusQueryVariables,
  MKWPropertyTypesQueryResponse,
  MKWPropertyTypesQueryVariables,
  MKWShapeOfKitchensQueryResponse,
  MKWShapeOfKitchensQueryVariables,
  MKWSizesQueryResponse,
  MKWSizesQueryVariables,
  MKWSubCategoriesQueryResponse,
  MKWTypesOfWardrobeQueryResponse,
  MKWTypesOfWardrobeQueryVariables,
  MKWUsagesQueryResponse,
  MKWUsagesQueryVariables,
  MKW_CRITERIAS_QUERY,
  MKW_PROPERTY_STATUS_QUERY,
  MKW_PROPERTY_TYPES_QUERY,
  MKW_SHAPE_OF_KITCHENS_QUERY,
  MKW_SIZES_QUERY,
  MKW_SUB_CATEGORIES_QUERY,
  MKW_TYPES_OF_WARDROBE_QUERY,
  MKW_USAGES_QUERY,
} from 'graphql/query/product/mkwProduct';
import { StatusEnum } from 'types/common';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';

const MKWProductFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const [getSubCategories, { data: subCategories, loading: loadingSubCategories }] =
    useLazyQuery<MKWSubCategoriesQueryResponse>(MKW_SUB_CATEGORIES_QUERY);

  const [getPropertyTypes, { data: propertyTypes, loading: loadingPropertyTypes }] = useLazyQuery<
    MKWPropertyTypesQueryResponse,
    MKWPropertyTypesQueryVariables
  >(MKW_PROPERTY_TYPES_QUERY);

  const [getPropertiesStatus, { data: propertiesStatus, loading: loadingPropertiesStatus }] =
    useLazyQuery<MKWPropertyStatusQueryResponse, MKWPropertyStatusQueryVariables>(
      MKW_PROPERTY_STATUS_QUERY
    );

  const [getUsages, { data: usages, loading: loadingUsages }] = useLazyQuery<
    MKWUsagesQueryResponse,
    MKWUsagesQueryVariables
  >(MKW_USAGES_QUERY);

  const [getCriterias, { data: criterias, loading: loadingCriterias }] = useLazyQuery<
    MKWCriteriasQueryResponse,
    MKWCriteriasQueryVariables
  >(MKW_CRITERIAS_QUERY);

  const [getShapeOfKitchens, { data: shapeOfKitchens, loading: loadingShapeOfKitchens }] =
    useLazyQuery<MKWShapeOfKitchensQueryResponse, MKWShapeOfKitchensQueryVariables>(
      MKW_SHAPE_OF_KITCHENS_QUERY
    );

  const [getTypesOfWardrobe, { data: typesOfWardrobe, loading: loadingTypesOfWardrobe }] =
    useLazyQuery<MKWTypesOfWardrobeQueryResponse, MKWTypesOfWardrobeQueryVariables>(
      MKW_TYPES_OF_WARDROBE_QUERY
    );

  const [getSizes, { data: sizes, loading: loadingSizes }] = useLazyQuery<
    MKWSizesQueryResponse,
    MKWSizesQueryVariables
  >(MKW_SIZES_QUERY);

  const handleChange = (fieldName: string, value: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Sub Category"
          fetch={getSubCategories}
          handleChange={val => handleChange('subCategory', val)}
          loading={loadingSubCategories}
          options={(subCategories?.getMkwProducts ?? []).map(p => p.subCategory)}
          value={state.subCategory ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>

      <Grid item xs={12}>
        <AutocompleteWithFetch
          label="Property Type"
          fetch={getPropertyTypes}
          handleChange={val => handleChange('propertyType', val)}
          loading={loadingPropertyTypes}
          options={(propertyTypes?.getMkwProducts ?? []).map(p => p.propertyType)}
          value={state.propertyType ?? { _id: '', name: '' }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Property Status"
          fetch={getPropertiesStatus}
          handleChange={val => handleChange('propertyStatus', val)}
          loading={loadingPropertiesStatus}
          options={(propertiesStatus?.getMkwProducts ?? []).map(p => p.propertyStatus)}
          value={state.propertyStatus ?? { _id: '', name: '' }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Usage"
          fetch={getUsages}
          handleChange={val => handleChange('usage', val)}
          loading={loadingUsages}
          options={(usages?.getMkwProducts ?? []).map(p => p.usage)}
          value={state.usage ?? { _id: '', name: '' }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Criteria"
          fetch={getCriterias}
          handleChange={val => handleChange('criteria', val)}
          loading={loadingCriterias}
          options={(criterias?.getMkwProducts ?? []).map(p => p.criteria)}
          value={state.criteria ?? { _id: '', name: '' }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Shape of Kitchen"
          fetch={getShapeOfKitchens}
          handleChange={val => handleChange('shapeOfKitchen', val)}
          loading={loadingShapeOfKitchens}
          options={(shapeOfKitchens?.getMkwProducts ?? []).map(p => p.shapeOfKitchen)}
          value={state.shapeOfKitchen ?? { _id: '', name: '' }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Type of Wardrobe"
          fetch={getTypesOfWardrobe}
          handleChange={val => handleChange('typeOfWardrobe', val)}
          loading={loadingTypesOfWardrobe}
          options={(typesOfWardrobe?.getMkwProducts ?? []).map(p => p.typeOfWardrobe)}
          value={state.typeOfWardrobe ?? { _id: '', name: '' }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Size"
          fetch={getSizes}
          handleChange={val => {
            handleChange('size', val);
          }}
          loading={loadingSizes}
          options={(sizes?.getMkwProducts ?? []).map(p => p.size)}
          value={state.size ?? { _id: '', name: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          label="Status"
          value={state.status}
          onChange={val => setState(prev => ({ ...prev, status: val }))}
          options={Object.values(StatusEnum).map(o => ({ label: o, value: o }))}
        />
      </Grid>
    </Grid>
  );
};

export default MKWProductFilters;
