import { createContext } from 'react';

interface UserContextState {
  isAuthenticated: boolean;
}

interface UserContextData {
  userState: UserContextState;
  userDispatch: ({
    type,
    payload,
  }: {
    type: 'USER_AUTHENTICATED' | 'USER_UNAUTHENTICATED';
    payload: any;
  }) => void;
}

const UserContext = createContext({} as UserContextData);

export default UserContext;
