import { gql, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import EastIcon from '@mui/icons-material/East';
import { Button, Grid, GridProps, Typography } from '@mui/material';
import { LEAD_UPDATE_SUCCESS } from 'data/notificationsConst';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Lead as LeadType } from 'types/common';

import GroupedAccordion from 'components/Accordion/GroupedAccordion';
import ProjectAdd from 'components/AddProject';
import GoBackButton from 'components/GoBackButton';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import TileList from 'components/TileList';

import LeadDetailsForm from './LeadDetailsForm';

type GetLeadQueryResponse = {
  getLeadById: LeadType;
};

type GetLeadQueryVariables̉ = {
  id: string;
};

type UpdateLeadMutationResponse = {
  updateLead: LeadType;
};

const GET_LEAD_QUERY = gql`
  query GetLeadById($id: ID!) {
    getLeadById(_id: $id) {
      _id
      referenceId
      project {
        _id
        referenceId
        name
        type
        status
        constructionType
        customer {
          _id
          referenceId
          fname
          lname
        }
        site {
          _id
          referenceId
          name
        }
      }
      customer {
        _id
        referenceId
        fname
        lname
        gender
        email
        isVerified
        mobile
        gender
        status
        prefferedLanguage
        customerType {
          _id
          name
        }
      }
      source {
        _id
        name
      }
      callCenterOwner {
        _id
        empId
        fname
        lname
      }
      storeAssociate {
        _id
        empId
        fname
        lname
      }
      fieldSalesAssociate {
        _id
        empId
        fname
        lname
      }
      productCategory
      underCompetition
      funnelState
      status
      fieldVisits {
        _id
        referenceId
        lead {
          _id
          referenceId
          area {
            _id
            name
          }
          project {
            referenceId
          }
          customer {
            _id
            fname
            lname
            referenceId
          }
        }
        createdBy {
          _id
          empId
          fname
          lname
        }
        assignedTo {
          _id
          empId
          fname
          lname
        }
        fieldVisitType
        fieldVisitDate
        preferredSlot
        comments
        status
        rescheduledCounter
        fieldVisitOutcome
        brand {
          _id
          name
        }
      }
      followups {
        _id
        referenceId
        callStatus
        followupOutcome
        followupNotes
        preferredSlot
        status
        followupDate
        followupType
        rescheduledCounter
        lead {
          _id
          referenceId
          customer {
            _id
            referenceId
            fname
            lname
          }
          area {
            _id
            name
          }
        }
      }
    }
  }
`;

const UPDATE_LEAD_MUTATION = gql`
  mutation UpdateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      _id
      project {
        _id
        referenceId
        name
        type
        status
        constructionType
        customer {
          _id
          referenceId
          fname
          lname
        }
        site {
          _id
          referenceId
          name
        }
      }
      customer {
        _id
        referenceId
        fname
        lname
        gender
        email
        isVerified
        mobile
        gender
        status
        prefferedLanguage
        customerType {
          _id
          name
        }
      }
      callCenterOwner {
        _id
        fname
        lname
        empId
      }
      fieldSalesAssociate {
        _id
        empId
        fname
        lname
      }
      funnelState
      productCategory
      referenceId
      status
      storeAssociate {
        _id
        fname
        lname
        empId
      }
      source {
        _id
        name
      }
    }
  }
`;

const Lead = () => {
  const [activeIndex, setActiveIndex] = useState<number | string>(0);
  const { leadId = '' } = useParams<{ leadId: string }>();
  const navigate = useNavigate();

  const { data: lead, loading: loadingLead } = useQuery<
    GetLeadQueryResponse,
    GetLeadQueryVariables̉
  >(GET_LEAD_QUERY, {
    variables: {
      id: leadId,
    },
  });

  const [updateLead, { loading: updatingLead }] = useMutation<UpdateLeadMutationResponse>(
    UPDATE_LEAD_MUTATION,
    {
      variables: {
        input: {
          _id: leadId,
        },
      },
    }
  );

  const handleUpdateLead = (updatedFields: any) => {
    updatedFields._id = leadId;

    updateLead({
      variables: {
        input: updatedFields,
      },
      onCompleted: _ => {
        toast.success(LEAD_UPDATE_SUCCESS);
      },
    });
  };

  return (
    <Navbar>
      {loadingLead && <GoBackButton title={`Lead Details`} />}
      {loadingLead || !lead ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <>
          <GoBackButton
            title={`Lead Details (${lead.getLeadById.productCategory})`}
            goBackAction={() => navigate('/leads')}
          />
          <Grid
            container
            flexDirection="column"
            rowGap={1.5}
            mt={1.5}
            p={2}
            border={1}
            borderColor="#d0d0d0"
            borderRadius={2}
            boxShadow="#6b6a6a59 0 2px 6px"
          >
            <NextPageLink title="Product Details" linkTo="products" />
            <NextPageLink title="Competition/Parchi" linkTo="products/competition" />
            <NextPageLink title="Lead Status" linkTo="lead-status" />
          </Grid>
          <Grid container flexDirection="column" mb={5}>
            <GroupedAccordion
              title={`Lead - ${lead.getLeadById.status}`}
              activeIndex={activeIndex}
              currIndex={0}
              setActiveIndex={setActiveIndex}
            >
              <Grid item xs={12} px={1} py={2}>
                <LeadDetailsForm
                  handleSubmit={handleUpdateLead}
                  initialData={lead?.getLeadById}
                  loading={updatingLead}
                />
              </Grid>
            </GroupedAccordion>
            <GroupedAccordion
              title="Project"
              activeIndex={activeIndex}
              currIndex={2}
              setActiveIndex={setActiveIndex}
            >
              {!!lead.getLeadById?.project ? (
                <Grid item xs={12} py={1.5} px={1.5}>
                  <TileList list={[lead.getLeadById?.project]} type="project" />
                </Grid>
              ) : (
                <Grid item xs={12} pt={2} px={1.5}>
                  <ProjectAdd
                    customerInfo={lead.getLeadById.customer}
                    showExistingButton
                    onSubmit={handleUpdateLead}
                    loading={updatingLead}
                  />
                </Grid>
              )}
            </GroupedAccordion>
            <GroupedAccordion
              title="Customer"
              activeIndex={activeIndex}
              currIndex={3}
              setActiveIndex={setActiveIndex}
            >
              <Grid item xs={12} py={1.5} px={1.5}>
                <TileList list={[lead.getLeadById.customer]} type="customer" />
              </Grid>
            </GroupedAccordion>
            <GroupedAccordion
              title="Field Visits"
              activeIndex={activeIndex}
              currIndex={4}
              setActiveIndex={setActiveIndex}
            >
              <Grid px={1.5} py={1.5}>
                {!!lead.getLeadById.fieldVisits.length && (
                  <Grid item xs={12} mb={2}>
                    <TileList list={lead.getLeadById.fieldVisits} type="fieldVisit" />
                  </Grid>
                )}
                <Grid item xs={12} container>
                  <Button
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ fontSize: '0.8rem' }}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      const data = {
                        _id: lead.getLeadById._id,
                        referenceId: lead.getLeadById.referenceId,
                        customer: {
                          fname: lead.getLeadById.customer.fname,
                          lname: lead.getLeadById.customer.lname,
                        },
                      };
                      navigate(
                        `/field-visits/create-field-visit?data=${JSON.stringify({ lead: data })}`
                      );
                    }}
                  >
                    Create New Field Visit
                  </Button>
                </Grid>
              </Grid>
            </GroupedAccordion>

            <GroupedAccordion
              title="Followups"
              activeIndex={activeIndex}
              currIndex={5}
              setActiveIndex={setActiveIndex}
            >
              <Grid px={1.5} py={1.5}>
                {!!lead.getLeadById.followups.length && (
                  <Grid item xs={12} mb={2}>
                    <TileList list={lead.getLeadById.followups} type="followup" />
                  </Grid>
                )}
                <Grid item xs={12} container>
                  <Button
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ fontSize: '0.8rem' }}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      const data = {
                        _id: lead.getLeadById._id,
                        referenceId: lead.getLeadById.referenceId,
                        customer: {
                          fname: lead.getLeadById.customer.fname,
                          lname: lead.getLeadById.customer.lname,
                        },
                      };
                      navigate(`/followups/create-followup?data=${JSON.stringify({ lead: data })}`);
                    }}
                  >
                    Create New Followup
                  </Button>
                </Grid>
              </Grid>
            </GroupedAccordion>
          </Grid>
        </>
      )}
    </Navbar>
  );
};

type NextPageLinkProps = {
  title: string;
  linkTo: string;
} & GridProps;

const NextPageLink: React.FC<NextPageLinkProps> = ({ title, linkTo, ...props }) => {
  const navigate = useNavigate();
  return (
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(linkTo)}
      {...props}
    >
      <Typography variant="body2" fontSize={16} fontWeight={700}>
        {title}
      </Typography>
      <EastIcon color="primary" fontSize="medium" />
    </Grid>
  );
};

export default Lead;
