import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React from 'react';
import { AutoCompleteOption } from 'types/common';

type AutocompleteWithFetchProps = {
  fetch: LazyQueryExecFunction<any, OperationVariables> | (() => void);
  label: string;
  handleChange: (arg: any) => void;
  disabled?: boolean;
  loading: boolean;
  variables?: any;
  required?: boolean;
  labelWithId?: boolean;
  error?: boolean;
  onBlur?: () => void;
} & (
  | {
      value: AutoCompleteOption;
      options: AutoCompleteOption[];
    }
  | {
      value: string;
      options: string[];
    }
);

const AutocompleteWithFetch: React.FC<AutocompleteWithFetchProps> = ({
  value,
  label,
  handleChange,
  disabled = false,
  required = false,
  variables = {},
  labelWithId = false,
  error = false,
  onBlur,
  ...props
}) => {
  const [open, setOpen] = React.useState(false);

  const transformOptions = (
    options: string[] | { name: string; _id: string }[]
  ): AutoCompleteOption[] => {
    if (Array.isArray(options) && options.length > 0 && typeof options[0] === 'string') {
      return options.map(option => ({ name: option, _id: option }));
    } else if (Array.isArray(options) && options.length > 0 && typeof options[0] === 'object') {
      return options as AutoCompleteOption[];
    }
    return [];
  };

  return (
    <>
      <Autocomplete
        open={open}
        onOpen={() => {
          props.fetch({
            variables,
          });
          setOpen(true);
        }}
        onClose={() => setOpen(false)}
        disabled={disabled}
        value={typeof value === 'string' ? { _id: value, name: value, referenceId: value } : value}
        getOptionLabel={option => {
          if (!!option && !!!option._id) return '';

          if (labelWithId && !!option.referenceId) {
            return `${option.name} (${option.referenceId})`;
          }
          return option.name;
        }}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        fullWidth
        includeInputInList
        onChange={(e, val) => handleChange(val ? val : { name: '', _id: '' })}
        options={props.options ? transformOptions(props.options) : []}
        loading={props.loading}
        renderInput={params => (
          <TextField
            required={required}
            {...params}
            error={error}
            onBlur={onBlur ? () => onBlur() : undefined}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};
export default AutocompleteWithFetch;
