import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

import Transition from 'components/Transition';

const DELETE_LEAD_COMPETITION = gql`
  mutation DeleteLeadCompetition($id: ID!) {
    deleteLeadCompetition(_id: $id)
  }
`;

const CompetitionDeletePopup = ({
  open,
  onClose,
  id,
  loading,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  id: string;
  loading: boolean;
  onDelete: () => void;
}) => {
  const [deleteLeadCompetition, { loading: deletingLeadCompetition }] =
    useMutation(DELETE_LEAD_COMPETITION);

  const handleDelete = () => {
    deleteLeadCompetition({
      variables: {
        id,
      },
      onCompleted: _ => {
        onDelete();
      },
    });
  };
  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle sx={{ fontWeight: 600, fontSize: '1.1rem', pb: 1.1 }}>
        Do you really want to delete this
      </DialogTitle>
      <DialogActions>
        <LoadingButton
          fullWidth
          onClick={handleDelete}
          variant="contained"
          loading={deletingLeadCompetition || loading}
        >
          Delete
        </LoadingButton>
        <Button fullWidth onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompetitionDeletePopup;
