import { Box, Grid, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { FC } from 'react';

import SidebarLayout from 'layouts/SidebarLayout';

import logOutOutline from 'media/icons/log-out-outline.svg';

import { logout } from 'utils/auth';

import theme from './theme.module.scss';

const Navbar: FC<{ title?: string; actions?: any[]; children: React.ReactNode }> = ({
  title,
  actions,
  children,
}) => {
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const renderContent = () => (
    <>
      <Grid
        className={theme.desktopContainer}
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        position={'fixed'}
        top={0}
      >
        <Grid item xs={2} position={'fixed'} right={'1rem'}>
          <Tooltip title="Logout">
            <IconButton onClick={() => logout()}>
              <img className={theme.logo} src={logOutOutline} alt="logout" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {title && (
        <Grid position={'fixed'} top={'3.5rem'} container direction="column" zIndex={10}>
          <div className={theme.headerContainer}>
            <span className={theme.title}>{title}</span>
            <Grid className={theme.title} position={'fixed'} right={'1rem'}>
              {actions && actions.length
                ? actions.map((action, idx) => <div key={idx}>{action}</div>)
                : null}
            </Grid>
          </div>
        </Grid>
      )}
    </>
  );

  const renderDesktopView = () => (
    <>
      <Grid
        className={theme.desktopContainer}
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        position={'fixed'}
        top={0}
        ml={'-2rem'}
      >
        <Grid item xs={2} position={'fixed'} right={'1rem'}>
          <Tooltip title="Logout">
            <IconButton onClick={() => logout()}>
              <img className={theme.logo} src={logOutOutline} alt="logout" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {title && (
        <Grid
          position={'fixed'}
          top={'3.5rem'}
          container
          direction="column"
          zIndex={10}
          ml={'-2rem'}
        >
          <div className={theme.headerContainer}>
            <span className={theme.title}>{title}</span>
            <Grid className={theme.title} position={'fixed'} right={'1rem'}>
              {actions && actions.length
                ? actions.map((action, idx) => <div key={idx}>{action}</div>)
                : null}
            </Grid>
          </div>
        </Grid>
      )}
    </>
  );

  return (
    <>
      {isMobileScreen ? (
        <Box mt={title ? '6rem' : '3.5rem'}>
          {renderContent()}
          <SidebarLayout>{children}</SidebarLayout>
        </Box>
      ) : (
        <SidebarLayout>
          {renderDesktopView()}
          <Box mt={title ? '6rem' : '3.5rem'}>{children}</Box>
        </SidebarLayout>
      )}
    </>
  );
};

export default Navbar;
