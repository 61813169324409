import { gql } from '@apollo/client';
import { AutoCompleteOption } from 'types/common';

export type TFLSubCategoriesQueryResponse = {
  getTflProducts: {
    subCategory: string;
  }[];
};

export const TFL_SUB_CATEGORIES_QUERY = gql`
  query GetTflProducts {
    getTflProducts {
      subCategory
    }
  }
`;

export type TFLGroupNamesQueryResponse = {
  getTflProducts: {
    group: string;
  }[];
};

export type TFLGroupNamesQueryVariables = {
  filter: {
    subCategory: string;
  };
};

export const TFL_GROUP_NAMES_QUERY = gql`
  query GetTflProducts($filter: TflProductFilter) {
    getTflProducts(filter: $filter) {
      group
    }
  }
`;

export type TFLFinishedQueryResponse = {
  getTflProducts: {
    finish: string;
  }[];
};

export type TFLFinishedQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
  };
};

export const TFL_FINISHED_QUERY = gql`
  query GetTflProducts($filter: TflProductFilter) {
    getTflProducts(filter: $filter) {
      finish
    }
  }
`;

export type TFLSizesQueryResponse = {
  getTflProducts: {
    size: string;
  }[];
};

export type TFLSizesQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    finish: string;
  };
};

export const TFL_SIZES_QUERY = gql`
  query GetTflProducts($filter: TflProductFilter) {
    getTflProducts(filter: $filter) {
      size
    }
  }
`;

export type TFLUnitsOfMeasureQueryResponse = {
  getTflProducts: {
    unitOfMeasure: string;
  }[];
};

export type TFLUnitsOfMeasureQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    size: string;
    finish: string;
  };
};

export const TFL_UNITS_OF_MEASURE_QUERY = gql`
  query GetTflProducts($filter: TflProductFilter) {
    getTflProducts(filter: $filter) {
      unitOfMeasure
    }
  }
`;

export type TFLBrandNamesQueryResponse = {
  getTflProducts: {
    _id: string;
    price: number;
    brand: AutoCompleteOption;
  }[];
};

export type TFLBrandNamesQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    size: string;
    unitOfMeasure: string;
    finish: string;
  };
};

export const TFL_BRAND_NAMES_QUERY = gql`
  query GetTflProducts($filter: TflProductFilter) {
    getTflProducts(filter: $filter) {
      _id
      price
      brand {
        _id
        name
      }
    }
  }
`;
