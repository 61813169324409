import { gql, useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { LEAD_UPDATE_SUCCESS } from 'data/notificationsConst';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FunnelStateEnum,
  Lead,
  LeadLostReasonEnum,
  LeadStatusEnum,
  PriceDiscussionEnum,
} from 'types/common';

import Fieldset from 'components/Fieldset';
import { getUpdatedFields } from 'components/FormPanel';
import GoBackButton from 'components/GoBackButton';
import Selection from 'components/Inputs/Selection';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

type FormStateProps = {
  willBuyThisMonth: boolean | null;
  haveBOQ: boolean | null;
  priceDiscussed: PriceDiscussionEnum;
  funnelState: FunnelStateEnum;
  status: LeadStatusEnum;
  lostReason?: LeadLostReasonEnum;
  lostReasonDescription?: string;
  orderIdIfConverted?: string;
};

const GET_LEAD_DETAILS = gql`
  query GetLeadById($id: ID!) {
    getLeadById(_id: $id) {
      _id
      haveBOQ
      willBuyThisMonth
      priceDiscussed
      funnelState
      status
      lostReason
      lostReasonDescription
      orderIdIfConverted
      quotationId
    }
  }
`;

type UpdateLeadMutationResponse = {
  updateLead: Lead;
};

const UPDATE_LEAD_MUTATION = gql`
  mutation UpdateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      _id
      haveBOQ
      willBuyThisMonth
      priceDiscussed
      funnelState
      status
      lostReason
      lostReasonDescription
      orderIdIfConverted
      quotationId
    }
  }
`;

const LeadStatus = () => {
  const { leadId = '' } = useParams<{ leadId: string }>();
  const { data: { getLeadById: leadDetails } = {}, loading: loadingLead } = useQuery(
    GET_LEAD_DETAILS,
    {
      variables: {
        id: leadId,
      },
    }
  );
  const navigate = useNavigate();

  return (
    <Navbar>
      <GoBackButton
        title="Lead Status"
        goBackAction={() => navigate(`/leads/${leadId}/products/competition`)}
      />
      {loadingLead || !!!leadDetails ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <LeadStatusForm
          initialData={{
            willBuyThisMonth: leadDetails.willBuyThisMonth,
            haveBOQ: leadDetails.haveBOQ,
            priceDiscussed: leadDetails.priceDiscussed,
            funnelState: leadDetails.funnelState,
            status: ![LeadStatusEnum.LOST, LeadStatusEnum.CONVERTED].includes(leadDetails.status)
              ? ''
              : leadDetails.status,
            lostReason: leadDetails.lostReason,
            lostReasonDescription: leadDetails.lostReasonDescription,
            orderIdIfConverted: leadDetails.orderIdIfConverted,
          }}
        />
      )}
    </Navbar>
  );
};

const LeadStatusForm: React.FC<{
  initialData: any;
}> = ({ initialData }) => {
  const [formState, setFormState] = useState<FormStateProps | Record<string, any>>({
    willBuyThisMonth: initialData.willBuyThisMonth,
    haveBOQ: initialData.haveBOQ,
    priceDiscussed: initialData.priceDiscussed,
    funnelState: initialData.funnelState,
    status: initialData.status,
    lostReason: initialData.lostReason,
    lostReasonDescription: initialData.lostReasonDescription,
    orderIdIfConverted: initialData.orderIdIfConverted,
  });

  const { leadId = '' } = useParams<{ leadId: string }>();
  const [updateLead, { loading: updatingLead }] =
    useMutation<UpdateLeadMutationResponse>(UPDATE_LEAD_MUTATION);

  const handleStatusValue = (val: LeadStatusEnum) => {
    const readOnlyStatus = [
      LeadStatusEnum.ALLOCATED,
      LeadStatusEnum.IN_PROGRESS,
      LeadStatusEnum.NEW,
    ];
    if (readOnlyStatus.includes(val)) return '';
    return val;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const updatedFields = getUpdatedFields(initialData, formState);

    updatedFields.willBuyThisMonth = formState.willBuyThisMonth;
    updatedFields.haveBOQ = formState.haveBOQ;
    updatedFields.priceDiscussed = formState.priceDiscussed;

    if (updatedFields.status !== null) {
      if (updatedFields.status === LeadStatusEnum.CONVERTED) {
        updatedFields.lostReason = null;
        updatedFields.lostReasonDescription = null;
      } else if (updatedFields.status === LeadStatusEnum.LOST) {
        updatedFields.quotationId = null;
        updatedFields.orderIdIfConverted = null;
      }
    }

    updatedFields._id = leadId;

    updateLead({
      variables: {
        input: updatedFields,
      },
      onCompleted: data => {
        const {
          updateLead: { _id, __typename, ...res },
        } = data as any;

        setFormState({ ...res, status: handleStatusValue(res.status) });

        toast.success(LEAD_UPDATE_SUCCESS);
      },
    });
  };

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container flexDirection="column" my={1.5} gap={1.5}>
        <Grid item xs={12} container columnSpacing={2} mt={1} rowGap={2}>
          <Grid item xs={12} md={6}>
            <Fieldset label="Will buy this month">
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <Selection
                    activeId={formState.willBuyThisMonth}
                    id={true}
                    label="YES"
                    handleChange={value => handleChange('willBuyThisMonth', value[0])}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Selection
                    activeId={formState.willBuyThisMonth}
                    id={false}
                    label="NO"
                    handleChange={value => handleChange('willBuyThisMonth', value[0])}
                  />
                </Grid>
              </Grid>
            </Fieldset>
          </Grid>

          <Grid item xs={12} md={6}>
            <Fieldset label="Have BOQ">
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <Selection
                    activeId={formState.haveBOQ}
                    id={true}
                    label="YES"
                    handleChange={value => handleChange('haveBOQ', value[0])}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Selection
                    activeId={formState.haveBOQ}
                    id={false}
                    label="NO"
                    handleChange={value => handleChange('haveBOQ', value[0])}
                  />
                </Grid>
              </Grid>
            </Fieldset>
          </Grid>
        </Grid>
        <Grid item container xs={12} columnSpacing={2} mt={1}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="price-discussed">Price Discussed</InputLabel>
              <Select
                labelId="price-discussed"
                id="price-discussed"
                value={formState.priceDiscussed ?? ''}
                label="Price Discussed"
                onChange={e => handleChange('priceDiscussed', e.target.value)}
              >
                {Object.values(PriceDiscussionEnum).map(priceDiscussed => (
                  <MenuItem key={priceDiscussed} value={priceDiscussed}>
                    {priceDiscussed}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={formState.funnelState ?? ''}
                disabled
                label="Funnel State"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container xs={12} columnSpacing={2} mt={1} rowGap={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="lead_status">Lost / Converted</InputLabel>
              <Select
                labelId="lead_status"
                id="lead_status"
                value={formState.status ?? ''}
                label="Lost / Converted"
                onChange={e => handleChange('status', e.target.value)}
              >
                <MenuItem value={''}>{''}</MenuItem>
                <MenuItem key={LeadStatusEnum.LOST} value={LeadStatusEnum.LOST}>
                  {LeadStatusEnum.LOST}
                </MenuItem>
                <MenuItem key={LeadStatusEnum.CONVERTED} value={LeadStatusEnum.CONVERTED}>
                  {LeadStatusEnum.CONVERTED}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {formState.status === LeadStatusEnum.LOST && (
            <LostReason formState={formState} handleChange={handleChange} />
          )}
          {formState.status === LeadStatusEnum.CONVERTED && (
            <ConvertedLead formState={formState} handleChange={handleChange} />
          )}
        </Grid>
        <LoadingButton
          loading={updatingLead}
          disabled={!!!Object.keys(getUpdatedFields(initialData, formState)).length}
          variant="contained"
          type="submit"
        >
          Submit
        </LoadingButton>
      </Grid>
    </form>
  );
};

const LostReason: React.FC<{
  formState: FormStateProps | Record<string, any>;
  handleChange: (fieldName: string, value: any) => void;
}> = ({ formState, handleChange }) => {
  return (
    <>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="lost_reason">Lost Reason</InputLabel>
          <Select
            labelId="lost_reason"
            id="lost_reason"
            required
            value={formState.lostReason ?? ''}
            label="Lost Reason"
            onChange={e => handleChange('lostReason', e.target.value)}
          >
            {Object.values(LeadLostReasonEnum).map(lr => (
              <MenuItem key={lr} value={lr}>
                {lr}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            label="Lost Reason Description"
            multiline
            required
            maxRows={2}
            value={formState.lostReasonDescription ?? ''}
            onChange={e => handleChange('lostReasonDescription', e.target.value)}
          />
        </FormControl>
      </Grid>
    </>
  );
};

const ConvertedLead: React.FC<{
  formState: FormStateProps | Record<string, any>;
  handleChange: (fieldName: string, value: any) => void;
}> = ({ formState, handleChange }) => {
  return (
    <>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            value={formState.orderIdIfConverted}
            label="Order ID"
            required
            onChange={e => handleChange('orderIdIfConverted', e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField label="Quote ID" disabled />
        </FormControl>
      </Grid>
    </>
  );
};

export default LeadStatus;
