import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { ADDRESS_UPDATE_SUCCESS } from 'data/notificationsConst';
import {
  UPDATE_ADDRESS_MUTATION,
  UpdateAddressMutationResponse,
  UpdateAddressMutationVariables,
} from 'graphql/mutation/address';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { AddressType } from 'types/common';

import Address from 'components/Address';
import { getUpdatedFields } from 'components/FormPanel';

import { deepEqual, isObject, removeEmptyFields } from 'utils/common';

type SiteAddressProps = {
  address: AddressType;
};

const SiteAddress: React.FC<SiteAddressProps> = ({ address }) => {
  const [formState, setFormState] = useState(address);
  const [updateAddress, { loading: updatingAddress }] = useMutation<
    UpdateAddressMutationResponse,
    UpdateAddressMutationVariables
  >(UPDATE_ADDRESS_MUTATION);

  const validateForm = (data: any) => {
    if (isObject(data)) {
      if (typeof data.pincode === 'number') {
        if (data.pincode.toString().length !== 6) {
          return false;
        } else if (!!!data.city || !!!data.state) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    let updatedFields = getUpdatedFields(address, formState);
    updatedFields = removeEmptyFields(updatedFields);
    delete updatedFields.__typename;
    updatedFields._id = address._id;

    if (validateForm(updatedFields)) {
      updateAddress({
        variables: {
          input: updatedFields,
        },
        onCompleted: _ => {
          toast.success(ADDRESS_UPDATE_SUCCESS);
        },
      });
    }
  };

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Address onAddressChange={setFormState} initialData={address} withMap isSite />
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          onClick={handleSubmit}
          disabled={deepEqual(formState, address) || updatingAddress}
          variant="contained"
          size="medium"
          loading={updatingAddress}
        >
          Submit
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default SiteAddress;
