import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { updateCachedList } from 'graphql/cacheUpdate';
import {
  CREATE_ADDRESS_MUTATION,
  CreateAddressMutationResponse,
  CreateAddressMutationVariables,
} from 'graphql/mutation/address';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_SITES_QUERY } from 'routes/Sites';
import {
  AutoCompleteOption,
  CommercialTypeEnum,
  Site,
  SiteSizeMeasurementEnum,
  SiteStatusEnum,
  SiteTypeEnum,
} from 'types/common';

import Address from 'components/Address';
import GoBackButton from 'components/GoBackButton';
import UploadInput from 'components/Inputs/UploadInput';
import Navbar from 'components/Navbar';

import { isObject, removeEmptyFields } from 'utils/common';

const CREATE_SITE_MUTATION = gql`
  mutation CreateSite($input: CreateSiteInput!) {
    createSite(input: $input) {
      _id
      name
      siteImages
      siteStatus
      siteSize
      siteType
      expectedCompletion
      noOfFloors
      noOfFlats
      address {
        _id
      }
      area {
        _id
      }
    }
  }
`;

type CreateSiteInputProps = {
  name: string;
  area: string;
  siteImages: File[];
  project?: string;
  siteStatus: SiteStatusEnum;
  siteSize: number;
  noOfFloors: number;
  expectedCompletion: string | Dayjs;
  noOfFlats: number;
  address: string;
  // assignedTo: null;
};

type CreateSiteMutationResponse = {
  createSite: Site;
};

type CreateSiteMutationVariables = {
  input: CreateSiteInputProps;
};

type FormStateProps = {
  name: string;
  siteStatus: SiteStatusEnum | '';
  siteType: SiteTypeEnum | '';
  commercialType?: CommercialTypeEnum | '';
  siteSizeMeasurement: SiteSizeMeasurementEnum | '';
  builtUpArea: number | '';
  area: AutoCompleteOption;
  siteImages: File[];
  siteSize: number | '';
  expectedCompletion: Dayjs | null;
  noOfFloors: number | '';
  noOfFlats: number | '';
  address: any;
};

const CreateSite = () => {
  const [createSite, { loading: creatingSite }] = useMutation<
    CreateSiteMutationResponse,
    CreateSiteMutationVariables
  >(CREATE_SITE_MUTATION);

  const [createAddress, { loading: creatingAddress }] = useMutation<
    CreateAddressMutationResponse,
    CreateAddressMutationVariables
  >(CREATE_ADDRESS_MUTATION);

  const [dateError, setDateError] = useState('');

  const navigate = useNavigate();

  const [formState, setFormState] = useState<FormStateProps>({
    name: '',
    siteStatus: '',
    siteSize: '',
    address: undefined,
    area: { _id: '', name: '' },
    builtUpArea: '',
    commercialType: '',
    siteSizeMeasurement: '',
    siteType: '',
    expectedCompletion: null,
    noOfFlats: '',
    noOfFloors: '',
    siteImages: [],
  });

  const validateForm = (data: any) => {
    if (isObject(data)) {
      if (typeof data.pincode === 'number') {
        if (data.pincode.toString().length !== 6) {
          return false;
        } else if (!!!data.city || !!!data.state) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!!!formState.expectedCompletion) {
      setDateError('Enter a valid date');
      return;
    }

    const siteImages = formState.siteImages;
    const formData = JSON.parse(JSON.stringify(formState));

    const addressFields = removeEmptyFields(formData.address);
    delete formData.address;

    const siteFields = removeEmptyFields(formData);

    siteFields.siteImages = siteImages;

    // because we are hiding area in site and showing it in address only
    siteFields.area = addressFields.area;

    if (validateForm(addressFields) && validateForm(formData)) {
      createAddress({
        variables: {
          input: addressFields,
        },
        onCompleted: addrs => {
          siteFields.address = addrs.createAddress._id;

          createSite({
            variables: {
              input: siteFields,
            },
            onCompleted: res => {
              updateCachedList(GET_SITES_QUERY, 'getSites', res.createSite);
              navigate(`/sites/${res.createSite._id}`);
            },
          });
        },
      });
    }
  };

  const handleChange = (fieldName: string, value: any) => {
    if (!!dateError) setDateError('');

    if (formState.siteType !== SiteTypeEnum.COMMERCIAL) delete formState.commercialType;

    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Navbar>
      <GoBackButton title="Create Site" />
      <form onSubmit={handleSubmit}>
        <Grid container mt={1.5} mb={4} columnSpacing={1.5} rowGap={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                value={formState.name}
                onChange={e => handleChange('name', e.target.value)}
                label="Site Name"
                type="string"
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id={'status-label'}>Site Status</InputLabel>
              <Select
                labelId="status-label"
                label="Site Status"
                size="medium"
                value={formState.siteStatus}
                fullWidth
                required
                onChange={e => handleChange('siteStatus', e.target.value)}
              >
                {Object.values(SiteStatusEnum).map(st => (
                  <MenuItem key={st} value={st}>
                    {st}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id={'type-label'}>Site Type</InputLabel>
              <Select
                labelId="type-label"
                label="Site Type"
                size="medium"
                value={formState.siteType}
                fullWidth
                required
                onChange={e => {
                  handleChange('siteType', e.target.value);
                  if (e.target.value !== SiteTypeEnum.RESIDENTIAL) {
                    handleChange('noOfFlats', '');
                  }
                }}
              >
                {Object.values(SiteTypeEnum).map(st => (
                  <MenuItem key={st} value={st}>
                    {st}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {formState.siteType === SiteTypeEnum.COMMERCIAL && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id={'commercial-type-label'}>Commercial Type</InputLabel>
                <Select
                  labelId="commercial-type-label"
                  label="Commercial Type"
                  size="medium"
                  value={formState.commercialType}
                  fullWidth
                  required
                  onChange={e => handleChange('commercialType', e.target.value)}
                >
                  {Object.values(CommercialTypeEnum).map(st => (
                    <MenuItem key={st} value={st}>
                      {st}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                value={formState.siteSize}
                label="Site Size"
                required
                onChange={e => handleChange('siteSize', !!e.target.value ? +e.target.value : '')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id={'measurement-unit-abel'}>Measurement unit</InputLabel>
              <Select
                labelId="measurement-unit-abel"
                label="Measurement unit"
                size="medium"
                value={formState.siteSizeMeasurement}
                fullWidth
                required
                onChange={e => handleChange('siteSizeMeasurement', e.target.value)}
              >
                {Object.values(SiteSizeMeasurementEnum).map(st => (
                  <MenuItem key={st} value={st}>
                    {st}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                value={formState.builtUpArea}
                label="Built Up Area (sqft)"
                required
                onChange={e =>
                  handleChange('builtUpArea', !!e.target.value ? Math.abs(+e.target.value) : '')
                }
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={formState.siteType !== SiteTypeEnum.COMMERCIAL ? 6 : 12} md={6}>
            <FormControl fullWidth>
              <AutocompleteWithFetch
                fetch={getAreas}
                handleChange={option => handleChange('area', option)}
                label="Area"
                required
                loading={areasLoading}
                options={areas?.getAreas ?? []}
                value={formState.area}
              />
            </FormControl>
          </Grid> */}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                name="noOfFloors"
                label="No of Floors"
                required
                value={formState.noOfFloors ?? ''}
                onChange={e => handleChange('noOfFloors', !!e.target.value ? +e.target.value : '')}
              />
            </FormControl>
          </Grid>
          {formState.siteType === SiteTypeEnum.RESIDENTIAL && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  value={formState.noOfFlats ?? ''}
                  label="No of Flats"
                  onChange={e => handleChange('noOfFlats', !!e.target.value ? +e.target.value : '')}
                  required
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <MobileDatePicker
                  label="Expected Completion"
                  value={formState.expectedCompletion}
                  disablePast
                  format="DD/MM/YYYY"
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      required: true,
                      error: !!dateError,
                    },
                  }}
                  onChange={newValue => handleChange('expectedCompletion', newValue)}
                />
                {!!dateError && (
                  <Typography
                    ml={0.5}
                    mt={0.2}
                    variant="caption"
                    color={theme => theme.palette.error.main}
                  >
                    {dateError}
                  </Typography>
                )}
              </FormControl>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Grid mb={1.5} px={0.5}>
              <Divider>
                <Typography fontWeight={600}>Address Details</Typography>
              </Divider>
            </Grid>
            <Address
              onAddressChange={data => handleChange('address', data)}
              initialData={formState.address}
              withMap
              isSite
            />
          </Grid>
          <Grid item xs={12}>
            <UploadInput
              onChange={data => handleChange('siteImages', data)}
              value={formState.siteImages}
              multiple
              required
              label="Site Images"
            />
          </Grid>
          <Grid item xs={12} container justifyContent="end">
            <LoadingButton
              type="submit"
              disabled={creatingSite || creatingAddress}
              loading={creatingSite || creatingAddress}
              variant="contained"
            >
              Create Site
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Navbar>
  );
};

export default CreateSite;
