import { gql } from '@apollo/client';
import { AutoCompleteOption, User } from 'types/common';

export type LeadSourceNamesQueryResponse = {
  getLeadSources: AutoCompleteOption[];
};

export const LEAD_SOURCE_NAMES_QUERY = gql`
  query GetLeadSources {
    getLeadSources {
      _id
      name
    }
  }
`;
export type GetStoreDetailsByStoreAssociateResponse = {
  getStoreDetailsByStoreAssociate: {
    store: {
      _id: string;
      name: string;
      referenceId: string;
    };
    storeLead: User;
  };
};

export type GetStoreDetailsByStoreAssociateVariables = {
  id: string;
};

export const GET_STORE_DETAILS_BY_STORE_ASSOCIATE = gql`
  query Store($id: ID!) {
    getStoreDetailsByStoreAssociate(_id: $id) {
      store {
        name
        referenceId
        _id
      }
      storeLead {
        empId
        fname
        lname
        _id
      }
    }
  }
`;
