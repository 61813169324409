import MinimizeIcon from '@mui/icons-material/ExpandLessOutlined';
import MaximizeIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import theme from './theme.module.scss';

const Title: FC<{
  title: string;
  activeIndex: number | string;
  currIndex: number | string;
  setActiveIndex: (idx: number | string) => void;
  actions?: any[];
}> = ({ title, activeIndex, setActiveIndex, currIndex, actions }) => {
  const handleMinimize = () => {
    activeIndex !== currIndex ? setActiveIndex(currIndex) : setActiveIndex(-1);
  };
  return (
    <div
      className={theme.header}
      title={activeIndex !== currIndex ? 'Click to expand' : 'Click to collapse'}
    >
      <div onClick={handleMinimize} className={theme.left}>
        {!!title && title}
      </div>
      <div className={theme.right}>
        {actions && actions.map((action, idx) => <div key={idx}>{action}</div>)}
        <div onClick={handleMinimize} title={activeIndex !== currIndex ? 'Maximize' : 'Minimize'}>
          {activeIndex !== currIndex ? <MaximizeIcon /> : <MinimizeIcon />}
        </div>
      </div>
    </div>
  );
};

type AccordionProps = {
  children: ReactNode;
  title?: string;
  isHorizontal?: boolean;
  actions?: any[];
  activeIndex: number | string;
  setActiveIndex: (arg: number | string) => void;
  currIndex: number | string;
};

const GroupedAccordion: FC<AccordionProps> = ({
  title = '',
  isHorizontal = false,
  children,
  actions = [],
  activeIndex,
  currIndex,
  setActiveIndex,
}) => {
  const isTabScreen = useMediaQuery('(max-width:900px)');
  if (isTabScreen) isHorizontal = false;

  return (
    <div
      className={classNames(
        isHorizontal ? theme.containerHorizontal : '',
        activeIndex !== currIndex ? theme.containerMinimized : theme.container
      )}
    >
      <Title
        title={title}
        currIndex={currIndex}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        actions={actions}
      />
      {children}
    </div>
  );
};

export default GroupedAccordion;
