import { gql, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { LEAD_PRODUCT_CREATE_SUCCESS } from 'data/notificationsConst';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LeadCategoryEnum, LeadProduct } from 'types/common';

import AddNewHFDProduct from './AddHFDProduct';
import AddNewHWTProduct from './AddHWTProduct';
import AddNewLHAProduct from './AddLHAProduct';
import AddNewMKWProduct from './AddMKWProduct';
import AddNewTFLProduct from './AddTFLProduct';
import AddNewWMTProduct from './AddWMTProduct';

type AddProductProps = {
  category: LeadCategoryEnum;
  onSubmit: (arg: LeadProduct) => void;
};

type CreateProductMutationResponse = {
  createLeadProduct: any;
};

type CreateProductMutationVariables = {
  input: {
    lead: string;
    quantity: number;
    category: LeadCategoryEnum;
    product: string;
  };
};

const CREATE_PRODUCT_MUTATION = gql`
  mutation CreateLeadProduct($input: CreateLeadProductInput!) {
    createLeadProduct(input: $input) {
      _id
      category
      product {
        ... on HfdProduct {
          _id
        }
        ... on HwtProduct {
          _id
        }
        ... on LhaProduct {
          _id
        }
        ... on MkwProduct {
          _id
        }
        ... on TflProduct {
          _id
        }
        ... on WmtProduct {
          _id
        }
      }
      quantity
      price
      estimatedValue
      createdAt
      updatedAt
    }
  }
`;

const AddProduct: React.FC<AddProductProps> = ({ category, onSubmit }) => {
  const { leadId = '' } = useParams<{ leadId: string }>();

  const [createLeadProduct, { loading: creatingProduct }] = useMutation<
    CreateProductMutationResponse,
    CreateProductMutationVariables
  >(CREATE_PRODUCT_MUTATION);

  const handleSubmit = ({ quantity, product }: { quantity: number; product: string }) => {
    createLeadProduct({
      variables: {
        input: {
          product,
          quantity,
          lead: leadId,
          category,
        },
      },
      onCompleted: res => {
        onSubmit(res.createLeadProduct);
        toast.success(LEAD_PRODUCT_CREATE_SUCCESS);
      },
    });
  };

  const renderContent = () => {
    switch (category) {
      case LeadCategoryEnum.TFL:
        return <AddNewTFLProduct loading={creatingProduct} onSubmit={handleSubmit} />;
      case LeadCategoryEnum.HWT:
        return <AddNewHWTProduct loading={creatingProduct} onSubmit={handleSubmit} />;
      case LeadCategoryEnum.HFD:
        return <AddNewHFDProduct loading={creatingProduct} onSubmit={handleSubmit} />;
      case LeadCategoryEnum.LHA:
        return <AddNewLHAProduct loading={creatingProduct} onSubmit={handleSubmit} />;
      case LeadCategoryEnum.WMT:
        return <AddNewWMTProduct loading={creatingProduct} onSubmit={handleSubmit} />;
      case LeadCategoryEnum.MKW:
        return <AddNewMKWProduct loading={creatingProduct} onSubmit={handleSubmit} />;
      default:
        return <></>;
    }
  };
  return (
    <Grid item xs={12}>
      {renderContent()}
    </Grid>
  );
};

export default AddProduct;
