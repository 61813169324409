import { gql, useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { areasQuery } from 'graphql/query/area';
import {
  GET_CRM_USERS_QUERY,
  GET_FIELD_SALES_USERS,
  GetCRMUsersQueryResponse,
  GetFieldSalesUsersQueryResponse,
} from 'graphql/query/common';
import { CustomerTypesResponse, GET_CUSTOMER_TYPES_QUERY } from 'graphql/query/customer';
import React, { useEffect, useMemo, useState } from 'react';
import { AutoCompleteOption, Customer, GenderEnum, LanguageEnum } from 'types/common';

import PincodeInput from 'components/Address/PincodeInput';
import { getUpdatedFields } from 'components/FormPanel';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';
import TextField, { ContactTextField } from 'components/Inputs/TextField';

import { deepEqual, removeEmptyFields } from 'utils/common';
import { transformCustomerNames } from 'utils/transformFn';

type CustomerFormProps = {
  customer?: Customer;
  loading: boolean;
  error: any;
  handleSubmit: (data: any) => void;
};

const PINCODES_QUERY = gql`
  query GetPincodes($filter: PincodeFilter) {
    getPincodes(filter: $filter) {
      stateName
      district
    }
  }
`;

type PincodesQueryResponse = {
  getPincodes: {
    stateName: string;
    district: string;
  }[];
};

type PincodesQueryVariables = {
  filter: {
    pincode: number;
  };
};

const CustomerForm: React.FC<CustomerFormProps> = ({ customer, loading, error, handleSubmit }) => {
  const [formState, setFormState] = useState<Partial<Customer> | undefined>(customer ?? {});
  const [invalidPhone, toggleInvalidPhone] = useState(false);

  const [
    getFieldSalesAssociates,
    { data: fieldSalesAssociates, loading: loadingFieldSalesAssociates },
  ] = useLazyQuery<GetFieldSalesUsersQueryResponse>(GET_FIELD_SALES_USERS);

  const [getCRMUsers, { data: crmUsers, loading: loadingCrmUsers }] =
    useLazyQuery<GetCRMUsersQueryResponse>(GET_CRM_USERS_QUERY);

  const [fetchCustomerTypes, { data: customerTypes, loading: typesLoading }] =
    useLazyQuery<CustomerTypesResponse>(GET_CUSTOMER_TYPES_QUERY);

  const [getAreas, { data: areas, loading: areasLoading }] = useLazyQuery(areasQuery);

  const [getPincodes, { loading: loadingPincodes }] = useLazyQuery<
    PincodesQueryResponse,
    PincodesQueryVariables
  >(PINCODES_QUERY);

  useEffect(() => {
    if (!deepEqual(customer, formState)) {
      setFormState(customer);
    }
  }, [customer]);

  const shouldDisableSubmit = useMemo(() => {
    const oldData = { ...customer };
    const newData = { ...formState };

    if (invalidPhone) {
      return true;
    }

    const isAddressUpdated = !!Object.keys(
      getUpdatedFields(oldData?.address ?? {}, newData?.address ?? {})
    ).length;

    if (isAddressUpdated) return false;

    delete oldData?.address;
    delete newData?.address;

    return !!!Object.keys(getUpdatedFields(oldData, newData)).length;
  }, [formState, customer]);

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleAddressChange = (fieldName: string, val: any) => {
    // @ts-ignore
    setFormState(prev => ({
      ...prev,
      address: {
        ...(prev?.address ?? {}),
        [fieldName]: val,
      },
    }));
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(formState);
      }}
    >
      <Grid container columnSpacing={2} rowGap={2}>
        {!!formState?._id && (
          <Grid item xs={6}>
            <TextField
              value={formState.referenceId ?? ''}
              disabled
              type="text"
              label="Customer ID"
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField
            label="First name"
            value={formState?.fname ?? ''}
            onChange={e => handleChange('fname', e.target.value)}
            required
            disabled={loading}
            type="text"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Last name"
            value={formState?.lname ?? ''}
            onChange={e => handleChange('lname', e.target.value)}
            disabled={loading}
            type="text"
          />
        </Grid>
        <Grid item xs={6}>
          <ContactTextField
            label="Mobile"
            setError={toggleInvalidPhone}
            onChange={e => handleChange('mobile', e.target.value)}
            value={formState?.mobile ?? ''}
            disabled={loading}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <ContactTextField
            label="Alternate Mobile"
            setError={toggleInvalidPhone}
            onChange={e => handleChange('alternateMobile', e.target.value)}
            value={formState?.alternateMobile ?? ''}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email"
            value={formState?.email ?? ''}
            onChange={e => handleChange('email', e.target.value)}
            type="email"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Gender"
            value={formState?.gender ?? ''}
            onChange={val => handleChange('gender', val)}
            options={Object.values(GenderEnum).map(option => ({
              label: option,
              value: option,
            }))}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            label="Customer Type"
            disabled={loading}
            value={formState?.customerType ?? { name: '', _id: '' }}
            fetch={fetchCustomerTypes}
            handleChange={val => handleChange('customerType', val)}
            loading={typesLoading}
            options={customerTypes?.getCustomerTypes ?? []}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            label="Assigned to (CRM)"
            disabled={loading}
            value={
              // @ts-ignore
              !!formState?.assignedToCRM?.fname
                ? transformCustomerNames([formState.assignedToCRM])[0]
                : (formState?.assignedToCRM as any) ?? {
                    _id: '',
                    name: '',
                    referenceId: '',
                  }
            }
            options={transformCustomerNames(crmUsers?.fetchCRMUsers)}
            loading={loadingCrmUsers}
            handleChange={val => handleChange('assignedToCRM', val)}
            fetch={getCRMUsers}
            labelWithId
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            label="Assigned to (SALES)"
            disabled={loading}
            value={
              // @ts-ignore
              !!formState?.assignedToSales?.fname
                ? transformCustomerNames([formState.assignedToSales])[0]
                : (formState?.assignedToSales as any) ?? {
                    _id: '',
                    name: '',
                    referenceId: '',
                  }
            }
            options={transformCustomerNames(fieldSalesAssociates?.fetchFieldSalesUsers)}
            loading={loadingFieldSalesAssociates}
            handleChange={val => handleChange('assignedToSales', val)}
            fetch={getFieldSalesAssociates}
            labelWithId
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Preffered language"
            type="select"
            disabled={loading}
            onChange={val => handleChange('prefferedLanguage', val)}
            value={formState?.prefferedLanguage ?? ''}
            options={Object.values(LanguageEnum).map(option => ({
              label: option,
              value: option,
            }))}
          />
        </Grid>
        {!!customer?._id && (
          <Grid item xs={6}>
            <TextField value={formState?.status ?? ''} disabled label="Status" />
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider>
            <Typography variant="button" fontWeight={600}>
              Address Details
            </Typography>
          </Divider>
        </Grid>

        <Grid item xs={6}>
          <TextField
            value={formState?.address?.houseNo ?? ''}
            label="House Number"
            onChange={e => handleAddressChange('houseNo', e.target.value)}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={formState?.address?.floorNo ?? ''}
            label="Floor Number"
            type="number"
            onChange={e =>
              handleAddressChange('floorNo', !!e.target.value ? Math.abs(+e.target.value) : '')
            }
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address Line 1"
            value={formState?.address?.addressLine1 ?? ''}
            onChange={e => handleAddressChange('addressLine1', e.target.value)}
            type="text"
            disabled={loading}
            required={
              !!Object.keys(removeEmptyFields({ ...(formState?.address ?? {}), addressLine1: '' }))
                .length
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address Line 2"
            value={formState?.address?.addressLine2 ?? ''}
            onChange={e => handleAddressChange('addressLine2', e.target.value)}
            type="text"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Landmark"
            value={formState?.address?.landmark ?? ''}
            onChange={e => handleAddressChange('landmark', e.target.value)}
            type="text"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            label="Area"
            disabled={loading}
            value={formState?.address?.area as AutoCompleteOption}
            options={areas?.getAreas ?? []}
            loading={areasLoading}
            handleChange={val => handleAddressChange('area', val)}
            fetch={getAreas}
          />
        </Grid>
        <Grid item xs={6}>
          <PincodeInput
            formState={formState?.address ?? {}}
            getPincodes={getPincodes}
            loadingPincodes={loadingPincodes}
            required={
              !!Object.keys(
                removeEmptyFields({
                  ...(formState?.address ?? {}),
                  pincode: '',
                  city: '',
                  state: '',
                })
              ).length
            }
            handleChange={vals => {
              // @ts-ignore
              setFormState(prev => ({
                ...prev,
                address: {
                  ...(prev?.address ?? {}),
                  ...vals,
                },
              }));
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label="City"
            value={formState?.address?.city ?? ''}
            required={
              !!Object.keys(
                removeEmptyFields({
                  ...(formState?.address ?? {}),
                  city: '',
                  state: '',
                })
              ).length
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            label="State"
            value={formState?.address?.state ?? ''}
            required={
              !!Object.keys(
                removeEmptyFields({
                  ...(formState?.address ?? {}),
                  city: '',
                  state: '',
                })
              ).length
            }
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={loading || shouldDisableSubmit}
            type="submit"
          >
            {customer?._id ? 'Update' : 'Create'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default CustomerForm;
