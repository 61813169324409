import { gql, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { updateCachedList } from 'graphql/cacheUpdate';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GET_LEADS_QUERY } from 'routes/Leads';
import { GET_LEAD_PRODUCTS_QUERY } from 'routes/ProductAndCompetition/Product';
import HFDProductForm from 'routes/ProductAndCompetition/Product/AddProduct/AddHFDProduct';
import HWTProductForm from 'routes/ProductAndCompetition/Product/AddProduct/AddHWTProduct';
import LHAProductForm from 'routes/ProductAndCompetition/Product/AddProduct/AddLHAProduct';
import MKWProductForm from 'routes/ProductAndCompetition/Product/AddProduct/AddMKWProduct';
import TFLProductForm from 'routes/ProductAndCompetition/Product/AddProduct/AddTFLProduct';
import WMTProductForm from 'routes/ProductAndCompetition/Product/AddProduct/AddWMTProduct';
import { Lead, LeadCategoryEnum, LeadProduct } from 'types/common';

import GoBackButton from 'components/GoBackButton';
import Navbar from 'components/Navbar';

import { isObject, removeEmptyFields } from 'utils/common';

import CreateLeadForm from './CreateLeadForm';

const CREATE_LEAD_MUTATION = gql`
  mutation CreateLead($input: CreateLeadInput!) {
    createLead(input: $input) {
      _id
      callCenterOwner {
        _id
        fname
        lname
        empId
      }
      customer {
        _id
        email
        fname
        gender
        lname
        mobile
        prefferedLanguage
      }
      fieldSalesAssociate {
        _id
        fname
        lname
        empId
      }
      funnelState
      productCategory
      project {
        _id
        constructionType
        customer {
          _id
          fname
          lname
          referenceId
        }
        name
        referenceId
        status
        type
      }
      referenceId
      source {
        _id
        name
      }
      status
      store {
        _id
        name
        referenceId
      }
      storeAssociate {
        _id
        empId
        fname
        lname
      }
      storeLead {
        _id
        fname
        lname
        empId
      }
    }
  }
`;

type AddProductProps = {
  category: LeadCategoryEnum;
  onSubmit: (arg: LeadProduct) => void;
  loading: boolean;
};

type CreateProductMutationResponse = {
  createLeadProduct: LeadProduct;
};

type CreateProductMutationVariables = {
  input: {
    lead: string;
    quantity: number;
    category: LeadCategoryEnum;
    product: string;
  };
};

const CREATE_PRODUCT_MUTATION = gql`
  mutation CreateLeadProduct($input: CreateLeadProductInput!) {
    createLeadProduct(input: $input) {
      _id
      category
      product {
        ... on HfdProduct {
          _id
        }
        ... on HwtProduct {
          _id
        }
        ... on LhaProduct {
          _id
        }
        ... on MkwProduct {
          _id
        }
        ... on TflProduct {
          _id
        }
        ... on WmtProduct {
          _id
        }
      }
      quantity
      price
      estimatedValue
      createdAt
      updatedAt
    }
  }
`;

type CreateLeadMutationResponse = {
  createLead: Lead;
};

type CreateLeadMutationVariables = {
  input: {
    productCategory: LeadCategoryEnum;
    customer: string;
    source: string;
    project?: string;
    callCenterOwner?: string;
    store?: string;
    storeLead?: string;
    storeAssociate?: string;
    fieldSalesAssociate?: string;
  };
};

const CreateLead = () => {
  const navigate = useNavigate();
  const [currScreen, setCurrentScreen] = useState(0);
  const [leadFormData, setLeadFormData] = useState<Record<string, any>>({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const parsedData = JSON.parse(queryParams.get('data') ?? '{}');

  let fromData: Record<string, string> = {};
  if (isObject(parsedData) && Object.keys(parsedData).length > 0) fromData = parsedData;

  const [createLead, { loading: creatingLead }] = useMutation<
    CreateLeadMutationResponse,
    CreateLeadMutationVariables
  >(CREATE_LEAD_MUTATION);

  const [createLeadProduct, { loading: creatingLeadProduct }] = useMutation<
    CreateProductMutationResponse,
    CreateProductMutationVariables
  >(CREATE_PRODUCT_MUTATION);

  const handleSubmit = (productInfo: Record<string, any>) => {
    const updatedFields = removeEmptyFields(leadFormData);
    createLead({
      variables: {
        input: updatedFields,
      },
      onCompleted: res => {
        const leadId = res.createLead._id;
        productInfo.lead = leadId;
        productInfo.category = res.createLead.productCategory;

        handleCreateLeadProduct(productInfo);
        updateCachedList(GET_LEADS_QUERY, 'getLeads', res.createLead);
      },
    });
  };

  const handleCreateLeadProduct = (leadProduct: any) => {
    createLeadProduct({
      variables: {
        input: leadProduct,
      },
      onCompleted: res => {
        updateCachedList(GET_LEAD_PRODUCTS_QUERY, 'getLeadProducts', res.createLeadProduct, {
          lead: leadProduct.lead,
        });
        navigate(`/leads/${leadProduct.lead}/products`);
      },
    });
  };

  const handleLeadFormData = (data: any) => {
    setLeadFormData(data);
    setCurrentScreen(1);
  };

  return (
    <Navbar>
      {currScreen === 0 ? (
        <GoBackButton title={'Create Lead'} key={'create-lead'} />
      ) : (
        <GoBackButton
          title={'Add Product'}
          key={'create-product'}
          goBackAction={() => setCurrentScreen(0)}
        />
      )}
      <Grid display={currScreen !== 0 ? 'none' : 'block'}>
        <CreateLeadForm
          handleSubmit={handleLeadFormData}
          loading={creatingLead}
          initialData={fromData}
        />
      </Grid>
      <Grid display={currScreen !== 1 ? 'none' : 'block'}>
        <AddProduct
          category={leadFormData.productCategory}
          onSubmit={handleSubmit}
          loading={creatingLeadProduct || creatingLead}
        />
      </Grid>
    </Navbar>
  );
};

const AddProduct: React.FC<AddProductProps> = ({ category, onSubmit, loading }) => {
  const renderContent = () => {
    switch (category) {
      case LeadCategoryEnum.TFL:
        return <TFLProductForm loading={loading} onSubmit={onSubmit} />;
      case LeadCategoryEnum.HWT:
        return <HWTProductForm loading={loading} onSubmit={onSubmit} />;
      case LeadCategoryEnum.HFD:
        return <HFDProductForm loading={loading} onSubmit={onSubmit} />;
      case LeadCategoryEnum.LHA:
        return <LHAProductForm loading={loading} onSubmit={onSubmit} />;
      case LeadCategoryEnum.WMT:
        return <WMTProductForm loading={loading} onSubmit={onSubmit} />;
      case LeadCategoryEnum.MKW:
        return <MKWProductForm loading={loading} onSubmit={onSubmit} />;
      default:
        return <></>;
    }
  };
  return (
    <Grid item xs={12}>
      {renderContent()}
    </Grid>
  );
};

export default CreateLead;
