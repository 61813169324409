import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PinIcon from '@mui/icons-material/Room';
import { Button, FormControl, Grid, TextField, useMediaQuery } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import {
  CREATE_ADDRESS_MUTATION,
  CreateAddressMutationResponse,
  CreateAddressMutationVariables,
  UPDATE_ADDRESS_MUTATION,
  UpdateAddressMutationResponse,
  UpdateAddressMutationVariables,
} from 'graphql/mutation/address';
import { areasQuery } from 'graphql/query/area';
import React, { useEffect, useState } from 'react';
import { AddressType, AutoCompleteOption } from 'types/common';

import { FormInput, FormPanel, getUpdatedFields } from 'components/FormPanel';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';

import { deepEqual } from 'utils/common';

import PincodeInput from './PincodeInput';

type AddressComponentProps = {
  onAddressChange: (arg: any) => void;
  initialData?: AddressProps;
  withMap?: boolean;
  isSite?: boolean;
};

type AddressProps = {
  _id?: string;
  houseNo?: string;
  addressLine1?: string;
  addressLine2?: string;
  area?: AutoCompleteOption;
  landmark?: string;
  pincode?: number | string;
  floorNo?: number;
  city?: string;
  state?: string;
  longitude?: number;
  latitude?: number;
};

const PINCODES_QUERY = gql`
  query GetPincodes($filter: PincodeFilter) {
    getPincodes(filter: $filter) {
      stateName
      district
    }
  }
`;

type PincodesQueryResponse = {
  getPincodes: {
    stateName: string;
    district: string;
  }[];
};

type PincodesQueryVariables = {
  filter: {
    pincode: number;
  };
};

const Location: React.FC<{ lng: number; lat: number }> = () => {
  return <PinIcon fontSize="small" color="error" />;
};

const Address: React.FC<AddressComponentProps> = ({
  onAddressChange,
  initialData = {},
  withMap = false,
  isSite = false,
}) => {
  const [formState, setFormState] = useState<AddressProps>(initialData);
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const [getAreas, { data: areas, loading: areasLoading }] = useLazyQuery(areasQuery);

  const [getPincodes, { loading: loadingPincodes }] = useLazyQuery<
    PincodesQueryResponse,
    PincodesQueryVariables
  >(PINCODES_QUERY);

  useEffect(() => {
    if (!deepEqual(initialData, formState)) setFormState(initialData);
  }, [initialData]);

  const updateState = (data: Record<string, any>) => {
    setFormState(prev => ({
      ...prev,
      ...data,
    }));
    onAddressChange({ ...formState, ...data });
  };

  const handleChange = (e: any) => {
    const fieldName = e.target.name;
    let newValue: any;

    if (e.target.type === 'number') {
      newValue = !!e.target.value ? +e.target.value : '';
    } else {
      newValue = e.target.value;
    }

    updateState({ [fieldName]: newValue });
  };

  return (
    <Grid container columnSpacing={1.5} rowGap={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="houseNo"
            value={formState.houseNo ?? ''}
            onChange={handleChange}
            label="House/Plot No"
          />
        </FormControl>
      </Grid>
      {!isSite && (
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              type="number"
              name="floorNo"
              label="Floor No"
              required
              value={formState.floorNo ?? ''}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
      )}
      {isSite && (
        <Grid item xs={6}>
          <FormControl fullWidth>
            <AutocompleteWithFetch
              fetch={getAreas}
              handleChange={option => {
                setFormState(prev => ({
                  ...prev,
                  area: option,
                }));
                onAddressChange({ ...formState, area: option });
              }}
              label="Area"
              loading={areasLoading}
              options={areas?.getAreas ?? []}
              value={formState.area ? formState.area : { _id: '', name: '' }}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="addressLine1"
            multiline
            maxRows={3}
            label="Address Line 1"
            value={formState.addressLine1 ?? ''}
            required
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="addressLine2"
            multiline
            maxRows={3}
            label="Address Line 2"
            value={formState.addressLine2 ?? ''}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>

      {!isSite && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <AutocompleteWithFetch
              fetch={getAreas}
              handleChange={option => {
                setFormState(prev => ({
                  ...prev,
                  area: option,
                }));
                onAddressChange({ ...formState, area: option });
              }}
              label="Area"
              loading={areasLoading}
              options={areas?.getAreas ?? []}
              value={formState.area ? formState.area : { _id: '', name: '' }}
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="landmark"
            label="Landmark"
            value={formState.landmark ?? ''}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <PincodeInput
          formState={formState}
          required
          getPincodes={getPincodes}
          handleChange={updateState}
          loadingPincodes={loadingPincodes}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="city"
            label="City"
            value={formState.city ?? ''}
            disabled
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="state"
            label="State"
            value={formState.state ?? ''}
            disabled
            variant="outlined"
          />
        </FormControl>
      </Grid>
      {withMap && (
        <>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                name="latitude"
                label="Latitude"
                required
                value={formState.latitude ? formState.latitude : ''}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                name="longitude"
                label="Longitude"
                required
                value={formState.longitude ? formState.longitude : ''}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item container xs={12} rowSpacing={1.5}>
            <Grid item xs={12} md={12}>
              <Grid height="7rem">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyAboynVzLAs5zd6coAMwTYKTPMPtMao4VY' }}
                  // bootstrapURLKeys={{ key: process.env.GOOGLE_MAP_API_KEY as string }}
                  center={{
                    lat: formState.latitude ?? 0,
                    lng: formState.longitude ?? 0,
                  }}
                  zoom={18}
                  options={{
                    fullscreenControl: false,
                    keyboardShortcuts: false,
                    draggable: false,
                  }}
                >
                  <Location lat={formState.latitude ?? 0} lng={formState.longitude ?? 0} />
                </GoogleMapReact>
              </Grid>
              <Grid>
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth={isMobileScreen}
                  sx={{ mt: 1 }}
                  onClick={() =>
                    navigator.geolocation.getCurrentPosition(pos => {
                      const coords = {
                        latitude: pos.coords.latitude,
                        longitude: pos.coords.longitude,
                      };
                      const newForm = { ...formState, ...coords };
                      setFormState(newForm);
                      onAddressChange(newForm);
                    })
                  }
                >
                  Capture Location
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export const AddressFormPanel: React.FC<{
  address: AddressType | null;
  variant?: 'NEW_ADDRESS' | 'EXISTING_ADDRESS';
}> = ({ address, variant = 'EXISTING_ADDRESS' }) => {
  const [updateAddress, { loading: updatingAddress, error }] = useMutation<
    UpdateAddressMutationResponse,
    UpdateAddressMutationVariables
  >(UPDATE_ADDRESS_MUTATION);

  const [createAddress, { loading: creatingAddress }] = useMutation<
    CreateAddressMutationResponse,
    CreateAddressMutationVariables
  >(CREATE_ADDRESS_MUTATION);

  const [getAreas, { data: areas, loading: areasLoading }] = useLazyQuery(areasQuery);

  const handleSubmit = (data: any) => {
    if (variant === 'NEW_ADDRESS') {
      createAddress({
        variables: {
          input: data,
        },
      });
    } else {
      const updatedFields = getUpdatedFields(address, data);
      updatedFields._id = address?._id;

      updateAddress({
        variables: {
          input: updatedFields,
        },
      });
    }
  };

  return (
    <FormPanel error={error} loading={updatingAddress || creatingAddress} onSubmit={handleSubmit}>
      <FormInput
        fieldName="houseNo"
        label="House Number"
        type="string"
        defaultValue={address?.houseNo ?? ''}
        disabled={updatingAddress || creatingAddress}
      />
      <FormInput
        fieldName="floorNo"
        label="Floor Number"
        type="number"
        defaultValue={address?.floorNo ?? ''}
        disabled={updatingAddress || creatingAddress}
      />
      <FormInput
        fieldName="addressLine1"
        label="Address Line1"
        type="string"
        fullWidth
        defaultValue={address?.addressLine1 ?? ''}
        disabled={updatingAddress || creatingAddress}
        validators={{
          dependsOn: [
            'houseNo',
            'pincode',
            'addressLine2',
            'city',
            'state',
            'landmark',
            'floorNo',
            'area',
          ],
        }}
      />
      <FormInput
        fieldName="addressLine2"
        label="Address Line2"
        type="string"
        fullWidth
        defaultValue={address?.addressLine2 ?? ''}
        disabled={updatingAddress || creatingAddress}
      />
      <FormInput
        fieldName="area"
        label="Area"
        type="auto_complete_with_fetch"
        fullWidth
        autoCompleteConfig={{
          fetchOptionsFn: getAreas,
          loading: areasLoading,
          options: areas?.getAreas,
        }}
        defaultValue={address?.area ?? null}
        disabled={updatingAddress || creatingAddress}
      />
      <FormInput
        fieldName="landmark"
        label="Landmark"
        type="string"
        defaultValue={address?.landmark ?? ''}
        disabled={updatingAddress || creatingAddress}
      />
      <FormInput
        fieldName="pincode"
        label="Pincode"
        type="number"
        defaultValue={address?.pincode ?? ''}
        disabled={updatingAddress || creatingAddress}
        validators={{
          dependsOn: [
            'houseNo',
            'addressLine1',
            'addressLine2',
            'city',
            'state',
            'landmark',
            'floorNo',
            'area',
          ],
          maxLength: 6,
          minLength: 6,
        }}
      />
      <FormInput
        fieldName="city"
        label="City"
        type="select"
        defaultValue={address?.city ?? ''}
        disabled={updatingAddress || creatingAddress}
        options={[
          { label: 'Bangalore', value: 'Bangalore' },
          { label: 'Mysore', value: 'Mysore' },
        ]}
        validators={{
          dependsOn: [
            'houseNo',
            'addressLine1',
            'addressLine2',
            'pincode',
            'state',
            'landmark',
            'floorNo',
            'area',
          ],
        }}
      />
      <FormInput
        fieldName="state"
        label="State"
        type="select"
        defaultValue={address?.state ?? ''}
        disabled={updatingAddress || creatingAddress}
        options={[{ label: 'Karnataka', value: 'Karnataka' }]}
        validators={{
          dependsOn: [
            'houseNo',
            'addressLine1',
            'addressLine2',
            'pincode',
            'city',
            'landmark',
            'floorNo',
            'area',
          ],
        }}
      />
    </FormPanel>
  );
};

export default Address;
