import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { Dayjs } from 'dayjs';

import { Validators } from 'utils/textValidators';

export type Customer = {
  _id: string;
  referenceId: string;
  fname: string;
  lname?: string;
  email?: string;
  mobile?: string;
  alternateMobile?: string;
  isVerified?: boolean;
  gender?: GenderEnum | null;
  customerType?: CustomerType;
  assignedToCRM?: User | string | null;
  assignedToSales?: User | string | null;
  projects: Project[];
  status?: CustomerStatusEnum;
  prefferedLanguage?: LanguageEnum | null;
  address: AddressType;
  leads: Lead[];
};
export type User = {
  _id: string;
  empId: string;
  fname: string;
  lname?: string;
  email?: string;
  mobile?: string;
  gender?: GenderEnum | null;
  passwordResetRequired: boolean;
  role: {
    _id: string;
    name: string;
  };
};

export type CompetitionType = {
  _id: string;
  competitionName: string;
  competitionValue: number;
  competitionQuoteImage: string[];
};

export enum CommunicationTypeEnum {
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
}

export type Lead = {
  _id: string;
  referenceId: string;
  customer: Customer;
  source: AutoCompleteOption;
  productCategory: LeadCategoryEnum;
  status: LeadStatusEnum;
  funnelState: FunnelStateEnum;
  hasParchi: boolean;
  parchiImage: string[];
  underCompetition: boolean;
  competitions: CompetitionType[];
  estimatedValue?: number;
  expectedClosure: string;
  project?: Project;
  store?: any;
  storeLead?: any;
  storeAssociate?: any;
  fieldSalesAssociate?: any;
  callCenterOwner?: any;
  leadProducts: LeadProduct[];
  fieldVisits: FieldVisit[];
  followups: Followup[];
  area: AreaType;
};

export type Project = {
  _id: string;
  referenceId: string;
  name: string;
  type: ProjectTypeEnum;
  status: ProjectStatusEnum;
  constructionType: ProjectConstructionTypeEnum;
  noOfBedrooms: number;
  noOfKitchens: number;
  noOfBathrooms: number;
  houseNo: string;
  floorNo: number;
  description: string;
  customer: Partial<Customer>;
  associatedCustomers: Customer[];
  site: Site;
  leads: Lead[];
  address: AddressType;
  createdAt: string;
  updatedAt: string;
};

export enum SiteTypeEnum {
  COMMERCIAL = 'COMMERCIAL',
  INDUSTRIAL = 'INDUSTRIAL',
  RESIDENTIAL = 'RESIDENTIAL',
}

export enum CommercialTypeEnum {
  CLINIC = 'CLINIC',
  HOSPITAL = 'HOSPITAL',
  MALL = 'MALL',
  SCHOOL = 'SCHOOL',
  OTHERS = 'OTHERS',
}

export enum SiteSizeMeasurementEnum {
  ACRES = 'ACRES',
  SQFT = 'SQFT',
}

export type Site = {
  _id: string;
  referenceId: string;
  name: string;
  siteType: SiteTypeEnum;
  commercialType: CommercialTypeEnum;
  area: AutoCompleteOption;
  siteImages: string[];
  siteStatus: SiteStatusEnum;
  siteSize: number;
  siteSizeMeasurement: SiteSizeMeasurementEnum;
  builtUpArea: number;
  expectedCompletion: string;
  noOfFloors: number;
  noOfFlats: number;
  address: AddressType;
  createdBy: User;
  updatedBy: User;
  assignedTo: User;
  status: string;
  projects: Project[];
  associatedCustomers: Customer[];
};

export type Followup = {
  _id: string;
  referenceId: string;
  lead: Lead;
  callStatus: CallStatusEnum;
  createdBy: User;
  preferredSlot: PreferredSlotEnum;
  status: FollowupStatusEnum;
  followupDate: string;
  rescheduledCounter: number;
  followupType: FollowupTypeEnum;
  assignedTo: User;
  followupOutcome: FollowupOutcomeEnum;
  followupNotes: string;
  nextFollowupDate: string;
};

export type FieldVisit = {
  _id: string;
  referenceId: string;
  lead: Lead;
  createdBy: User;
  assignedTo: User;
  fieldVisitType: FieldVisitTypeEnum;
  fieldVisitDate: string;
  preferredSlot: PreferredSlotEnum;
  comments: string;
  status: string;
  rescheduledCounter: number;
  fieldVisitOutcome: FieldVisitOutcomeEnum;
  brand: any;
};

export type AreaType = {
  _id: string;
  name: string;
};

export type AddressType = {
  _id: string;
  addressLine1: string;
  addressLine2?: string;
  area?: AreaType;
  houseNo?: string;
  floorNo?: number;
  landmark?: string;
  pincode: number;
  city: string;
  state: string;
  latitude?: number;
  longitude?: number;
};

export type AutoCompleteOption = { name: string; _id: string; referenceId?: string };

export type CustomerType = {
  _id: string;
  name: string;
};

interface LeadTile {
  type: 'lead';
  data: Lead;
}

interface CustomerTile {
  type: 'customer';
  data: Customer;
}

interface ProjectTile {
  type: 'project';
  data: Project;
}

interface SiteTile {
  type: 'site';
  data: Site;
}

interface FollowupTile {
  type: 'followup';
  data: Followup;
}

interface FieldVisitTile {
  type: 'fieldVisit';
  data: FieldVisit;
}

export enum LeadStatus {
  NEW = 'NEW',
  ALLOCATED = 'ALLOCATED',
  IN_PROGRESS = 'IN_PROGRESS',
  LOST = 'LOST',
  CONVERTED = 'CONVERTED',
}

export type TileProps = { variant?: 'elevation' | 'outlined' } & (
  | LeadTile
  | CustomerTile
  | ProjectTile
  | SiteTile
  | FollowupTile
  | FieldVisitTile
);

// filters
export type FiltersVariant =
  | 'leads'
  | 'projects'
  | 'sites'
  | 'customers'
  | 'follow-ups'
  | 'field-visits';

export type FilterProps = {
  fieldName: string;
  type:
    | 'select'
    | 'select_with_search'
    | 'selection'
    | 'date'
    | 'range-slider'
    | 'date-range'
    | 'multiple_select'
    | 'auto_complete_with_fetch'
    | 'string';
  defaultValue?: any;
  label: string;
  editable?: boolean;
  readOnlyMode?: boolean;
  options?: {
    label: string;
    value: any;
  }[];
  autoCompleteConfig?: {
    fetchOptionsFn: LazyQueryExecFunction<any, OperationVariables> | (() => void);
    loading: boolean;
    options: AutoCompleteOption[];
    labelWithId?: boolean;
  };
  validators?: Validators;
  fullWidth?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  minValue?: number;
  maxValue?: number;
  minDistance?: number;
  minLabel?: string;
  maxLabel?: string;
  placeholder?: string;
  conditionToShow?:
    | {
        field: string;
        condition: '===' | '!==' | '>' | '<' | '>=' | '<=';
        value: string;
      }[]
    | {
        field: string;
        condition: 'in' | 'not_in';
        values: string[];
      }[];
  dangerouslySetInnerHTML?: { __html: string };
  disabled?: boolean;
  minRows?: number;
};

//project
export enum ProjectStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ProjectConstructionTypeEnum {
  RENOVATION = 'RENOVATION',
  NEW_RENOVATION = 'NEW_RENOVATION',
  NEW_CONSTRUCTION = 'NEW_CONSTRUCTION',
}

export enum ProjectTypeEnum {
  APARTMENT = 'APARTMENT',
  INDEPENDENT_HOUSE = 'INDEPENDENT_HOUSE',
  VILLA = 'VILLA',
  COMMERCIAL = 'COMMERCIAL',
  INDUSTRIAL = 'INDUSTRIAL',
}

//sites
export enum SiteStatusEnum {
  FOUNDATION_LAID = 'FOUNDATION_LAID',
  STRUCTURAL_FRAMING = 'STRUCTURAL_FRAMING',
  ROOFING = 'ROOFING',
  EXTERIORS_FINISH = 'EXTERIORS_FINISH',
  FIXTURES = 'FIXTURES',
  INTERIORS_FINISH = 'INTERIORS_FINISH',
  COMPLETED = 'COMPLETED',
  RENOVATION = 'RENOVATION',
}

export enum LanguageEnum {
  ENGLISH = 'ENGLISH',
  HINDI = 'HINDI',
  KANADA = 'KANADA',
  MALAYALAM = 'MALAYALAM',
  TAMIL = 'TAMIL',
  TELUGU = 'TELUGU',
  TULU = 'TULU',
  OTHERS = 'OTHERS',
}

export enum CustomerStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum FollowupTypeEnum {
  STORE_VISIT = 'STORE_VISIT',
  CALL = 'CALL',
}

export enum FollowupStatusEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  MISSED_FOLLOWUP = 'MISSED_FOLLOWUP',
}

export enum CallStatusEnum {
  NOT_PICKED = 'NOT_PICKED',
  PICKED = 'PICKED',
  NOT_REACHABLE = 'NOT_REACHABLE',
  CALLBACK = 'CALLBACK',
  DISCONNECTED = 'DISCONNECTED',
}

export enum FieldVisitTypeEnum {
  CUSTOMER_LOCATION = 'CUSTOMER_LOCATION',
  SITE_LOCATION = 'SITE_LOCATION',
}

export enum FieldVisitStatusEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  MISSED_VISIT = 'MISSED_VISIT',
  RESCHEDULED = 'RESCHEDULED',
}

export enum CustomerTypeEnum {
  OWNER = 'OWNER',
  CARPENTER = 'CARPENTER',
  ELECTRICIAN = 'ELECTRICIAN',
  ARCHITECT = 'ARCHITECT',
  INTERIOR_DESIGNER = 'INTERIOR_DESIGNER',
  BUILDER = 'BUILDER',
  PLUMBER = 'PLUMBER',
  BRAND = 'BRAND',
  SITE_POC = 'SITE_POC',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  STORE = 'STORE',
}

export type LeadProduct = {
  _id: string;
  lead: Lead;
  category: LeadCategoryEnum;
  product: any;
  quantity: number;
  price: number;
  estimatedValue: number;
};

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  BLANK = 'BLANK',
}

export enum PriceDiscussionEnum {
  HIGH = 'HIGH',
  SMALL_DIFFERENCE = 'SMALL_DIFFERENCE',
  WE_ARE_LOW = 'WE_ARE_LOW',
  NOT_DISCUSSED = 'NOT_DISCUSSED',
}

export enum LeadLostReasonEnum {
  HIGH_ON_PRICE = 'HIGH_ON_PRICE',
  CUSTOMER_ALREADY_BOUGHT = 'CUSTOMER_ALREADY_BOUGHT',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum LeadCategoryEnum {
  HFD = 'HFD',
  HWT = 'HWT',
  MKW = 'MKW',
  LHA = 'LHA',
  TFL = 'TFL',
  WMT = 'WMT',
}

export enum SourceEnum {
  WEBSITE = 'WEBSITE',
  LANDING_PAGE = 'LANDING_PAGE',
  INDIAMART = 'INDIAMART',
  JUSTDIAL = 'JUSTDIAL',
  INBOUND = 'INBOUND',
  STORE_WALKIN = 'STORE_WALKIN',
  FIELD_SALES = 'FIELD_SALES',
  SITE_SCANNING = 'SITE_SCANNING',
  OTHERS = 'OTHERS',
}

export enum PreferredSlotEnum {
  SLOT1 = '_09AM_10AM',
  SLOT2 = '_10AM_11AM',
  SLOT3 = '_11AM_12PM',
  SLOT4 = '_12PM_13PM',
  SLOT5 = '_13PM_14PM',
  SLOT6 = '_14PM_15PM',
  SLOT7 = '_15PM_16PM',
  SLOT8 = '_16PM_17PM',
  SLOT9 = '_17PM_18PM',
}

export const preferredSlotMap = {
  _09AM_10AM: '9AM-10AM',
  _10AM_11AM: '10AM-11AM',
  _11AM_12PM: '11AM-12PM',
  _12PM_13PM: '12PM-1PM',
  _13PM_14PM: '1PM-2PM',
  _14PM_15PM: '2PM-3PM',
  _15PM_16PM: '3PM-4PM',
  _16PM_17PM: '4PM-5PM',
  _17PM_18PM: '5PM-6PM',
};

export enum FollowupOutcomeEnum {
  CALLBACK = 'CALLBACK',
  CREATE_CART = 'CREATE_CART',
  CUSTOMER_REQUESTED_SITE_VISIT = 'CUSTOMER_REQUESTED_SITE_VISIT',
  CUSTOMER_WILL_VISIT_STORE = 'CUSTOMER_WILL_VISIT_STORE',
  NOT_REACHABLE = 'NOT_REACHABLE',
  REQUESTED_1ST_CUT_DRAWING = 'REQUESTED_1ST_CUT_DRAWING',
  REQUESTED_SITE_MEASUREMENT = 'REQUESTED_SITE_MEASUREMENT',
  SEND_CART_FOR_BOOKING = 'SEND_CART_FOR_BOOKING',
  SEND_QUOTATION = 'SEND_QUOTATION',
}

export enum FieldVisitOutcomeEnum {
  REQUESTED_SITE_MEASUREMENT = 'REQUESTED_SITE_MEASUREMENT',
  REQUESTED_1ST_CUT_DRAWING = 'REQUESTED_1ST_CUT_DRAWING',
  SEND_QUOTATION = 'SEND_QUOTATION',
  CUSTOMER_REQUESTED_SITE_VISIT = 'CUSTOMER_REQUESTED_SITE_VISIT',
  CUSTOMER_WILL_VISIT_STORE = 'CUSTOMER_WILL_VISIT_STORE',
  CREATE_CART = 'CREATE_CART',
  SEND_CART_FOR_BOOKING = 'SEND_CART_FOR_BOOKING',
}

export enum AdminUpdateModuleEnum {
  AREA = 'AREA',
  BRAND = 'BRAND',
  TARGET = 'TARGET',
  FIELDVISIT = 'FIELDVISIT',
  FOLLOWUP = 'FOLLOWUP',
  HFDPRODUCT = 'HFDPRODUCT',
  HWTPRODUCT = 'HWTPRODUCT',
  LHAPRODUCT = 'LHAPRODUCT',
  MKWPRODUCT = 'MKWPRODUCT',
  TFLPRODUCT = 'TFLPRODUCT',
  WMTPRODUCT = 'WMTPRODUCT',
  LEADSOURCE = 'LEADSOURCE',
  ROLE = 'ROLE',
  USER = 'USER',
  PINCODE = 'PINCODE',
  CUSTOMER = 'CUSTOMER',
  PROJECT = 'PROJECT',
  SITE = 'SITE',
  STORE = 'STORE',
  LEAD = 'LEAD',
  STOREAREA = 'STOREAREA',
  LEAD_ORDER_MAPPING = 'LEAD_ORDER',
}
export enum AdminCreateModuleEnum {
  AREA = 'AREA',
  BRAND = 'BRAND',
  TARGET = 'TARGET',
  FIELDVISIT = 'FIELDVISIT',
  FOLLOWUP = 'FOLLOWUP',
  HFDPRODUCT = 'HFDPRODUCT',
  HWTPRODUCT = 'HWTPRODUCT',
  LHAPRODUCT = 'LHAPRODUCT',
  MKWPRODUCT = 'MKWPRODUCT',
  TFLPRODUCT = 'TFLPRODUCT',
  WMTPRODUCT = 'WMTPRODUCT',
  LEADSOURCE = 'LEADSOURCE',
  ROLE = 'ROLE',
  USER = 'USER',
  PINCODE = 'PINCODE',
  CUSTOMER = 'CUSTOMER',
  PROJECT = 'PROJECT',
  SITE = 'SITE',
  STORE = 'STORE',
  STOREAREA = 'STOREAREA',
}

export enum BrandStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
}

export enum RoleEnum {
  ADMIN = 'ADMIN',
  CALL_CENTER_ADMIN = 'CALL_CENTER_ADMIN',
  CALL_CENTER_ASSOCIATE = 'CALL_CENTER_ASSOCIATE',
  SITE_SCANNING_ASSOCIATE = 'SITE_SCANNING_ASSOCIATE',
  STORE_ADMIN = 'STORE_ADMIN',
  STORE_LEAD_TILES = 'STORE_LEAD_TILES',
  STORE_ASSOCIATE_TILES = 'STORE_ASSOCIATE_TILES',
  STORE_LEAD_WMT = 'STORE_LEAD_WMT',
  STORE_ASSOCIATE_WMT = 'STORE_ASSOCIATE_WMT',
  STORE_LEAD_HARDWARE = 'STORE_LEAD_HARDWARE',
  STORE_ASSOCIATE_HARDWARE = 'STORE_ASSOCIATE_HARDWARE',
  STORE_LEAD_MODULAR_KITCHEN = 'STORE_LEAD_MODULAR_KITCHEN',
  STORE_ASSOCIATE_MODULAR_KITCHEN = 'STORE_ASSOCIATE_MODULAR_KITCHEN',
  STORE_LEAD_LIGHTING = 'STORE_LEAD_LIGHTING',
  STORE_ASSOCIATE_LIGHTING = 'STORE_ASSOCIATE_LIGHTING',
  STORE_LEAD_HOME_FURNISHING = 'STORE_LEAD_HOME_FURNISHING',
  STORE_ASSOCIATE_HOME_FURNISHING = 'STORE_ASSOCIATE_HOME_FURNISHING',
}

export enum RoleStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type RoleType = {
  _id: string;
  referenceId: string;
  name: string;
  status: RoleStatusEnum;
  roleLevel: number;
};

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum UserTypeEnum {
  BRAND = 'BRAND',
  EMPLOYEE = 'EMPLOYEE',
}

export enum DepartmentEnum {
  IT = 'IT',
  SALES = 'SALES',
  CATEGORY = 'CATEGORY',
  CRM = 'CRM',
  MARKETING = 'MARKETING',
  SCM = 'SCM',
  DESIGN = 'DESIGN',
  FINANCE = 'FINANCE',
  HR = 'HR',
  LEGAL = 'LEGAL',
}

export enum LeadStatusEnum {
  NEW = 'NEW',
  ALLOCATED = 'ALLOCATED',
  IN_PROGRESS = 'IN_PROGRESS',
  LOST = 'LOST',
  CONVERTED = 'CONVERTED',
}

export enum FunnelStateEnum {
  HOT = 'HOT',
  WARM = 'WARM',
  COLD = 'COLD',
}

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
