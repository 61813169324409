import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldVisit } from 'types/common';

import Filters from 'components/Filters';
import SearchBar from 'components/Inputs/Search';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import TileList from 'components/TileList';

import { transformFilters } from 'utils/transformFn';

import theme from './theme.module.scss';

type FieldVisitQueryResponse = {
  getFieldVisits: FieldVisit[];
};

export const FIELD_VISITS_QUERY = gql`
  query GetFieldVisits($filter: FieldVisitFilter) {
    getFieldVisits(filter: $filter) {
      _id
      referenceId
      lead {
        _id
        referenceId
        area {
          _id
          name
        }
        project {
          referenceId
        }
        customer {
          _id
          fname
          lname
          referenceId
        }
      }
      createdBy {
        _id
        empId
        fname
        lname
      }
      assignedTo {
        _id
        empId
        fname
        lname
      }
      fieldVisitType
      fieldVisitDate
      preferredSlot
      comments
      status
      rescheduledCounter
      fieldVisitOutcome
      brand {
        _id
        name
      }
    }
  }
`;

const MyFieldVisits: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, any>>({});

  const {
    data: fieldVisits,
    refetch,
    networkStatus,
  } = useQuery<FieldVisitQueryResponse>(FIELD_VISITS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetch({
      filter: newFilters,
    });
  };

  const fieldVisitsActions = [
    <Button variant="contained" size="small" onClick={() => navigate('create-field-visit')}>
      <AddIcon fontSize="small" />
    </Button>,
  ];

  const renderContent = () => {
    return (
      <div className={theme.container}>
        <SearchBar
          name="field_visit_search"
          label="Search a Field Visit"
          placeholder="ID / Lead ID / Customer ID / Customer Name / Phone"
          onSearchInputChange={searchFilter => {
            applyFilters({
              ...filters,
              searchTerm: !!searchFilter ? searchFilter : null,
            });
          }}
        />
        <Filters
          refetch={data => {
            applyFilters({
              searchTerm: filters.searchTerm ?? null,
              ...transformFilters(data),
            });
          }}
          type="field-visits"
        />
        {networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.refetch ||
        !!!fieldVisits ? (
          <LoadingIndicator size="1.6rem" />
        ) : (
          <TileList type="fieldVisit" list={fieldVisits.getFieldVisits} />
        )}
      </div>
    );
  };

  return (
    <Navbar title="Field Visits" actions={fieldVisitsActions}>
      {renderContent()}
    </Navbar>
  );
};

export default MyFieldVisits;
