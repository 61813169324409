import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import {
  AppBar,
  Button,
  Dialog,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { deleteCachedListItem, updateCachedList } from 'graphql/cacheUpdate';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Lead, LeadProduct, LeadStatusEnum } from 'types/common';

import GroupedAccordion from 'components/Accordion/GroupedAccordion';
import { GoBackAndForthButton } from 'components/GoBackButton';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import Transition from 'components/Transition';

import { formatCurrency } from 'utils/formatHelper';

import AddProduct from './AddProduct';
import DeletePopup from './DeletePopup';
import LeadProductDetails from './Details';

type GetLeadQueryResponse = {
  getLeadById: Lead;
};

export const GET_LEAD_QUERY = gql`
  query GetLeadById($id: ID!) {
    getLeadById(_id: $id) {
      _id
      status
      productCategory
      estimatedValue
    }
  }
`;

type GetLeadProductsQueryResponse = {
  getLeadProducts: LeadProduct[];
};

export const GET_LEAD_PRODUCTS_QUERY = gql`
  query GetLeadProducts($lead: ID!) {
    getLeadProducts(lead: $lead) {
      _id
      category
      quantity
      price
      estimatedValue
      product {
        ... on HfdProduct {
          _id
          subCategory
          group
          standard
          size
          brand {
            _id
            name
          }
          unitOfMeasure
          price
        }
        ... on HwtProduct {
          _id
          subCategory
          group
          size
          weight
          standard
          type
          unitOfMeasure
          price
        }
        ... on LhaProduct {
          _id
          subCategory
          group
          application
          wattage
          brand {
            _id
            name
          }
          price
        }
        ... on MkwProduct {
          _id
          subCategory
          propertyType
          propertyStatus
          usage
          criteria
          shapeOfKitchen
          typeOfWardrobe
          size
          price
        }
        ... on TflProduct {
          _id
          subCategory
          group
          size
          unitOfMeasure
          finish
          brand {
            _id
            name
          }
          price
        }
        ... on WmtProduct {
          _id
          subCategory
          group
          product
          gradeOrFinish
          size
          unitOfMeasure
          brand {
            _id
            referenceId
            name
          }
          price
          status
        }
      }
    }
  }
`;

const LeadProducts: React.FC = () => {
  const navigate = useNavigate();
  const { leadId = '' } = useParams<{ leadId: string }>();
  const [openNewForm, setOpenNewForm] = useState(false);
  const [editableForms, setEditableForms] = useState<Record<string, boolean>>({});
  const [enableDeletePopup, toggleDeletePopup] = useState({
    id: '',
    status: false,
  });
  const [activeProductIndex, setActiveProductIndex] = useState<number | string>(1);
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const {
    data: leadProducts,
    loading: loadingLeadProducts,
    networkStatus,
  } = useQuery<GetLeadProductsQueryResponse>(GET_LEAD_PRODUCTS_QUERY, {
    variables: {
      lead: leadId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: leadDetails } = useQuery<GetLeadQueryResponse>(GET_LEAD_QUERY, {
    variables: {
      id: leadId,
    },
  });

  const handleDeleteLeadProduct = (id: string) => {
    toggleDeletePopup({
      id,
      status: true,
    });
  };

  const disableActions = useMemo(() => {
    return (
      leadDetails?.getLeadById?.status === LeadStatusEnum.CONVERTED ||
      leadDetails?.getLeadById?.status === LeadStatusEnum.LOST
    );
  }, [leadDetails?.getLeadById]);

  return (
    <Navbar>
      <GoBackAndForthButton
        title="Product Details"
        nextAction={() => navigate('competition')}
        goBackAction={() => navigate(`/leads/${leadId}`)}
      />
      {networkStatus === NetworkStatus.loading || !leadProducts || !leadDetails ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid container direction="column" pb={8} position="relative">
          {leadProducts.getLeadProducts.map((leadProduct, idx) => (
            <Grid item xs={12} key={leadProduct._id}>
              <GroupedAccordion
                title={(idx as number) + 1 + `. ${leadProduct.product?.subCategory}`}
                activeIndex={activeProductIndex}
                setActiveIndex={setActiveProductIndex}
                currIndex={idx + 1}
                actions={[
                  <EditIcon
                    color={disableActions ? 'disabled' : 'primary'}
                    onClick={() =>
                      !disableActions &&
                      setEditableForms(prev => ({
                        ...prev,
                        [leadProduct._id]: !!!prev[leadProduct._id],
                      }))
                    }
                  />,
                  leadProducts.getLeadProducts.length > 1 ? (
                    <DeleteForeverIcon
                      color="error"
                      onClick={() => handleDeleteLeadProduct(leadProduct._id)}
                    />
                  ) : (
                    <DeleteForeverIcon color="disabled" />
                  ),
                ]}
              >
                <LeadProductDetails
                  leadProduct={leadProduct}
                  editableForm={editableForms[leadProduct._id] ?? false}
                  category={leadProduct.category}
                />
              </GroupedAccordion>
            </Grid>
          ))}
          {!!!leadProducts.getLeadProducts.length && (
            <Grid
              item
              xs={12}
              mt={'30%'}
              container
              direction={'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Typography variant="subtitle2" pb={1.5} textAlign={'center'}>
                It looks like you don't have any product added yet..
              </Typography>
              <Button
                disabled={disableActions}
                variant="outlined"
                size="large"
                onClick={() => setOpenNewForm(true)}
              >
                ADD Product
              </Button>
            </Grid>
          )}
          <Grid
            item
            container
            columnSpacing={2}
            position="fixed"
            py={1.7}
            zIndex={10}
            bottom={0}
            alignItems="center"
            sx={{
              backgroundColor: '#f2f2f2',
            }}
          >
            <Grid container item xs={12} alignItems={'center'}>
              <Grid container width="100%" direction="column" columnGap={1} item xs={9.5}>
                <Grid item container xs={12} alignItems={'center'}>
                  <Typography textAlign="center" variant="overline">
                    Overall Estimated Value:
                  </Typography>
                  <Typography variant="button" ml={0.5} fontWeight={700}>
                    {formatCurrency(
                      leadProducts.getLeadProducts.reduce(
                        (prev, curr) => prev + curr.estimatedValue,
                        0
                      ),
                      0
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} rowSpacing={5}>
                  <Typography textAlign="center" variant="caption" sx={{ color: 'red' }}>
                    **Estimated value is for internal purpose only
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={2} position="fixed" right="5%">
                <IconButton onClick={() => !!!disableActions && setOpenNewForm(true)}>
                  <AddIcon
                    fontSize="medium"
                    sx={{
                      p: 0.5,
                      borderRadius: 50,
                      bgcolor: theme =>
                        disableActions ? theme.palette.grey[300] : theme.palette.primary.main,
                      color: theme =>
                        disableActions ? theme.palette.text.disabled : theme.palette.common.white,
                    }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Dialog
            fullScreen={isMobileScreen}
            fullWidth={!isMobileScreen}
            open={openNewForm}
            onClose={() => setOpenNewForm(false)}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <Typography sx={{ ml: 1, flex: 1 }} variant="h6" component="div">
                  Add New Product
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setOpenNewForm(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Grid p={2} container>
              <AddProduct
                category={leadDetails.getLeadById.productCategory}
                onSubmit={data => {
                  updateCachedList(GET_LEAD_PRODUCTS_QUERY, 'getLeadProducts', data, {
                    lead: leadId,
                  });
                  setOpenNewForm(false);
                }}
              />
            </Grid>
          </Dialog>
          <DeletePopup
            id={enableDeletePopup.id}
            open={enableDeletePopup.status}
            loading={loadingLeadProducts || networkStatus === NetworkStatus.refetch}
            onDelete={() => {
              deleteCachedListItem(
                GET_LEAD_PRODUCTS_QUERY,
                'getLeadProducts',
                enableDeletePopup.id,
                {
                  lead: leadId,
                }
              );
              toggleDeletePopup({ id: '', status: false });
            }}
            onClose={() => {
              toggleDeletePopup({ id: '', status: false });
            }}
          />
        </Grid>
      )}
    </Navbar>
  );
};

export default LeadProducts;
