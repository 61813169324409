import { gql } from '@apollo/client';
import { AutoCompleteOption } from 'types/common';

export type HFDSubCategoriesQueryResponse = {
  getHfdProducts: {
    subCategory: string;
  }[];
};

export const HFD_SUB_CATEGORIES_QUERY = gql`
  query GetHfdProducts {
    getHfdProducts {
      subCategory
    }
  }
`;

export type HFDGroupNamesQueryResponse = {
  getHfdProducts: {
    group: string;
  }[];
};

export type HFDGroupNamesQueryVariables = {
  filter: {
    subCategory: string;
  };
};

export const HFD_GROUP_NAMES_QUERY = gql`
  query GetHfdProducts($filter: HfdProductFilter) {
    getHfdProducts(filter: $filter) {
      group
    }
  }
`;

export type HFDStandardsQueryResponse = {
  getHfdProducts: {
    standard: string;
  }[];
};

export type HFDStandardsQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
  };
};

export const HFD_STANDARDS_QUERY = gql`
  query GetHfdProducts($filter: HfdProductFilter) {
    getHfdProducts(filter: $filter) {
      standard
    }
  }
`;

export type HFDSizesQueryResponse = {
  getHfdProducts: {
    size: string;
  }[];
};

export type HFDSizesQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    standard: string;
  };
};

export const HFD_SIZES_QUERY = gql`
  query GetHfdProducts($filter: HfdProductFilter) {
    getHfdProducts(filter: $filter) {
      size
    }
  }
`;

export type HFDUnitsOfMeasureQueryResponse = {
  getHfdProducts: {
    unitOfMeasure: string;
  }[];
};

export type HFDUnitsOfMeasureQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    standard: string;
    size: string;
  };
};

export const HFD_UNITS_OF_MEASURE_QUERY = gql`
  query GetHfdProducts($filter: HfdProductFilter) {
    getHfdProducts(filter: $filter) {
      unitOfMeasure
    }
  }
`;

export type HFDBrandNamesQueryResponse = {
  getHfdProducts: {
    _id: string;
    price: number;
    brand: AutoCompleteOption;
  }[];
};

export type HFDBrandNamesQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    standard: string;
    size: string;
    unitOfMeasure: string;
  };
};

export const HFD_BRAND_NAMES_QUERY = gql`
  query GetHfdProducts($filter: HfdProductFilter) {
    getHfdProducts(filter: $filter) {
      _id
      price
      brand {
        _id
        name
      }
    }
  }
`;
