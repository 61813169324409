import { gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Site, SiteStatusEnum } from 'types/common';

import Filters from 'components/Filters';
import SearchBar from 'components/Inputs/Search';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import TileList from 'components/TileList';

import { transformFilters } from 'utils/transformFn';

import theme from './theme.module.scss';

export const GET_SITES_QUERY = gql`
  query GetSites($filter: SiteFilter) {
    getSites(filter: $filter) {
      _id
      referenceId
      name
      siteImages
      expectedCompletion
      siteType
      siteStatus
      siteSize
      builtUpArea
      siteSizeMeasurement
      commercialType
      noOfFloors
      noOfFlats
      area {
        _id
        name
      }
    }
  }
`;

type SitesQueryResponse = {
  getSites: Site[];
};

type SitesQueryVariables = {
  filter: {
    area?: string[];
    expectedCompletionDateRange?: string[];
    siteMapping?: boolean[];
    siteStatus?: SiteStatusEnum[];
    searchTerm?: string;
  };
};

const Sites = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, any>>({});
  const {
    data: sites,
    loading: sitesLoading,
    refetch,
  } = useQuery<SitesQueryResponse, SitesQueryVariables>(GET_SITES_QUERY);

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetch({
      filter: newFilters,
    });
  };

  const siteActions = [
    <Button size="small" variant="contained" onClick={() => navigate('/sites/create-site')}>
      <AddIcon />
    </Button>,
  ];

  const renderContent = () => {
    return (
      <div className={theme.container}>
        <SearchBar
          name="site search"
          label="Search a Site"
          placeholder="ID / Name / Customer ID / Customer Name / Phone"
          onSearchInputChange={searchFilter => {
            applyFilters({
              ...filters,
              searchTerm: !!searchFilter ? searchFilter : null,
            });
          }}
        />
        <Filters
          type="sites"
          refetch={data => {
            applyFilters({
              searchTerm: filters.searchTerm ?? null,
              ...transformFilters(data, ['area']),
            });
          }}
        />
        {sitesLoading || !!!sites ? (
          <LoadingIndicator size="1.6rem" />
        ) : (
          <TileList type="site" list={sites.getSites} />
        )}
      </div>
    );
  };

  return (
    <Navbar title="Sites" actions={siteActions}>
      {renderContent()}
    </Navbar>
  );
};

export default Sites;
