import { NetworkStatus, gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Followup } from 'types/common';

import Filters from 'components/Filters';
import SearchBar from 'components/Inputs/Search';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import TileList from 'components/TileList';

import { transformFilters } from 'utils/transformFn';

import theme from './theme.module.scss';

type FollowupQueryResponse = {
  getFollowups: Followup[];
};

export const FOLLOWUP_QUERY = gql`
  query GetFollowups($filter: FollowupFilter) {
    getFollowups(filter: $filter) {
      _id
      referenceId
      callStatus
      followupOutcome
      followupNotes
      preferredSlot
      status
      followupDate
      followupType
      rescheduledCounter
      lead {
        _id
        referenceId
        customer {
          _id
          referenceId
          fname
          lname
          email
          mobile
        }
        area {
          _id
          name
        }
      }
    }
  }
`;

const MyFollowups: FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, any>>({});

  const {
    data: followups,
    refetch,
    networkStatus,
  } = useQuery<FollowupQueryResponse>(FOLLOWUP_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetch({
      filter: newFilters,
    });
  };

  const followupActions = [
    <Button variant="contained" size="small" onClick={() => navigate('create-followup')}>
      <AddIcon fontSize="small" />
    </Button>,
  ];

  const renderContent = () => {
    return (
      <div className={theme.container}>
        <SearchBar
          name="followup search"
          label="Search a Followup"
          placeholder="ID / Lead ID / Customer ID / Customer Name / Phone"
          onSearchInputChange={searchFilter => {
            applyFilters({
              ...filters,
              searchTerm: !!searchFilter ? searchFilter : null,
            });
          }}
        />
        <Filters
          refetch={data => {
            applyFilters({
              searchTerm: filters.searchTerm ?? null,
              ...transformFilters(data),
            });
          }}
          type="follow-ups"
        />
        {networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.refetch ||
        !!!followups ? (
          <LoadingIndicator size="1.6rem" />
        ) : (
          <TileList type="followup" list={followups.getFollowups} />
        )}
      </div>
    );
  };

  return (
    <Navbar title="My Follow ups" actions={followupActions}>
      {renderContent()}
    </Navbar>
  );
};

export default MyFollowups;
