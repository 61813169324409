import { useLazyQuery } from '@apollo/client';
import { FormControl, Grid, TextField } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { areasQuery } from 'graphql/query/area';
import { FunnelStateEnum, LeadStatusEnum } from 'types/common';

import Fieldset from 'components/Fieldset';
import DateRange from 'components/Inputs/DateRange';
import { MultiSelect, Select } from 'components/Inputs/Select';

const LeadFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const [getAreas, { data: areas, loading: areasLoading }] = useLazyQuery(areasQuery);

  const handleChange = (fieldName: string, val: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            value={state.searchTerm ?? ''}
            label="Lead Search"
            variant="outlined"
            onChange={e => handleChange('searchTerm', e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            value={state.referenceId ?? ''}
            label="Reference ID"
            variant="outlined"
            onChange={e => handleChange('referenceId', e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Status"
          handleChange={val => handleChange('status', val)}
          options={Object.values(LeadStatusEnum).map(o => ({ label: o, value: o }))}
          value={state.status ?? []}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Funnel State"
          handleChange={val => handleChange('funnelState', val)}
          options={Object.values(FunnelStateEnum).map(o => ({ label: o, value: o }))}
          value={state.funnelState ?? []}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Area"
          handleChange={val => handleChange('area', val)}
          options={
            areas?.getAreas.map(o => ({
              label: o.name,
              value: o._id,
            })) ?? []
          }
          value={state.area ?? []}
          fetchConfig={{
            fetchFn: getAreas,
            variables: {},
            loading: areasLoading,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <DateRange
          value={state.ageRange ?? [null, null]}
          onChange={val => handleChange('ageRange', val)}
          title="Age Range"
        />
      </Grid>

      <Grid item xs={12}>
        <DateRange
          value={state.expectedClosureDateRange ?? [null, null]}
          onChange={val => handleChange('expectedClosureDateRange', val)}
          title="Expected Closure Date"
        />
      </Grid>

      <Grid item xs={12}>
        <DateRange
          value={state.followupDateRange ?? [null, null]}
          onChange={val => handleChange('followupDateRange', val)}
          title="Followup Date Range"
        />
      </Grid>
    </Grid>
  );
};

export default LeadFilters;
