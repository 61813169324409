import UserContext from 'contexts/userContext';
import { useContext, useEffect, useState } from 'react';

const useUserLocation = () => {
  const [locationPopupFlag, toggleLocationPopupFlag] = useState(false);
  const { userState } = useContext(UserContext);

  console.log(userState);

  useEffect(() => {
    if (userState.isAuthenticated) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          console.log(pos);
        },
        err => {
          toggleLocationPopupFlag(true);
        }
      );
    }
  }, [userState.isAuthenticated]);

  return [locationPopupFlag, toggleLocationPopupFlag];
};

export default useUserLocation;
