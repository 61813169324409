import { gql, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { Lead } from 'types/common';

import { GoBackAndForthButton } from 'components/GoBackButton';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

import CompetitionDetails from './Competition';
import ParchiDetails from './ParchiDetails';

type GetLeadQueryResponse = {
  getLeadById: Lead;
};

type GetLeadQueryVariables = {
  id: string;
};

const GET_LEAD_QUERY = gql`
  query GetLeadById($id: ID!) {
    getLeadById(_id: $id) {
      _id
      expectedClosure
      hasParchi
      parchiImage
      underCompetition
      competitions {
        _id
        competitionName
        competitionValue
        competitionQuoteImage
      }
    }
  }
`;

const ProductCompetition: React.FC = () => {
  const { leadId = '' } = useParams<{ leadId: string }>();
  const navigate = useNavigate();
  const { data: leadDetails, loading: loadingLeadDetails } = useQuery<
    GetLeadQueryResponse,
    GetLeadQueryVariables
  >(GET_LEAD_QUERY, {
    variables: {
      id: leadId,
    },
  });

  return (
    <Navbar>
      <GoBackAndForthButton
        title="Competitions"
        nextAction={() => navigate(`/leads/${leadId}/lead-status`)}
        goBackAction={() => navigate(`/leads/${leadId}/products`)}
      />
      {loadingLeadDetails || !!!leadDetails ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid mb={16}>
          <ParchiDetails
            initialData={{
              expectedClosure: !!leadDetails.getLeadById.expectedClosure
              ? dayjs(leadDetails.getLeadById.expectedClosure)
                : null,
              hasParchi: leadDetails.getLeadById.hasParchi,
              parchiImage: leadDetails.getLeadById.parchiImage,
            }}
          />
          <CompetitionDetails
            key={leadDetails.getLeadById._id}
            initialData={leadDetails.getLeadById}
          />
        </Grid>
      )}
    </Navbar>
  );
};

export default ProductCompetition;
