import { Button, Grid, Typography } from '@mui/material';
import UserContext from 'contexts/userContext';
import useUserLocation from 'hooks/useUserLocation';
import { Suspense, useReducer } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import userReducer, { userInitialState } from 'reducers/userReducer';

import LoadingIndicator from 'components/LoadingIndicator';
import { SimplePopup } from 'components/Popup';
import ProtectedRoutes from 'components/ProtectedRoutes';

import { logout } from 'utils/auth';

import BulkUpload from './Admin/BulkUpload';
import CreateFieldVisit from './CreateFieldVisit';
import CreateFollowup from './CreateFollowup';
import CreateLead from './CreateLead';
import CreateProject from './CreateProject';
import CreateSite from './CreateSite';
import Customer from './Customer';
import CreateNewCustomer from './Customer/CreateNewCustomer';
import Customers from './Customers';
import Dashboard from './Dashboard';
import FieldVisitPage from './FieldVisit';
import FollowupPage from './Followup';
import ForgotPassword from './ForgotPassword';
import Lead from './Lead';
import LeadStatus from './LeadStatus';
import Leads from './Leads';
import Login from './Login';
import MyFieldVisits from './MyFieldVisits';
import MyFollowups from './MyFollowups';
import ProductCompetition from './ProductAndCompetition/CompetitionAndParchi';
import ProductDetails from './ProductAndCompetition/Product';
import ProjectPage from './Project';
import Projects from './Projects';
import ResetPassword from './ResetPassword';
import RouteNotFound from './RouteNotFound';
import Site from './Site';
import Sites from './Sites';

export default function App() {
  const [userState, userDispatch] = useReducer(userReducer, userInitialState);
  const userContext = { userState, userDispatch };

  return (
    <UserContext.Provider value={userContext}>
      <Router>
        <Suspense fallback={<LoadingIndicator />}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/customers/:customerId" element={<Customer />} />
              <Route path="/customers/create-customer" element={<CreateNewCustomer />} />
              <Route path="/leads" element={<Leads />} />
              <Route path="/leads/:leadId" element={<Lead />} />
              <Route path="/leads/:leadId/products" element={<ProductDetails />} />
              <Route path="/leads/:leadId/products/competition" element={<ProductCompetition />} />
              <Route path="/leads/:leadId/lead-status" element={<LeadStatus />} />
              <Route path="/leads/create-lead" element={<CreateLead />} />
              <Route path="/sites" element={<Sites />} />
              <Route path="/sites/create-site" element={<CreateSite />} />
              <Route path="/sites/:siteId" element={<Site />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/create-project" element={<CreateProject />} />
              <Route path="/projects/:projectId" element={<ProjectPage />} />
              <Route path="/field-visits" element={<MyFieldVisits />} />
              <Route path="/field-visits/create-field-visit" element={<CreateFieldVisit />} />
              <Route path="/field-visits/:fieldVisitId" element={<FieldVisitPage />} />
              <Route path="/followups" element={<MyFollowups />} />
              <Route path="/followups/create-followup" element={<CreateFollowup />} />
              <Route path="/followups/:followupId" element={<FollowupPage />} />
              <Route path="/settings" element={<ResetPassword />} />
              <Route path="/admin" element={<BulkUpload />} />
            </Route>
            <Route path="*" element={<RouteNotFound />} />
          </Routes>
          <LocationWarningPopup />
        </Suspense>
      </Router>
    </UserContext.Provider>
  );
}

const LocationWarningPopup = () => {
  const [openPopup] = useUserLocation();

  return (
    <SimplePopup
      fullWidth
      maxWidth="lg"
      open={openPopup as boolean}
      onClose={() => {
        logout();
      }}
    >
      <Grid container flexDirection="column" p={2}>
        <Grid item xs={12} mb={1}>
          <Typography variant="h6" textAlign="center">
            You are being logged out because you have not provided access to your location.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" textAlign="center">
            You can allow the location by going to:
          </Typography>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Typography variant="subtitle2" textAlign="center" fontWeight={600}>
            {'Settings > Homealankar > location'}
          </Typography>
        </Grid>
        <Grid item xs={6} mx="auto">
          <Button variant="contained" onClick={logout}>
            Ok
          </Button>
        </Grid>
      </Grid>
    </SimplePopup>
  );
};
