import PinIcon from '@mui/icons-material/Room';
import { FormControl, Grid, TextField } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import React, { useState } from 'react';
import { AddressType } from 'types/common';

type ProjectAddressProps = {
  siteAddress: Partial<AddressType>;
  onChange: (arg: any) => void;
  initialData?: {
    floorNo: number | '';
    houseNo: string;
  };
};

const ProjectAddress: React.FC<ProjectAddressProps> = ({
  siteAddress,
  onChange,
  initialData = {
    floorNo: '',
    houseNo: '',
  },
}) => {
  const [formState, setFormState] = useState({
    houseNo: initialData.houseNo,
    floorNo: initialData.floorNo,
  });

  const handleChange = (fieldName: string, value: any) => {
    const newFormState = {
      ...formState,
      [fieldName]: value,
    };
    onChange(newFormState);
    setFormState(newFormState);
  };

  return (
    <Grid container columnSpacing={1.5} rowGap={2}>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="houseNo"
            value={formState.houseNo ?? ''}
            required
            onChange={e => handleChange('houseNo', e.target.value)}
            label="House/Plot No"
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="number"
            name="floorNo"
            label="Floor No"
            required
            value={formState.floorNo ?? ''}
            onChange={e => handleChange('floorNo', !!e.target.value ? +e.target.value : '')}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="addressLine1"
            multiline
            maxRows={3}
            label="Address Line 1"
            disabled
            value={siteAddress.addressLine1 ?? ''}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="addressLine2"
            multiline
            maxRows={3}
            label="Address Line 2"
            disabled
            value={siteAddress.addressLine2 ?? ''}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField type="string" value={siteAddress.area?.name ?? ''} label="Area" disabled />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="landmark"
            label="Landmark"
            value={siteAddress.landmark ?? ''}
            disabled
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="number"
            name="pincode"
            label="Pincode"
            required
            disabled
            value={siteAddress.pincode ?? ''}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="string"
            name="city"
            label="City"
            required
            value={siteAddress.city ?? ''}
            disabled
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="string"
            name="state"
            label="State"
            required
            value={siteAddress.state ?? ''}
            disabled
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="number"
            name="latitude"
            label="Latitude"
            required
            value={siteAddress.latitude ?? ''}
            disabled
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            type="number"
            name="longitude"
            label="Longitude"
            required
            value={siteAddress.longitude ?? ''}
            disabled
          />
        </FormControl>
      </Grid>
      <Grid item container xs={12} rowSpacing={1.5}>
        <Grid item xs={12} md={12}>
          <Grid height="7rem">
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyAboynVzLAs5zd6coAMwTYKTPMPtMao4VY' }}
              // bootstrapURLKeys={{ key: process.env.GOOGLE_MAP_API_KEY as string }}
              center={{
                lat: siteAddress.latitude ?? 0,
                lng: siteAddress.longitude ?? 0,
              }}
              zoom={18}
              options={{
                fullscreenControl: false,
                keyboardShortcuts: false,
                draggable: false,
              }}
            >
              <Location lat={siteAddress.latitude ?? 0} lng={siteAddress.longitude ?? 0} />
            </GoogleMapReact>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Location: React.FC<{ lng: number; lat: number }> = () => {
  return <PinIcon fontSize="small" color="error" />;
};

export default ProjectAddress;
