import { Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import {
  Customer,
  FieldVisit,
  Followup,
  Lead,
  LeadCategoryEnum,
  Project,
  Site,
} from 'types/common';

import { truncateString } from 'utils/common';
import { formatCurrency, getDate } from 'utils/formatHelper';

import theme from './theme.module.scss';

type CustomerTileDetailsProps = {
  customer: Customer;
};

type LeadTileDetailsProps = {
  lead: Lead;
};

type ProjectTileDetailsProps = {
  project: Project;
};

type FollowupTileDetailsProps = {
  followup: Followup;
};

type FieldVisitTileDetailsProps = {
  fieldVisit: FieldVisit;
};

type SiteTileDetailsProps = {
  site: Site;
};

const NameWithID: React.FC<{ name: string; idLabel: string; id: string }> = ({
  name,
  idLabel,
  id,
}) => {
  return (
    <Typography component={'div'}>
      <Typography
        variant="subtitle1"
        component="span"
        sx={{ fontSize: 18 }}
        mr={0.5}
        color="dark"
        fontWeight={600}
      >
        {truncateString(name)}
      </Typography>
      <Typography component="span" variant="subtitle2">
        {idLabel ? `(${idLabel}: ${id})` : `(${id})`}
      </Typography>
    </Typography>
  );
};

export const CustomerTileDetails: React.FC<CustomerTileDetailsProps> = ({ customer }) => {
  return (
    <div className={theme.container}>
      <div className={theme.leftContainer}>
        <Typography variant="overline" color="GrayText" fontWeight={600}>
          {typeof customer.customerType !== 'string' ? customer.customerType?.name : ''}
        </Typography>
      </div>
      <div className={theme.rightContainer}>
        <Typography variant="caption" fontWeight={600}>
          {customer.prefferedLanguage}
        </Typography>
      </div>
    </div>
  );
};

export const ProjectTileDetails: React.FC<ProjectTileDetailsProps> = ({ project }) => {
  return (
    <div className={theme.container}>
      <div className={theme.leftContainer}>
        {project.customer ? (
          <NameWithID
            name={(project.customer.fname ?? '') + ' ' + (project.customer.lname ?? '')}
            id={project.customer.referenceId ?? ''}
            idLabel=""
          />
        ) : (
          <Typography variant="subtitle1" fontWeight={600}>
            Customer not mapped
          </Typography>
        )}
        <Typography variant="overline" color="GrayText" fontWeight={700}>
          {project.type}
        </Typography>
      </div>
      <div className={theme.rightContainer}>
        <Typography variant="overline" color="GrayText" fontWeight={700} mt={'auto'}>
          {project.constructionType}
        </Typography>
      </div>
    </div>
  );
};

export const SiteTileDetails: React.FC<SiteTileDetailsProps> = ({ site }) => {
  return (
    <div className={theme.siteTile}>
      <div className={theme.container}>
        <div className={theme.siteDetails}>
          <p className={theme.siteId}>{site.referenceId}</p>
          <h2 className={theme.customerName}>{truncateString(site.name, 17)}</h2>
          <p className={theme.location}>{site.area.name}</p>
          <p className={theme.typeAndStatus}>{site.siteStatus}</p>
        </div>
        <div className={theme.imgAndDateContainer}>
          <div className={theme.imageContainer}>
            <img
              alt={theme.siteTile}
              src={
                !!site.siteImages
                  ? site.siteImages[Math.floor(Math.random() * site.siteImages.length)]
                  : ''
              }
              className={theme.image}
            />
          </div>
          <p className={theme.date}>{getDate(site.expectedCompletion)}</p>
        </div>
      </div>
      <p className={classNames(theme.location, theme.siteExtraInfo)}>
        {site.siteType} {!!site.commercialType ? `| ${site.commercialType} ` : ''}| SIZE:{' '}
        {site.siteSize} ({site.siteSizeMeasurement}) | BUILTUP AREA: {site.builtUpArea} (SQFT)
      </p>
    </div>
  );
};

export const FollowupTileDetails: React.FC<FollowupTileDetailsProps> = ({ followup }) => {
  return (
    <div className={theme.container}>
      <div className={theme.leftContainer}>
        <NameWithID
          name={
            (followup.lead?.customer?.fname ?? '') + ' ' + (followup.lead?.customer?.lname ?? '')
          }
          id={followup.lead?.referenceId}
          idLabel=""
        />
        <Typography variant="subtitle2" color="GrayText" fontWeight={600}>
          {!!followup.followupNotes?.length ? truncateString(followup.followupNotes, 31) : 'Notes'}
        </Typography>
      </div>
      <div className={theme.rightContainer}>
        <Typography variant="subtitle2">{followup.status ?? '-'}</Typography>
        {/* <Typography variant="subtitle2">RSC: {followup.rescheduledCounter ?? '0'}</Typography> */}
      </div>
    </div>
  );
};

export const FieldVisitTileDetails: React.FC<FieldVisitTileDetailsProps> = ({ fieldVisit }) => {
  return (
    <div className={theme.container}>
      <div className={theme.leftContainer}>
        <NameWithID
          name={
            (fieldVisit.lead.customer?.fname ?? '') + ' ' + (fieldVisit.lead.customer?.lname ?? '')
          }
          id={fieldVisit.lead.referenceId}
          idLabel=""
        />
        <Typography variant="subtitle2" color="GrayText" fontWeight={600}>
          {!!fieldVisit.comments ? truncateString(fieldVisit.comments, 35) : 'Comments: -'}
        </Typography>
      </div>
      <div className={theme.rightContainer}>
        <Typography variant="subtitle2">{fieldVisit.status ?? '-'}</Typography>
        {/* <Typography variant="subtitle2">RSC: {fieldVisit.rescheduledCounter ?? '0'}</Typography> */}
      </div>
    </div>
  );
};

export const LeadTileDetails: React.FC<LeadTileDetailsProps> = ({ lead }) => {
  const formatRequirements = () => {
    const product = !!lead.leadProducts?.length ? lead.leadProducts[0].product : null;

    if (!!product) {
      const {
        subCategory,
        group,
        propertyType,
        size,
        wattage,
        unitOfMeasure,
        criteria,
        finish,
        weight,
        standard,
        type,
      } = product;
      const brand = product?.brand ?? { name: 'OTHER' };

      switch (lead.productCategory) {
        case LeadCategoryEnum.LHA:
          return `${subCategory} | ${group} | ${brand.name} | ${wattage}`;
        case LeadCategoryEnum.MKW:
          return `${subCategory} | ${propertyType} | ${brand.name} | ${size} ${criteria}`;
        case LeadCategoryEnum.HWT:
          return `${subCategory} | ${group} | ${standard} | ${size} ${unitOfMeasure} | ${weight} | ${type}`;
        case LeadCategoryEnum.TFL:
          return `${subCategory} | ${group} | ${brand.name} | ${finish} | ${size} ${unitOfMeasure}`;
        default:
          return `${subCategory} | ${group} | ${brand.name} | ${size} ${unitOfMeasure}`;
      }
    }
    return '';
  };

  return (
    <Grid container direction={'column'}>
      <div className={theme.leadContainer}>
        <div className={theme.leftContainer}>
          <Typography variant="subtitle1" sx={{ fontSize: 18 }} color="dark" fontWeight={600}>
            {truncateString(lead.customer.fname + ' ' + (lead.customer.lname ?? ''))}
          </Typography>
        </div>
        <div className={theme.rightContainer}>
          <Typography variant="subtitle1" fontWeight={600}>
            {formatCurrency(lead.estimatedValue, 0)}
          </Typography>
        </div>
      </div>
      <Typography variant="overline" color="GrayText" fontWeight={600} mt={0.4}>
        {formatRequirements()}
      </Typography>
    </Grid>
  );
};
