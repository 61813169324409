import { Box, Tab, Tabs } from '@mui/material';
import { FC, useState } from 'react';

import Navbar from 'components/Navbar';

import FieldVisitSection from './FieldVisitSection';
import FollowupSection from './FollowupSection';
import LeadSection from './LeadSection';

type DashboardTabsType = 'lead' | 'fieldVisit' | 'followup';

const Home: FC = () => {
  const [currTab, setCurrTab] = useState<DashboardTabsType>('lead');

  const tabsOptions = [
    {
      label: 'Leads',
      value: 'lead',
    },
    {
      label: 'Follow ups',
      value: 'followup',
    },
    {
      label: 'Field Visits',
      value: 'fieldVisit',
    },
  ];

  return (
    <Navbar title="Dashboard">
      <Box sx={{ width: '100%', maxWidth: '1024px', mx: 'auto' }} mb={2}>
        <Tabs
          value={currTab}
          onChange={(_, newVal) => setCurrTab(newVal)}
          variant="fullWidth"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          sx={{
            '&.MuiTabs-root': {
              minHeight: 20,
              boxShadow: '#6b6a6a59 0 3px 6px',
            },
          }}
        >
          {tabsOptions.map(tab => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              sx={{
                fontSize: 12,
                pt: 1,
                pb: 1,
                '&.MuiButtonBase-root': {
                  minHeight: 20,
                  color: theme => theme.palette.primary.dark,
                  ':hover': {
                    bgcolor: theme => theme.palette.grey[200],
                  },
                  borderRight: theme => `1px solid ${theme.palette.grey[300]}`,
                },
                '&.Mui-selected': {
                  color: theme => theme.palette.common.white,
                  bgcolor: theme => theme.palette.primary.main,
                  ':hover': {
                    bgcolor: theme => theme.palette.primary.main,
                  },
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      {currTab === 'lead' && <LeadSection />}
      {currTab === 'followup' && <FollowupSection />}
      {currTab === 'fieldVisit' && <FieldVisitSection />}
    </Navbar>
  );
};

export default Home;
