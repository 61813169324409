import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { FIELDVISIT_CREATE_SUCCESS } from 'data/notificationsConst';
import dayjs from 'dayjs';
import { updateCachedList } from 'graphql/cacheUpdate';
import {
  GET_USERS_FOR_FIELD_VISIT_ASSIGNMENT,
  GetUsersForFieldVisitAssignmentQueryResponse,
} from 'graphql/query/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FIELD_VISITS_QUERY } from 'routes/MyFieldVisits';
import {
  FieldVisit,
  FieldVisitTypeEnum,
  Lead,
  PreferredSlotEnum,
  preferredSlotMap,
} from 'types/common';

import { FormInput, FormPanel } from 'components/FormPanel';
import GoBackButton from 'components/GoBackButton';
import Navbar from 'components/Navbar';

import { getUserInfo } from 'utils/auth';
import { isObject, removeEmptyFields } from 'utils/common';
import { convertLeadsToAutoCompleteOptions, transformCustomerNames } from 'utils/transformFn';

type GetLeadsQueryResponse = {
  getLeads: Lead[];
};

const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      customer {
        _id
        fname
        lname
      }
      referenceId
    }
  }
`;

type CreateFieldVisitMutationResponse = {
  createFieldVisit: FieldVisit;
};

type CreateFieldVisitMutationVariables = {
  input: Record<string, any>;
};

const CREATE_FIELD_VISIT_MUTATION = gql`
  mutation CreateFieldVisit($input: CreateFieldVisitInput!) {
    createFieldVisit(input: $input) {
      _id
      referenceId
      assignedTo {
        _id
        fname
        empId
        lname
      }
      fieldVisitType
      fieldVisitDate
      preferredSlot
      rescheduledCounter
      brand {
        _id
        name
      }
      fieldVisitOutcome
      comments
      status
      createdBy {
        _id
        empId
        fname
        lname
      }
    }
  }
`;

const CreateFieldVisit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const parsedData = JSON.parse(queryParams.get('data') ?? '{}');

  let fromData: Record<string, any> = {
    lead: {
      _id: '',
      referenceId: '',
      customer: {
        fname: '',
        lname: '',
      },
    },
  };

  if (isObject(parsedData) && Object.keys(parsedData).length > 0) fromData = parsedData;

  const [getLeads, { loading: loadingLeads, data: leads }] =
    useLazyQuery<GetLeadsQueryResponse>(GET_LEADS_QUERY);

  const [fetchUsers, { data: users, loading: loadingUsers }] =
    useLazyQuery<GetUsersForFieldVisitAssignmentQueryResponse>(
      GET_USERS_FOR_FIELD_VISIT_ASSIGNMENT
    );

  const [createFieldVisit, { loading: creating }] = useMutation<
    CreateFieldVisitMutationResponse,
    CreateFieldVisitMutationVariables
  >(CREATE_FIELD_VISIT_MUTATION);

  const handleSubmit = (data: any) => {
    const refinedData = removeEmptyFields(data);
    refinedData.fieldVisitDate = dayjs(data.fieldVisitDate).format('YYYY-MM-DD');
    delete refinedData.createdBy;

    createFieldVisit({
      variables: {
        input: refinedData,
      },
      onCompleted: res => {
        updateCachedList(FIELD_VISITS_QUERY, 'getFieldVisits', res.createFieldVisit);
        toast.success(FIELDVISIT_CREATE_SUCCESS);
        navigate(`/field-visits/${res.createFieldVisit._id}`);
      },
    });
  };

  return (
    <Navbar>
      <GoBackButton title="Create Field Visit" />
      <Grid container mt={1.5} mb={4} columnSpacing={1.5} rowGap={2}>
        <Grid item xs={12}>
          <FormPanel
            error={null}
            loading={creating}
            onSubmit={handleSubmit}
            submitButtonLabel="Create"
          >
            <FormInput
              fieldName="fieldVisitType"
              label="Field Visit Type"
              type="select"
              defaultValue={''}
              options={Object.values(FieldVisitTypeEnum).map(o => ({ label: o, value: o }))}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fieldName="fieldVisitDate"
              label="Field Visit Date"
              type="date"
              dateConfig={{
                disablePast: true,
              }}
              defaultValue={null}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fieldName="lead"
              label="Lead"
              type="auto_complete_with_fetch"
              fullWidth
              defaultValue={
                fromData?.lead ? convertLeadsToAutoCompleteOptions([fromData.lead])[0] : null
              }
              autoCompleteConfig={{
                fetchOptionsFn: getLeads,
                loading: loadingLeads,
                options: convertLeadsToAutoCompleteOptions(leads?.getLeads),
                labelWithId: true,
              }}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fieldName="preferredSlot"
              label="Preferred Slot"
              type="select"
              defaultValue={''}
              options={Object.values(PreferredSlotEnum).map(o => ({
                label: preferredSlotMap[o],
                value: o,
              }))}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fieldName="createdBy"
              label="Created By"
              type="auto_complete_with_fetch"
              defaultValue={transformCustomerNames([getUserInfo()])[0]}
              disabled
              validators={{
                required: true,
              }}
            />
            <FormInput
              fieldName="assignedTo"
              label="Assigned to"
              type="auto_complete_with_fetch"
              fullWidth
              autoCompleteConfig={{
                options: transformCustomerNames(users?.fetchUsersForFieldVisitAssignment),
                loading: loadingUsers,
                fetchOptionsFn: fetchUsers,
                labelWithId: true,
              }}
            />
          </FormPanel>
        </Grid>
      </Grid>
    </Navbar>
  );
};

export default CreateFieldVisit;
