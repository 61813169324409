import Cookies from 'js-cookie';
import { User } from 'types/common';

export const isAuthenticated = () => !!Cookies.get('token');

export const getToken = () => (Cookies.get('token') ? Cookies.get('token') : '');
export const getUserInfo = (): User =>
  Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {};

export const logout = () => {
  Cookies.remove('token');
  Cookies.remove('userId');
  // removeRoleOnCookie();
  refresh();
};

export const storeLoginCredentials = (tokenId: string, userInfo: User) => {
  Cookies.set('token', tokenId, { expires: 1 });
  Cookies.set('userInfo', JSON.stringify(userInfo), { expires: 1 });
};

const refresh = () => window.location.reload();
