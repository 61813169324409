import { useLazyQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  HWTGroupNamesQueryResponse,
  HWTGroupNamesQueryVariables,
  HWTSizesQueryResponse,
  HWTSizesQueryVariables,
  HWTSubCategoriesQueryResponse,
  HWTUnitsOfMeasureQueryResponse,
  HWTUnitsOfMeasureQueryVariables,
  HWT_GROUP_NAMES_QUERY,
  HWT_SIZES_QUERY,
  HWT_SUB_CATEGORIES_QUERY,
  HWT_UNITS_OF_MEASURE_QUERY,
} from 'graphql/query/product/hwtProduct';
import { StatusEnum } from 'types/common';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';

const HWTProductFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const [getSubCategories, { data: subCategories, loading: loadingSubCategories }] =
    useLazyQuery<HWTSubCategoriesQueryResponse>(HWT_SUB_CATEGORIES_QUERY);

  const [getGroupNames, { data: groups, loading: loadingGroupNames }] = useLazyQuery<
    HWTGroupNamesQueryResponse,
    HWTGroupNamesQueryVariables
  >(HWT_GROUP_NAMES_QUERY);

  const [getSizes, { data: sizes, loading: loadingSizes }] = useLazyQuery<
    HWTSizesQueryResponse,
    HWTSizesQueryVariables
  >(HWT_SIZES_QUERY);

  const [getUnitsOfMeasure, { data: unitsOfMeasure, loading: loadingUnitsOfMeasure }] =
    useLazyQuery<HWTUnitsOfMeasureQueryResponse, HWTUnitsOfMeasureQueryVariables>(
      HWT_UNITS_OF_MEASURE_QUERY
    );

  const handleChange = (fieldName: string, value: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Sub Category"
          fetch={getSubCategories}
          handleChange={val => handleChange('subCategory', val)}
          loading={loadingSubCategories}
          options={(subCategories?.getHwtProducts ?? []).map(p => p.subCategory)}
          value={state.subCategory ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Group"
          fetch={getGroupNames}
          handleChange={val => handleChange('group', val)}
          loading={loadingGroupNames}
          options={(groups?.getHwtProducts ?? []).map(p => p.group)}
          value={state.group ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Size"
          fetch={getSizes}
          handleChange={val => handleChange('size', val)}
          loading={loadingSizes}
          options={(sizes?.getHwtProducts ?? []).map(p => p.size)}
          value={state.size ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Unit of Measure"
          fetch={getUnitsOfMeasure}
          handleChange={val => handleChange('unitOfMeasure', val)}
          loading={loadingUnitsOfMeasure}
          options={(unitsOfMeasure?.getHwtProducts ?? []).map(p => p.unitOfMeasure)}
          value={state.unitOfMeasure ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          label="Status"
          value={state.status}
          onChange={val => setState(prev => ({ ...prev, status: val }))}
          options={Object.values(StatusEnum).map(o => ({ label: o, value: o }))}
        />
      </Grid>
    </Grid>
  );
};

export default HWTProductFilters;
