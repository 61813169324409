import React from 'react';
import { Customer, Followup, Lead, LeadStatusEnum } from 'types/common';

import { EmailAction, PhoneAction, QuotationAction, WhatsAppAction } from './ActionsButton';

type CustomerTileActionsProps = {
  customer: Customer;
};
type LeadTileActionsProps = {
  lead: Lead;
};
type FollowUpTileActionsProps = {
  followup: Followup;
};

export const CustomerTileActions: React.FC<CustomerTileActionsProps> = ({ customer }) => {
  return (
    <>
      <PhoneAction recipientId={customer._id} />
      <EmailAction customerId={customer._id} disabled={!!!customer.email} />
      <WhatsAppAction number={customer.mobile} isLastAction />
    </>
  );
};

export const LeadTileActions: React.FC<LeadTileActionsProps> = ({ lead }) => {
  const isLeadClosed = lead.status === LeadStatusEnum.CONVERTED;
  return (
    <>
      <PhoneAction recipientId={lead.customer._id} disabled={isLeadClosed} />
      <EmailAction
        customerId={lead.customer._id}
        disabled={!!!lead.customer.email || isLeadClosed}
      />
      <WhatsAppAction number={lead.customer.mobile} disabled={isLeadClosed} />
      <QuotationAction isLastAction disabled={isLeadClosed} />
    </>
  );
};

export const FollowupTileActions: React.FC<FollowUpTileActionsProps> = ({ followup }) => {
  return (
    <>
      <PhoneAction recipientId={followup.lead.customer._id} />
      <EmailAction
        customerId={followup.lead.customer._id}
        disabled={!!!followup.lead.customer.email}
      />
      <WhatsAppAction number={followup.lead.customer.mobile} isLastAction />
    </>
  );
};
