import { gql } from '@apollo/client';
import { AddressType } from 'types/common';

export type CreateAddressMutationResponse = {
  createAddress: AddressType;
};
export type CreateAddressMutationVariables = {
  input: Omit<AddressType, '_id'>;
};
export const CREATE_ADDRESS_MUTATION = gql`
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      _id
      houseNo
      floorNo
      addressLine1
      addressLine2
      area {
        _id
        name
      }
      landmark
      pincode
      city
      state
      latitude
      longitude
    }
  }
`;
export type UpdateAddressMutationResponse = {
  updateAddress: Partial<AddressType> & { _id: string };
};

export type UpdateAddressMutationVariables = {
  input: AddressType;
};

export const UPDATE_ADDRESS_MUTATION = gql`
  mutation UpdateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      _id
      houseNo
      floorNo
      addressLine1
      addressLine2
      area {
        _id
        name
      }
      landmark
      pincode
      city
      state
      latitude
      longitude
      status
    }
  }
`;
