import { FormControl, Grid, TextField } from '@mui/material';
import { ProjectConstructionTypeEnum, ProjectStatusEnum, ProjectTypeEnum } from 'types/common';

import DateRange from 'components/Inputs/DateRange';
import { MultiSelect } from 'components/Inputs/Select';

const ProjectFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const handleChange = (fieldName: string, val: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            value={state.searchTerm ?? ''}
            label="Store Search"
            variant="outlined"
            onChange={e => handleChange('searchTerm', e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Status"
          handleChange={val => handleChange('status', val)}
          options={Object.values(ProjectStatusEnum).map(o => ({ label: o, value: o }))}
          value={state.status ?? []}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Type"
          handleChange={val => handleChange('type', val)}
          options={Object.values(ProjectTypeEnum).map(o => ({ label: o, value: o }))}
          value={state.type ?? []}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelect
          label="ConstructionType"
          handleChange={val => handleChange('constructionType', val)}
          options={Object.values(ProjectConstructionTypeEnum).map(o => ({ label: o, value: o }))}
          value={state.constructionType ?? []}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            value={state.referenceId ?? ''}
            label="Reference ID"
            variant="outlined"
            onChange={e => handleChange('referenceId', e.target.value)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <DateRange
          value={state.createdAtDateRange ?? [null, null]}
          onChange={val => handleChange('createdAtDateRange', val)}
          title="Project Created Date"
        />
      </Grid>
    </Grid>
  );
};

export default ProjectFilters;
