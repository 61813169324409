import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { FOLLOWUP_CREATE_SUCCESS } from 'data/notificationsConst';
import dayjs from 'dayjs';
import { updateCachedList } from 'graphql/cacheUpdate';
import {
  GET_CRM_USERS_QUERY,
  GET_STORE_ASSOCIATE_USERS,
  GetCRMUsersQueryResponse,
  GetStoreAssociateUsersQueryResponse,
} from 'graphql/query/common';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FOLLOWUP_QUERY } from 'routes/MyFollowups';
import {
  Followup,
  FollowupTypeEnum,
  Lead,
  PreferredSlotEnum,
  preferredSlotMap,
} from 'types/common';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import GoBackButton from 'components/GoBackButton';
import DateInput from 'components/Inputs/Date';
import { Select } from 'components/Inputs/Select';
import Navbar from 'components/Navbar';

import { getUserInfo } from 'utils/auth';
import { isObject, removeEmptyFields } from 'utils/common';
import { rankedFieldsChangeHandler } from 'utils/formHandlers';
import { convertLeadsToAutoCompleteOptions, transformCustomerNames } from 'utils/transformFn';

type GetLeadsQueryResponse = {
  getLeads: Lead[];
};

const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      customer {
        _id
        fname
        lname
      }
      referenceId
    }
  }
`;

type CreateFollowupMutationResponse = {
  createFollowup: Followup;
};

type CreateFollowupMutationVariables = {
  input: Record<string, any>;
};

const CREATE_FOLLOW_UP_MUTATION = gql`
  mutation CreateFollowup($input: CreateFollowupInput!) {
    createFollowup(input: $input) {
      _id
      referenceId
      assignedTo {
        _id
        fname
        empId
        lname
      }
      followupType
      followupDate
      preferredSlot
      rescheduledCounter
      followupOutcome
      callStatus
      followupNotes
      createdBy {
        _id
        empId
        fname
        lname
      }
      status
    }
  }
`;

const CreateFollowup = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const parsedData = JSON.parse(queryParams.get('data') ?? '{}');

  let fromData: Record<string, any> = {};
  if (isObject(parsedData) && Object.keys(parsedData).length > 0) fromData = parsedData;

  const [formState, setFormState] = useState<Record<string, any>>({
    ...(fromData ?? {}),
    lead: fromData.lead?.customer
      ? convertLeadsToAutoCompleteOptions([fromData.lead])[0]
      : undefined,
  });

  const isStoreVisit = formState.followupType === FollowupTypeEnum.STORE_VISIT;

  const [getLeads, { loading: loadingLeads, data: leads }] =
    useLazyQuery<GetLeadsQueryResponse>(GET_LEADS_QUERY);

  const [getCRMUsers, { data: crmUsers, loading: loadingCrmUsers }] =
    useLazyQuery<GetCRMUsersQueryResponse>(GET_CRM_USERS_QUERY);

  const [getStoreAssociates, { data: storeAssociates, loading: loadingStoreAssociates }] =
    useLazyQuery<GetStoreAssociateUsersQueryResponse>(GET_STORE_ASSOCIATE_USERS);

  const [createFollowup, { loading: creating, error }] = useMutation<
    CreateFollowupMutationResponse,
    CreateFollowupMutationVariables
  >(CREATE_FOLLOW_UP_MUTATION);

  const ranks = ['followupType', 'assignedTo'];

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const refinedData = removeEmptyFields(formState);

    if (dayjs(formState.followupDate) < dayjs()) {
      return;
    }

    createFollowup({
      variables: {
        input: refinedData,
      },
      onCompleted: res => {
        updateCachedList(FOLLOWUP_QUERY, 'getFollowups', res.createFollowup);
        toast.success(FOLLOWUP_CREATE_SUCCESS);
        navigate(`/followups/${res.createFollowup._id}`);
      },
    });
  };

  return (
    <Navbar>
      <GoBackButton title="Create Followup" />
      <form onSubmit={handleSubmit}>
        <Grid container mt={1.5} mb={4} columnSpacing={1.5} rowGap={2}>
          <Grid item xs={6}>
            <Select
              value={formState.followupType ?? ''}
              options={Object.values(FollowupTypeEnum).map(o => ({ label: o, value: o }))}
              onChange={val => rankedFieldsChangeHandler('followupType', val, setFormState, ranks)}
              label="Followup Type"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              value={formState.followupDate}
              onChange={val => handleChange('followupDate', val)}
              label="Followup Date"
              disablePast
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutocompleteWithFetch
              handleChange={val => handleChange('lead', val)}
              fetch={getLeads}
              labelWithId
              label="Lead"
              loading={loadingLeads}
              options={convertLeadsToAutoCompleteOptions(leads?.getLeads)}
              required
              value={!!formState.lead ? formState.lead : { _id: '', name: '' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              label="Preferred Slot"
              required
              value={formState.preferredSlot ?? ''}
              onChange={val => handleChange('preferredSlot', val)}
              options={Object.values(PreferredSlotEnum).map(o => ({
                label: preferredSlotMap[o],
                value: o,
              }))}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteWithFetch
              label="Created By"
              handleChange={() => {}}
              fetch={() => {}}
              loading={false}
              options={[]}
              value={transformCustomerNames([getUserInfo()])[0]}
              disabled
              labelWithId
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutocompleteWithFetch
              labelWithId
              label="Assigned To"
              disabled={!!!formState.followupType}
              fetch={isStoreVisit ? getStoreAssociates : getCRMUsers}
              loading={isStoreVisit ? loadingStoreAssociates : loadingCrmUsers}
              value={!!formState.assignedTo ? formState.assignedTo : { _id: '', name: '' }}
              handleChange={val =>
                rankedFieldsChangeHandler('assignedTo', val, setFormState, ranks)
              }
              options={transformCustomerNames(
                isStoreVisit ? storeAssociates?.fetchStoreAssociateUsers : crmUsers?.fetchCRMUsers
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton type="submit" loading={creating} variant="contained">
              Create
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Navbar>
  );
};

export default CreateFollowup;
