import { FormControl, Grid, TextField } from "@mui/material";
import { MultiSelect } from "components/Inputs/Select";
import { BrandStatusEnum } from "types/common";

const BrandFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
    setState,
    state,
  }) => {
    const handleChange = (fieldName: string, val: any) => {
      setState(prev => ({
        ...prev,
        [fieldName]: val,
      }));
    };
  
    return (
      <Grid container columnSpacing={2} alignItems={'center'}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              value={state.searchTerm ?? ''}
              label="Brand Name"
              variant="outlined"
              onChange={e => handleChange('searchTerm', e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <MultiSelect
            label="Status"
            handleChange={val => handleChange('status', val)}
            options={Object.values(BrandStatusEnum).map(o => ({ label: o, value: o }))}
            value={state.status ?? []}
          />
        </Grid>
      </Grid>
    );
  };

  export default BrandFilters