import UserContext from 'contexts/userContext';
import { FC, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoutes: FC = () => {
  const { userState } = useContext(UserContext);

  return userState.isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};
export default ProtectedRoutes;
