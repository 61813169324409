import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import useBulkDownloadFn from 'hooks/admin/useBulkDownloadFn';
import React, { useEffect, useMemo, useState } from 'react';
import { AdminUpdateModuleEnum } from 'types/common';

import { downloadBinaryData } from 'utils/admin';
import { removeEmptyFields } from 'utils/common';

import BrandFilters from './BrandsFilters';
import CustomerFilters from './CustomerFilters';
import FieldVisitFilters from './FieldVisitFilters';
import FollowupFilters from './FollowupFilters';
import HFDProductFilters from './HFDProductFilters';
import HWTProductFilters from './HWTProductFilters';
import LHAProductFilters from './LHAProductFilters';
import LeadFilters from './LeadFilters';
import LeadSourcesFilters from './LeadSourcesFilters';
import MKWProductFilters from './MKWProductFilters';
import ProjectFilters from './ProjectFilters';
import RoleFilters from './RolesFilters';
import SiteFilters from './SiteFilters';
import StoreAreasFilters from './StoreAreaFilters';
import StoreFilters from './StoreFilters';
import TFLProductFilters from './TFLProductFilters';
import UsersFilters from './UsersFilters';
import WMTProductFilters from './WMTProductFilters';

type DownloadRecordsTemplateProps = {
  type: AdminUpdateModuleEnum;
  updateModalSchema: {
    required: boolean;
    field: string;
    label: string;
  }[];
  onClose: () => void;
};

const DownloadRecordsTemplate: React.FC<DownloadRecordsTemplateProps> = ({
  type,
  onClose,
  updateModalSchema,
}) => {
  const [currFilters, setCurrFilters] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState('');

  const [fieldsState, setFieldsState] = useState(
    updateModalSchema.reduce((acc, curr) => {
      if (curr.field === 'referenceId' || curr.field === 'empId') acc[curr.field] = true;
      else acc[curr.field] = !!curr.required;
      return acc;
    }, {})
  );

  const fieldLabels = useMemo(() => {
    return updateModalSchema.reduce((acc, curr) => {
      acc[curr.field] = curr.label;
      return acc;
    }, {});
  }, [updateModalSchema]);

  const [
    downloadFilteredRecords,
    { loading: downloadingRecords, error: downloadError, responsePath },
  ] = useBulkDownloadFn({ type });

  useEffect(() => {
    if (downloadError && !!downloadError.message) {
      setErrors(downloadError.message);
    }
  }, [downloadError]);

  useEffect(() => {
    if (errors) {
      setErrors('');
    }
  }, [currFilters]);

  const handleSubmit = () => {
    const refinedFilters = removeEmptyFields(currFilters);

    const selectedFields = Object.keys(fieldsState).reduce((prev, curr) => {
      if (fieldsState[curr]) prev.push(curr);
      return prev;
    }, [] as string[]);

    downloadFilteredRecords({
      variables: {
        filter: refinedFilters,
        fields: selectedFields,
      },
      onCompleted: res => {
        downloadBinaryData(res[responsePath]);
        onClose();
      },
    });
  };

  const handleFieldChange = (e: any) => {
    setFieldsState(prev => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const renderFilters = () => {
    switch (type) {
      case AdminUpdateModuleEnum.BRAND:
        return <BrandFilters setState={setCurrFilters} state={currFilters} />;
      case AdminUpdateModuleEnum.ROLE:
        return <RoleFilters setState={setCurrFilters} state={currFilters} />;
      case AdminUpdateModuleEnum.LEADSOURCE:
        return <LeadSourcesFilters setState={setCurrFilters} state={currFilters} />;
      case AdminUpdateModuleEnum.USER:
        return <UsersFilters setState={setCurrFilters} state={currFilters} />;
      case AdminUpdateModuleEnum.FOLLOWUP:
        return <FollowupFilters setState={setCurrFilters} state={currFilters} />;
      case AdminUpdateModuleEnum.FIELDVISIT:
        return <FieldVisitFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.CUSTOMER:
        return <CustomerFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.STORE:
        return <StoreFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.SITE:
        return <SiteFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.PROJECT:
        return <ProjectFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.LEAD:
        return <LeadFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.HFDPRODUCT:
        return <HFDProductFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.HWTPRODUCT:
        return <HWTProductFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.LHAPRODUCT:
        return <LHAProductFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.MKWPRODUCT:
        return <MKWProductFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.WMTPRODUCT:
        return <WMTProductFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.TFLPRODUCT:
        return <TFLProductFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.LEAD_ORDER_MAPPING:
        return <LeadFilters state={currFilters} setState={setCurrFilters} />;
      case AdminUpdateModuleEnum.STOREAREA:
        return <StoreAreasFilters state={currFilters} setState={setCurrFilters} />;
      default:
        return null;
    }
  };

  return (
    <Box display="flex" flexDirection="column" rowGap={2.5} px={1.5} py={2} maxHeight={'85vh'}>
      <Typography variant="h6" fontWeight={600}>
        Download Records
      </Typography>
      {renderFilters()}

      <Typography variant="h6" fontWeight={600} mb={-2}>
        Select Fields
      </Typography>
      <Grid container width="100%" columnSpacing={2} alignItems={'center'}>
        {Object.keys(fieldsState).map(field => (
          <Grid key={field} item xs={6} md={6} lg={4}>
            <FormControlLabel
              checked={fieldsState[field]}
              control={<Checkbox />}
              label={fieldLabels[field]}
              name={field}
              disabled={field === 'referenceId' || field === 'empId'}
              onChange={handleFieldChange}
            />
          </Grid>
        ))}
      </Grid>

      <Box display="flex" flexDirection="column" alignItems={'flex-end'} rowGap={0.8} mt={'auto'}>
        {!!errors && (
          <Typography variant="caption" color="crimson" fontWeight={500}>
            {errors ?? ''}
          </Typography>
        )}

        <Box display="flex" justifyContent="end">
          <Button
            variant="text"
            onClick={onClose}
            sx={{
              mr: 1.5,
            }}
          >
            Cancel
          </Button>
          <LoadingButton variant="contained" loading={downloadingRecords} onClick={handleSubmit}>
            Download
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default DownloadRecordsTemplate;
