import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { AdminUpdateModuleEnum } from 'types/common';

const DOWNLOAD_BRANDS = gql`
  mutation DownloadBrands($filter: BrandFilter, $fields: [String]) {
    downloadBrands(filter: $filter, fields: $fields) {
      filename
      content
    }
  }
`;

type DownloadBrandsMutationResponse = {
  downloadBrands: {
    filename: string;
    content: string;
  };
};

type DownloadBrandsMutationVariables = {
  fields: string[];
  filter: {
    status?: string;
    searchTerm?: string;
  };
};

const DOWNLOAD_ROLES = gql`
  mutation DownloadRoles($fields: [String], $filter: RoleFilter) {
    downloadRoles(fields: $fields, filter: $filter) {
      filename
      content
    }
  }
`;

type DownloadRolesMutationResponse = {
  downloadRoles: {
    filename: string;
    content: string;
  };
};

type DownloadRolesMutationVariables = {
  filter: {
    name?: string;
    status?: 'ACTIVE' | 'INACTIVE';
    searchTerm?: string;
  };
};

const DOWNLOAD_LEAD_SOURCES_MUTATION = gql`
  mutation DownloadLeadSources($filter: LeadSourceFilter, $fields: [String]) {
    downloadLeadSources(filter: $filter, fields: $fields) {
      filename
      content
    }
  }
`;

type DownloadLeadSourcesMutationResponse = {
  downloadLeadSources: {
    filename: string;
    content: string;
  };
};

type DownloadLeadSourcesMutationVariables = {
  filter: {
    status?: string;
    searchTerm?: string;
  };
};

const DOWNLOAD_USERS_MUTATION = gql`
  mutation DownloadUsers($filter: UserFilter) {
    downloadUsers(filter: $filter) {
      filename
      content
    }
  }
`;

type DownloadUsersMutationResponse = {
  downloadUsers: {
    filename: string;
    content: string;
  };
};

type DownloadUsersMutationVariables = {
  filter: any;
};

const DOWNLOAD_FOLLOW_UPS_MUTATION = gql`
  mutation DownloadFollowups($fields: [String], $filter: FollowupFilter) {
    downloadFollowups(fields: $fields, filter: $filter) {
      filename
      content
    }
  }
`;

type DownloadFollowupsMutationResponse = {
  downloadFollowups: {
    filename: string;
    content: string;
  };
};

type DownloadFollowupsMutationVariables = {
  filter: any;
};

const DOWNLOAD_FIELD_VISITS_MUTATION = gql`
  mutation DownloadFieldVisits($filter: FieldVisitFilter, $fields: [String]) {
    downloadFieldVisits(filter: $filter, fields: $fields) {
      filename
      content
    }
  }
`;

type DownloadFieldVisitsMutationResponse = {
  downloadFieldVisits: {
    filename: string;
    content: string;
  };
};

type DownloadFieldVisitsMutationVariables = {
  filter: any;
};

const DOWNLOAD_HFD_PRODUCTS_MUTATION = gql`
  mutation DownloadHfdProducts($filter: HfdProductFilter) {
    downloadHfdProducts(filter: $filter) {
      filename
      content
    }
  }
`;

type DownloadHfdProductsMutationResponse = {
  downloadHfdProducts: {
    filename: string;
    content: string;
  };
};

type DownloadHfdProductsMutationVariables = {
  filter: any;
};

type DownloadLeadResponse = {
  downloadLeads: {
    filename: string;
    content: string;
  };
};

type DownloadLeadVariables = {
  filter: any;
};

const DOWNLOAD_PINCODES_MUTATION = gql`
  mutation DownloadPincodes($filter: PincodeFilter) {
    downloadPincodes(filter: $filter) {
      filename
      content
    }
  }
`;

const DOWNLOAD_STORES_MUTATION = gql`
  mutation DownloadStores($filter: StoreFilter) {
    downloadStores(filter: $filter) {
      content
      filename
    }
  }
`;

const DOWNLOAD_PROJECTS_MUTATION = gql`
  mutation DownloadProjects($filter: ProjectFilter) {
    downloadProjects(filter: $filter) {
      content
      filename
    }
  }
`;

const DOWNLOAD_SITES_MUTATION = gql`
  mutation DownloadSites($filter: SiteFilter) {
    downloadSites(filter: $filter) {
      content
      filename
    }
  }
`;

const DOWNLOAD_CUSTOMERS_MUTATION = gql`
  mutation DownloadCustomers($filter: CustomerFilter) {
    downloadCustomers(filter: $filter) {
      content
      filename
    }
  }
`;

const DOWNLOAD_HWT_PRODUCTS_MUTATION = gql`
  mutation DownloadHwtProducts($filter: HwtProductFilter) {
    downloadHwtProducts(filter: $filter) {
      filename
      content
    }
  }
`;

const DOWNLOAD_LHA_PRODUCTS_MUTATION = gql`
  mutation DownloadLhaProducts($filter: LhaProductFilter) {
    downloadLhaProducts(filter: $filter) {
      filename
      content
    }
  }
`;

const DOWNLOAD_MKW_PRODUCTS_MUTATION = gql`
  mutation DownloadMkwProducts($filter: MkwProductFilter) {
    downloadMkwProducts(filter: $filter) {
      filename
      content
    }
  }
`;

const DOWNLOAD_TFL_PRODUCTS_MUTATION = gql`
  mutation DownloadTflProducts($filter: TflProductFilter) {
    downloadTflProducts(filter: $filter) {
      filename
      content
    }
  }
`;

const DOWNLOAD_WMT_PRODUCTS_MUTATION = gql`
  mutation DownloadWmtProducts($filter: WmtProductFilter) {
    downloadWmtProducts(filter: $filter) {
      filename
      content
    }
  }
`;

const DOWNLOAD_LEAD_MUTATION = gql`
  mutation DownloadLeads($filter: LeadFilter) {
    downloadLeads(filter: $filter) {
      filename
      content
    }
  }
`;

const DOWNLOAD_LEAD_ORDER_MAPPING_MUTATION = gql`
  mutation DownloadLeadsForOrderIds($filter: LeadFilter) {
    downloadLeadsForOrderIds(filter: $filter) {
      content
      filename
    }
  }
`;

const DOWNLOAD_STORE_AREA_MAPPING_MUTATION = gql`
  mutation DownloadStoreAreas($fields: [String], $filter: StoreAreaFilter) {
    downloadStoreAreas(fields: $fields, filter: $filter) {
      content
      filename
    }
  }
`;

const DOWNLOAD_AREAS_MUTATION = gql`
  mutation DownloadAreas($filter: AreaFilter, $fields: [String]) {
    downloadAreas(filter: $filter, fields: $fields) {
      filename
      content
    }
  }
`;

const DOWNLOAD_TARGETS_MUTATION = gql`
  mutation DownloadTargets($filter: TargetFilter) {
    downloadTargets(filter: $filter) {
      filename
      content
    }
  }
`;

const useBulkDownloadFn = ({ type }: { type: AdminUpdateModuleEnum }) => {
  const [bulkDownloadParams, setBulkDownloadFuncParams] = useState<
    [(arg: any) => void, { loading: boolean; error: any; responsePath: string }]
  >([() => {}, { loading: false, error: null, responsePath: '' }]);

  const [downloadBrands, { loading: downloadingBrands, error: downloadBrandsError }] = useMutation<
    DownloadBrandsMutationResponse,
    DownloadBrandsMutationVariables
  >(DOWNLOAD_BRANDS);

  const [downloadRoles, { loading: downloadingRoles, error: downloadRolesError }] = useMutation<
    DownloadRolesMutationResponse,
    DownloadRolesMutationVariables
  >(DOWNLOAD_ROLES);

  const [
    downloadLeadSources,
    { loading: downloadingLeadSources, error: downloadLeadSourcesError },
  ] = useMutation<DownloadLeadSourcesMutationResponse, DownloadLeadSourcesMutationVariables>(
    DOWNLOAD_LEAD_SOURCES_MUTATION
  );

  const [downloadUsers, { loading: downloadingUsers, error: downloadUsersError }] = useMutation<
    DownloadUsersMutationResponse,
    DownloadUsersMutationVariables
  >(DOWNLOAD_USERS_MUTATION);

  const [downloadFollowups, { loading: downloadingFollowups, error: downloadFollowupsError }] =
    useMutation<DownloadFollowupsMutationResponse, DownloadFollowupsMutationVariables>(
      DOWNLOAD_FOLLOW_UPS_MUTATION
    );

  const [
    downloadFieldVisits,
    { loading: downloadingFieldVisits, error: downloadFieldVisitsError },
  ] = useMutation<DownloadFieldVisitsMutationResponse, DownloadFieldVisitsMutationVariables>(
    DOWNLOAD_FIELD_VISITS_MUTATION
  );

  const [
    downloadHfdProducts,
    { loading: downloadingHfdProducts, error: downloadHfdProductsError },
  ] = useMutation<DownloadHfdProductsMutationResponse, DownloadHfdProductsMutationVariables>(
    DOWNLOAD_HFD_PRODUCTS_MUTATION
  );

  const [downloadPincodes, { loading: downloadingPincodes, error: downloadPincodesError }] =
    useMutation(DOWNLOAD_PINCODES_MUTATION);

  const [downloadSites, { loading: downloadingSites, error: downloadSitesError }] =
    useMutation(DOWNLOAD_SITES_MUTATION);

  const [downloadProjects, { loading: downloadingProjects, error: downloadProjectsError }] =
    useMutation(DOWNLOAD_PROJECTS_MUTATION);

  const [downloadStores, { loading: downloadingStores, error: downloadStoresError }] =
    useMutation(DOWNLOAD_STORES_MUTATION);

  const [downloadCustomers, { loading: downloadingCustomers, error: downloadCustomersError }] =
    useMutation(DOWNLOAD_CUSTOMERS_MUTATION);

  const [
    downloadHwtProducts,
    { loading: downloadingHwtProducts, error: downloadHwtProductsError },
  ] = useMutation(DOWNLOAD_HWT_PRODUCTS_MUTATION);

  const [
    downloadLhaProducts,
    { loading: downloadingLhaProducts, error: downloadLhaProductsError },
  ] = useMutation(DOWNLOAD_LHA_PRODUCTS_MUTATION);

  const [
    downloadMkwProducts,
    { loading: downloadingMkwProducts, error: downloadMkwProductsError },
  ] = useMutation(DOWNLOAD_MKW_PRODUCTS_MUTATION);

  const [
    downloadTflProducts,
    { loading: downloadingTflProducts, error: downloadTflProductsError },
  ] = useMutation(DOWNLOAD_TFL_PRODUCTS_MUTATION);

  const [
    downloadWmtProducts,
    { loading: downloadingWmtProducts, error: downloadWmtProductsError },
  ] = useMutation(DOWNLOAD_WMT_PRODUCTS_MUTATION);

  const [downloadLeads, { loading: downloadingLeads, error: downloadLeadsError }] = useMutation<
    DownloadLeadResponse,
    DownloadLeadVariables
  >(DOWNLOAD_LEAD_MUTATION);

  const [
    downloadLeadsOrderMapping,
    { loading: downloadingLeadsOrderMapping, error: downloadLeadsOrderMappingError },
  ] = useMutation(DOWNLOAD_LEAD_ORDER_MAPPING_MUTATION);

  const [
    downloadStoreAreaMapping,
    { loading: downloadingStoreAreaMapping, error: downloadStoreAreaMappingError },
  ] = useMutation(DOWNLOAD_STORE_AREA_MAPPING_MUTATION);

  const [downloadArea, { loading: downloadingAreas, error: downloadAreasError }] =
    useMutation(DOWNLOAD_AREAS_MUTATION);

  const [downloadTargets, { loading: downloadingTargets, error: downloadTargetsError }] =
    useMutation(DOWNLOAD_TARGETS_MUTATION);

  useEffect(() => {
    switch (type) {
      case AdminUpdateModuleEnum.BRAND:
        setBulkDownloadFuncParams([
          downloadBrands,
          {
            loading: downloadingBrands,
            error: downloadBrandsError,
            responsePath: 'downloadBrands',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.ROLE:
        setBulkDownloadFuncParams([
          downloadRoles,
          {
            loading: downloadingRoles,
            error: downloadRolesError,
            responsePath: 'downloadRoles',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.LEADSOURCE:
        setBulkDownloadFuncParams([
          downloadLeadSources,
          {
            loading: downloadingLeadSources,
            error: downloadLeadSourcesError,
            responsePath: 'downloadLeadSources',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.USER:
        setBulkDownloadFuncParams([
          downloadUsers,
          {
            loading: downloadingUsers,
            error: downloadUsersError,
            responsePath: 'downloadUsers',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.FOLLOWUP:
        setBulkDownloadFuncParams([
          downloadFollowups,
          {
            loading: downloadingFollowups,
            error: downloadFollowupsError,
            responsePath: 'downloadFollowups',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.FIELDVISIT:
        setBulkDownloadFuncParams([
          downloadFieldVisits,
          {
            loading: downloadingFieldVisits,
            error: downloadFieldVisitsError,
            responsePath: 'downloadFieldVisits',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.HFDPRODUCT:
        setBulkDownloadFuncParams([
          downloadHfdProducts,
          {
            loading: downloadingHfdProducts,
            error: downloadHfdProductsError,
            responsePath: 'downloadHfdProducts',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.PINCODE:
        setBulkDownloadFuncParams([
          downloadPincodes,
          {
            loading: downloadingPincodes,
            error: downloadPincodesError,
            responsePath: 'downloadPincodes',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.SITE:
        setBulkDownloadFuncParams([
          downloadSites,
          {
            loading: downloadingSites,
            error: downloadSitesError,
            responsePath: 'downloadSites',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.PROJECT:
        setBulkDownloadFuncParams([
          downloadProjects,
          {
            loading: downloadingProjects,
            error: downloadProjectsError,
            responsePath: 'downloadProjects',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.STORE:
        setBulkDownloadFuncParams([
          downloadStores,
          {
            loading: downloadingStores,
            error: downloadStoresError,
            responsePath: 'downloadStores',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.CUSTOMER:
        setBulkDownloadFuncParams([
          downloadCustomers,
          {
            loading: downloadingCustomers,
            error: downloadCustomersError,
            responsePath: 'downloadCustomers',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.HWTPRODUCT:
        setBulkDownloadFuncParams([
          downloadHwtProducts,
          {
            loading: downloadingHwtProducts,
            error: downloadHwtProductsError,
            responsePath: 'downloadHwtProducts',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.LHAPRODUCT:
        setBulkDownloadFuncParams([
          downloadLhaProducts,
          {
            loading: downloadingLhaProducts,
            error: downloadLhaProductsError,
            responsePath: 'downloadLhaProducts',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.MKWPRODUCT:
        setBulkDownloadFuncParams([
          downloadMkwProducts,
          {
            loading: downloadingMkwProducts,
            error: downloadMkwProductsError,
            responsePath: 'downloadMkwProducts',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.TFLPRODUCT:
        setBulkDownloadFuncParams([
          downloadTflProducts,
          {
            loading: downloadingTflProducts,
            error: downloadTflProductsError,
            responsePath: 'downloadTflProducts',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.WMTPRODUCT:
        setBulkDownloadFuncParams([
          downloadWmtProducts,
          {
            loading: downloadingWmtProducts,
            error: downloadWmtProductsError,
            responsePath: 'downloadWmtProducts',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.LEAD:
        setBulkDownloadFuncParams([
          downloadLeads,
          {
            loading: downloadingLeads,
            error: downloadLeadsError,
            responsePath: 'downloadLeads',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.LEAD_ORDER_MAPPING:
        setBulkDownloadFuncParams([
          downloadLeadsOrderMapping,
          {
            loading: downloadingLeadsOrderMapping,
            error: downloadLeadsOrderMappingError,
            responsePath: 'downloadLeadsForOrderIds',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.STOREAREA:
        setBulkDownloadFuncParams([
          downloadStoreAreaMapping,
          {
            loading: downloadingStoreAreaMapping,
            error: downloadStoreAreaMappingError,
            responsePath: 'downloadStoreAreas',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.AREA:
        setBulkDownloadFuncParams([
          downloadArea,
          {
            loading: downloadingAreas,
            error: downloadAreasError,
            responsePath: 'downloadAreas',
          },
        ]);
        break;
      case AdminUpdateModuleEnum.TARGET:
        setBulkDownloadFuncParams([
          downloadTargets,
          {
            loading: downloadingTargets,
            error: downloadTargetsError,
            responsePath: 'downloadTargets',
          },
        ]);
        break;
      default:
        break;
    }
  }, [
    type,
    downloadingBrands,
    downloadBrandsError,
    downloadingLeads,
    downloadLeadsError,
    downloadingRoles,
    downloadingUsers,
    downloadingFollowups,
    downloadingFieldVisits,
    downloadingHfdProducts,
    downloadingHwtProducts,
    downloadingLhaProducts,
    downloadingMkwProducts,
    downloadingTflProducts,
    downloadingWmtProducts,
    downloadingPincodes,
    downloadingStores,
    downloadingSites,
    downloadingProjects,
    downloadingCustomers,
    downloadingLeadsOrderMapping,
    downloadingStoreAreaMapping,
    downloadingAreas,
    downloadingLeadSources,
    downloadingTargets,
    downloadRolesError,
    downloadLeadSourcesError,
    downloadUsersError,
    downloadFollowupsError,
    downloadFieldVisitsError,
    downloadHfdProductsError,
    downloadHwtProductsError,
    downloadLhaProductsError,
    downloadMkwProductsError,
    downloadTflProductsError,
    downloadWmtProductsError,
    downloadPincodesError,
    downloadSitesError,
    downloadProjectsError,
    downloadStoresError,
    downloadCustomersError,
    downloadLeadsOrderMappingError,
    downloadStoreAreaMappingError,
    downloadAreasError,
    downloadTargetsError,
  ]);

  return bulkDownloadParams;
};

export default useBulkDownloadFn;
