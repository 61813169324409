import { LazyQueryExecFunction } from '@apollo/client';
import { TextFieldProps } from '@mui/material';
import React from 'react';

import TextField from 'components/Inputs/TextField';

type PincodeInputProps = {
  formState: Record<string, any>;
  loadingPincodes: boolean;
  getPincodes: LazyQueryExecFunction<any, { filter: { pincode: number } }>;
  handleChange: (arg: any) => void;
} & TextFieldProps;

const PincodeInput: React.FC<PincodeInputProps> = ({
  formState,
  loadingPincodes,
  handleChange,
  getPincodes,
  required,
  ...props
}) => {
  let pincodeInvalidError = '';

  if (!!formState.pincode) {
    if (formState.pincode.toString().length !== 6) {
      pincodeInvalidError = 'Enter a valid pincode';
    } else if (!!!formState.city || !!!formState.state) {
      pincodeInvalidError = 'Invalid Pincode';
    }
  }

  return (
    <TextField
      type="number"
      name="pincode"
      label="Pincode"
      required={required}
      value={formState.pincode ?? ''}
      error={!!pincodeInvalidError}
      helperText={!!pincodeInvalidError ? pincodeInvalidError : null}
      loading={loadingPincodes}
      FormHelperTextProps={{
        sx: { ml: 0 },
      }}
      onChange={e => {
        const newPincode = !!e.target.value ? +e.target.value : '';
        if (newPincode.toString().length === 6) {
          getPincodes({
            variables: {
              filter: {
                pincode: newPincode as number,
              },
            },
            onCompleted: res => {
              handleChange({
                pincode: newPincode,
                state: res.getPincodes?.[0]?.stateName ?? '',
                city: res.getPincodes?.[0]?.district ?? '',
              });
            },
          });
        } else {
          handleChange({
            pincode: newPincode,
            state: '',
            city: '',
          });
        }
      }}
      {...props}
    />
  );
};

export default PincodeInput;
