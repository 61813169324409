import { gql } from '@apollo/client';
import { AutoCompleteOption } from 'types/common';

export type HWTSubCategoriesQueryResponse = {
  getHwtProducts: {
    subCategory: string;
  }[];
};

export const HWT_SUB_CATEGORIES_QUERY = gql`
  query GetHwtProducts {
    getHwtProducts {
      subCategory
    }
  }
`;

export type HWTGroupNamesQueryResponse = {
  getHwtProducts: {
    group: string;
  }[];
};

export type HWTGroupNamesQueryVariables = {
  filter: {
    subCategory: string;
  };
};

export const HWT_GROUP_NAMES_QUERY = gql`
  query GetHwtProducts($filter: HwtProductFilter) {
    getHwtProducts(filter: $filter) {
      group
    }
  }
`;

export type HWTStandardsQueryResponse = {
  getHwtProducts: {
    standard: string;
  }[];
};

export type HWTStandardsQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
  };
};

export const HWT_STANDARDS_QUERY = gql`
  query GetHwtProducts($filter: HwtProductFilter) {
    getHwtProducts(filter: $filter) {
      standard
    }
  }
`;

export type HWTTypesQueryResponse = {
  getHwtProducts: {
    type: string;
  }[];
};

export type HWTTypesQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    standard: string;
  };
};

export const HWT_TYPES_QUERY = gql`
  query GetHwtProducts($filter: HwtProductFilter) {
    getHwtProducts(filter: $filter) {
      type
    }
  }
`;

export type HWTSizesQueryResponse = {
  getHwtProducts: {
    size: string;
  }[];
};

export type HWTSizesQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    standard: string;
    type: string;
  };
};

export const HWT_SIZES_QUERY = gql`
  query GetHwtProducts($filter: HwtProductFilter) {
    getHwtProducts(filter: $filter) {
      size
    }
  }
`;

export type HWTUnitsOfMeasureQueryResponse = {
  getHwtProducts: {
    unitOfMeasure: string;
  }[];
};

export type HWTUnitsOfMeasureQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    standard: string;
    type: string;
    size: string;
  };
};

export const HWT_UNITS_OF_MEASURE_QUERY = gql`
  query GetHwtProducts($filter: HwtProductFilter) {
    getHwtProducts(filter: $filter) {
      unitOfMeasure
    }
  }
`;

export type HWTWeightsQueryResponse = {
  getHwtProducts: {
    weight: string;
  }[];
};

export type HWTWeightsQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    standard: string;
    type: string;
    size: string;
    unitOfMeasure: string;
  };
};

export const HWT_WEIGHTS_QUERY = gql`
  query GetHwtProducts($filter: HwtProductFilter) {
    getHwtProducts(filter: $filter) {
      _id
      price
      weight
    }
  }
`;
