import { gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Project,
  ProjectConstructionTypeEnum,
  ProjectStatusEnum,
  ProjectTypeEnum,
} from 'types/common';

import Filters from 'components/Filters';
import SearchBar from 'components/Inputs/Search';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import TileList from 'components/TileList';

import { transformFilters } from 'utils/transformFn';

import theme from './theme.module.scss';

export const GET_PROJECTS_QUERY = gql`
  query GetProjects($filter: ProjectFilter) {
    getProjects(filter: $filter) {
      _id
      referenceId
      name
      type
      status
      constructionType
      customer {
        _id
        referenceId
        fname
        lname
      }
      site {
        _id
        referenceId
      }
    }
  }
`;

type ProjectsQueryResponse = {
  getProjects: Project[];
};

type ProjectsQueryVariables = {
  filter: {
    constructionType?: null | ProjectConstructionTypeEnum[];
    type?: ProjectTypeEnum[] | null;
    status?: null | ProjectStatusEnum[];
    createdAtDateRange?: any;
  };
};

const Projects: FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, any>>({});
  const {
    data: projects,
    loading: projectsLoading,
    refetch,
  } = useQuery<ProjectsQueryResponse, ProjectsQueryVariables>(GET_PROJECTS_QUERY);

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetch({
      filter: newFilters,
    });
  };

  const projectActions = [
    <Button variant="contained" size="small" onClick={() => navigate('create-project')}>
      <AddIcon fontSize="small" />
    </Button>,
  ];

  const renderContent = () => {
    return (
      <div className={theme.container}>
        <SearchBar
          name="project search"
          label="Search a Project"
          placeholder="ID / Name / Customer ID / Customer Name / Phone"
          onSearchInputChange={searchFilter => {
            applyFilters({
              ...filters,
              searchTerm: !!searchFilter ? searchFilter : null,
            });
          }}
        />
        <Filters
          refetch={data => {
            applyFilters({
              searchTerm: filters.searchTerm ?? null,
              ...transformFilters(data),
            });
          }}
          type="projects"
        />
        {projectsLoading ? (
          <LoadingIndicator size="1.6rem" />
        ) : (
          <TileList type="project" list={projects?.getProjects} />
        )}
      </div>
    );
  };

  return (
    <Navbar title="Projects" actions={projectActions}>
      {renderContent()}
    </Navbar>
  );
};

export default Projects;
