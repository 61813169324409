import { gql, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { SITE_UPDATE_SUCCESS } from 'data/notificationsConst';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Site } from 'types/common';

import GroupedAccordion from 'components/Accordion/GroupedAccordion';
import AssociatedCustomers from 'components/AssociatedCustomers';
import { getUpdatedFields } from 'components/FormPanel';
import GoBackButton from 'components/GoBackButton';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import TileList from 'components/TileList';

import { removeEmptyFields } from 'utils/common';

import SiteAddress from './SiteAddress';
import UpdateSiteForm from './UpdateSiteForm';

const GET_SITE_QUERY = gql`
  query GetSiteById($id: ID!) {
    getSiteById(_id: $id) {
      _id
      referenceId
      name
      area {
        _id
        name
      }
      siteImages
      siteStatus
      siteType
      commercialType
      siteSizeMeasurement
      builtUpArea
      siteSize
      expectedCompletion
      noOfFloors
      noOfFlats
      associatedCustomers {
        _id
        fname
        lname
        mobile
        referenceId
        email
        gender
        isVerified
        prefferedLanguage
        status
        customerType {
          _id
          name
        }
      }
      address {
        _id
        houseNo
        floorNo
        addressLine1
        addressLine2
        area {
          _id
          name
        }
        landmark
        pincode
        city
        state
        latitude
        longitude
        status
      }
      projects {
        _id
        name
        type
        description
        status
        constructionType
        customer {
          referenceId
          fname
          lname
          _id
        }
        site {
          _id
          referenceId
        }
      }
    }
  }
`;

const UPDATE_SITE_MUTATION = gql`
  mutation UpdateSite($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      _id
      referenceId
      name
      area {
        _id
        name
      }
      siteImages
      siteStatus
      siteType
      commercialType
      siteSizeMeasurement
      builtUpArea
      siteSize
      expectedCompletion
      noOfFloors
      noOfFlats
      address {
        _id
        houseNo
        floorNo
        addressLine1
        addressLine2
        area {
          _id
          name
        }
        landmark
        pincode
        city
        state
        latitude
        longitude
        status
      }
      associatedCustomers {
        _id
        fname
        lname
        mobile
        referenceId
        email
        gender
        isVerified
        prefferedLanguage
        status
        customerType {
          _id
          name
        }
      }
      projects {
        _id
        name
        type
        description
        status
        constructionType
        customer {
          referenceId
          fname
          lname
          _id
        }
        site {
          _id
          referenceId
        }
      }
    }
  }
`;

type GetSiteQueryResponse = {
  getSiteById: Site;
};

type GetSiteQueryVariables = {
  id: string;
};

type UpdateSiteMutationResponse = {
  updateSite: Site;
};

type UpdateSiteMutationVariables = {
  input: any;
};

const SitePage = () => {
  const [activeIndex, setActiveIndex] = useState<number | string>(0);
  const { siteId = '' } = useParams<{ siteId: string }>();
  const navigate = useNavigate();

  const { data: site, loading: siteLoading } = useQuery<
    GetSiteQueryResponse,
    GetSiteQueryVariables
  >(GET_SITE_QUERY, {
    variables: {
      id: siteId,
    },
  });

  const [updateSite, { loading: updatingSite }] = useMutation<
    UpdateSiteMutationResponse,
    UpdateSiteMutationVariables
  >(UPDATE_SITE_MUTATION);

  const handleSubmit = (data: Site) => {
    const siteImages = data.siteImages;
    let updatedFields = getUpdatedFields(site?.getSiteById, data);
    if (
      JSON.stringify(site?.getSiteById.expectedCompletion) ===
      JSON.stringify(data.expectedCompletion)
    ) {
      delete updatedFields.expectedCompletion;
    }

    updatedFields = removeEmptyFields(updatedFields);
    updatedFields._id = site?.getSiteById._id;

    if (JSON.stringify(siteImages) !== JSON.stringify(site?.getSiteById.siteImages))
      updatedFields.siteImages = siteImages;

    updateSite({
      variables: {
        input: updatedFields,
      },
      onCompleted: _ => {
        toast.success(SITE_UPDATE_SUCCESS);
      },
    });
  };

  const stringifiedSiteInfo = JSON.stringify({
    site: {
      _id: site?.getSiteById._id,
      referenceId: site?.getSiteById.referenceId,
      name: site?.getSiteById.name,
    },
  });

  return (
    <Navbar>
      <GoBackButton title="Site Details" />
      {siteLoading || !!!site ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid container flexDirection="column" pb={4}>
          <Grid item xs={12}>
            <GroupedAccordion
              activeIndex={activeIndex}
              currIndex={0}
              setActiveIndex={setActiveIndex}
              title="Site"
            >
              <Grid px={1} py={1.5}>
                <UpdateSiteForm
                  loading={siteLoading || updatingSite}
                  handleSubmit={handleSubmit}
                  site={site.getSiteById}
                />
              </Grid>
            </GroupedAccordion>
          </Grid>
          <Grid item xs={12}>
            <GroupedAccordion
              title="Site Address"
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              currIndex={1}
            >
              <Grid px={1} py={1.5}>
                <SiteAddress address={site.getSiteById.address} />
              </Grid>
            </GroupedAccordion>
          </Grid>
          <Grid item xs={12}>
            <GroupedAccordion
              title="Projects"
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              currIndex={2}
            >
              <Grid px={1.5} py={1.5}>
                {!!site.getSiteById.projects.length && (
                  <Grid item xs={12} mb={2}>
                    <TileList type="project" list={site.getSiteById.projects} variant="elevation" />
                  </Grid>
                )}

                <Grid container item xs={12} my={0.5}>
                  <Button
                    fullWidth
                    variant={'contained'}
                    size="small"
                    sx={{ fontSize: '0.8rem' }}
                    startIcon={<AddIcon />}
                    onClick={() => navigate(`/projects/create-project?data=${stringifiedSiteInfo}`)}
                  >
                    Create New
                  </Button>
                </Grid>
              </Grid>
            </GroupedAccordion>
          </Grid>
          <Grid item xs={12}>
            <GroupedAccordion
              title="Associated Customers"
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              currIndex={3}
            >
              {/* <Grid px={1.5} py={1.5}>
                {!!site.getSiteById.associatedCustomers.length && (
                  <Grid item xs={12} mb={2}>
                    <TileList
                      type="customer"
                      list={site.getSiteById.associatedCustomers}
                      variant="elevation"
                    />
                  </Grid>
                )}

                <Grid container item xs={12} my={0.5}>
                  <Button
                    fullWidth
                    variant={'contained'}
                    size="small"
                    sx={{ fontSize: '0.8rem' }}
                    startIcon={<AddIcon />}
                    // onClick={() => navigate(`/customers/create-customer?data=${}`)}
                  >
                    Create New
                  </Button>
                </Grid>
              </Grid> */}
              <Grid px={1.5} py={1.5}>
                <AssociatedCustomers
                  id={site.getSiteById._id}
                  associatedCustomers={site.getSiteById.associatedCustomers ?? []}
                  updating={updatingSite}
                  updateMutation={updateSite}
                />
              </Grid>
            </GroupedAccordion>
          </Grid>
        </Grid>
      )}
    </Navbar>
  );
};

export default SitePage;
