import { gql } from '@apollo/client';
import { User } from 'types/common';

export type GetCRMUsersQueryResponse = {
  fetchCRMUsers: User[];
};

export const GET_CRM_USERS_QUERY = gql`
  query FetchCRMUsers {
    fetchCRMUsers {
      _id
      empId
      fname
      lname
    }
  }
`;

export type GetFieldSalesUsersQueryResponse = {
  fetchFieldSalesUsers: User[];
};

export const GET_FIELD_SALES_USERS = gql`
  query FetchFieldSalesUsers {
    fetchFieldSalesUsers {
      _id
      empId
      fname
      lname
    }
  }
`;

export type GetStoreLeadUsersQueryResponse = {
  fetchStoreLeadUsers: User[];
};

export const GET_STORE_LEAD_USERS = gql`
  query FetchStoreLeadUsers {
    fetchStoreLeadUsers {
      _id
      empId
      fname
      lname
    }
  }
`;

export type GetStoreAssociateUsersQueryResponse = {
  fetchStoreAssociateUsers: User[];
};

export const GET_STORE_ASSOCIATE_USERS = gql`
  query FetchStoreAssociateUsers {
    fetchStoreAssociateUsers {
      _id
      empId
      fname
      lname
    }
  }
`;

export type GetUsersForFollowupAssignmentQueryResponse = {
  fetchUsersForFollowupAssignment: User[];
};

export const GET_USERS_FOR_FOLLOWUP_ASSIGNMENT = gql`
  query FetchUsersForFollowupAssignment {
    fetchUsersForFollowupAssignment {
      _id
      empId
      fname
      lname
    }
  }
`;

export type GetUsersForFieldVisitAssignmentQueryResponse = {
  fetchUsersForFieldVisitAssignment: User[];
};

export const GET_USERS_FOR_FIELD_VISIT_ASSIGNMENT = gql`
  query FetchUsersForFieldVisitAssignment {
    fetchUsersForFieldVisitAssignment {
      _id
      empId
      fname
      lname
    }
  }
`;
