import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Customer, FieldVisit, Followup, Lead, Project, Site } from 'types/common';

import Tile from 'components/Tile';

import theme from './theme.module.scss';

type TileListProps = {
  variant?: 'elevation' | 'outlined';
  list?: Customer[] | Project[] | Site[] | Lead[] | Followup[] | FieldVisit[];
  type: 'customer' | 'project' | 'site' | 'lead' | 'followup' | 'fieldVisit';
};

const TileList: React.FC<TileListProps> = ({ list = [], type, variant = 'elevation' }) => {
  return (
    <Grid container className={theme.tilesContainer}>
      {list.map(item => (
        <Grid className={theme.tile} item key={item._id}>
          <Tile type={type} data={item} variant={variant} />
        </Grid>
      ))}
    </Grid>
  );
};

export default TileList;
