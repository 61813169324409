import { Card, CardActions, CardContent, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FunnelStateEnum, LeadStatusEnum, TileProps } from 'types/common';

import { CustomerTileActions, FollowupTileActions, LeadTileActions } from './Actions';
import {
  CustomerTileDetails,
  FieldVisitTileDetails,
  FollowupTileDetails,
  LeadTileDetails,
  ProjectTileDetails,
  SiteTileDetails,
} from './Details';
import {
  CustomerTileHeader,
  FieldVisitTileHeader,
  FollowupTileHeader,
  LeadTileHeader,
  ProjectTileHeader,
} from './Header';
import theme from './theme.module.scss';

const Tile: FC<TileProps> = ({ type, data, variant = 'elevation' }) => {
  const navigate = useNavigate();
  const assignedComponent = useMemo(() => {
    switch (type) {
      case 'customer':
        return {
          TileHeader: <CustomerTileHeader customer={data} />,
          TileDetails: <CustomerTileDetails customer={data} />,
          TileActions: <CustomerTileActions customer={data} />,
          navigateTo: '/customers',
        };

      case 'lead':
        return {
          TileHeader: <LeadTileHeader lead={data} />,
          TileDetails: <LeadTileDetails lead={data} />,
          TileActions: <LeadTileActions lead={data} />,
          navigateTo: '/leads',
        };
      case 'project':
        return {
          TileHeader: <ProjectTileHeader project={data} />,
          TileDetails: <ProjectTileDetails project={data} />,
          TileActions: <></>,
          navigateTo: '/projects',
        };
      case 'site':
        return {
          TileHeader: null,
          TileDetails: <SiteTileDetails site={data} />,
          TileActions: <></>,
          navigateTo: '/sites',
        };
      case 'followup':
        return {
          TileHeader: <FollowupTileHeader followup={data} />,
          TileDetails: <FollowupTileDetails followup={data} />,
          TileActions: <FollowupTileActions followup={data} />,
          navigateTo: '/followups',
        };
      case 'fieldVisit':
        return {
          TileHeader: <FieldVisitTileHeader fieldVisit={data} />,
          TileDetails: <FieldVisitTileDetails fieldVisit={data} />,
          TileActions: <></>,
          navigateTo: '/field-visits',
        };
      default:
        return {
          TileHeader: null,
          TileDetails: null,
          TileActions: null,
          navigateTo: '',
        };
    }
  }, [type, data]);

  const cardCustomCSS = () => {
    return {
      backgroundColor: (theme: Theme) => {
        if (type === 'lead') {
          if (data.status === LeadStatusEnum.CONVERTED) return grey[200];
          else if (data.funnelState === FunnelStateEnum.HOT) return theme.palette.funnelState.hot;
          else if (data.funnelState === FunnelStateEnum.WARM) return theme.palette.funnelState.warm;
          else return theme.palette.background.default;
        } else {
          return theme.palette.background.default;
        }
      },
      minWidth: '280px',
      // border: (theme: Theme) =>
      //   `2px solid ${
      //     type === 'lead' && data.funnelState === FunnelStateEnum.HOT
      //       ? '#f5b9b9'
      //       : type === 'lead' && data.funnelState === FunnelStateEnum.WARM
      //       ? '#bcf4bc'
      //       : 'none'
      //   }`,
    };
  };

  return (
    <Card variant={variant} elevation={4} className={theme.cardContainer} sx={cardCustomCSS()}>
      <CardContent
        onClick={() => navigate(`${assignedComponent.navigateTo}/${data._id}`)}
        className={theme.cardBody}
      >
        {assignedComponent.TileHeader && (
          <div className={theme.cardHeaderContainer}>{assignedComponent.TileHeader}</div>
        )}
        {assignedComponent.TileDetails}
      </CardContent>
      {assignedComponent.TileActions && (
        <CardActions className={theme.actionContainer} sx={{ p: 0 }} disableSpacing>
          {assignedComponent.TileActions}
        </CardActions>
      )}
    </Card>
  );
};

export default Tile;
