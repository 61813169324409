import { gql, useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { GetCustomersQueryResponse, customersName } from 'graphql/query/area';
import { GET_SITES_OPTIONS_QUERY, GetSitesOptionsQueryResponse } from 'graphql/query/site';
import React, { useEffect, useState } from 'react';
import ProjectAddress from 'routes/Project/ProjectAddress';
import {
  AutoCompleteOption,
  Project,
  ProjectConstructionTypeEnum,
  ProjectTypeEnum,
  Site,
  SiteTypeEnum,
} from 'types/common';

import { getUpdatedFields } from 'components/FormPanel';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';

import { removeEmptyFields } from 'utils/common';
import { transformCustomerNames } from 'utils/transformFn';

// const CUSTOMER_TYPE_QUERY = gql`
//   query GetCustomerTypeIdByName($name: String!) {
//     getCustomerTypeIdByName(name: $name) {
//       _id
//       name
//     }
//   }
// `;

const GET_ADDRESS_BY_SITE_ID = gql`
  query GetSiteById($id: ID!) {
    getSiteById(_id: $id) {
      _id
      siteType
      address {
        _id
        addressLine2
        addressLine1
        landmark
        pincode
        city
        state
        latitude
        longitude
        area {
          _id
          name
        }
      }
    }
  }
`;

type GetAddressBySiteQueryResponse = {
  getSiteById: Site;
};

// type CustomerTypeQueryResponse = {
//   getCustomerTypeIdByName: CustomerType;
// };

const ProjectForm: React.FC<{
  onSubmit: (arg: any) => void;
  initialData?: Partial<Project>;
  variant: 'CREATE' | 'UDPATE';
  loading: boolean;
  renderAddress?: boolean;
}> = ({ onSubmit, initialData = {}, variant, loading, renderAddress = false }) => {
  const [selectedSiteType, setSelectedSiteType] = useState(initialData.site?.siteType ?? '');
  const [formState, setFormState] = useState<Record<string, any>>(initialData);

  // const { data: customerType } = useQuery<CustomerTypeQueryResponse>(CUSTOMER_TYPE_QUERY, {
  //   variables: {
  //     name: 'END CUSTOMER',
  //   },
  // });

  const [getSelectedSiteById] = useLazyQuery<GetAddressBySiteQueryResponse>(GET_ADDRESS_BY_SITE_ID);

  const [getCustomers, { data: customers, loading: customersLoading }] =
    useLazyQuery<GetCustomersQueryResponse>(customersName, {
      // variables: {
      //   filter: {
      //     customerType: [customerType?.getCustomerTypeIdByName._id ?? ''],
      //   },
      // },
    });

  const [getSites, { data: sites, loading: sitesLoading }] =
    useLazyQuery<GetSitesOptionsQueryResponse>(GET_SITES_OPTIONS_QUERY);

  useEffect(() => {
    if (!!initialData.site?._id) assignAddressAndType(initialData.site._id);
  }, [initialData.site?._id]);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const assignAddressAndType = (siteId: string) => {
    getSelectedSiteById({
      variables: {
        id: siteId,
      },
      onCompleted: res => {
        setFormState(prev => ({
          ...prev,
          site: {
            ...(prev.site ?? {}),
            address: res.getSiteById.address,
          },
        }));
        setSelectedSiteType(res.getSiteById.siteType);
      },
    });
  };

  const handleSiteChange = (option: AutoCompleteOption) => {
    setFormState(prev => ({
      ...prev,
      site: option,
      type: '',
      noOfBathrooms: '',
      noOfKitchens: '',
      noOfBedrooms: '',
    }));

    if (!!option._id) {
      assignAddressAndType(option._id);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const projectFields = removeEmptyFields(formState);

    delete projectFields.leads;

    if (
      selectedSiteType !== SiteTypeEnum.RESIDENTIAL &&
      (formState.noOfBedrooms !== null ||
        formState.noOfKitchens !== null ||
        formState.noOfBathrooms !== null)
    ) {
      projectFields.noOfBedrooms = null;
      projectFields.noOfKitchens = null;
      projectFields.noOfBathrooms = null;
    }

    onSubmit(projectFields);
  };

  const getProjectTypes = () => {
    const emptyOption = (
      <MenuItem key="empty-option" value={''}>
        Please select site first
      </MenuItem>
    );

    let availableTypes: string[] = [];

    if (!!selectedSiteType) {
      if (selectedSiteType === SiteTypeEnum.RESIDENTIAL) {
        availableTypes = [
          ProjectTypeEnum.APARTMENT,
          ProjectTypeEnum.INDEPENDENT_HOUSE,
          ProjectTypeEnum.VILLA,
        ];
      } else if (selectedSiteType === SiteTypeEnum.COMMERCIAL) {
        availableTypes = [ProjectTypeEnum.COMMERCIAL];
      } else if (selectedSiteType === SiteTypeEnum.INDUSTRIAL) {
        availableTypes = [ProjectTypeEnum.INDUSTRIAL];
      }
    } else {
      return [emptyOption];
    }
    const res = Object.values(availableTypes).map(o => (
      <MenuItem key={o} value={o}>
        {o}
      </MenuItem>
    ));

    return res;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container mt={1.5} mb={2} columnSpacing={1.5} rowGap={2}>
        {variant === 'UDPATE' && (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                value={formState.referenceId}
                disabled
                label="Project ID"
                type="string"
                required
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              value={formState.name ?? ''}
              onChange={e => handleChange('name', e.target.value)}
              label="Project Name"
              type="string"
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id={'construction-label'}>Construction Type</InputLabel>
            <Select
              labelId="construction-label"
              label="Construction Type"
              size="medium"
              value={formState.constructionType ?? ''}
              fullWidth
              required
              onChange={e => handleChange('constructionType', e.target.value)}
            >
              {Object.values(ProjectConstructionTypeEnum).map(el => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={variant === 'CREATE' ? 12 : 6} md={6}>
          <FormControl fullWidth>
            <AutocompleteWithFetch
              fetch={getSites}
              handleChange={option => handleSiteChange(option)}
              label="Site"
              required
              labelWithId
              loading={sitesLoading}
              options={sites?.getSites ?? []}
              value={formState.site ?? { _id: '', name: '' }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField value={selectedSiteType} disabled label="Site Type" variant="outlined" />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id={'project-type-label'}>Project Type</InputLabel>
            <Select
              labelId="project-type-label"
              label="Project Type"
              size="medium"
              value={formState.type ?? ''}
              fullWidth
              required
              onChange={e => handleChange('type', e.target.value)}
            >
              {getProjectTypes()}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={selectedSiteType === SiteTypeEnum.RESIDENTIAL ? 6 : 12}>
          <FormControl fullWidth>
            <AutocompleteWithFetch
              fetch={getCustomers}
              handleChange={option => handleChange('customer', option)}
              label="Customer"
              required
              loading={customersLoading}
              labelWithId
              options={transformCustomerNames(customers?.getCustomers ?? [])}
              value={
                !!formState.customer?.fname
                  ? transformCustomerNames([formState.customer])[0]
                  : formState.customer ?? {
                      _id: '',
                      name: '',
                      referenceId: '',
                    }
              }
              // variables={{
              //   filter: {
              //     customerType: [customerType?.getCustomerTypeIdByName._id],
              //   },
              // }}
            />
          </FormControl>
        </Grid>
        {selectedSiteType === SiteTypeEnum.RESIDENTIAL && (
          <>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  name="noOfBedrooms"
                  label="No of Bedrooms"
                  required
                  value={formState.noOfBedrooms ?? ''}
                  onChange={e =>
                    handleChange('noOfBedrooms', !!e.target.value ? +e.target.value : '')
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  name="noOfKitchens"
                  label="No of Kitchens"
                  required
                  value={formState.noOfKitchens ?? ''}
                  onChange={e =>
                    handleChange('noOfKitchens', !!e.target.value ? +e.target.value : '')
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  name="noOfBathrooms"
                  label="No of Bathrooms"
                  required
                  value={formState.noOfBathrooms ?? ''}
                  onChange={e =>
                    handleChange('noOfBathrooms', !!e.target.value ? +e.target.value : '')
                  }
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              type="text"
              rows={3}
              value={formState.description ?? ''}
              label="Description"
              onChange={e => handleChange('description', e.target.value)}
            />
          </FormControl>
        </Grid>
        {renderAddress && (
          <Grid item xs={12}>
            <Grid mb={1.5} px={0.5}>
              <Divider>
                <Typography fontWeight={600}>Address Details</Typography>
              </Divider>
            </Grid>
            <ProjectAddress
              siteAddress={formState.site?.address ?? {}}
              onChange={({ floorNo, houseNo }) =>
                setFormState(prev => ({ ...prev, floorNo, houseNo }))
              }
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <LoadingButton
            type="submit"
            disabled={
              loading ||
              (variant === 'UDPATE' &&
                !!!Object.keys(getUpdatedFields(initialData, formState)).length)
            }
            loading={loading}
            variant="contained"
          >
            {variant === 'CREATE' ? 'Create' : 'Update'}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProjectForm;
