import { gql } from '@apollo/client';
import { AutoCompleteOption } from 'types/common';

export type CustomerTypesResponse = {
  getCustomerTypes: AutoCompleteOption[];
};

export const GET_CUSTOMER_TYPES_QUERY = gql`
  query GetCustomerTypes {
    getCustomerTypes {
      _id
      name
    }
  }
`;
