export function downloadBinaryData(templateResponse: { filename: string; content: string }) {
  const binaryData = atob(templateResponse.content);

  const arrayBuffer = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    arrayBuffer[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = templateResponse.filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
