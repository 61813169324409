import { FormControl, Grid, TextField } from '@mui/material';
import { CallStatusEnum, FollowupStatusEnum, FollowupTypeEnum } from 'types/common';

import DateRange from 'components/Inputs/DateRange';
import { MultiSelect } from 'components/Inputs/Select';

const FollowupFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const handleChange = (fieldName: string, val: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            value={state.searchTerm ?? ''}
            label="Follow up Search"
            variant="outlined"
            onChange={e => handleChange('searchTerm', e.target.value)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <DateRange
          value={state.followupDateRange ?? [null, null]}
          onChange={val => handleChange('followupDateRange', val)}
          title="Followup Date"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Status"
          handleChange={val => handleChange('status', val)}
          options={Object.values(FollowupStatusEnum).map(o => ({ label: o, value: o }))}
          value={state.status ?? []}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Type"
          handleChange={val => handleChange('followupType', val)}
          options={Object.values(FollowupTypeEnum).map(o => ({ label: o, value: o }))}
          value={state.followupType ?? []}
        />
      </Grid>

      <Grid item xs={12}>
        <MultiSelect
          label="Call Status"
          handleChange={val => handleChange('callStatus', val)}
          options={Object.values(CallStatusEnum).map(o => ({ label: o, value: o }))}
          value={state.callStatus ?? []}
        />
      </Grid>
    </Grid>
  );
};

export default FollowupFilters;
