import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { AdminCreateModuleEnum } from 'types/common';

const CREATE_BRANDS_MUTATION = gql`
  mutation CreateBrands($input: GraphQLUpload!) {
    createBrands(input: $input)
  }
`;

const CREATE_ROLES_MUTATION = gql`
  mutation CreateRoles($input: GraphQLUpload!) {
    createRoles(input: $input)
  }
`;

const CREATE_LEAD_SOURCES_MUTATION = gql`
  mutation CreateLeadSources($input: GraphQLUpload!) {
    createLeadSources(input: $input)
  }
`;

const CREATE_USERS_MUTATION = gql`
  mutation CreateUsers($input: GraphQLUpload!) {
    createUsers(input: $input)
  }
`;

const CREATE_FOLLOWUPS_MUTATION = gql`
  mutation CreateFollowups($input: GraphQLUpload!) {
    createFollowups(input: $input)
  }
`;

const CREATE_FIELD_VISITS_MUTATION = gql`
  mutation CreateFieldVisits($input: GraphQLUpload!) {
    createFieldVisits(input: $input)
  }
`;

const CREATE_HFD_PRODUCTS_MUTATION = gql`
  mutation CreateHfdProducts($input: GraphQLUpload!) {
    createHfdProducts(input: $input)
  }
`;

const CREATE_PINCODES_MUTATION = gql`
  mutation CreatePincodes($input: GraphQLUpload!) {
    createPincodes(input: $input)
  }
`;

const CREATE_PROJECTS_MUTATION = gql`
  mutation CreateProjects($input: GraphQLUpload!) {
    createProjects(input: $input)
  }
`;

const CREATE_SITES_MUTATION = gql`
  mutation CreateSites($input: GraphQLUpload!) {
    createSites(input: $input)
  }
`;

const CREATE_STORES_MUTATION = gql`
  mutation CreateStores($input: GraphQLUpload!) {
    createStores(input: $input)
  }
`;

const CREATE_CUSTOMERS_MUTATION = gql`
  mutation CreateCustomers($input: GraphQLUpload!) {
    createCustomers(input: $input)
  }
`;

const CREATE_HWT_PRODUCTS_MUTATION = gql`
  mutation CreateHwtProducts($input: GraphQLUpload!) {
    createHwtProducts(input: $input)
  }
`;

const CREATE_LHA_PRODUCTS_MUTATION = gql`
  mutation CreateLhaProducts($input: GraphQLUpload!) {
    createLhaProducts(input: $input)
  }
`;

const CREATE_MKW_PRODUCTS_MUTATION = gql`
  mutation CreateMkwProducts($input: GraphQLUpload!) {
    createMkwProducts(input: $input)
  }
`;

const CREATE_TFL_PRODUCTS_MUTATION = gql`
  mutation CreateTflProducts($input: GraphQLUpload!) {
    createTflProducts(input: $input)
  }
`;

const CREATE_WMT_PRODUCTS_MUTATION = gql`
  mutation CreateWmtProducts($input: GraphQLUpload!) {
    createWmtProducts(input: $input)
  }
`;

const CREATE_STORE_AREAS_MUTATION = gql`
  mutation CreateStoreAreas($input: GraphQLUpload!) {
    createStoreAreas(input: $input)
  }
`;

const CREATE_AREAS_MUTATION = gql`
  mutation CreateAreas($input: GraphQLUpload!) {
    createAreas(input: $input)
  }
`;

const CREATE_TARGETS_MUTATION = gql`
  mutation CreateTargets($input: GraphQLUpload!) {
    createTargets(input: $input)
  }
`;

const useBulkCreateFn = ({ type }: { type: AdminCreateModuleEnum }) => {
  const [bulkUploadParams, setBulkUploadFuncParams] = useState<any>([() => {}, false, null]);

  const [createBrands, { loading: creatingBrands, error: createBrandsError }] =
    useMutation(CREATE_BRANDS_MUTATION);

  const [createRoles, { loading: creatingRoles, error: createRolesError }] =
    useMutation(CREATE_ROLES_MUTATION);

  const [createLeadSources, { loading: creatingLeadSources, error: createLeadSourcesError }] =
    useMutation(CREATE_LEAD_SOURCES_MUTATION);

  const [createUsers, { loading: creatingUsers, error: createUsersError }] =
    useMutation(CREATE_USERS_MUTATION);

  const [createFollowups, { loading: creatingFollowups, error: createFollowupsError }] =
    useMutation(CREATE_FOLLOWUPS_MUTATION);

  const [createFieldVisits, { loading: creatingFieldVisits, error: createFieldVisitsError }] =
    useMutation(CREATE_FIELD_VISITS_MUTATION);

  const [createHfdProducts, { loading: creatingHfdProducts, error: createHfdProductsError }] =
    useMutation(CREATE_HFD_PRODUCTS_MUTATION);

  const [createPincodes, { loading: creatingPincodes, error: createPincodesError }] =
    useMutation(CREATE_PINCODES_MUTATION);

  const [createStores, { loading: creatingStores, error: createStoresError }] =
    useMutation(CREATE_STORES_MUTATION);

  const [createProjects, { loading: creatingProjects, error: createProjectsError }] =
    useMutation(CREATE_PROJECTS_MUTATION);

  const [createSites, { loading: creatingSites, error: createSitesError }] =
    useMutation(CREATE_SITES_MUTATION);

  const [createCustomers, { loading: creatingCustomers, error: createCustomersError }] =
    useMutation(CREATE_CUSTOMERS_MUTATION);

  const [createHwtProducts, { loading: creatingHwtProducts, error: createHwtProductsError }] =
    useMutation(CREATE_HWT_PRODUCTS_MUTATION);

  const [createLhaProducts, { loading: creatingLhaProducts, error: createLhaProductsError }] =
    useMutation(CREATE_LHA_PRODUCTS_MUTATION);

  const [createMkwProducts, { loading: creatingMkwProducts, error: createMkwProductsError }] =
    useMutation(CREATE_MKW_PRODUCTS_MUTATION);

  const [createTflProducts, { loading: creatingTflProducts, error: createTflProductsError }] =
    useMutation(CREATE_TFL_PRODUCTS_MUTATION);

  const [createWmtProducts, { loading: creatingWmtProducts, error: createWmtProductsError }] =
    useMutation(CREATE_WMT_PRODUCTS_MUTATION);

  const [createStoreAreas, { loading: creatingStoreAreas, error: createStoreAreasError }] =
    useMutation(CREATE_STORE_AREAS_MUTATION);

  const [createAreas, { loading: creatingAreas, error: createAreasError }] =
    useMutation(CREATE_AREAS_MUTATION);

  const [createTargets, { loading: creatingTargets, error: createTargetsError }] =
    useMutation(CREATE_TARGETS_MUTATION);

  useEffect(() => {
    switch (type) {
      case AdminCreateModuleEnum.BRAND:
        setBulkUploadFuncParams([createBrands, creatingBrands, createBrandsError]);
        break;
      case AdminCreateModuleEnum.ROLE:
        setBulkUploadFuncParams([createRoles, creatingRoles, createRolesError]);
        break;
      case AdminCreateModuleEnum.LEADSOURCE:
        setBulkUploadFuncParams([createLeadSources, creatingLeadSources, createLeadSourcesError]);
        break;
      case AdminCreateModuleEnum.USER:
        setBulkUploadFuncParams([createUsers, creatingUsers, createUsersError]);
        break;
      case AdminCreateModuleEnum.FOLLOWUP:
        setBulkUploadFuncParams([createFollowups, creatingFollowups, createFollowupsError]);
        break;
      case AdminCreateModuleEnum.FIELDVISIT:
        setBulkUploadFuncParams([createFieldVisits, creatingFieldVisits, createFieldVisitsError]);
        break;
      case AdminCreateModuleEnum.HFDPRODUCT:
        setBulkUploadFuncParams([createHfdProducts, creatingHfdProducts, createHfdProductsError]);
        break;
      case AdminCreateModuleEnum.PINCODE:
        setBulkUploadFuncParams([createPincodes, creatingPincodes, createPincodesError]);
        break;
      case AdminCreateModuleEnum.PROJECT:
        setBulkUploadFuncParams([createProjects, creatingProjects, createProjectsError]);
        break;
      case AdminCreateModuleEnum.SITE:
        setBulkUploadFuncParams([createSites, creatingSites, createSitesError]);
        break;
      case AdminCreateModuleEnum.STORE:
        setBulkUploadFuncParams([createStores, creatingStores, createStoresError]);
        break;
      case AdminCreateModuleEnum.CUSTOMER:
        setBulkUploadFuncParams([createCustomers, creatingCustomers, createCustomersError]);
        break;
      case AdminCreateModuleEnum.HWTPRODUCT:
        setBulkUploadFuncParams([createHwtProducts, creatingHwtProducts, createHwtProductsError]);
        break;
      case AdminCreateModuleEnum.LHAPRODUCT:
        setBulkUploadFuncParams([createLhaProducts, creatingLhaProducts, createLhaProductsError]);
        break;
      case AdminCreateModuleEnum.MKWPRODUCT:
        setBulkUploadFuncParams([createMkwProducts, creatingMkwProducts, createMkwProductsError]);
        break;
      case AdminCreateModuleEnum.TFLPRODUCT:
        setBulkUploadFuncParams([createTflProducts, creatingTflProducts, createTflProductsError]);
        break;
      case AdminCreateModuleEnum.WMTPRODUCT:
        setBulkUploadFuncParams([createWmtProducts, creatingWmtProducts, createWmtProductsError]);
        break;
      case AdminCreateModuleEnum.STOREAREA:
        setBulkUploadFuncParams([createStoreAreas, creatingStoreAreas, createStoreAreasError]);
        break;
      case AdminCreateModuleEnum.AREA:
        setBulkUploadFuncParams([createAreas, creatingAreas, createAreasError]);
        break;
      case AdminCreateModuleEnum.TARGET:
        setBulkUploadFuncParams([createTargets, creatingTargets, createTargetsError]);
        break;
      default:
        break;
    }
  }, [
    type,
    creatingRoles,
    creatingBrands,
    creatingLeadSources,
    creatingUsers,
    creatingFollowups,
    creatingFieldVisits,
    creatingHfdProducts,
    creatingHwtProducts,
    creatingLhaProducts,
    creatingMkwProducts,
    creatingTflProducts,
    creatingWmtProducts,
    creatingPincodes,
    creatingSites,
    creatingStores,
    creatingProjects,
    creatingCustomers,
    creatingStoreAreas,
    creatingAreas,
    creatingTargets,
    createRolesError,
    createBrandsError,
    createLeadSourcesError,
    createUsersError,
    createFollowupsError,
    createFieldVisitsError,
    createHfdProductsError,
    createHwtProductsError,
    createLhaProductsError,
    createMkwProductsError,
    createTflProductsError,
    createWmtProductsError,
    createPincodesError,
    createProjectsError,
    createSitesError,
    createStoresError,
    createCustomersError,
    createStoreAreasError,
    createAreasError,
    createTargetsError,
  ]);

  return bulkUploadParams;
};

export default useBulkCreateFn;
