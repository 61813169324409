import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, InputAdornment, TextField } from '@mui/material';
import {
  WMTBrandNamesQueryResponse,
  WMTBrandNamesQueryVariables,
  WMTGradeOrFinishesQueryResponse,
  WMTGradeOrFinishesQueryVariables,
  WMTGroupNamesQueryResponse,
  WMTGroupNamesQueryVariables,
  WMTProductsQueryResponse,
  WMTProductsQueryVariables,
  WMTSizesQueryResponse,
  WMTSizesQueryVariables,
  WMTSubCategoriesQueryResponse,
  WMTUnitsOfMeasureQueryResponse,
  WMTUnitsOfMeasureQueryVariables,
  WMT_BRAND_NAMES_QUERY,
  WMT_GRADE_OR_FINISHES_QUERY,
  WMT_GROUP_NAMES_QUERY,
  WMT_PRODUCTS_QUERY,
  WMT_SIZES_QUERY,
  WMT_SUB_CATEGORIES_QUERY,
  WMT_UNITS_OF_MEASURE_QUERY,
} from 'graphql/query/product/wmtProduct';
import React, { useState } from 'react';
import { AutoCompleteOption } from 'types/common';

import { getUpdatedFields } from 'components/FormPanel';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';

import { removeEmptyFields } from 'utils/common';
import { rankedFieldsChangeHandler } from 'utils/formHandlers';

const WMTProductForm: React.FC<{
  onSubmit: (arg: any) => void;
  loading: boolean;
  disabled?: boolean;
  initialData?: Record<string, any>;
  variant?: 'CREATE' | 'UPDATE';
}> = ({ onSubmit, loading, disabled = false, initialData = {}, variant = 'CREATE' }) => {
  const [formState, setFormState] = useState<Record<string, any>>(initialData);
  const [selectedProduct, setSelectedProduct] = useState<{
    _id: string;
    price: number;
    brand: AutoCompleteOption;
  }>({
    _id: '',
    brand: initialData.brand ?? { _id: '', name: '' },
    price: initialData.price ?? '',
  });
  const rankedFields = [
    'subCategory',
    'group',
    'product',
    'gradeOrFinish',
    'size',
    'unitOfMeasure',
    'brand',
    'quantity',
  ];

  const [getSubCategories, { data: subCategories, loading: loadingSubCategories }] =
    useLazyQuery<WMTSubCategoriesQueryResponse>(WMT_SUB_CATEGORIES_QUERY);

  const [getGroupNames, { data: groups, loading: loadingGroupNames }] = useLazyQuery<
    WMTGroupNamesQueryResponse,
    WMTGroupNamesQueryVariables
  >(WMT_GROUP_NAMES_QUERY);

  const [getProducts, { data: products, loading: loadingProducts }] = useLazyQuery<
    WMTProductsQueryResponse,
    WMTProductsQueryVariables
  >(WMT_PRODUCTS_QUERY);

  const [getGradeOrFinishes, { data: gradeOrFinishes, loading: loadingGradeOrFinishes }] =
    useLazyQuery<WMTGradeOrFinishesQueryResponse, WMTGradeOrFinishesQueryVariables>(
      WMT_GRADE_OR_FINISHES_QUERY
    );

  const [getSizes, { data: sizes, loading: loadingSizes }] = useLazyQuery<
    WMTSizesQueryResponse,
    WMTSizesQueryVariables
  >(WMT_SIZES_QUERY);

  const [getUnitsOfMeasure, { data: unitsOfMeasure, loading: loadingUnitsOfMeasure }] =
    useLazyQuery<WMTUnitsOfMeasureQueryResponse, WMTUnitsOfMeasureQueryVariables>(
      WMT_UNITS_OF_MEASURE_QUERY
    );

  const [getBrands, { data: brands, loading: loadingBrands }] = useLazyQuery<
    WMTBrandNamesQueryResponse,
    WMTBrandNamesQueryVariables
  >(WMT_BRAND_NAMES_QUERY);

  const getPrice = (value: AutoCompleteOption) => {
    if (!!brands) {
      const currProduct = brands.getWmtProducts.find(p => p.brand._id === value._id) as any;
      setSelectedProduct(currProduct);
    }
  };

  const handleSubmit = () => {
    const newFields = removeEmptyFields({ ...formState });

    if (!!newFields.brand) {
      onSubmit({
        product: selectedProduct._id,
        quantity: formState.quantity,
      });
    }
  };

  return (
    <Grid container px={1} py={2} rowGap={2} columnSpacing={1.5}>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Sub Category"
          fetch={getSubCategories}
          handleChange={val =>
            rankedFieldsChangeHandler('subCategory', val, setFormState, rankedFields)
          }
          loading={loadingSubCategories}
          disabled={disabled}
          options={(subCategories?.getWmtProducts ?? []).map(p => p.subCategory)}
          value={formState.subCategory ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Group"
          fetch={getGroupNames}
          handleChange={val => rankedFieldsChangeHandler('group', val, setFormState, rankedFields)}
          loading={loadingGroupNames}
          options={(groups?.getWmtProducts ?? []).map(p => p.group)}
          value={formState.group ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.subCategory || !!!formState.subCategory?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteWithFetch
          label="Product"
          fetch={getProducts}
          handleChange={val =>
            rankedFieldsChangeHandler('product', val, setFormState, rankedFields)
          }
          loading={loadingProducts}
          options={(products?.getWmtProducts ?? []).map(p => p.product)}
          value={formState.product ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.group || !!!formState.group?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Grade/Finish"
          fetch={getGradeOrFinishes}
          handleChange={val =>
            rankedFieldsChangeHandler('gradeOrFinish', val, setFormState, rankedFields)
          }
          loading={loadingGradeOrFinishes}
          options={(gradeOrFinishes?.getWmtProducts ?? []).map(p => p.gradeOrFinish)}
          value={formState.gradeOrFinish ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.product || !!!formState.product?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
              product: formState.product?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Size"
          fetch={getSizes}
          handleChange={val => rankedFieldsChangeHandler('size', val, setFormState, rankedFields)}
          loading={loadingSizes}
          options={(sizes?.getWmtProducts ?? []).map(p => p.size)}
          value={formState.size ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.gradeOrFinish || !!!formState.gradeOrFinish?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
              product: formState.product?._id,
              gradeOrFinish: formState.gradeOrFinish?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Unit of Measure"
          fetch={getUnitsOfMeasure}
          handleChange={val =>
            rankedFieldsChangeHandler('unitOfMeasure', val, setFormState, rankedFields)
          }
          loading={loadingUnitsOfMeasure}
          options={(unitsOfMeasure?.getWmtProducts ?? []).map(p => p.unitOfMeasure)}
          value={formState.unitOfMeasure ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.size || !!!formState.size._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
              product: formState.product?._id,
              gradeOrFinish: formState.gradeOrFinish?._id,
              size: formState.size?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Brand"
          fetch={getBrands}
          handleChange={val => {
            rankedFieldsChangeHandler('brand', val, setFormState, rankedFields);
            getPrice(val);
          }}
          loading={loadingBrands}
          options={(brands?.getWmtProducts ?? []).map(product => product.brand)}
          value={formState.brand ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.unitOfMeasure || !!!formState.unitOfMeasure._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
              product: formState.product?._id,
              gradeOrFinish: formState.gradeOrFinish?._id,
              size: formState.size?._id,
              unitOfMeasure: formState.unitOfMeasure?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            label="Quantity"
            value={formState.quantity ?? ''}
            type="number"
            onChange={e =>
              rankedFieldsChangeHandler(
                'quantity',
                !!e.target.value ? +e.target.value : '',
                setFormState,
                rankedFields
              )
            }
            variant="outlined"
            required
            disabled={!!!formState.brand || !!!formState.brand._id || disabled}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            label="Estimated Value"
            type="number"
            variant="outlined"
            disabled
            value={formState.quantity ? +formState.quantity * selectedProduct.price : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <b color="lightGray">₹</b>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Grid>
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={4} marginRight="auto">
          <LoadingButton
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            loading={loading}
            disabled={
              variant === 'CREATE'
                ? !!!formState.quantity
                : !(
                    !!formState.quantity &&
                    !!Object.keys(getUpdatedFields(initialData, formState)).length
                  )
            }
          >
            {variant === 'CREATE' ? 'Create' : 'Update'}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WMTProductForm;
