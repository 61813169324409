import { FormControl } from '@mui/material';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

type DateInputProps = {
  value: Dayjs | null;
  onChange: (arg: any) => void;
  required?: boolean;
} & DatePickerProps<any>;

const DateInput = ({ onChange, value, required = false, ...props }: DateInputProps) => {
  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={!!value ? dayjs(value) : null}
          format="DD-MM-YYYY"
          onChange={newValue => {
            onChange(dayjs(newValue).format('YYYY-MM-DD'));
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              fullWidth: true,
              required,
            },
          }}
          {...props}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default DateInput;
