import { gql } from '@apollo/client';
import { Customer } from 'types/common';

export type UpdateCustomerResponse = {
  updateCustomer: Customer;
};
export type UpdateCustomerVariables = {
  input: Partial<Customer> & { _id: string };
};

export const UPDATE_CUSTOMER_MUTATION = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      _id
      fname
      lname
      mobile
      alternateMobile
      prefferedLanguage
      status
      isVerified
      gender
      email
      customerType {
        name
        _id
      }
      assignedToSales {
        _id
        fname
        lname
      }
      assignedToCRM {
        fname
        lname
        _id
      }
      address {
        _id
        houseNo
        floorNo
        addressLine1
        addressLine2
        area {
          _id
          name
        }
        landmark
        pincode
        city
        state
      }
    }
  }
`;
