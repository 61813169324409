import { Grid, GridProps } from '@mui/material';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

type DataTableProps = {
  data: { rows: any[]; columns: any[] };
  height?: number;
  containerProps?: GridProps;
  tableProps?: DataGridProps;
};

const DataTable: React.FC<DataTableProps> = ({
  data,
  height = 600,
  containerProps,
  tableProps,
}) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    handleFileChange(data);
  }, [data]);

  const handleFileChange = files => {
    if (files && files[0]) {
      const file = files[0];

      const reader = new FileReader();

      reader.onload = function (e) {
        if (e.target) {
          const data = new Uint8Array(e.target.result as ArrayBufferLike);

          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData: any[] = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          let newColumns = jsonData[0].map((d, ind) => ({
            field: 'f' + ind,
            headerName: d,
            width: 250,
            renderHeader: params => <strong>{params.colDef.headerName}</strong>,
          }));

          let newRows = jsonData.slice(1).map((row, index) =>
            row.reduce(
              (acc, value, ind) => {
                acc['f' + ind] = value;
                return acc;
              },
              { id: index }
            )
          );
          setColumns(newColumns);
          setRows(newRows);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      {!!rows.length || !!columns.length ? (
        <Grid item xs={12} container height={height} {...containerProps}>
          <Grid item xs={12}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50]}
              {...tableProps}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default DataTable;
