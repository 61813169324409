import { gql, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import {
  CUSTOMER_ADDRESS_CREATE_SUCCESS,
  CUSTOMER_ADDRESS_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_SUCCESS,
} from 'data/notificationsConst';
import {
  CREATE_ADDRESS_MUTATION,
  CreateAddressMutationResponse,
  CreateAddressMutationVariables,
  UPDATE_ADDRESS_MUTATION,
  UpdateAddressMutationResponse,
  UpdateAddressMutationVariables,
} from 'graphql/mutation/address';
import {
  UPDATE_CUSTOMER_MUTATION,
  UpdateCustomerResponse,
  UpdateCustomerVariables,
} from 'graphql/mutation/customer';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Customer as CustomerProps } from 'types/common';

import GroupedAccordion from 'components/Accordion/GroupedAccordion';
import ProjectAdd from 'components/AddProject';
import { getUpdatedFields } from 'components/FormPanel';
import GoBackButton from 'components/GoBackButton';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import TileList from 'components/TileList';

import { removeEmptyFields } from 'utils/common';

import CustomerForm from './CustomerForm';
import VerifyCustomer from './VerifyCustomer';

const GET_CUSTOMER_QUERY = gql`
  query GetCustomerById($id: ID!) {
    getCustomerById(_id: $id) {
      _id
      referenceId
      fname
      lname
      mobile
      alternateMobile
      prefferedLanguage
      status
      isVerified
      gender
      email
      projects {
        _id
        referenceId
        name
        type
        status
        constructionType
        customer {
          _id
          referenceId
          fname
          lname
        }
        site {
          _id
          referenceId
        }
      }
      customerType {
        name
        _id
      }
      assignedToSales {
        _id
        fname
        lname
        empId
      }
      assignedToCRM {
        fname
        lname
        _id
        empId
      }
      address {
        _id
        houseNo
        floorNo
        addressLine1
        addressLine2
        area {
          _id
          name
        }
        landmark
        pincode
        city
        state
      }
      leads {
        _id
        customer {
          _id
          fname
          lname
          referenceId
          email
          mobile
        }
        productCategory
        status
        referenceId
        expectedClosure
        estimatedValue
        funnelState
        leadProducts {
          _id
          category
          product {
            ... on HfdProduct {
              _id
              subCategory
              group
              standard
              size
              unitOfMeasure
              price
              brand {
                _id
                name
              }
              status
              createdAt
              updatedAt
            }
            ... on HwtProduct {
              _id
              subCategory
              group
              size
              unitOfMeasure
              price
              weight
              standard
              type
              status
              createdAt
              updatedAt
            }
            ... on LhaProduct {
              _id
              subCategory
              group
              application
              wattage
              price
              brand {
                _id
                name
              }
              status
              createdAt
              updatedAt
            }
            ... on MkwProduct {
              _id
              subCategory
              propertyType
              propertyStatus
              usage
              criteria
              shapeOfKitchen
              typeOfWardrobe
              size
              price
              status
              createdAt
              updatedAt
            }
            ... on TflProduct {
              _id
              subCategory
              group
              size
              unitOfMeasure
              price
              brand {
                _id
                name
              }
              finish
              status
              createdAt
              updatedAt
            }
            ... on WmtProduct {
              _id
              subCategory
              group
              product
              gradeOrFinish
              size
              unitOfMeasure
              brand {
                _id
                referenceId
                name
              }
              price
              status
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;

type CustomerQueryResponse = {
  getCustomerById: CustomerProps;
};
type CustomerQueryVariables = {
  id: string;
};

const Customer = () => {
  const { customerId = '' } = useParams<{ customerId: string }>();
  const [openPopup, togglePopup] = useState(false);
  const navigate = useNavigate();

  const { data: customer, loading } = useQuery<CustomerQueryResponse, CustomerQueryVariables>(
    GET_CUSTOMER_QUERY,
    {
      variables: {
        id: customerId,
      },
    }
  );

  const [updateCustomer, { loading: updatingCustomer, error }] = useMutation<
    UpdateCustomerResponse,
    UpdateCustomerVariables
  >(UPDATE_CUSTOMER_MUTATION);

  const [createAddress, { loading: creatingAddress }] = useMutation<
    CreateAddressMutationResponse,
    CreateAddressMutationVariables
  >(CREATE_ADDRESS_MUTATION);

  const [updateAddress, { loading: updatingAddress }] = useMutation<
    UpdateAddressMutationResponse,
    UpdateAddressMutationVariables
  >(UPDATE_ADDRESS_MUTATION);

  const [activeIdx, setActiveIdx] = useState<string | number>(0);

  const handleUpdateCustomer = (updatedFields: any) => {
    if (Object.keys(updatedFields).length > 1) {
      updateCustomer({
        variables: {
          input: updatedFields,
        },
        onCompleted: data => {
          toast.success(CUSTOMER_UPDATE_SUCCESS);
          if (!data.updateCustomer.isVerified) {
            togglePopup(true);
          }
        },
      });
    }
  };

  const handleSubmit = (updatedCustomer: CustomerProps) => {
    const newData = { ...updatedCustomer };
    if (customer) {
      const oldData = customer.getCustomerById;

      const addressFields: any = newData.address ?? {};

      // @ts-ignore
      delete newData.address;

      const updatedCustomerFields = getUpdatedFields(oldData, newData);
      updatedCustomerFields._id = oldData._id;

      if (oldData.address && oldData.address._id) {
        const updatedAddressFields = getUpdatedFields(oldData.address, addressFields);

        if (!!Object.keys(updatedAddressFields).length) {
          updatedAddressFields._id = oldData.address._id;
          updateAddress({
            variables: {
              input: updatedAddressFields,
            },
            onCompleted: _ => {
              toast.success(CUSTOMER_ADDRESS_UPDATE_SUCCESS);
            },
          });
        }
        handleUpdateCustomer(updatedCustomerFields);
      } else if (Object.keys(removeEmptyFields(addressFields)).length > 1) {
        createAddress({
          variables: {
            input: removeEmptyFields(addressFields),
          },
          onCompleted: res => {
            toast.success(CUSTOMER_ADDRESS_CREATE_SUCCESS);
            updatedCustomerFields.address = res.createAddress._id;
            handleUpdateCustomer(updatedCustomerFields);
          },
        });
      } else {
        handleUpdateCustomer(updatedCustomerFields);
      }
    }
  };

  return (
    <Navbar>
      <GoBackButton title="Customer Details" />

      {loading || !customer ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid container flexDirection="column" mb={1.5}>
          <GroupedAccordion
            currIndex={0}
            activeIndex={activeIdx}
            setActiveIndex={setActiveIdx}
            title="Customer"
          >
            <Grid item xs={12} py={1.5} px={1}>
              <CustomerForm
                customer={customer.getCustomerById}
                loading={loading || updatingCustomer || updatingAddress || creatingAddress}
                error={error}
                handleSubmit={handleSubmit}
              />
            </Grid>
          </GroupedAccordion>
          <GroupedAccordion
            activeIndex={activeIdx}
            currIndex={1}
            setActiveIndex={setActiveIdx}
            title="Projects"
          >
            <Grid px={1.5} py={1.5}>
              {!!customer.getCustomerById.projects.length && (
                <Grid item xs={12} mb={2}>
                  <TileList type="project" list={customer.getCustomerById.projects} />
                </Grid>
              )}
              <Grid item xs={12} container>
                <ProjectAdd customerInfo={customer.getCustomerById} />
              </Grid>
            </Grid>
          </GroupedAccordion>
          <GroupedAccordion
            activeIndex={activeIdx}
            currIndex={2}
            setActiveIndex={setActiveIdx}
            title="Leads"
          >
            <Grid px={1.5} py={1.5}>
              {!!customer.getCustomerById.leads.length && (
                <Grid item xs={12} mb={2}>
                  <TileList type="lead" list={customer.getCustomerById.leads} />
                </Grid>
              )}
              <Grid item xs={12} container>
                <Button
                  fullWidth
                  variant={'outlined'}
                  size="small"
                  sx={{ fontSize: '0.8rem' }}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    const data = {
                      _id: customer.getCustomerById._id,
                      referenceId: customer.getCustomerById.referenceId,
                      name:
                        customer.getCustomerById.fname +
                        ' ' +
                        (customer.getCustomerById.lname ?? ''),
                    };
                    navigate(`/leads/create-lead?data=${JSON.stringify({ customer: data })}`);
                  }}
                >
                  Create New Lead
                </Button>
              </Grid>
            </Grid>
          </GroupedAccordion>
          <Grid item xs={12}>
            <VerifyCustomer
              onClose={() => togglePopup(false)}
              open={openPopup}
              id={customer.getCustomerById._id}
            />
          </Grid>
        </Grid>
      )}
    </Navbar>
  );
};

export default Customer;
