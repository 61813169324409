import { NetworkStatus, gql, useQuery } from '@apollo/client';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Lead } from 'types/common';

import LoadingIndicator from 'components/LoadingIndicator';
import { SimplePopup } from 'components/Popup';
import TileList from 'components/TileList';

import { formatCurrency } from 'utils/formatHelper';

import Timeline from './Timeline';
import theme from './theme.module.scss';

const DASHBOARD_QUERY = gql`
  query GetDashboardMetrics($dateRange: [Date]!) {
    getDashboardMetrics(dateRange: $dateRange) {
      targetLeadCount
      assignedLeadCount
      closedLeadCount
      lostLeadCount
      openLeadCount
      targetLeadValue
      assignedLeadValue
      closedLeadValue
      lostLeadValue
      openLeadValue
      leads {
        _id
        customer {
          _id
          fname
          lname
          referenceId
          email
          mobile
        }
        productCategory
        status
        referenceId
        expectedClosure
        estimatedValue
        funnelState
        leadProducts {
          _id
          category
          product {
            ... on HfdProduct {
              _id
              subCategory
              group
              standard
              size
              unitOfMeasure
              price
              brand {
                _id
                name
              }
              status
              createdAt
              updatedAt
            }
            ... on HwtProduct {
              _id
              subCategory
              group
              size
              unitOfMeasure
              price
              status
              createdAt
              updatedAt
              weight
              standard
              type
            }
            ... on LhaProduct {
              _id
              subCategory
              group
              application
              wattage
              price
              brand {
                _id
                name
              }
              status
              createdAt
              updatedAt
            }
            ... on MkwProduct {
              _id
              subCategory
              propertyType
              propertyStatus
              usage
              criteria
              shapeOfKitchen
              typeOfWardrobe
              size
              price
              status
              createdAt
              updatedAt
            }
            ... on TflProduct {
              _id
              subCategory
              group
              size
              unitOfMeasure
              price
              brand {
                _id
                name
              }
              status
              createdAt
              updatedAt
              finish
            }
            ... on WmtProduct {
              _id
              subCategory
              group
              product
              gradeOrFinish
              size
              unitOfMeasure
              brand {
                _id
                referenceId
                name
              }
              price
              status
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;

type DashboardMatricsType = {
  targetLeadCount: number;
  assignedLeadCount: number;
  closedLeadCount: number;
  lostLeadCount: number;
  openLeadCount: number;
  targetLeadValue: number;
  assignedLeadValue: number;
  closedLeadValue: number;
  lostLeadValue: number;
  openLeadValue: number;
};

type DashboardQueryResponse = {
  getDashboardMetrics: DashboardMatricsType & {
    leads: Lead[];
  };
};

type DashboardQueryVariables = {
  dateRange: (string | null)[];
};

enum LeadTimelineEnum {
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS WEEK',
  THIS_MONTH = 'THIS MONTH',
  CUSTOM = 'CUSTOM',
}

const LeadSection = () => {
  const [filterByLeads, setFilterByLeads] = useState<Lead[]>([]);
  const [openCalender, toggleCalenderView] = useState(false);
  const [currDateFilter, setCurrDateFilter] = useState<(Dayjs | null)[]>([null, null]);
  const [currTimeline, setCurrTimeline] = useState(LeadTimelineEnum.THIS_MONTH);

  const [localDashboardMatrics, setLocalDashboardMatrics] = useState<DashboardMatricsType | null>(
    null
  );

  const {
    data: dashboardMetrics,
    refetch: refetchDashboardMetrics,
    networkStatus: dashboardNetworkStatus,
  } = useQuery<DashboardQueryResponse, DashboardQueryVariables>(DASHBOARD_QUERY, {
    variables: {
      dateRange: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (dashboardNetworkStatus === NetworkStatus.ready) {
      setFilterByLeads(dashboardMetrics?.getDashboardMetrics?.leads ?? []);

      if (dashboardMetrics?.getDashboardMetrics) {
        const newMatrics = { ...dashboardMetrics?.getDashboardMetrics };
        // @ts-ignore
        delete newMatrics.leads;
        setLocalDashboardMatrics(newMatrics ?? null);
      }
    }
  }, [dashboardNetworkStatus]);

  const mapping = {
    all: ['ALLOCATED', 'IN_PROGRESS', 'CONVERTED', 'LOST'],
    closed: ['CONVERTED'],
    lost: ['LOST'],
    open: ['IN_PROGRESS', 'ALLOCATED'],
  };

  const leadTimelines = [
    {
      label: LeadTimelineEnum.TODAY,
      value: LeadTimelineEnum.TODAY,
    },
    {
      label: LeadTimelineEnum.THIS_WEEK,
      value: LeadTimelineEnum.THIS_WEEK,
    },
    {
      label: LeadTimelineEnum.THIS_MONTH,
      value: LeadTimelineEnum.THIS_MONTH,
    },
    {
      label: LeadTimelineEnum.CUSTOM,
      value: LeadTimelineEnum.CUSTOM,
    },
  ];

  const handleDateFilterSubmit = (data: (Dayjs | null)[]) => {
    toggleCalenderView(false);
    setCurrTimeline(LeadTimelineEnum.CUSTOM);
    setCurrDateFilter(data);

    const newDateRange = [
      data[0]?.format('YYYY-MM-DD') ?? null,
      data[1]?.format('YYYY-MM-DD') ?? dayjs().format('YYYY-MM-DD'),
    ];

    refetchDashboardMetrics({
      dateRange: newDateRange,
    });
  };

  const handleTimelineChange = (val: LeadTimelineEnum) => {
    if (val === LeadTimelineEnum.CUSTOM) {
      toggleCalenderView(true);
      return;
    }

    setCurrTimeline(val);
    if (currDateFilter[0] !== null || currDateFilter[1] !== null) setCurrDateFilter([null, null]);

    let startDate, endDate;

    switch (val) {
      case LeadTimelineEnum.TODAY:
        startDate = dayjs().startOf('day').format('YYYY-MM-DD');
        endDate = dayjs().format('YYYY-MM-DD');
        break;
      case LeadTimelineEnum.THIS_WEEK:
        startDate = dayjs().startOf('week').format('YYYY-MM-DD');
        endDate = dayjs().format('YYYY-MM-DD');
        break;
      case LeadTimelineEnum.THIS_MONTH:
        startDate = dayjs().startOf('month').format('YYYY-MM-DD');
        endDate = dayjs().format('YYYY-MM-DD');
        break;
      default:
        break;
    }

    const newDateRange: string[] = !!startDate ? [startDate, endDate] : [];

    refetchDashboardMetrics({
      dateRange: newDateRange,
    });
  };

  const loadingDashboardMetrics =
    dashboardNetworkStatus === NetworkStatus.loading ||
    dashboardNetworkStatus === NetworkStatus.refetch ||
    dashboardNetworkStatus === NetworkStatus.setVariables;

  const StatusCard = ({ label, value, type }) => (
    <div
      className={theme.statusCard}
      onClick={e => {
        filterByStatusCardClick({ value, type });
      }}
    >
      <div className={theme.label}>{label}</div>
      <div className={theme.value}>{value}</div>
    </div>
  );

  const TargetStatusCard = ({ label, value }) => (
    <div className={theme.targetStatusCard}>
      <div className={theme.label}>{label}</div>
      <div className={theme.value}>{value}</div>
    </div>
  );

  const filterByStatusCardClick = e => {
    var newLeads = (dashboardMetrics?.getDashboardMetrics?.leads ?? []).filter(lead => {
      return mapping[e.type].indexOf(lead.status) >= 0;
    });
    setFilterByLeads(newLeads);
  };

  return (
    <Grid container className={theme.container} direction={'column'} mb={5}>
      {dashboardNetworkStatus === NetworkStatus.loading || !!!localDashboardMatrics ? (
        <Grid item xs={12} justifySelf={'center'}>
          <LoadingIndicator size="1.6rem" />
        </Grid>
      ) : (
        <>
          <div className={theme.statusContainer}>
            <TargetStatusCard label="Target Leads" value={localDashboardMatrics.targetLeadCount} />
            <TargetStatusCard
              label="Target Value"
              value={formatCurrency(localDashboardMatrics.targetLeadValue, 0)}
            />
            <StatusCard
              label={'Assigned Leads'}
              type={'all'}
              value={localDashboardMatrics.assignedLeadCount}
            />
            <StatusCard
              label={'Assigned (₹)'}
              type={'all'}
              value={formatCurrency(localDashboardMatrics.assignedLeadValue, 0)}
            />
            <StatusCard
              label={'Closed Leads'}
              type={'closed'}
              value={localDashboardMatrics.closedLeadCount}
            />
            <StatusCard
              label={'Closed (₹)'}
              type={'closed'}
              value={formatCurrency(localDashboardMatrics.closedLeadValue, 0)}
            />
            <StatusCard
              label={'Lost Leads'}
              type={'lost'}
              value={localDashboardMatrics.lostLeadCount}
            />
            <StatusCard
              label={'Lost (₹)'}
              type={'lost'}
              value={formatCurrency(localDashboardMatrics.lostLeadValue, 0)}
            />
            <StatusCard
              label={'Open Leads'}
              type={'open'}
              value={localDashboardMatrics.openLeadCount}
            />
            <StatusCard
              label={'Open (₹)'}
              type={'open'}
              value={formatCurrency(localDashboardMatrics.openLeadValue, 0)}
            />
          </div>

          <Timeline
            currTimeline={currTimeline}
            handleTimeline={handleTimelineChange}
            timelines={leadTimelines}
          />

          {loadingDashboardMetrics || !!!dashboardMetrics ? (
            <Grid item xs={12} justifySelf={'center'}>
              <LoadingIndicator size="1.3rem" />
            </Grid>
          ) : !!dashboardMetrics.getDashboardMetrics.leads.length ? (
            <TileList
              type="lead"
              list={filterByLeads ?? dashboardMetrics.getDashboardMetrics.leads}
            />
          ) : (
            <Box height="20vh" display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2">No leads to show. Try changing the date range</Typography>
            </Box>
          )}
          <SimplePopup onClose={() => toggleCalenderView(false)} open={openCalender}>
            <CalenderView initialDateRange={currDateFilter} onSubmit={handleDateFilterSubmit} />
          </SimplePopup>
        </>
      )}
    </Grid>
  );
};

const CalenderView: React.FC<{
  onSubmit: (arg: (Dayjs | null)[]) => void;
  initialDateRange: (Dayjs | null)[];
}> = ({ initialDateRange, onSubmit }) => {
  const [fromCalender, setFromCalender] = useState<{ show: boolean; value: Dayjs | null }>({
    show: true,
    value: initialDateRange[0],
  });
  const [toCalender, setToCalender] = useState<{ show: boolean; value: Dayjs | null }>({
    show: false,
    value: initialDateRange[1],
  });

  return (
    <Grid container direction="column">
      <Typography variant="h6" px={2} pt={2} fontWeight={600}>
        {fromCalender.show ? 'Start Date' : 'End Date'}
      </Typography>
      {fromCalender.show && (
        <Grid container direction={'column'}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={fromCalender.value}
              maxDate={!!toCalender.value ? toCalender.value : undefined}
              disableFuture
              onChange={val =>
                setFromCalender(prev => ({
                  ...prev,
                  value: val,
                }))
              }
            />
          </LocalizationProvider>
          <Grid container item xs={12} mb={2} justifyContent={'flex-end'} pr={3}>
            <Button
              variant="outlined"
              onClick={() => {
                setFromCalender(prev => ({ ...prev, show: false }));
                setToCalender(prev => ({ ...prev, show: true }));
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
      {toCalender.show && (
        <Grid container direction={'column'}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={toCalender.value}
              minDate={fromCalender.value ? fromCalender.value : undefined}
              disableFuture
              onChange={val =>
                setToCalender({
                  show: true,
                  value: val,
                })
              }
            />
          </LocalizationProvider>
          <Grid container item xs={12} mb={2} justifyContent={'flex-end'} pr={3}>
            <Button
              variant="outlined"
              onClick={() => {
                setFromCalender(prev => ({ ...prev, show: true }));
                setToCalender(prev => ({ ...prev, show: false }));
              }}
              sx={{
                mr: 1,
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onSubmit([fromCalender.value, toCalender.value]);
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default LeadSection;
