import { DocumentNode } from 'graphql';

import { client } from './client';

export const updateCachedList = (
  query: DocumentNode,
  queryResponsePath: string,
  newRecord: any,
  variables?: Record<string, any>
) => {
  const currList = client.readQuery({ query, variables }) ?? { [queryResponsePath]: [] };

  client.writeQuery({
    query,
    variables: variables,
    data: {
      [queryResponsePath]: [newRecord, ...currList[queryResponsePath]],
    },
  });
};

export const deleteCachedListItem = (
  query: DocumentNode,
  queryResponsePath: string,
  deletedItemId: string,
  variables?: Record<string, any>
) => {
  const currList = client.readQuery({ query, variables }) ?? { [queryResponsePath]: [] };

  client.writeQuery({
    query,
    variables: variables,
    data: {
      [queryResponsePath]: currList[queryResponsePath].filter(li => li._id !== deletedItemId),
    },
  });
};
