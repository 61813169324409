import { LazyQueryExecFunction, OperationVariables, useLazyQuery } from '@apollo/client';
import { areasQuery } from 'graphql/query/area';
import { CustomerTypesResponse, GET_CUSTOMER_TYPES_QUERY } from 'graphql/query/customer';
import { useEffect, useState } from 'react';
import {
  AutoCompleteOption,
  CallStatusEnum,
  CustomerStatusEnum,
  FieldVisitStatusEnum,
  FieldVisitTypeEnum,
  FilterProps,
  FiltersVariant,
  FollowupStatusEnum,
  FollowupTypeEnum,
  FunnelStateEnum,
  LanguageEnum,
  LeadStatus,
  ProjectConstructionTypeEnum,
  ProjectStatusEnum,
  ProjectTypeEnum,
  SiteStatusEnum,
} from 'types/common';

/**
 * Reason for this being async is to get all the dynamic options from backend here.
 */
const getCustomersFilterSchema = async (initialData: any) => {
  const { customerType, prefferedLanguage, status, area, isVerified } = initialData;

  const filterSchema: FilterProps[] = [
    {
      fieldName: 'prefferedLanguage',
      type: 'multiple_select',
      label: 'Preffered Language',
      fullWidth: true,
      defaultValue: prefferedLanguage ?? [],
      options: Object.values(LanguageEnum).map(lng => ({
        label: lng,
        value: lng,
      })),
    },
    {
      fieldName: 'customerType',
      label: 'Customer Type',
      type: 'auto_complete_with_fetch',
      defaultValue: customerType ?? { name: '', _id: '' },
    },
    {
      fieldName: 'status',
      type: 'select',
      label: 'Status',
      placeholder: 'Status',
      defaultValue: status ?? [''],
      options: Object.values(CustomerStatusEnum).map(status => ({
        label: status,
        value: status,
      })),
    },
    {
      fieldName: 'area',
      label: 'Area',
      type: 'auto_complete_with_fetch',
      fullWidth: true,
      defaultValue: area ?? { name: '', _id: '' },
    },
    {
      fieldName: 'isVerified',
      label: 'Is Verified',
      type: 'selection',
      fullWidth: true,
      defaultValue: isVerified ?? [null],
      options: [
        {
          label: 'Verified',
          value: true,
        },
        {
          label: 'Not Verified',
          value: false,
        },
      ],
    },
  ];
  return filterSchema;
};

const getLeadsFilterSchema = async (initialData: any) => {
  const {
    funnelState,
    status,
    expectedClosureDateRange,
    ageRange,
    followupDateRange,
    estimatedValueRange,
  } = initialData;

  const filterSchema: FilterProps[] = [
    {
      fieldName: 'funnelState',
      type: 'select',
      label: 'Funnel State',
      placeholder: 'Funnel State',
      defaultValue: funnelState ?? [''],
      options: Object.values(FunnelStateEnum).map(state => ({
        label: state,
        value: state,
      })),
    },
    {
      fieldName: 'status',
      type: 'select',
      label: 'Status',
      placeholder: 'Status',
      defaultValue: status ?? [''],
      options: Object.values(LeadStatus).map(status => ({
        label: status,
        value: status,
      })),
    },
    {
      fieldName: 'expectedClosureDateRange',
      type: 'date-range',
      label: 'Expected Closure',
      fullWidth: true,
      placeholder: 'Expected Closure',
      defaultValue: expectedClosureDateRange ?? [null, null],
    },
    {
      fieldName: 'ageRange',
      type: 'date-range',
      label: 'Age',
      fullWidth: true,
      placeholder: 'Age',
      defaultValue: ageRange ?? [null, null],
    },
    {
      fieldName: 'followupDateRange',
      type: 'date-range',
      label: 'Followup Date',
      fullWidth: true,
      placeholder: 'Followup Date',
      defaultValue: followupDateRange ?? [null, null],
    },
    {
      fieldName: 'estimatedValueRange',
      type: 'range-slider',
      label: 'Estimated Value',
      minLabel: 'Min Amount',
      maxLabel: 'Max Amount',
      placeholder: 'Estimated value',
      fullWidth: true,
      defaultValue: estimatedValueRange ?? ['', ''],
    },
  ];
  return filterSchema;
};

const getProjectsFilterSchema = async (initialData: any) => {
  const { type, status, constructionType, createdAtDateRange } = initialData;
  const filterSchema: FilterProps[] = [
    {
      fieldName: 'type',
      type: 'multiple_select',
      label: 'Type',
      placeholder: 'Type',
      defaultValue: type ?? [],
      fullWidth: true,
      options: Object.values(ProjectTypeEnum).map(type => ({
        label: type,
        value: type,
      })),
    },
    {
      type: 'selection',
      fieldName: 'status',
      label: 'Status',
      options: Object.values(ProjectStatusEnum).map(type => ({
        label: type,
        value: type,
      })),
      fullWidth: true,
      defaultValue: status ?? [null],
    },
    {
      type: 'selection',
      fieldName: 'constructionType',
      label: 'Construction Type',
      options: Object.values(ProjectConstructionTypeEnum).map(type => ({
        label: type,
        value: type,
      })),
      defaultValue: constructionType ?? [null],
      fullWidth: true,
    },
    {
      fieldName: 'createdAtDateRange',
      type: 'date-range',
      label: 'Created at',
      fullWidth: true,
      placeholder: 'Created at',
      defaultValue: createdAtDateRange ?? [null, null],
    },
  ];

  return filterSchema;
};

const getSitesFilterSchema = async (initialData: any) => {
  const { siteMapping, siteStatus, expectedCompletion, area } = initialData;

  const filterSchema: FilterProps[] = [
    {
      fieldName: 'siteStatus',
      type: 'multiple_select',
      label: 'Site Status',
      placeholder: 'Status',
      fullWidth: true,
      defaultValue: siteStatus ?? [],
      options: Object.values(SiteStatusEnum).map(status => ({
        label: status,
        value: status,
      })),
    },
    {
      fieldName: 'area',
      label: 'Area',
      fullWidth: true,
      type: 'auto_complete_with_fetch',
      defaultValue: area ?? [],
    },
    {
      fieldName: 'expectedCompletion',
      type: 'date-range',
      label: 'Expected Completion',
      fullWidth: true,
      placeholder: 'Expected Completion',
      defaultValue: expectedCompletion ?? [null, null],
    },
    {
      //@Check mapping?
      type: 'selection',
      fieldName: 'siteMapping',
      label: 'Site Mapping',
      options: [
        {
          label: 'AVAILABLE',
          value: true,
        },
        {
          label: 'NOT AVAILABLE',
          value: false,
        },
      ],
      defaultValue: siteMapping ?? [null],
      fullWidth: true,
    },
  ];

  return filterSchema;
};

const getFollowupsFilterSchema = async (initialData: any) => {
  const { followupType, status, callStatus, followupDateRange } = initialData;

  const filterSchema: FilterProps[] = [
    {
      fieldName: 'followupType',
      type: 'select',
      label: 'Followup Type',
      placeholder: 'Followup Type',
      defaultValue: followupType ?? [],
      options: Object.values(FollowupTypeEnum).map(type => ({
        label: type,
        value: type,
      })),
    },
    {
      fieldName: 'status',
      type: 'multiple_select',
      label: 'Followup Status',
      placeholder: 'Followup Status',
      defaultValue: status ?? [],
      options: Object.values(FollowupStatusEnum).map(type => ({
        label: type,
        value: type,
      })),
    },
    {
      fieldName: 'callStatus',
      type: 'multiple_select',
      label: 'Call Status',
      placeholder: 'Call Status',
      fullWidth: true,
      defaultValue: callStatus ?? [],
      options: Object.values(CallStatusEnum).map(status => ({
        label: status,
        value: status,
      })),
    },
    {
      fieldName: 'followupDateRange',
      type: 'date-range',
      label: 'Followup Date',
      fullWidth: true,
      placeholder: 'Followup Date',
      defaultValue: followupDateRange ?? [null, null],
    },
  ];

  return filterSchema;
};

const getFieldVisitsFilterSchema = async (initialData: any) => {
  const { fieldVisitType, status, fieldVisitDateRange } = initialData;

  const filterSchema: FilterProps[] = [
    {
      fieldName: 'fieldVisitType',
      type: 'select',
      label: 'Field Visit Type',
      placeholder: 'Field Visit Type',
      defaultValue: fieldVisitType ?? [''],
      options: Object.values(FieldVisitTypeEnum).map(type => ({
        label: type,
        value: type,
      })),
    },
    {
      fieldName: 'status',
      type: 'multiple_select',
      label: 'Field Visit Status',
      placeholder: 'Field Visit Status',
      defaultValue: status ?? [],
      options: Object.values(FieldVisitStatusEnum).map(status => ({
        label: status,
        value: status,
      })),
    },
    {
      fieldName: 'fieldVisitDateRange',
      type: 'date-range',
      label: 'Field Visit Date',
      placeholder: 'Field Visit Date',
      defaultValue: fieldVisitDateRange ?? [null, null],
      fullWidth: true,
    },
  ];

  return filterSchema;
};

const useFilterSchema = (type: FiltersVariant, initialData: any) => {
  const [filterSchema, setFilterSchema] = useState<any[]>([]);
  const [getAreas, { data: areas, loading: areasLoading }] = useLazyQuery(areasQuery);
  const [fetchCustomerTypes, { data: customerTypes, loading: typesLoading }] =
    useLazyQuery<CustomerTypesResponse>(GET_CUSTOMER_TYPES_QUERY);

  const attachGqlFnsToAutocomplete = (
    fieldName: string,
    filters: any[],
    config: {
      fetchOptionsFn: LazyQueryExecFunction<any, OperationVariables> | (() => void);
      loading: boolean;
      options: AutoCompleteOption[];
    }
  ) => {
    const idx = filters.findIndex(el => el.fieldName === fieldName);
    filters[idx].autoCompleteConfig = config;
  };

  useEffect(() => {
    const getfilterSchema = async () => {
      let filters: FilterProps[];
      switch (type) {
        case 'customers':
          filters = await getCustomersFilterSchema(initialData ?? {});
          attachGqlFnsToAutocomplete('area', filters, {
            fetchOptionsFn: getAreas,
            loading: areasLoading,
            options: areas?.getAreas ?? [],
          });
          attachGqlFnsToAutocomplete('customerType', filters, {
            fetchOptionsFn: fetchCustomerTypes,
            loading: typesLoading,
            options: customerTypes?.getCustomerTypes ?? [],
          });

          break;
        case 'leads':
          filters = await getLeadsFilterSchema(initialData ?? {});
          break;
        case 'projects':
          filters = await getProjectsFilterSchema(initialData ?? {});
          break;
        case 'sites':
          filters = await getSitesFilterSchema(initialData ?? {});
          attachGqlFnsToAutocomplete('area', filters, {
            fetchOptionsFn: getAreas,
            loading: areasLoading,
            options: areas?.getAreas ?? [],
          });
          break;
        case 'follow-ups':
          filters = await getFollowupsFilterSchema(initialData ?? {});
          break;
        case 'field-visits':
          filters = await getFieldVisitsFilterSchema(initialData ?? {});
          break;
        default:
          filters = [];
          break;
      }
      setFilterSchema(filters);
    };
    getfilterSchema();
  }, [type, initialData, areas, areasLoading, customerTypes, typesLoading]);

  return [filterSchema];
};

export default useFilterSchema;
