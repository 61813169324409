import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
      funnelState: {
        hot: string;
        warm: string;
        cold?: string
      };
    }
  
    interface PaletteOptions {
      funnelState: {
        hot: string;
        warm: string;
      };
    }
  }
  
  const extendedTheme = createTheme({
    palette: {
      primary: {
        main: '#006AB4',
      },
      funnelState: {
        hot: '#e2fbe2',
        warm: '#fbf3da',
      },
    },
    typography: {
        fontFamily: `'Open Sans', sans-serif`,
        body1: {
          fontWeight: 400,
          fontSize: '0.8rem',
        },
        h1: {
          fontSize: '2rem',
          fontWeight: 700,
          marginTop: '2rem',
          color: '#212121',
        },
        h2: {
          fontSize: '2rem',
          fontWeight: 700,
          lineHeight: '2.125rem',
          marginTop: '2.5rem',
          marginBottom: '2.5rem',
          color: '#212121',
        },
        h3: {
          fontSize: '1.1rem',
          fontWeight: 600,
          lineHeight: '1.425rem',
        },
        overline: {
          lineHeight: '1.2rem'
        },
      },
  });

  export default extendedTheme