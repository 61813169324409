import { gql } from '@apollo/client';
import { AutoCompleteOption } from 'types/common';

export type GetSitesOptionsQueryResponse = {
  getSites: AutoCompleteOption[];
};
export const GET_SITES_OPTIONS_QUERY = gql`
  query GetSites {
    getSites {
      _id
      name
      referenceId
    }
  }
`;
