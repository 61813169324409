import { gql, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { CUSTOMER_CREATE_SUCCESS } from 'data/notificationsConst';
import { updateCachedList } from 'graphql/cacheUpdate';
import {
  CREATE_ADDRESS_MUTATION,
  CreateAddressMutationResponse,
  CreateAddressMutationVariables,
} from 'graphql/mutation/address';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CUSTOMERS_LIST_QUERY } from 'routes/Customers';
import { AddressType, Customer } from 'types/common';

import { clearInputErrors } from 'components/FormPanel';
import GoBackButton from 'components/GoBackButton';
import Navbar from 'components/Navbar';

import { isAutoCompleteOption } from 'utils/common';

import CustomerForm from './CustomerForm';
import VerifyCustomer from './VerifyCustomer';

const CREATE_CUSTOMER_MUTATION = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      _id
      fname
      lname
      email
      isVerified
      mobile
      customerType {
        _id
        name
      }
      gender
      assignedToCRM {
        _id
        fname
        lname
      }
      assignedToSales {
        _id
        fname
        lname
      }
      status
      prefferedLanguage
    }
  }
`;

type CreateCustomerResponse = {
  createCustomer: Customer;
};
type CreateCustomerVariables = {
  input: Omit<Customer, '_id'> & { fname: string; mobile: string };
};

const CreateNewCustomer = () => {
  const [createCustomer, { loading }] = useMutation<
    CreateCustomerResponse,
    CreateCustomerVariables
  >(CREATE_CUSTOMER_MUTATION);

  const [createAddress, { loading: creatingAddress }] = useMutation<
    CreateAddressMutationResponse,
    CreateAddressMutationVariables
  >(CREATE_ADDRESS_MUTATION);

  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState('');
  const [openPopup, togglePopup] = useState(false);

  const handleCreateCustomer = (formState: any) => {
    createCustomer({
      variables: {
        input: formState,
      },
      onCompleted: data => {
        updateCachedList(CUSTOMERS_LIST_QUERY, 'getCustomers', data.createCustomer);
        setCustomerId(data.createCustomer._id);
        toast.success(CUSTOMER_CREATE_SUCCESS);
        togglePopup(true);
      },
    });
  };

  const handleSubmit = (data: any) => {
    const formState = { ...data };

    for (const fieldName in formState) {
      const value = formState[fieldName];
      if (isAutoCompleteOption(value)) {
        if (!!value._id) formState[fieldName] = value._id;
        else delete formState[fieldName];
      } else if (!!!value) delete formState[fieldName];
      else if (fieldName === 'mobile' || fieldName === 'alternateMobile')
        formState[fieldName] = String(value);
    }

    const addressFields: Omit<AddressType, '_id'> = formState.address;
    delete formState.address;

    if (!!addressFields?.addressLine1) {
      createAddress({
        variables: {
          input: addressFields,
        },
        onCompleted: data => {
          formState.address = data.createAddress._id;
          handleCreateCustomer(formState);
        },
      });
    } else {
      handleCreateCustomer(formState);
    }
  };

  const onPopupClose = () => {
    togglePopup(false);
    navigate(`/customers/${customerId}`);
  };

  //Added by Naresh to clear the input validation errors;
  clearInputErrors();
  return (
    <Navbar>
      <GoBackButton title="Create new Customer" />
      <Grid container flexDirection="column" my={1.5}>
        <Grid item xs={12}>
          <CustomerForm
            error={null}
            handleSubmit={handleSubmit}
            loading={loading || creatingAddress}
          />
        </Grid>
        <Grid item xs={12}>
          <VerifyCustomer onClose={onPopupClose} open={openPopup} id={customerId} />
        </Grid>
      </Grid>
    </Navbar>
  );
};

export default CreateNewCustomer;
