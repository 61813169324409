import { gql } from '@apollo/client';
import { Project } from 'types/common';

export type ProjectNamesQueryResponse = {
  getProjects: Project[];
};

export type ProjectNamesQueryVariables = {
  filter: {
    searchTerm: string;
  };
};

export const PROJECT_NAMES_QUERY = gql`
  query GetProjects($filter: ProjectFilter) {
    getProjects(filter: $filter) {
      _id
      name
      referenceId
    }
  }
`;
