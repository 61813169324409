import { FormControl, Grid } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

import Fieldset from 'components/Fieldset';

const DateRange: React.FC<{
  value: [Dayjs | undefined, Dayjs | undefined];
  onChange: (arg: any) => void;
  startLabel?: string;
  endLabel?: string;
  title: string;
}> = ({ value, onChange, title, startLabel = 'Start Date', endLabel = 'End Date' }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Fieldset label={title}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <FormControl fullWidth>
              <MobileDatePicker
                label={startLabel}
                value={!!value && !!value[0] ? dayjs(value[0]) : null}
                format="DD/MM/YYYY"
                maxDate={value[1] ? dayjs(value[1]) : undefined}
                onChange={newValue =>
                  onChange([dayjs(newValue).format('YYYY-MM-DD'), value[1] ?? null])
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <MobileDatePicker
                label={endLabel}
                value={!!value && !!value[1] ? dayjs(value[1]) : null}
                minDate={value[0] ? dayjs(value[0]) : undefined}
                disabled={!!!value[0]}
                format="DD/MM/YYYY"
                onChange={newValue =>
                  onChange([value[0] ?? null, dayjs(newValue).format('YYYY-MM-DD')])
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </Fieldset>
    </LocalizationProvider>
  );
};

export default DateRange;
