import { gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Filters from 'components/Filters';
import SearchBar from 'components/Inputs/Search';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import TileList from 'components/TileList';

import { transformFilters } from 'utils/transformFn';

import theme from './theme.module.scss';

export const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      customer {
        _id
        fname
        lname
        referenceId
        email
        mobile
      }
      productCategory
      status
      referenceId
      expectedClosure
      estimatedValue
      funnelState
      leadProducts {
        _id
        category
        product {
          ... on HfdProduct {
            _id
            subCategory
            group
            standard
            size
            unitOfMeasure
            price
            brand {
              _id
              name
            }
            status
            createdAt
            updatedAt
          }
          ... on HwtProduct {
            _id
            subCategory
            group
            size
            unitOfMeasure
            price
            weight
            standard
            type
            status
            createdAt
            updatedAt
          }
          ... on LhaProduct {
            _id
            subCategory
            group
            application
            wattage
            price
            brand {
              _id
              name
            }
            status
            createdAt
            updatedAt
          }
          ... on MkwProduct {
            _id
            subCategory
            propertyType
            propertyStatus
            usage
            criteria
            shapeOfKitchen
            typeOfWardrobe
            size
            price
            status
            createdAt
            updatedAt
          }
          ... on TflProduct {
            _id
            subCategory
            group
            size
            unitOfMeasure
            price
            brand {
              _id
              name
            }
            finish
            status
            createdAt
            updatedAt
          }
          ... on WmtProduct {
            _id
            subCategory
            group
            product
            gradeOrFinish
            size
            unitOfMeasure
            brand {
              _id
              referenceId
              name
            }
            price
            status
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

const Leads = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, any>>({});

  const { data: leads, loading: leadsLoading, refetch } = useQuery(GET_LEADS_QUERY);

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetch({
      filter: newFilters,
    });
  };

  const leadActions = [
    <Button variant="contained" size="small" onClick={() => navigate('create-lead')}>
      <AddIcon fontSize="small" />
    </Button>,
  ];

  const renderContent = () => {
    return (
      <div className={theme.container}>
        <SearchBar
          name="lead search"
          label="Search a Lead"
          placeholder="ID / Customer ID / Customer Name / Phone"
          onSearchInputChange={searchFilter => {
            applyFilters({
              ...filters,
              searchTerm: !!searchFilter ? searchFilter : null,
            });
          }}
        />
        <Filters
          type="leads"
          refetch={data => {
            applyFilters({
              searchTerm: filters.searchTerm ?? null,
              ...transformFilters(data),
            });
          }}
        />
        {leadsLoading ? (
          <LoadingIndicator size="1.6rem" />
        ) : (
          <Grid mt={0.5}>
            <TileList type="lead" list={leads?.getLeads} />
          </Grid>
        )}
      </div>
    );
  };

  return (
    <Navbar title="Leads" actions={leadActions}>
      {renderContent()}
    </Navbar>
  );
};

export default Leads;
