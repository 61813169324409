import classNames from 'classnames';

import theme from './theme.module.scss';

const Fieldset: React.FC<{
  children: React.ReactNode;
  label: string;
  variant?: 'standard' | 'small';
}> = ({ children, label, variant = 'standard' }) => {
  return (
    <fieldset
      className={classNames(variant === 'standard' ? theme.container : theme.smallContainer)}
    >
      <legend className={theme.legendName}>{label}</legend>
      {children}
    </fieldset>
  );
};

export default Fieldset;
