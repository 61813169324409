import { gql, useLazyQuery } from '@apollo/client';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ADMIN_BULK_CREATE_SUCCESS, ADMIN_BULK_UPDATE_SUCCESS } from 'data/notificationsConst';
import useBulkCreateFn from 'hooks/admin/useBulkCreateFn';
import useBulkUpdateFn from 'hooks/admin/useBulkUpdateFn';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AdminCreateModuleEnum, AdminUpdateModuleEnum } from 'types/common';

import DataTable from 'components/Admin/DataTable';
import DownloadRecordsTemplate from 'components/Admin/DownloadRecordsTemplate';
import DownloadTemplate from 'components/Admin/DownloadTemplate';
import { Select } from 'components/Inputs/Select';
import UploadExcel from 'components/Inputs/UploadExcel';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import { SimplePopup } from 'components/Popup';

import { rankedFieldsChangeHandler } from 'utils/formHandlers';

enum UploadTypeEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

const GET_MODEL_FIELDS = gql`
  query GetModelFields($type: FileUploadTypeEnum!) {
    getModelFields(type: $type) {
      field
      required
      label
    }
  }
`;

type GetModelFieldsResponse = {
  getModelFields: {
    field: string;
    required: boolean;
    label: string;
  }[];
};

type GetModelFieldsVariables = {
  type: AdminCreateModuleEnum;
};

const BulkUpload = () => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState('');
  const [shouldDisableSubmit, toggleSubmitDisable] = useState(true);
  const [openDownloadRecordsPopup, toggleDownloadRecordsPopup] = useState(false);
  const [openDownloadTemplatePopup, toggleDownloadTemplatePopup] = useState(false);

  const rankedFields = ['type', 'tableName', 'selectedFile'];

  const [bulkCreateFn, uploadingBulkData, bulkCreateError] = useBulkCreateFn({
    type: formState.tableName,
  });

  const [updateBulk, { loading: updatingBulk, error: bulkUpdateError }] = useBulkUpdateFn({
    type: formState.tableName,
  });

  const [getModelFields, { data: modelFields, loading: loadingModelFields }] = useLazyQuery<
    GetModelFieldsResponse,
    GetModelFieldsVariables
  >(GET_MODEL_FIELDS);

  useEffect(() => {
    if (bulkCreateError && !!bulkCreateError.message) {
      setErrors(bulkCreateError.message);
    }
  }, [bulkCreateError]);

  useEffect(() => {
    if (bulkUpdateError && !!bulkUpdateError.message) {
      setErrors(bulkUpdateError.message);
    }
  }, [bulkUpdateError]);

  useEffect(() => {
    if (errors) {
      setErrors('');
    }
    if (shouldDisableSubmit) {
      toggleSubmitDisable(false);
    }
  }, [formState]);

  const handleChange = (fieldName: string, value: string) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    if (!!formState.selectedFile && !!formState.selectedFile.length)
      if (formState.type === UploadTypeEnum.CREATE)
        bulkCreateFn({
          variables: {
            input: formState.selectedFile[0],
          },
          onCompleted: _ => {
            toggleSubmitDisable(true);
            toast.success(ADMIN_BULK_CREATE_SUCCESS);
          },
        });
      else {
        updateBulk({
          variables: {
            input: formState.selectedFile[0],
          },
          onCompleted: _ => {
            toast.success(ADMIN_BULK_UPDATE_SUCCESS);
            toggleSubmitDisable(true);
          },
        });
      }
  };

  return (
    <Navbar title="Admin">
      <form>
        <Grid container direction={'column'} rowGap={2} columnSpacing={2} pt={1.5} mb={5}>
          <Grid item xs={12} container>
            <Grid item xs={12} md={4}>
              <Select
                value={formState.type ?? ''}
                onChange={val => rankedFieldsChangeHandler('type', val, setFormState, rankedFields)}
                options={Object.values(UploadTypeEnum).map(o => ({ label: o, value: o }))}
                label="Upload Type"
                size="medium"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} md={4}>
              <Select
                value={formState.tableName ?? ''}
                onChange={val => {
                  rankedFieldsChangeHandler('tableName', val, setFormState, rankedFields);
                  getModelFields({
                    variables: {
                      type: val,
                    },
                  });
                }}
                options={Object.values(
                  formState.type === UploadTypeEnum.CREATE
                    ? AdminCreateModuleEnum
                    : AdminUpdateModuleEnum
                ).map(o => ({ label: o, value: o }))}
                label="Table Name"
                size="medium"
                disabled={!!!formState.type}
              />
            </Grid>
          </Grid>
          {formState.type === UploadTypeEnum.CREATE && (
            <Grid item xs={12}>
              <Button
                onClick={() => toggleDownloadTemplatePopup(true)}
                variant="outlined"
                size="medium"
                disabled={!!!formState.tableName}
                endIcon={<FileDownloadIcon />}
              >
                Download Template
              </Button>
            </Grid>
          )}
          {formState.type === UploadTypeEnum.UPDATE && (
            <Grid item xs={12}>
              <Button
                onClick={() => toggleDownloadRecordsPopup(true)}
                variant="outlined"
                size="medium"
                disabled={!!!formState.tableName}
                endIcon={<FilterAltIcon />}
              >
                Select the Records
              </Button>
            </Grid>
          )}
          {!!errors && (
            <Grid item xs={12} mb={-1}>
              <Typography variant="caption" color="crimson">
                {errors}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6} container>
            <UploadExcel
              value={!!formState.selectedFile ? formState.selectedFile : []}
              onChange={files => handleChange('selectedFile', files)}
              label="Upload File"
              required
              disabled={!!!formState.tableName}
            />
            <LoadingButton
              variant="contained"
              size="medium"
              sx={{
                ml: 2,
              }}
              loading={uploadingBulkData || updatingBulk}
              disabled={!!!formState.selectedFile || shouldDisableSubmit || !!errors}
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </Grid>
          {!!formState.selectedFile && (
            <DataTable
              data={formState.selectedFile}
              containerProps={{
                pt: 2,
                pr: 1.5,
              }}
            />
          )}
          <SimplePopup
            onClose={() => toggleDownloadRecordsPopup(false)}
            open={openDownloadRecordsPopup}
            fullWidth
            maxWidth="lg"
          >
            {loadingModelFields ? (
              <Box
                display="flex"
                height="30vh"
                justifyContent="center"
                alignItems="center"
                bgcolor="ButtonShadow"
              >
                <LoadingIndicator size="1.6rem" />
              </Box>
            ) : (
              <DownloadRecordsTemplate
                type={formState.tableName}
                updateModalSchema={modelFields?.getModelFields ?? []}
                onClose={() => toggleDownloadRecordsPopup(false)}
              />
            )}
          </SimplePopup>
          <SimplePopup
            onClose={() => toggleDownloadTemplatePopup(false)}
            open={openDownloadTemplatePopup}
            fullWidth
            maxWidth="lg"
          >
            {loadingModelFields ? (
              <Box
                display="flex"
                height="30vh"
                justifyContent="center"
                alignItems="center"
                bgcolor="ButtonShadow"
              >
                <LoadingIndicator size="1.6rem" />
              </Box>
            ) : (
              <DownloadTemplate
                type={formState.tableName}
                createModalSchema={modelFields?.getModelFields ?? []}
                onClose={() => toggleDownloadTemplatePopup(false)}
              />
            )}
          </SimplePopup>
        </Grid>
      </form>
    </Navbar>
  );
};

export default BulkUpload;
