import { useLazyQuery } from '@apollo/client';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VerifiedIcon from '@mui/icons-material/Verified';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import { GetCustomersQueryResponse, customersName } from 'graphql/query/area';
import { CustomerTypesResponse, GET_CUSTOMER_TYPES_QUERY } from 'graphql/query/customer';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Customer } from 'types/common';

import Fieldset from 'components/Fieldset';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { ConfirmationPopup } from 'components/Popup';
import { EmailAction, PhoneAction, WhatsAppAction } from 'components/Tile/Actions/ActionsButton';

import { truncateString } from 'utils/common';
import { transformCustomerNames } from 'utils/transformFn';

type AssociatedCustomersProps = {
  id: string;
  associatedCustomers?: Customer[];
  updateMutation: any;
  updating: boolean;
};

const AssociatedCustomers: React.FC<AssociatedCustomersProps> = ({
  id,
  associatedCustomers = [],
  updateMutation,
  updating,
}) => {
  const [enableAddNewCustomer, toggleAddNewCustomer] = useState(false);
  const [customerType, setCustomerType] = useState({ _id: '', name: '' });
  const [selectedCustomer, setSelectedCustomer] = useState({ _id: '', name: '' });

  const [getCustomerTypes, { data: customerTypes, loading: customerTypesLoading }] =
    useLazyQuery<CustomerTypesResponse>(GET_CUSTOMER_TYPES_QUERY);

  const [getCustomers, { data: customers, loading: customersLoading }] =
    useLazyQuery<GetCustomersQueryResponse>(customersName, {
      variables: {
        filter: {
          customerType: [customerType._id],
        },
      },
    });

  const resetForm = () => {
    setCustomerType({ _id: '', name: '' });
    setSelectedCustomer({ _id: '', name: '' });
  };

  const deleteAssociatedCustomer = (cusIdToDelete: string) => {
    const newCusIds =
      associatedCustomers.map(cus => cus._id).filter(cusId => cusId !== cusIdToDelete) ?? [];

    updateMutation({
      variables: {
        input: {
          _id: id,
          associatedCustomers: newCusIds.length ? newCusIds : null,
        },
      },
    });
  };

  const handleAddNewCustomer = () => {
    if (selectedCustomer && !!selectedCustomer._id) {
      const existingCustomers = associatedCustomers.map(cus => cus._id);
      updateMutation({
        variables: {
          input: {
            _id: id,
            associatedCustomers: [...existingCustomers, selectedCustomer._id],
          },
        },
        onCompleted: (_: any) => {
          resetForm();
          toggleAddNewCustomer(false);
        },
      });
    }
  };

  return (
    <>
      <Grid item container xs={12} pt={1} pb={2} spacing={1.5}>
        {associatedCustomers.map(customer => (
          <Grid item xs={12} md={6} key={customer._id}>
            <CustomerCard
              customer={customer}
              onDelete={deleteAssociatedCustomer}
              deleting={updating}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container item xs={12}>
        {!enableAddNewCustomer ? (
          <Button
            fullWidth
            variant="outlined"
            size="small"
            startIcon={<PersonAddIcon />}
            onClick={() => toggleAddNewCustomer(true)}
          >
            Add New Customer
          </Button>
        ) : (
          <Grid item xs={12}>
            <Fieldset label="Add Customer" variant="small">
              <Grid container rowGap={1.5} columnSpacing={1.5} px={1} pt={1.5}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <AutocompleteWithFetch
                      fetch={getCustomerTypes}
                      handleChange={option => {
                        setCustomerType(option);
                        setSelectedCustomer({ _id: '', name: '' });
                      }}
                      label="Customer Type"
                      loading={customerTypesLoading}
                      options={customerTypes?.getCustomerTypes ?? []}
                      value={customerType}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <AutocompleteWithFetch
                      fetch={getCustomers}
                      handleChange={option => setSelectedCustomer(option)}
                      label="Customer"
                      loading={customersLoading}
                      disabled={!!!customerType._id}
                      options={transformCustomerNames(customers?.getCustomers) ?? []}
                      value={selectedCustomer}
                      labelWithId
                      variables={{
                        filter: {
                          customerType: [customerType._id],
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item container justifyContent="flex-end" xs={12}>
                  <Button
                    onClick={() => {
                      resetForm();
                      toggleAddNewCustomer(false);
                    }}
                    size="medium"
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={updating}
                    variant="contained"
                    size="medium"
                    disabled={!!!selectedCustomer._id}
                    onClick={handleAddNewCustomer}
                  >
                    Add
                  </LoadingButton>
                </Grid>
              </Grid>
            </Fieldset>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const CustomerCard: React.FC<{
  customer: Customer;
  onDelete: (id: string) => void;
  deleting: boolean;
}> = ({ customer, onDelete, deleting }) => {
  const navigate = useNavigate();
  const [openDeletePopup, toggleDeletePopup] = useState(false);

  return (
    <Card variant="elevation" elevation={4}>
      <CardContent
        onClick={() => navigate(`/customers/${customer._id}`)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.2rem',
          cursor: 'pointer',
        }}
      >
        <Grid container justifyContent="space-between" mb={0.3} alignItems="center">
          <div>
            <Grid container alignItems="center" columnGap={0.2}>
              <Typography sx={{ fontSize: 20 }} fontWeight={600}>
                {truncateString(`${customer.fname} ${customer.lname}`)}
              </Typography>
              {customer.isVerified && <VerifiedIcon color="success" fontSize="small" />}
            </Grid>
          </div>
          <div>
            <Typography variant="subtitle2">{customer.gender}</Typography>
          </div>
          <div>
            <Typography variant="subtitle2">{customer.status}</Typography>
          </div>
        </Grid>
        <Grid container justifyContent="space-between">
          <div>
            <Grid container flexDirection="column" rowGap={0.15}>
              <Typography variant="overline" color="GrayText" fontWeight={600}>
                {typeof customer.customerType !== 'string' ? customer.customerType?.name : ''}
              </Typography>
            </Grid>
          </div>
          <div>
            <Grid
              container
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Typography variant="caption" fontWeight={600}>
                {customer.prefferedLanguage}
              </Typography>
            </Grid>
          </div>
        </Grid>
      </CardContent>
      <CardActions sx={{ p: 0 }} disableSpacing>
        <PhoneAction recipientId={customer._id} />
        <EmailAction customerId={customer._id} disabled={!!!customer.email} />
        <WhatsAppAction number={customer.mobile} />
        <Grid
          sx={{
            cursor: 'pointer',
            flexGrow: 1,
            textAlign: 'center',
            color: 'black',
            padding: '0.4rem 0 0.2rem 0',
            borderTop: '1.2px solid rgb(189, 187, 187)',
            borderRight: 0,
          }}
        >
          <DeleteOutlineIcon
            fontSize="medium"
            color="error"
            onClick={() => toggleDeletePopup(true)}
          />
        </Grid>
      </CardActions>
      <ConfirmationPopup
        open={openDeletePopup}
        onClose={() => toggleDeletePopup(false)}
        onConfirmation={() => onDelete(customer._id)}
        title={`Do you want to remove?`}
        confirmationLabel="Yes"
        loading={deleting}
      />
    </Card>
  );
};

export default AssociatedCustomers;
