import { gql, useMutation } from '@apollo/client';
import { LEAD_PRODUCT_UPDATE_SUCCESS } from 'data/notificationsConst';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { AutoCompleteOption, LeadCategoryEnum, LeadProduct } from 'types/common';

import { transformEnumsToAutoCompleteOptions } from 'utils/transformFn';

import HFDProductForm from '../AddProduct/AddHFDProduct';
import HWTProductForm from '../AddProduct/AddHWTProduct';
import LHAProductForm from '../AddProduct/AddLHAProduct';
import MKWProductForm from '../AddProduct/AddMKWProduct';
import TFLProductForm from '../AddProduct/AddTFLProduct';
import WMTProductForm from '../AddProduct/AddWMTProduct';

type ProductDetailsProps = {
  leadProduct: LeadProduct;
  category: LeadCategoryEnum;
  editableForm: boolean;
};
type UpdateProductMutationResponse = {};

type UpdateProductMutationVariables = {};

const UPDATE_PRODUCT_MUTATION = gql`
  mutation UpdateLeadProduct($input: UpdateLeadProductInput!) {
    updateLeadProduct(input: $input) {
      _id
      category
      quantity
      price
      estimatedValue
      lead {
        _id
        estimatedValue
      }
      product {
        ... on HfdProduct {
          _id
          subCategory
          group
          standard
          size
          brand {
            _id
            name
          }
          unitOfMeasure
          price
          status
        }
        ... on HwtProduct {
          _id
          subCategory
          group
          size
          weight
          standard
          type
          unitOfMeasure
          price
          status
        }
        ... on LhaProduct {
          _id
          subCategory
          group
          application
          wattage
          brand {
            _id
            name
          }
          price
          status
        }
        ... on MkwProduct {
          _id
          subCategory
          propertyType
          propertyStatus
          usage
          criteria
          shapeOfKitchen
          typeOfWardrobe
          size
          price
          status
        }
        ... on TflProduct {
          _id
          subCategory
          group
          size
          unitOfMeasure
          brand {
            _id
            name
          }
          finish
          price
          status
        }
        ... on WmtProduct {
          _id
          subCategory
          group
          product
          gradeOrFinish
          size
          unitOfMeasure
          brand {
            _id
            referenceId
            name
          }
          price
          status
        }
      }
    }
  }
`;

const LeadProductDetails: React.FC<ProductDetailsProps> = ({
  leadProduct,
  category,
  editableForm,
}) => {
  const [updateLeadProduct, { loading: updatingLeadProduct }] = useMutation<
    UpdateProductMutationResponse,
    UpdateProductMutationVariables
  >(UPDATE_PRODUCT_MUTATION);

  const initialData = useMemo(() => {
    const product = leadProduct.product;

    const price = leadProduct.price;
    const quantity = leadProduct.quantity;

    switch (category) {
      case LeadCategoryEnum.TFL:
        const tflInitialData = {
          subCategory: product.subCategory,
          group: product.group,
          unitOfMeasure: product.unitOfMeasure,
          size: product.size,
          finish: product.finish,
        };
        return {
          ...helper(tflInitialData),
          brand: product.brand,
          price,
          quantity,
        };

      case LeadCategoryEnum.HWT:
        const hwtInitialData = {
          subCategory: product.subCategory,
          group: product.group,
          unitOfMeasure: product.unitOfMeasure,
          size: product.size,
          weight: product.weight,
          standard: product.standard,
          type: product.type,
        };
        return {
          ...helper(hwtInitialData),
          price,
          quantity,
        };

      case LeadCategoryEnum.HFD:
        const hfdInitialData = {
          subCategory: product.subCategory,
          group: product.group,
          unitOfMeasure: product.unitOfMeasure,
          size: product.size,
          standard: product.standard,
        };
        return {
          ...helper(hfdInitialData),
          brand: product.brand,
          price,
          quantity,
        };

      case LeadCategoryEnum.WMT:
        const wmtInitialData = {
          subCategory: product.subCategory,
          group: product.group,
          product: product.product,
          gradeOrFinish: product.gradeOrFinish,
          size: product.size,
          unitOfMeasure: product.unitOfMeasure,
        };
        return {
          ...helper(wmtInitialData),
          brand: product.brand,
          price,
          quantity,
        };

      default:
        return {};
    }
  }, [leadProduct, category]);

  // if (
  //   [
  //     LeadCategoryEnum.HFD,
  //     LeadCategoryEnum.HWT,
  //     LeadCategoryEnum.TFL,
  //     LeadCategoryEnum.WMT,
  //     LeadCategoryEnum.LHA,
  //   ].includes(leadProduct.category)
  // ) {
  //   group = transformEnumsToAutoCompleteOptions([product.group])[0];
  //   unitOfMeasure = transformEnumsToAutoCompleteOptions([product.unitOfMeasure])[0];
  //   thickness = transformEnumsToAutoCompleteOptions([product.thickness])[0];
  //   size = transformEnumsToAutoCompleteOptions([product.size])[0];
  //   wattage = transformEnumsToAutoCompleteOptions([product.wattage])[0];
  //   brand = product.brand;
  // } else {
  //   propertyType = transformEnumsToAutoCompleteOptions([product.propertyType])[0];
  //   propertyStatus = transformEnumsToAutoCompleteOptions([product.propertyStatus])[0];
  //   usage = transformEnumsToAutoCompleteOptions([product.usage])[0];
  //   criteria = transformEnumsToAutoCompleteOptions([product.criteria])[0];
  //   shapeOfKitchen = transformEnumsToAutoCompleteOptions([product.shapeOfKitchen])[0];
  //   typeOfWardrobe = transformEnumsToAutoCompleteOptions([product.typeOfWardrobe])[0];
  //   size = transformEnumsToAutoCompleteOptions([product.size])[0];
  // }

  // if (leadProduct.category === LeadCategoryEnum.HFD) {
  //   standard = transformEnumsToAutoCompleteOptions([product.standard])[0];
  // } else if (leadProduct.category === LeadCategoryEnum.LHA) {
  //   application = transformEnumsToAutoCompleteOptions([product.application])[0];
  // }

  const handleSubmit = ({ quantity, product }: { quantity: number; product: string }) => {
    updateLeadProduct({
      variables: {
        input: {
          _id: leadProduct._id,
          product: !!product ? product : leadProduct.product._id,
          quantity,
          category: leadProduct.category,
        },
      },
      onCompleted: _ => {
        toast.success(LEAD_PRODUCT_UPDATE_SUCCESS);
      },
    });
  };

  const renderProduct = () => {
    switch (category) {
      case LeadCategoryEnum.TFL:
        return (
          <TFLProductForm
            loading={updatingLeadProduct}
            onSubmit={handleSubmit}
            initialData={initialData}
            variant="UPDATE"
            disabled={!editableForm}
          />
        );
      case LeadCategoryEnum.HFD:
        return (
          <HFDProductForm
            loading={updatingLeadProduct}
            onSubmit={handleSubmit}
            initialData={initialData}
            variant="UPDATE"
            disabled={!editableForm}
          />
        );
      case LeadCategoryEnum.HWT:
        return (
          <HWTProductForm
            loading={updatingLeadProduct}
            onSubmit={handleSubmit}
            initialData={initialData}
            variant="UPDATE"
            disabled={!editableForm}
          />
        );
      case LeadCategoryEnum.WMT:
        return (
          <WMTProductForm
            loading={updatingLeadProduct}
            onSubmit={handleSubmit}
            initialData={initialData}
            variant="UPDATE"
            disabled={!editableForm}
          />
        );
      // case LeadCategoryEnum.LHA:
      //   return (
      //     <LHAProductForm
      //       loading={updatingLeadProduct}
      //       onSubmit={handleSubmit}
      //       initialData={{
      //         subCategory,
      //         group,
      //         application,
      //         wattage,
      //         brand,
      //         quantity,
      //         price,
      //       }}
      //       variant="UPDATE"
      //       disabled={!editableForm}
      //     />
      //   );
      // case LeadCategoryEnum.MKW:
      //   return (
      //     <MKWProductForm
      //       loading={updatingLeadProduct}
      //       onSubmit={handleSubmit}
      //       initialData={{
      //         subCategory,
      //         propertyType,
      //         propertyStatus,
      //         usage,
      //         criteria,
      //         shapeOfKitchen,
      //         typeOfWardrobe,
      //         size,
      //         quantity,
      //         price,
      //       }}
      //       variant="UPDATE"
      //       disabled={!editableForm}
      //     />
      //   );

      default:
        return <></>;
    }
  };

  return renderProduct();
};

const helper = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj[key] = transformEnumsToAutoCompleteOptions([obj[key]])[0];
    }
  }
  return obj;
};

export default LeadProductDetails;
