import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, InputAdornment, TextField } from '@mui/material';
import {
  HWTGroupNamesQueryResponse,
  HWTGroupNamesQueryVariables,
  HWTSizesQueryResponse,
  HWTSizesQueryVariables,
  HWTStandardsQueryResponse,
  HWTStandardsQueryVariables,
  HWTSubCategoriesQueryResponse,
  HWTTypesQueryResponse,
  HWTTypesQueryVariables,
  HWTUnitsOfMeasureQueryResponse,
  HWTUnitsOfMeasureQueryVariables,
  HWTWeightsQueryResponse,
  HWTWeightsQueryVariables,
  HWT_GROUP_NAMES_QUERY,
  HWT_SIZES_QUERY,
  HWT_STANDARDS_QUERY,
  HWT_SUB_CATEGORIES_QUERY,
  HWT_TYPES_QUERY,
  HWT_UNITS_OF_MEASURE_QUERY,
  HWT_WEIGHTS_QUERY,
} from 'graphql/query/product/hwtProduct';
import React, { useState } from 'react';
import { AutoCompleteOption } from 'types/common';

import { getUpdatedFields } from 'components/FormPanel';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';

import { removeEmptyFields } from 'utils/common';
import { rankedFieldsChangeHandler } from 'utils/formHandlers';

const HWTProductForm: React.FC<{
  onSubmit: (arg: any) => void;
  loading: boolean;
  disabled?: boolean;
  initialData?: Record<string, any>;
  variant?: 'CREATE' | 'UPDATE';
}> = ({ onSubmit, loading, disabled = false, initialData = {}, variant = 'CREATE' }) => {
  const [formState, setFormState] = useState<Record<string, any>>(initialData);
  const [selectedProduct, setSelectedProduct] = useState<{
    _id: string;
    price: number;
    brand: AutoCompleteOption;
  }>({
    _id: '',
    brand: initialData.brand ?? { _id: '', name: '' },
    price: initialData.price ?? '',
  });
  const rankedFields = [
    'subCategory',
    'group',
    'standard',
    'type',
    'size',
    'unitOfMeasure',
    'weight',
    'quantity',
  ];

  const [getSubCategories, { data: subCategories, loading: loadingSubCategories }] =
    useLazyQuery<HWTSubCategoriesQueryResponse>(HWT_SUB_CATEGORIES_QUERY);

  const [getGroupNames, { data: groups, loading: loadingGroupNames }] = useLazyQuery<
    HWTGroupNamesQueryResponse,
    HWTGroupNamesQueryVariables
  >(HWT_GROUP_NAMES_QUERY);

  const [getSizes, { data: sizes, loading: loadingSizes }] = useLazyQuery<
    HWTSizesQueryResponse,
    HWTSizesQueryVariables
  >(HWT_SIZES_QUERY);

  const [getUnitsOfMeasure, { data: unitsOfMeasure, loading: loadingUnitsOfMeasure }] =
    useLazyQuery<HWTUnitsOfMeasureQueryResponse, HWTUnitsOfMeasureQueryVariables>(
      HWT_UNITS_OF_MEASURE_QUERY
    );

  const [getStandards, { data: standards, loading: loadingStandards }] = useLazyQuery<
    HWTStandardsQueryResponse,
    HWTStandardsQueryVariables
  >(HWT_STANDARDS_QUERY);

  const [getTypes, { data: types, loading: loadingTypes }] = useLazyQuery<
    HWTTypesQueryResponse,
    HWTTypesQueryVariables
  >(HWT_TYPES_QUERY);

  const [getWeights, { data: weights, loading: loadingWeights }] = useLazyQuery<
    HWTWeightsQueryResponse,
    HWTWeightsQueryVariables
  >(HWT_WEIGHTS_QUERY);

  const getPrice = (value: AutoCompleteOption) => {
    if (!!weights) {
      const currProduct = weights.getHwtProducts.find(p => p.weight === value._id) as any;
      setSelectedProduct(currProduct);
    }
  };

  const handleSubmit = () => {
    const newFields = removeEmptyFields({ ...formState });

    if (!!newFields.weight) {
      onSubmit({
        product: selectedProduct._id,
        quantity: formState.quantity,
      });
    }
  };

  return (
    <Grid container px={1} py={2} rowGap={2} columnSpacing={1.5}>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Sub Category"
          fetch={getSubCategories}
          handleChange={val =>
            rankedFieldsChangeHandler('subCategory', val, setFormState, rankedFields)
          }
          loading={loadingSubCategories}
          disabled={disabled}
          options={(subCategories?.getHwtProducts ?? []).map(p => p.subCategory)}
          value={formState.subCategory ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Group"
          fetch={getGroupNames}
          handleChange={val => rankedFieldsChangeHandler('group', val, setFormState, rankedFields)}
          loading={loadingGroupNames}
          options={(groups?.getHwtProducts ?? []).map(p => p.group)}
          value={formState.group ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.subCategory || !!!formState.subCategory?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Standard"
          fetch={getStandards}
          handleChange={val =>
            rankedFieldsChangeHandler('standard', val, setFormState, rankedFields)
          }
          loading={loadingStandards}
          options={(standards?.getHwtProducts ?? []).map(p => p.standard)}
          value={formState.standard ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.group || !!!formState.group?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Type"
          fetch={getTypes}
          handleChange={val => rankedFieldsChangeHandler('type', val, setFormState, rankedFields)}
          loading={loadingTypes}
          options={(types?.getHwtProducts ?? []).map(p => p.type)}
          value={formState.type ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.standard || !!!formState.standard?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
              standard: formState.standard?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Size"
          fetch={getSizes}
          handleChange={val => rankedFieldsChangeHandler('size', val, setFormState, rankedFields)}
          loading={loadingSizes}
          options={(sizes?.getHwtProducts ?? []).map(p => p.size)}
          value={formState.size ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.type || !!!formState.type?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
              standard: formState.standard?._id,
              type: formState.type?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Unit of Measure"
          fetch={getUnitsOfMeasure}
          handleChange={val =>
            rankedFieldsChangeHandler('unitOfMeasure', val, setFormState, rankedFields)
          }
          loading={loadingUnitsOfMeasure}
          options={(unitsOfMeasure?.getHwtProducts ?? []).map(p => p.unitOfMeasure)}
          value={formState.unitOfMeasure ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.size || !!!formState.size._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
              standard: formState.standard?._id,
              type: formState.type?._id,
              size: formState.size?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Weight"
          fetch={getWeights}
          handleChange={val => {
            rankedFieldsChangeHandler('weight', val, setFormState, rankedFields);
            getPrice(val);
          }}
          loading={loadingWeights}
          options={(weights?.getHwtProducts ?? []).map(product => product.weight)}
          value={formState.weight ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.unitOfMeasure || !!!formState.unitOfMeasure._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
              size: formState.size?._id,
              unitOfMeasure: formState.unitOfMeasure?._id,
              standard: formState.standard?._id,
              type: formState.type?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            label="Quantity"
            value={formState.quantity ?? ''}
            type="number"
            onChange={e =>
              rankedFieldsChangeHandler(
                'quantity',
                !!e.target.value ? +e.target.value : '',
                setFormState,
                rankedFields
              )
            }
            variant="outlined"
            required
            disabled={!!!formState.weight || !!!formState.weight._id || disabled}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            label="Estimated Value"
            type="number"
            variant="outlined"
            disabled
            value={formState.quantity ? +formState.quantity * selectedProduct.price : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <b color="lightGray">₹</b>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Grid>
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={4} marginRight="auto">
          <LoadingButton
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            loading={loading}
            disabled={
              variant === 'CREATE'
                ? !!!formState.quantity
                : !(
                    !!formState.quantity &&
                    !!Object.keys(getUpdatedFields(initialData, formState)).length
                  )
            }
          >
            {variant === 'CREATE' ? 'Create' : 'Update'}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HWTProductForm;
