import VerifiedIcon from '@mui/icons-material/Verified';
import { Typography } from '@mui/material';
import React from 'react';
import { Customer, FieldVisit, Followup, Lead, Project, preferredSlotMap } from 'types/common';

import { truncateString } from 'utils/common';
import { getDate, getDateTime } from 'utils/formatHelper';

import theme from './theme.module.scss';

type CustomerTileHeaderProps = {
  customer: Customer;
};

type LeadTileHeaderProps = {
  lead: Lead;
};

type ProjectTileHeaderProps = {
  project: Project;
};

type FollowupTileHeaderProps = {
  followup: Followup;
};

type FieldVisitTileHeaderProps = {
  fieldVisit: FieldVisit;
};

export const CustomerTileHeader: React.FC<CustomerTileHeaderProps> = ({ customer }) => {
  return (
    <>
      <div className={theme.headerItem}>
        <Typography sx={{ fontSize: 20 }} fontWeight={600}>
          {truncateString(`${customer.fname} ${customer.lname ?? ''}`)}
        </Typography>
        {customer.isVerified && <VerifiedIcon color="success" className={theme.verified} />}
      </div>
      <Typography variant="subtitle2" fontWeight={600}>
        {customer.status}
      </Typography>
      <Typography variant="subtitle2" fontWeight={600}>
        {customer.referenceId}
      </Typography>
    </>
  );
};

export const LeadTileHeader: React.FC<LeadTileHeaderProps> = ({ lead }) => {
  return (
    <>
      <Typography variant="caption" fontWeight={600}>
        {lead.referenceId}
      </Typography>
      {/* <Typography variant="subtitle2">{lead.productCategory}</Typography> */}
      <Typography variant="caption" fontWeight={550}>
        {lead.status}
      </Typography>
      <Typography variant="subtitle2">
        {lead.expectedClosure ? getDate(lead.expectedClosure, 'DD MMM YYYY') : 'Exp. Closure'}
      </Typography>
    </>
  );
};

export const ProjectTileHeader: React.FC<ProjectTileHeaderProps> = ({ project }) => {
  return (
    <>
      <Typography variant="subtitle2" fontWeight={600}>
        {project.name}
      </Typography>
      <Typography variant="subtitle2" fontWeight={600}>
        {project.status}
      </Typography>
      <Typography variant="subtitle2" fontWeight={600}>
        {(project?.referenceId as string) ?? '-'}
      </Typography>
    </>
  );
};

export const FieldVisitTileHeader: React.FC<FieldVisitTileHeaderProps> = ({ fieldVisit }) => {
  const formatedTime = getDateTime(fieldVisit.fieldVisitDate, 'DD MMM');
  return (
    <>
      <Typography variant="subtitle2" fontWeight={600}>
        {formatedTime}
      </Typography>
      <Typography variant="subtitle2" fontWeight={600}>
        {fieldVisit.lead.project?.referenceId ?? '-'}
      </Typography>
      <Typography variant="subtitle2" fontWeight={600}>
        Slot: {preferredSlotMap[fieldVisit.preferredSlot]}
      </Typography>
    </>
  );
};

export const FollowupTileHeader: React.FC<FollowupTileHeaderProps> = ({ followup }) => {
  const formatedTime =
    preferredSlotMap[followup.preferredSlot] + ', ' + getDateTime(followup.followupDate, 'DD MMM');

  return (
    <>
      <Typography variant="subtitle2" fontWeight={600}>
        {formatedTime}
      </Typography>
      <Typography variant="subtitle2" fontWeight={600}>
        {followup.followupType ?? '-'}
      </Typography>
      <Typography variant="subtitle2" fontWeight={600}>
        {followup.callStatus ?? 'Call Status'}
      </Typography>
    </>
  );
};
