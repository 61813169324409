import { gql, useMutation } from '@apollo/client';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, FormControl, Grid, TextField, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { CALL_INITIATED_SUCCESS, EMAIL_SENT_SUCCESS } from 'data/notificationsConst';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CommunicationTypeEnum } from 'types/common';

import { ConfirmationPopup } from 'components/Popup';

import theme from './theme.module.scss';

type PhoneCallProps = {
  recipientId: string;
  isLastAction?: boolean;
  className?: string;
  disabled?: boolean;
};

type EmailActionProps = {
  customerId: string;
  isLastAction?: boolean;
  disabled?: boolean;
} & React.LinkHTMLAttributes<HTMLAnchorElement>;

type WhatsAppActionProps = {
  number: string | undefined;
  isLastAction?: boolean;
  disabled?: boolean;
} & React.LinkHTMLAttributes<HTMLAnchorElement>;

type EmailMutationVariables = {
  input: {
    communicationType: CommunicationTypeEnum;
    recipient: string;
    subject: string;
    message: string;
  };
};

const EMAIL_MUTATION = gql`
  mutation SendMessage($input: CommunicationLogInput!) {
    sendMessage(input: $input)
  }
`;

const CALL_MUTATION = gql`
  mutation InitiateVoiceCall($recipient: ID!) {
    initiateVoiceCall(recipient: $recipient)
  }
`;

export const PhoneAction: React.FC<PhoneCallProps> = ({
  recipientId,
  className,
  isLastAction,
  disabled = false,
}) => {
  const [callCustomer, { loading }] = useMutation(CALL_MUTATION, {
    variables: {
      recipient: recipientId,
    },
  });

  const [callConfirmationDialog, toggleCallConfirmation] = useState(false);

  return (
    <>
      <div
        className={classNames(theme.actionButton, className, {
          [theme.lastActionButton]: isLastAction,
        })}
        onClick={() => !disabled && toggleCallConfirmation(true)}
      >
        <PhoneIcon color={disabled ? 'disabled' : 'info'} />
      </div>
      <ConfirmationPopup
        onClose={() => {
          toggleCallConfirmation(false);
        }}
        onConfirmation={() => {
          callCustomer({
            variables: {
              recipient: recipientId,
            },
            onCompleted: _ => {
              toggleCallConfirmation(false);
              toast.success(CALL_INITIATED_SUCCESS);
            },
          });
        }}
        open={callConfirmationDialog}
        title="Do you want to initiate a call?"
        loading={loading}
        confirmationLabel="Yes"
      />
    </>
  );
};

export const EmailAction: React.FC<EmailActionProps> = ({
  customerId,
  className,
  isLastAction,
  disabled = false,
}) => {
  const [enableEmailPopup, toggleEmailPopup] = useState(false);
  const [emailFormState, setEmailFormState] = useState({
    subject: '',
    body: '',
  });
  const [errors, setErrors] = useState({ subject: '', body: '' });
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const [sendEmail, { loading: sendingEmail }] = useMutation<{}, EmailMutationVariables>(
    EMAIL_MUTATION
  );

  const resetForm = () =>
    setEmailFormState({
      subject: '',
      body: '',
    });

  const handleChange = (fieldName: string, value: string) => {
    if (!!!value) {
      setErrors(prev => ({ ...prev, [fieldName]: 'This field is required' }));
    } else {
      setErrors(prev => ({ ...prev, [fieldName]: '' }));
    }

    setEmailFormState(prev => ({ ...prev, [fieldName]: value }));
  };

  const handleEmailSubmit = () => {
    if (!!!emailFormState.body || !!!emailFormState.subject) {
      return;
    }

    if (!!customerId)
      sendEmail({
        variables: {
          input: {
            communicationType: CommunicationTypeEnum.EMAIL,
            subject: emailFormState.subject,
            message: emailFormState.body,
            recipient: customerId,
          },
        },
        onCompleted: _ => {
          toggleEmailPopup(false);
          toast.success(EMAIL_SENT_SUCCESS);
          resetForm();
        },
      });
  };

  return (
    <>
      <div
        className={classNames(theme.actionButton, className, {
          [theme.lastActionButton]: isLastAction,
        })}
        onClick={() => {
          !disabled && toggleEmailPopup(true);
        }}
      >
        <MailOutlineIcon color={!disabled ? 'error' : 'disabled'} />
      </div>
      <ConfirmationPopup
        onClose={() => {
          resetForm();
          toggleEmailPopup(false);
        }}
        onConfirmation={handleEmailSubmit}
        open={enableEmailPopup}
        fullScreen={isMobileScreen}
        fullWidth={!isMobileScreen}
        title="Create email"
        loading={sendingEmail}
        confirmationLabel="Send"
      >
        <Grid container direction={'column'} rowGap={1.5} mt={1}>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                value={emailFormState.subject}
                onChange={e => handleChange('subject', e.target.value)}
                label="Subject"
                placeholder="Subject"
                variant="outlined"
                error={!!errors.subject}
                helperText={!!errors.subject ? errors.subject : null}
                FormHelperTextProps={{
                  sx: { ml: 0 },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                multiline
                minRows={10}
                value={emailFormState.body}
                onChange={e => handleChange('body', e.target.value)}
                label="Compose Email"
                variant="outlined"
                error={!!errors.body}
                helperText={!!errors.body ? errors.body : null}
                FormHelperTextProps={{
                  sx: { ml: 0 },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </ConfirmationPopup>
    </>
  );
};

export const WhatsAppAction: React.FC<WhatsAppActionProps> = ({
  number,
  className,
  isLastAction,
  disabled = false,
  ...props
}) => {
  const whatsAppTo = `https://wa.me/+91${number}`;

  return (
    <>
      {!disabled ? (
        <Link
          to={whatsAppTo}
          target="_blank"
          className={classNames(theme.actionButton, className, {
            [theme.lastActionButton]: isLastAction,
          })}
          {...props}
        >
          <WhatsAppIcon color={disabled ? 'disabled' : 'success'} fontSize="medium" />
        </Link>
      ) : (
        <Box
          className={classNames(theme.actionButton, className, {
            [theme.lastActionButton]: isLastAction,
          })}
        >
          <WhatsAppIcon color={disabled ? 'disabled' : 'success'} fontSize="medium" />
        </Box>
      )}
    </>
  );
};

/**
 * @todo need the implementation
 */
export const QuotationAction: React.FC<{
  className?: string;
  isLastAction?: boolean;
  disabled?: boolean;
}> = ({ className, isLastAction, disabled, ...props }) => {
  return (
    <Box
      className={classNames(theme.actionButton, className, {
        [theme.lastActionButton]: isLastAction,
      })}
    >
      <CurrencyRupeeIcon color={disabled ? 'disabled' : 'primary'} fontSize="medium" />
    </Box>
  );
};
