import { useLazyQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  LHAApplicationsQueryResponse,
  LHAApplicationsQueryVariables,
  LHABrandNamesQueryResponse,
  LHABrandNamesQueryVariables,
  LHAGroupNamesQueryResponse,
  LHAGroupNamesQueryVariables,
  LHASubCategoriesQueryResponse,
  LHAWattagesQueryResponse,
  LHAWattagesQueryVariables,
  LHA_APPLICATIONS_QUERY,
  LHA_BRAND_NAMES_QUERY,
  LHA_GROUP_NAMES_QUERY,
  LHA_SUB_CATEGORIES_QUERY,
  LHA_WATTAGES_QUERY,
} from 'graphql/query/product/lhaProduct';
import { StatusEnum } from 'types/common';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';

const LHAProductFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const [getSubCategories, { data: subCategories, loading: loadingSubCategories }] =
    useLazyQuery<LHASubCategoriesQueryResponse>(LHA_SUB_CATEGORIES_QUERY);

  const [getGroupNames, { data: groups, loading: loadingGroupNames }] = useLazyQuery<
    LHAGroupNamesQueryResponse,
    LHAGroupNamesQueryVariables
  >(LHA_GROUP_NAMES_QUERY);

  const [getApplications, { data: applications, loading: loadingApplications }] = useLazyQuery<
    LHAApplicationsQueryResponse,
    LHAApplicationsQueryVariables
  >(LHA_APPLICATIONS_QUERY);

  const [getWattages, { data: wattages, loading: loadingWattages }] = useLazyQuery<
    LHAWattagesQueryResponse,
    LHAWattagesQueryVariables
  >(LHA_WATTAGES_QUERY);

  const [getBrands, { data: brands, loading: loadingBrands }] = useLazyQuery<
    LHABrandNamesQueryResponse,
    LHABrandNamesQueryVariables
  >(LHA_BRAND_NAMES_QUERY);

  const handleChange = (fieldName: string, value: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Sub Category"
          fetch={getSubCategories}
          handleChange={val => handleChange('subCategory', val)}
          loading={loadingSubCategories}
          options={(subCategories?.getLhaProducts ?? []).map(p => p.subCategory)}
          value={state.subCategory ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Group"
          fetch={getGroupNames}
          handleChange={val => handleChange('group', val)}
          loading={loadingGroupNames}
          options={(groups?.getLhaProducts ?? []).map(p => p.group)}
          value={state.group ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Application"
          fetch={getApplications}
          handleChange={val => handleChange('application', val)}
          loading={loadingApplications}
          options={(applications?.getLhaProducts ?? []).map(p => p.application)}
          value={state.application ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Wattage"
          fetch={getWattages}
          handleChange={val => handleChange('wattage', val)}
          loading={loadingWattages}
          options={(wattages?.getLhaProducts ?? []).map(p => p.wattage)}
          value={state.wattage ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Brand"
          fetch={getBrands}
          handleChange={val => {
            handleChange('brand', val);
          }}
          loading={loadingBrands}
          options={(brands?.getLhaProducts ?? []).map(product => product.brand)}
          value={state.brand ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          label="Status"
          value={state.status}
          onChange={val => setState(prev => ({ ...prev, status: val }))}
          options={Object.values(StatusEnum).map(o => ({ label: o, value: o }))}
        />
      </Grid>
    </Grid>
  );
};

export default LHAProductFilters;
