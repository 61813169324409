import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PARCHI_UPDATE_SUCCESS } from 'data/notificationsConst';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Lead } from 'types/common';

import Fieldset from 'components/Fieldset';
import Selection from 'components/Inputs/Selection';
import UploadInput from 'components/Inputs/UploadInput';

type ParchiProps = {
  initialData: Record<string, any>;
};

type UpdateLeadMutationResponse = {
  updateLead: Lead;
};

type UpdateLeadMutationVariables = {
  input: {
    _id: string;
    expectedClosure?: string;
    hasParchi?: boolean;
    parchiImage?: FileList;
  };
};

const UPDATE_LEAD_MUTATION = gql`
  mutation UpdateLead($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      _id
      expectedClosure
      hasParchi
      parchiImage
      underCompetition
      competitions {
        _id
        competitionName
        competitionValue
        competitionQuoteImage
      }
    }
  }
`;

const ParchiDetails: React.FC<ParchiProps> = ({ initialData = {} }) => {
  const [formState, setFormState] = useState(initialData);
  const { leadId = '' } = useParams<{ leadId: string }>();

  const [updateLead, { loading: updatingLead }] = useMutation<
    UpdateLeadMutationResponse,
    UpdateLeadMutationVariables
  >(UPDATE_LEAD_MUTATION);

  const handleChange = (fieldName: string, val: any) => {
    setFormState(prev => ({ ...prev, [fieldName]: val }));
  };

  const refineFieldsAndUpdateLead = (data: any) => {
    const updatedFields = { ...data };

    if (!!updatedFields.expectedClosure) {
      updatedFields.expectedClosure = dayjs(updatedFields.expectedClosure).format('YYYY-MM-DD');
    }

    updateLead({
      variables: {
        input: {
          _id: leadId,
          ...updatedFields,
        },
      },
      onCompleted: data => {
        const {
          updateLead: { hasParchi, parchiImage, expectedClosure },
        } = data as any;

        setFormState({
          expectedClosure: dayjs(expectedClosure),
          hasParchi,
          parchiImage,
        });

        toast.success(PARCHI_UPDATE_SUCCESS);
      },
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();

    if (!!!formState.hasParchi) {
      const newState = {
        expectedClosure: formState.expectedClosure,
        parchiImage: [],
        hasParchi: formState.hasParchi,
      };
      setFormState(newState);
      refineFieldsAndUpdateLead(newState);
    } else {
      refineFieldsAndUpdateLead(formState);
    }
  };

  return (
    <form onSubmit={submitHandler}>
      <Grid container spacing={2} mt={0}>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl fullWidth>
              <MobileDatePicker
                label="Expected Closure Date"
                format="DD/MM/YYYY"
                onChange={newValue => handleChange('expectedClosure', newValue)}
                value={formState.expectedClosure}
              />
            </FormControl>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} columnSpacing={2}>
          <Fieldset label="Has Parchi">
            <Grid container columnSpacing={2} rowGap={2.5}>
              <Grid item xs={6}>
                <Selection
                  activeId={formState.hasParchi}
                  id={true}
                  label="YES"
                  handleChange={value => handleChange('hasParchi', value[0])}
                />
              </Grid>
              <Grid item xs={6}>
                <Selection
                  activeId={formState.hasParchi}
                  id={false}
                  label="NO"
                  handleChange={value => handleChange('hasParchi', value[0])}
                />
              </Grid>
              {formState.hasParchi && (
                <Grid item xs={12}>
                  <UploadInput
                    onChange={val => handleChange('parchiImage', val)}
                    value={formState.parchiImage ?? []}
                    multiple
                    required
                    label="Upload Parchi"
                  />
                </Grid>
              )}
            </Grid>
          </Fieldset>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="column">
            <LoadingButton
              size="small"
              variant="contained"
              loading={updatingLead}
              disabled={shouldDisableSubmit(initialData, formState)}
              type="submit"
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const shouldDisableSubmit = (oldData: any, newData: any) => {
  if (!!!newData.expectedClosure) return true;

  if (JSON.stringify(newData.expectedClosure) !== JSON.stringify(oldData.expectedClosure))
    return false;

  if (!!!newData.hasParchi) return !!!(newData.hasParchi !== oldData.hasParchi);

  if (JSON.stringify(newData.parchiImage) === JSON.stringify(oldData.parchiImage)) return true;

  return false;
};

export default ParchiDetails;
