import { gql, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Customer } from 'types/common';

import Filters from 'components/Filters';
import SearchBar from 'components/Inputs/Search';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import TileList from 'components/TileList';

import { transformFilters } from 'utils/transformFn';

import theme from './theme.module.scss';

type CustomersListResponse = {
  getCustomers: Customer[];
};

export const CUSTOMERS_LIST_QUERY = gql`
  query GetCustomers($filter: CustomerFilter) {
    getCustomers(filter: $filter) {
      _id
      referenceId
      fname
      createdAt
      email
      gender
      isVerified
      lname
      mobile
      otp
      prefferedLanguage
      status
      customerType {
        _id
        name
      }
      assignedToCRM {
        fname
        _id
        lname
      }
      assignedToSales {
        fname
        _id
        lname
      }
    }
  }
`;

const Customers: FC = () => {
  const navigate = useNavigate();
  const {
    data: customersList,
    loading,
    refetch,
  } = useQuery<CustomersListResponse>(CUSTOMERS_LIST_QUERY, {
    variables: {
      filter: {},
    },
  });

  const [filters, setFilters] = useState<Record<string, any>>({});

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
    refetch({
      filter: newFilters,
    });
  };

  const customerActions = [
    <Button variant="contained" size="small" onClick={() => navigate('create-customer')}>
      <AddIcon fontSize="small" />
    </Button>,
  ];

  const renderContent = () => {
    return (
      <div className={theme.container}>
        <SearchBar
          name="customer search"
          label="Search a Customer"
          placeholder="ID / Name / Phone / Email"
          onSearchInputChange={searchFilter => {
            applyFilters({
              ...filters,
              searchTerm: !!searchFilter ? searchFilter : null,
            });
          }}
        />
        <Filters
          refetch={data => {
            applyFilters({
              searchTerm: filters.searchTerm ?? null,
              ...transformFilters(data, ['customerType', 'area']),
            });
          }}
          type="customers"
        />
        {loading ? (
          <LoadingIndicator size="1.6rem" />
        ) : (
          <TileList type="customer" list={customersList?.getCustomers} />
        )}
      </div>
    );
  };

  return (
    <Navbar title="Customers" actions={customerActions}>
      {renderContent()}
    </Navbar>
  );
};

export default Customers;
