import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { customersName } from 'graphql/query/area';
import {
  GET_CRM_USERS_QUERY,
  GET_FIELD_SALES_USERS,
  GET_STORE_ASSOCIATE_USERS,
  GetCRMUsersQueryResponse,
  GetFieldSalesUsersQueryResponse,
  GetStoreAssociateUsersQueryResponse,
} from 'graphql/query/common';
import {
  GET_STORE_DETAILS_BY_STORE_ASSOCIATE,
  GetStoreDetailsByStoreAssociateResponse,
  GetStoreDetailsByStoreAssociateVariables,
  LEAD_SOURCE_NAMES_QUERY,
  LeadSourceNamesQueryResponse,
} from 'graphql/query/lead';
import {
  PROJECT_NAMES_QUERY,
  ProjectNamesQueryResponse,
  ProjectNamesQueryVariables,
} from 'graphql/query/project';
import { useEffect, useMemo, useState } from 'react';
import { Lead, LeadCategoryEnum } from 'types/common';

import { getUpdatedFields } from 'components/FormPanel';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';
import TextField from 'components/Inputs/TextField';

import { transformCustomerNames } from 'utils/transformFn';

type LeadDetailsFormProps = {
  handleSubmit: (arg: any) => void;
  loading: boolean;
  initialData: Lead;
};

const LeadDetailsForm: React.FC<LeadDetailsFormProps> = ({
  handleSubmit,
  loading,
  initialData,
}) => {
  const [formState, setFormState] = useState<Lead>(initialData);

  useEffect(() => {
    if (formState.project?._id !== initialData.project?._id) {
      setFormState(prev => ({ ...prev, project: initialData.project }));
    }
  }, [initialData.project]);

  const [getCustomers, { data: customers, loading: customersLoading }] =
    useLazyQuery(customersName);

  const [getProjects, { data: projects, loading: projectsLoading }] = useLazyQuery<
    ProjectNamesQueryResponse,
    ProjectNamesQueryVariables
  >(PROJECT_NAMES_QUERY);

  const [getStoresDetails, { data: storesDetails, loading: loadingStoreDetails }] = useLazyQuery<
    GetStoreDetailsByStoreAssociateResponse,
    GetStoreDetailsByStoreAssociateVariables
  >(GET_STORE_DETAILS_BY_STORE_ASSOCIATE);

  const [getCRMUsers, { data: crmUsers, loading: loadingCrmUsers }] =
    useLazyQuery<GetCRMUsersQueryResponse>(GET_CRM_USERS_QUERY);

  const [getStoreAssociates, { data: storeAssociates, loading: loadingStoreAssociates }] =
    useLazyQuery<GetStoreAssociateUsersQueryResponse>(GET_STORE_ASSOCIATE_USERS);

  const [
    getFieldSalesAssociates,
    { data: fieldSalesAssociates, loading: loadingFieldSalesAssociates },
  ] = useLazyQuery<GetFieldSalesUsersQueryResponse>(GET_FIELD_SALES_USERS);

  const [getLeadSources, { data: leadSources, loading: leadSourcesLoading }] =
    useLazyQuery<LeadSourceNamesQueryResponse>(LEAD_SOURCE_NAMES_QUERY);

  const selectedStore = useMemo(() => {
    if (storesDetails?.getStoreDetailsByStoreAssociate) {
      const { store, storeLead } = storesDetails.getStoreDetailsByStoreAssociate;
      return {
        store: store ? `${store.name} (${store.referenceId})` : '',
        storeLead: storeLead
          ? `${storeLead.fname} ${storeLead?.lname ?? ''} (${storeLead.empId})`
          : '',
      };
    }
    return {
      store: '',
      storeLead: '',
    };
  }, [storesDetails]);

  useEffect(() => {
    if (!!initialData.storeAssociate?._id) {
      getStoresDetails({
        variables: {
          id: initialData.storeAssociate._id,
        },
      });
    }
  }, [initialData]);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        const updatedFields = getUpdatedFields(initialData, formState);
        handleSubmit(updatedFields);
      }}
    >
      <Grid container mt={0.5} columnSpacing={1.5} rowGap={2}>
        <Grid item xs={6}>
          <TextField
            value={formState.referenceId}
            disabled
            label="Lead ID"
            type="string"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Lead Category"
            size="medium"
            value={formState.productCategory}
            disabled
            required
            options={Object.values(LeadCategoryEnum).map(o => ({ value: o, label: o }))}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Funnel State"
            size="medium"
            value={formState.funnelState ?? ''}
            disabled
            required
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            fetch={getLeadSources}
            handleChange={val => handleChange('source', val)}
            label="Source"
            loading={leadSourcesLoading}
            options={leadSources?.getLeadSources ?? []}
            value={formState.source}
            required
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteWithFetch
            fetch={getCustomers}
            handleChange={val => {
              handleChange('customer', val);
              handleChange('project', null);
            }}
            label="Customer"
            disabled
            loading={customersLoading}
            labelWithId
            options={transformCustomerNames(customers?.getCustomers) ?? []}
            value={
              !!formState.customer?.fname
                ? transformCustomerNames([formState.customer])[0]
                : (formState.customer as any) ?? {
                    _id: '',
                    name: '',
                    referenceId: '',
                  }
            }
            required
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            fetch={getProjects}
            handleChange={val => handleChange('project', val)}
            label="Project"
            loading={projectsLoading}
            options={projects?.getProjects ?? []}
            disabled={!!!formState.customer.referenceId}
            value={formState.project ?? { _id: '', name: '', referenceId: '' }}
            variables={{
              filter: {
                searchTerm: formState.customer.referenceId,
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            fetch={getCRMUsers}
            handleChange={val => handleChange('callCenterOwner', val)}
            label="CRM"
            loading={loadingCrmUsers}
            options={transformCustomerNames(crmUsers?.fetchCRMUsers) ?? []}
            value={
              !!formState.callCenterOwner?.fname
                ? transformCustomerNames([formState.callCenterOwner])[0]
                : formState.callCenterOwner ?? {
                    _id: '',
                    name: '',
                    referenceId: '',
                  }
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            fetch={getFieldSalesAssociates}
            handleChange={val => handleChange('fieldSalesAssociate', val)}
            label="Field Sales Associates"
            loading={loadingFieldSalesAssociates}
            options={transformCustomerNames(fieldSalesAssociates?.fetchFieldSalesUsers) ?? []}
            value={
              !!formState.fieldSalesAssociate?.fname
                ? transformCustomerNames([formState.fieldSalesAssociate])[0]
                : formState.fieldSalesAssociate ?? {
                    _id: '',
                    name: '',
                    referenceId: '',
                  }
            }
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            labelWithId
            fetch={getStoreAssociates}
            label="Store Associate"
            loading={loadingStoreAssociates}
            options={transformCustomerNames(storeAssociates?.fetchStoreAssociateUsers) ?? []}
            handleChange={val => {
              handleChange('storeAssociate', val);
              !!val?._id &&
                getStoresDetails({
                  variables: {
                    id: val._id,
                  },
                });
            }}
            value={
              !!formState.storeAssociate?.fname
                ? transformCustomerNames([formState.storeAssociate])[0]
                : formState.storeAssociate ?? {
                    _id: '',
                    name: '',
                    referenceId: '',
                  }
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            label="Store"
            loading={loadingStoreDetails}
            value={!!!formState.storeAssociate?._id ? '' : selectedStore.store}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled
            label="Store Lead"
            loading={loadingStoreDetails}
            value={!!!formState.storeAssociate?._id ? '' : selectedStore.storeLead}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            loading={loading}
            disabled={loading || !!!Object.keys(getUpdatedFields(initialData, formState)).length}
            variant="contained"
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default LeadDetailsForm;
