import { useLazyQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  TFLBrandNamesQueryResponse,
  TFLBrandNamesQueryVariables,
  TFLGroupNamesQueryResponse,
  TFLGroupNamesQueryVariables,
  TFLSizesQueryResponse,
  TFLSizesQueryVariables,
  TFLSubCategoriesQueryResponse,
  TFLUnitsOfMeasureQueryResponse,
  TFLUnitsOfMeasureQueryVariables,
  TFL_BRAND_NAMES_QUERY,
  TFL_GROUP_NAMES_QUERY,
  TFL_SIZES_QUERY,
  TFL_SUB_CATEGORIES_QUERY,
  TFL_UNITS_OF_MEASURE_QUERY,
} from 'graphql/query/product/tflProduct';
import { StatusEnum } from 'types/common';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';

const TFLProductFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const [getSubCategories, { data: subCategories, loading: loadingSubCategories }] =
    useLazyQuery<TFLSubCategoriesQueryResponse>(TFL_SUB_CATEGORIES_QUERY);

  const [getGroupNames, { data: groups, loading: loadingGroupNames }] = useLazyQuery<
    TFLGroupNamesQueryResponse,
    TFLGroupNamesQueryVariables
  >(TFL_GROUP_NAMES_QUERY);

  const [getSizes, { data: sizes, loading: loadingSizes }] = useLazyQuery<
    TFLSizesQueryResponse,
    TFLSizesQueryVariables
  >(TFL_SIZES_QUERY);

  const [getUnitsOfMeasure, { data: unitsOfMeasure, loading: loadingUnitsOfMeasure }] =
    useLazyQuery<TFLUnitsOfMeasureQueryResponse, TFLUnitsOfMeasureQueryVariables>(
      TFL_UNITS_OF_MEASURE_QUERY
    );

  const [getBrands, { data: brands, loading: loadingBrands }] = useLazyQuery<
    TFLBrandNamesQueryResponse,
    TFLBrandNamesQueryVariables
  >(TFL_BRAND_NAMES_QUERY);
  const handleChange = (fieldName: string, value: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Sub Category"
          fetch={getSubCategories}
          handleChange={val => handleChange('subCategory', val)}
          loading={loadingSubCategories}
          options={(subCategories?.getTflProducts ?? []).map(p => p.subCategory)}
          value={state.subCategory ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Group"
          fetch={getGroupNames}
          handleChange={val => handleChange('group', val)}
          loading={loadingGroupNames}
          options={(groups?.getTflProducts ?? []).map(p => p.group)}
          value={state.group ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Size"
          fetch={getSizes}
          handleChange={val => handleChange('size', val)}
          loading={loadingSizes}
          options={(sizes?.getTflProducts ?? []).map(p => p.size)}
          value={state.size ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Unit of Measure"
          fetch={getUnitsOfMeasure}
          handleChange={val => handleChange('unitOfMeasure', val)}
          loading={loadingUnitsOfMeasure}
          options={(unitsOfMeasure?.getTflProducts ?? []).map(p => p.unitOfMeasure)}
          value={state.unitOfMeasure ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Brand"
          fetch={getBrands}
          handleChange={val => {
            handleChange('brand', val);
          }}
          loading={loadingBrands}
          options={(brands?.getTflProducts ?? []).map(product => product.brand)}
          value={state.brand ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          label="Status"
          value={state.status}
          onChange={val => setState(prev => ({ ...prev, status: val }))}
          options={Object.values(StatusEnum).map(o => ({ label: o, value: o }))}
        />
      </Grid>
    </Grid>
  );
};

export default TFLProductFilters;
