import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, InputAdornment, TextField } from '@mui/material';
import {
  MKWCriteriasQueryResponse,
  MKWCriteriasQueryVariables,
  MKWPropertyStatusQueryResponse,
  MKWPropertyStatusQueryVariables,
  MKWPropertyTypesQueryResponse,
  MKWPropertyTypesQueryVariables,
  MKWShapeOfKitchensQueryResponse,
  MKWShapeOfKitchensQueryVariables,
  MKWSizesQueryResponse,
  MKWSizesQueryVariables,
  MKWSubCategoriesQueryResponse,
  MKWTypesOfWardrobeQueryResponse,
  MKWTypesOfWardrobeQueryVariables,
  MKWUsagesQueryResponse,
  MKWUsagesQueryVariables,
  MKW_CRITERIAS_QUERY,
  MKW_PROPERTY_STATUS_QUERY,
  MKW_PROPERTY_TYPES_QUERY,
  MKW_SHAPE_OF_KITCHENS_QUERY,
  MKW_SIZES_QUERY,
  MKW_SUB_CATEGORIES_QUERY,
  MKW_TYPES_OF_WARDROBE_QUERY,
  MKW_USAGES_QUERY,
} from 'graphql/query/product/mkwProduct';
import React, { useState } from 'react';
import { AutoCompleteOption } from 'types/common';

import { getUpdatedFields } from 'components/FormPanel';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';

import { removeEmptyFields } from 'utils/common';
import { rankedFieldsChangeHandler } from 'utils/formHandlers';

const MKWProductForm: React.FC<{
  onSubmit: (arg: any) => void;
  loading: boolean;
  disabled?: boolean;
  initialData?: Record<string, any>;
  variant?: 'CREATE' | 'UPDATE';
}> = ({ onSubmit, loading, disabled = false, initialData = {}, variant = 'CREATE' }) => {
  const [formState, setFormState] = useState<Record<string, any>>(initialData);
  const [selectedProduct, setSelectedProduct] = useState<{
    _id: string;
    price: number;
    size: AutoCompleteOption;
  }>({
    _id: '',
    size: initialData.size ?? { _id: '', name: '' },
    price: initialData.price ?? '',
  });

  const rankedFields = [
    'subCategory',
    'propertyType',
    'propertyStatus',
    'usage',
    'criteria',
    'shapeOfKitchen',
    'typeOfWardrobe',
    'size',
    'quantity',
  ];

  const [getSubCategories, { data: subCategories, loading: loadingSubCategories }] =
    useLazyQuery<MKWSubCategoriesQueryResponse>(MKW_SUB_CATEGORIES_QUERY);

  const [getPropertyTypes, { data: propertyTypes, loading: loadingPropertyTypes }] = useLazyQuery<
    MKWPropertyTypesQueryResponse,
    MKWPropertyTypesQueryVariables
  >(MKW_PROPERTY_TYPES_QUERY);

  const [getPropertiesStatus, { data: propertiesStatus, loading: loadingPropertiesStatus }] =
    useLazyQuery<MKWPropertyStatusQueryResponse, MKWPropertyStatusQueryVariables>(
      MKW_PROPERTY_STATUS_QUERY
    );

  const [getUsages, { data: usages, loading: loadingUsages }] = useLazyQuery<
    MKWUsagesQueryResponse,
    MKWUsagesQueryVariables
  >(MKW_USAGES_QUERY);

  const [getCriterias, { data: criterias, loading: loadingCriterias }] = useLazyQuery<
    MKWCriteriasQueryResponse,
    MKWCriteriasQueryVariables
  >(MKW_CRITERIAS_QUERY);

  const [getShapeOfKitchens, { data: shapeOfKitchens, loading: loadingShapeOfKitchens }] =
    useLazyQuery<MKWShapeOfKitchensQueryResponse, MKWShapeOfKitchensQueryVariables>(
      MKW_SHAPE_OF_KITCHENS_QUERY
    );

  const [getTypesOfWardrobe, { data: typesOfWardrobe, loading: loadingTypesOfWardrobe }] =
    useLazyQuery<MKWTypesOfWardrobeQueryResponse, MKWTypesOfWardrobeQueryVariables>(
      MKW_TYPES_OF_WARDROBE_QUERY
    );

  const [getSizes, { data: sizes, loading: loadingSizes }] = useLazyQuery<
    MKWSizesQueryResponse,
    MKWSizesQueryVariables
  >(MKW_SIZES_QUERY);

  const getPrice = (value: AutoCompleteOption) => {
    if (!!sizes) {
      const currProduct = sizes.getMkwProducts.find(p => p.size === value._id) as any;
      setSelectedProduct(currProduct);
    }
  };

  const handleSubmit = () => {
    const newFields = removeEmptyFields({ ...formState });

    if (!!newFields.size) {
      onSubmit({
        product: selectedProduct._id,
        quantity: formState.quantity,
      });
    }
  };

  return (
    <Grid container px={1} py={2} rowGap={2} columnSpacing={1.5}>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Sub Category"
          fetch={getSubCategories}
          handleChange={val =>
            rankedFieldsChangeHandler('subCategory', val, setFormState, rankedFields)
          }
          loading={loadingSubCategories}
          disabled={disabled}
          options={(subCategories?.getMkwProducts ?? []).map(p => p.subCategory)}
          value={formState.subCategory ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Property Type"
          fetch={getPropertyTypes}
          handleChange={val =>
            rankedFieldsChangeHandler('propertyType', val, setFormState, rankedFields)
          }
          loading={loadingPropertyTypes}
          options={(propertyTypes?.getMkwProducts ?? []).map(p => p.propertyType)}
          value={formState.propertyType ?? { _id: '', name: '' }}
          disabled={!!!formState.subCategory || !!!formState.subCategory?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
            },
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Property Status"
          fetch={getPropertiesStatus}
          handleChange={val =>
            rankedFieldsChangeHandler('propertyStatus', val, setFormState, rankedFields)
          }
          loading={loadingPropertiesStatus}
          options={(propertiesStatus?.getMkwProducts ?? []).map(p => p.propertyStatus)}
          value={formState.propertyStatus ?? { _id: '', name: '' }}
          disabled={!!!formState.propertyType || !!!formState.propertyType?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              propertyType: formState.propertyType?._id,
            },
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Usage"
          fetch={getUsages}
          handleChange={val => rankedFieldsChangeHandler('usage', val, setFormState, rankedFields)}
          loading={loadingUsages}
          options={(usages?.getMkwProducts ?? []).map(p => p.usage)}
          value={formState.usage ?? { _id: '', name: '' }}
          disabled={!!!formState.propertyStatus || !!!formState.propertyStatus?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              propertyType: formState.propertyType?._id,
              propertyStatus: formState.propertyStatus?._id,
            },
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Criteria"
          fetch={getCriterias}
          handleChange={val =>
            rankedFieldsChangeHandler('criteria', val, setFormState, rankedFields)
          }
          loading={loadingCriterias}
          options={(criterias?.getMkwProducts ?? []).map(p => p.criteria)}
          value={formState.criteria ?? { _id: '', name: '' }}
          disabled={!!!formState.usage || !!!formState.usage?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              propertyType: formState.propertyType?._id,
              propertyStatus: formState.propertyStatus?._id,
              usage: formState.usage?._id,
            },
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Shape of Kitchen"
          fetch={getShapeOfKitchens}
          handleChange={val =>
            rankedFieldsChangeHandler('shapeOfKitchen', val, setFormState, rankedFields)
          }
          loading={loadingShapeOfKitchens}
          options={(shapeOfKitchens?.getMkwProducts ?? []).map(p => p.shapeOfKitchen)}
          value={formState.shapeOfKitchen ?? { _id: '', name: '' }}
          disabled={!!!formState.criteria || !!!formState.criteria?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              propertyType: formState.propertyType?._id,
              propertyStatus: formState.propertyStatus?._id,
              usage: formState.usage?._id,
              criteria: formState.criteria?._id,
            },
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Type of Wardrobe"
          fetch={getTypesOfWardrobe}
          handleChange={val =>
            rankedFieldsChangeHandler('typeOfWardrobe', val, setFormState, rankedFields)
          }
          loading={loadingTypesOfWardrobe}
          options={(typesOfWardrobe?.getMkwProducts ?? []).map(p => p.typeOfWardrobe)}
          value={formState.typeOfWardrobe ?? { _id: '', name: '' }}
          disabled={!!!formState.shapeOfKitchen || !!!formState.shapeOfKitchen?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              propertyType: formState.propertyType?._id,
              propertyStatus: formState.propertyStatus?._id,
              usage: formState.usage?._id,
              criteria: formState.criteria?._id,
              shapeOfKitchen: formState.shapeOfKitchen?._id,
            },
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Size"
          fetch={getSizes}
          handleChange={val => {
            rankedFieldsChangeHandler('size', val, setFormState, rankedFields);
            getPrice(val);
          }}
          loading={loadingSizes}
          options={(sizes?.getMkwProducts ?? []).map(p => p.size)}
          value={formState.size ?? { _id: '', name: '' }}
          disabled={!!!formState.typeOfWardrobe || !!!formState.typeOfWardrobe?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              propertyType: formState.propertyType?._id,
              propertyStatus: formState.propertyStatus?._id,
              usage: formState.usage?._id,
              criteria: formState.criteria?._id,
              shapeOfKitchen: formState.shapeOfKitchen?._id,
              typeOfWardrobe: formState.typeOfWardrobe?._id,
            },
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            label="Quantity"
            value={formState.quantity ?? ''}
            type="number"
            onChange={e =>
              rankedFieldsChangeHandler(
                'quantity',
                !!e.target.value ? +e.target.value : '',
                setFormState,
                rankedFields
              )
            }
            variant="outlined"
            required
            disabled={!!!formState.size || !!!formState.size._id || disabled}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            label="Estimated Value"
            type="number"
            variant="outlined"
            disabled
            value={formState.quantity ? +formState.quantity * selectedProduct.price : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <b color="lightGray">₹</b>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Grid>
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={4} marginRight="auto">
          <LoadingButton
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            loading={loading}
            disabled={
              variant === 'CREATE'
                ? !!!formState.quantity
                : !(
                    !!formState.quantity &&
                    !!Object.keys(getUpdatedFields(initialData, formState)).length
                  )
            }
          >
            {variant === 'CREATE' ? 'Create' : 'Update'}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MKWProductForm;
