import { useLazyQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import {
  HFDBrandNamesQueryResponse,
  HFDBrandNamesQueryVariables,
  HFDGroupNamesQueryResponse,
  HFDGroupNamesQueryVariables,
  HFDSizesQueryResponse,
  HFDSizesQueryVariables,
  HFDStandardsQueryResponse,
  HFDStandardsQueryVariables,
  HFDSubCategoriesQueryResponse,
  HFDUnitsOfMeasureQueryResponse,
  HFDUnitsOfMeasureQueryVariables,
  HFD_BRAND_NAMES_QUERY,
  HFD_GROUP_NAMES_QUERY,
  HFD_SIZES_QUERY,
  HFD_STANDARDS_QUERY,
  HFD_SUB_CATEGORIES_QUERY,
  HFD_UNITS_OF_MEASURE_QUERY,
} from 'graphql/query/product/hfdProduct';
import { StatusEnum } from 'types/common';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import { Select } from 'components/Inputs/Select';

const HFDProductFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const [getSubCategories, { data: subCategories, loading: loadingSubCategories }] =
    useLazyQuery<HFDSubCategoriesQueryResponse>(HFD_SUB_CATEGORIES_QUERY);

  const [getGroupNames, { data: groups, loading: loadingGroupNames }] = useLazyQuery<
    HFDGroupNamesQueryResponse,
    HFDGroupNamesQueryVariables
  >(HFD_GROUP_NAMES_QUERY);

  const [getStandards, { data: standards, loading: loadingStandards }] = useLazyQuery<
    HFDStandardsQueryResponse,
    HFDStandardsQueryVariables
  >(HFD_STANDARDS_QUERY);

  const [getSizes, { data: sizes, loading: loadingSizes }] = useLazyQuery<
    HFDSizesQueryResponse,
    HFDSizesQueryVariables
  >(HFD_SIZES_QUERY);

  const [getUnitsOfMeasure, { data: unitsOfMeasure, loading: loadingUnitsOfMeasure }] =
    useLazyQuery<HFDUnitsOfMeasureQueryResponse, HFDUnitsOfMeasureQueryVariables>(
      HFD_UNITS_OF_MEASURE_QUERY
    );

  const [getBrands, { data: brands, loading: loadingBrands }] = useLazyQuery<
    HFDBrandNamesQueryResponse,
    HFDBrandNamesQueryVariables
  >(HFD_BRAND_NAMES_QUERY);

  const handleChange = (fieldName: string, value: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Sub Category"
          fetch={getSubCategories}
          handleChange={val => handleChange('subCategory', val)}
          loading={loadingSubCategories}
          options={(subCategories?.getHfdProducts ?? []).map(p => p.subCategory)}
          value={state.subCategory ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Group"
          fetch={getGroupNames}
          handleChange={val => handleChange('group', val)}
          loading={loadingGroupNames}
          options={(groups?.getHfdProducts ?? []).map(p => p.group)}
          value={state.group ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Standard"
          fetch={getStandards}
          handleChange={val => handleChange('standard', val)}
          loading={loadingStandards}
          options={(standards?.getHfdProducts ?? []).map(p => p.standard)}
          value={state.standard ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Size"
          fetch={getSizes}
          handleChange={val => handleChange('size', val)}
          loading={loadingSizes}
          options={(sizes?.getHfdProducts ?? []).map(p => p.size)}
          value={state.size ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Unit of Measure"
          fetch={getUnitsOfMeasure}
          handleChange={val => handleChange('unitOfMeasure', val)}
          loading={loadingUnitsOfMeasure}
          options={(unitsOfMeasure?.getHfdProducts ?? []).map(p => p.unitOfMeasure)}
          value={state.unitOfMeasure ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Brand"
          fetch={getBrands}
          handleChange={val => {
            handleChange('brand', val);
          }}
          loading={loadingBrands}
          options={(brands?.getHfdProducts ?? []).map(product => product.brand)}
          value={state.brand ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>

      <Grid item xs={6}>
        <Select
          label="Status"
          value={state.status}
          onChange={val => setState(prev => ({ ...prev, status: val }))}
          options={Object.values(StatusEnum).map(o => ({ label: o, value: o }))}
        />
      </Grid>
    </Grid>
  );
};

export default HFDProductFilters;
