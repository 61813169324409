import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button } from '@mui/material';
import { FC } from 'react';

import { VisuallyHiddenInput } from 'components/Inputs/VisuallyHiddenInput';

type UploadExcelProps = {
  value?: any;
  onChange: (arg: any) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
};

const UploadExcel: FC<UploadExcelProps> = ({
  value = [],
  onChange,
  label,
  required = false,
  disabled = false,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files ?? []);
    onChange(files);

    setTimeout(() => {
      e.target.value = '';
    });
  };

  return (
    <Button
      component="label"
      variant="outlined"
      size="small"
      disabled={disabled}
      startIcon={<CloudUploadIcon />}
    >
      {label ? label : 'Upload Excel'}
      <VisuallyHiddenInput
        type="file"
        accept=".xlsx,.csv,.xls"
        name="Upload Excel"
        required={!!value ? false : required}
        onChange={handleChange}
        disabled={disabled}
      />
    </Button>
  );
};

export default UploadExcel;
