import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';

import Transition from 'components/Transition';

type VerifyCustomerProps = {
  open: boolean;
  onClose: () => void;
  id: string;
};

const VerifyCustomer: React.FC<VerifyCustomerProps> = ({ open, onClose, id }) => {
  const [otpValue, setOtpValue] = useState('');

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle sx={{ fontWeight: 600, fontSize: '1.1rem', pb: 1.1 }}>
        Please verify the customer
      </DialogTitle>
      <DialogContent>
        <Grid container pt={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                value={otpValue}
                onChange={e => setOtpValue(e.target.value)}
                type="tel"
                variant="outlined"
                label="OTP"
                size="small"
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          fullWidth
          onClick={onClose}
          variant="contained"
          loading={false}
          disabled={!!!otpValue}
        >
          Submit
        </LoadingButton>
        <Button fullWidth onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerifyCustomer;
