import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import React from 'react';

import LoadingIndicator from 'components/LoadingIndicator';

type SelectProps = {
  options: { label: string; value: string }[];
  onChange?: (arg: any) => void;
  showNone?: boolean;
} & MuiSelectProps;

export const Select: React.FC<SelectProps> = ({
  label,
  error,
  size = 'medium',
  value,
  onChange = () => {},
  options,
  placeholder,
  required,
  showNone = true,
  ...props
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel error={error && error} id={label + '-label'} required={required}>
        {label}
      </InputLabel>
      <MuiSelect
        labelId={label + '-label'}
        label={label}
        size={size}
        value={value}
        error={error && error}
        onChange={e => onChange(e.target.value)}
        displayEmpty={true}
        renderValue={value => (value ? options?.find(o => o.value === value)?.label : placeholder)}
        required={required}
        {...props}
      >
        {showNone && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {options &&
          options.map(o => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
      </MuiSelect>
    </FormControl>
  );
};

export const MultiSelect: React.FC<{
  label: string;
  value: any[];
  handleChange: (arg: any) => void;
  options: {
    label: string;
    value: any;
  }[];
  required?: boolean;
  fetchConfig?: {
    fetchFn: any;
    variables: Record<string, any>;
    loading: boolean;
  };
}> = ({ label, value, handleChange, options = [], required = false, fetchConfig }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={label + '-label'} required={required}>
        {label}
      </InputLabel>
      <MuiSelect
        multiple
        labelId={label + '-label'}
        label={label}
        name={label}
        id={label}
        value={value}
        onOpen={() =>
          !!fetchConfig && !!fetchConfig.fetchFn
            ? fetchConfig.fetchFn({ variables: fetchConfig.variables })
            : undefined
        }
        required={required}
        onChange={e =>
          handleChange(
            typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
          )
        }
        endAdornment={
          fetchConfig?.loading && (
            <Box mr={2}>
              <LoadingIndicator size="1rem" />
            </Box>
          )
        }
        renderValue={selected => {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip
                  key={JSON.stringify(value)}
                  size="small"
                  label={options?.find(o => o.value === value)?.label}
                />
              ))}
            </Box>
          );
        }}
        MenuProps={{ PaperProps: { sx: { maxHeight: '75%' } } }}
      >
        {options &&
          options.map(o => (
            <MenuItem key={JSON.stringify(o.value)} value={o.value}>
              <Checkbox size="small" checked={value.indexOf(o.value) > -1} />
              {o.label}
            </MenuItem>
          ))}
      </MuiSelect>
    </FormControl>
  );
};
