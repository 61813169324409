// lead
export const LEAD_CREATE_SUCCESS = 'Lead Created Successfully';
export const LEAD_UPDATE_SUCCESS = 'Lead Updated Successfully';

// lead product
export const LEAD_PRODUCT_CREATE_SUCCESS = 'Product Created Successfully';
export const LEAD_PRODUCT_UPDATE_SUCCESS = 'Product Updated Successfully';
export const LEAD_PRODUCT_DELETE_SUCCESS = 'Product Deleted Successfully';

// lead competiton
export const COMPETITION_CREATE_SUCCESS = 'Competition Added Successfully';
export const COMPETITION_UPDATE_SUCCESS = 'Competition Updated Successfully';
export const COMPETITION_DELETE_SUCCESS = 'Competition Deleted Successfully';

// lead parchi
export const PARCHI_CREATE_SUCCESS = 'Parchi Created Successfully';
export const PARCHI_UPDATE_SUCCESS = 'Parchi Updated Successfully';

// admin
export const ADMIN_BULK_CREATE_SUCCESS = 'Data Uploaded Successfully';
export const ADMIN_BULK_UPDATE_SUCCESS = 'Data Updated Successfully';

// card actions
export const CALL_INITIATED_SUCCESS = 'Call Initiated Successfully!';
export const EMAIL_SENT_SUCCESS = 'Email Sent Successfully!';

// project
export const PROJECT_CREATE_SUCCESS = 'Project Created Successfully';
export const PROJECT_UPDATE_SUCCESS = 'Project Updated Successfully';

// site
export const SITE_CREATE_SUCCESS = 'Site Created Successfully.';
export const SITE_UPDATE_SUCCESS = 'Site Updated Successfully.';

// address
export const ADDRESS_CREATE_SUCCESS = 'Address Created Successfully.';
export const ADDRESS_UPDATE_SUCCESS = 'Address Updated Successfully.';

// followup
export const FOLLOWUP_CREATE_SUCCESS = 'Followup Created Successfully.';
export const FOLLOWUP_UPDATE_SUCCESS = 'Followup Updated Successfully.';

// fieldvisit
export const FIELDVISIT_CREATE_SUCCESS = 'Field Visit Created Successfully.';
export const FIELDVISIT_UPDATE_SUCCESS = 'Field Visit Updated Successfully.';

// customer
export const CUSTOMER_CREATE_SUCCESS = 'Customer Created Successfully.';
export const CUSTOMER_UPDATE_SUCCESS = 'Customer Updated Successfully.';
export const CUSTOMER_ADDRESS_UPDATE_SUCCESS = 'Customer Address Updated Successfully.';
export const CUSTOMER_ADDRESS_CREATE_SUCCESS = 'Customer Address Created Successfully.';
