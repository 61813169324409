import { Grid, Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';

import Accordion from 'components/Accordion';
import GroupedAccordion from 'components/Accordion/GroupedAccordion';
import Fieldset from 'components/Fieldset';

import { fixToTwoLocalPrice } from 'utils/formatHelper';
import getStatusDetail from 'utils/statusMessage';

import theme from './theme.module.scss';

type Data = {
  label?: string;
  value?: any;
  type?:
    | 'DATE'
    | 'DATETIME'
    | 'STRING'
    | 'CURRENCY'
    | 'IMAGE'
    | 'NUMBER'
    | 'STATUS'
    | 'FILE'
    | 'SELECT'
    | 'CUSTOM'
    | 'PRODUCTS'
    | 'LINK'
    | 'EXTERNAL_LINK';

  navigateTo?: string;
  fullWidth?: boolean;
};

const getValue = (value: Data['value'], type: Data['type'], navigateTo?: Data['navigateTo']) => {
  switch (type) {
    case 'CURRENCY':
      return `₹ ${fixToTwoLocalPrice(value)}`;
    case 'DATE':
      return dayjs(value).format('DD MMMM YYYY');
    case 'DATETIME':
      return dayjs(value).format('h:mm A, DD MMMM YYYY');
    case 'FILE':
      return value ? (
        <a href={value} rel="noreferrer" target="_blank">
          Download
        </a>
      ) : (
        'Not Available'
      );
    case 'IMAGE':
      return (
        <div
          className={theme.image}
          style={{
            backgroundImage: `url("${value}")`,
          }}
        />
      );
    case 'NUMBER':
      return value;
    case 'STATUS':
      return getStatusDetail(value).labelWithBadge;
    case 'LINK':
      return <a href={navigateTo || ''}>{value}</a>;
    case 'EXTERNAL_LINK':
      return (
        <a href={navigateTo || ''} rel="noreferrer" target="_blank">
          {value}
        </a>
      );
    default:
      return value;
  }
};

const Details: FC<{ data: Data[]; title?: string; isHorizontal?: boolean; open?: boolean }> = ({
  data,
  title = '',
  isHorizontal = false,
  open = false,
}) => {
  const isTabScreen = useMediaQuery('(max-width:900px)');
  if (isTabScreen) isHorizontal = false;

  return (
    <Accordion open={open} title={title} isHorizontal={isHorizontal}>
      <Grid
        container
        flexWrap="wrap"
        alignItems="center"
        columnSpacing={1}
        rowGap={2}
        px={1}
        py={1.5}
      >
        {data.map(row => {
          return (
            <Grid item key={row.label} xs={row.fullWidth ? 12 : 6}>
              <Fieldset label={row.label ?? ''} variant="small">
                <Typography>
                  {row.value ? getValue(row.value, row.type, row.navigateTo) : '-'}
                </Typography>
              </Fieldset>
            </Grid>
          );
        })}
      </Grid>
    </Accordion>
  );
};

export const DetailsControlled: FC<{
  data: Data[];
  title?: string;
  isHorizontal?: boolean;
  activeIdx: number | string;
  currIdx: number | string;
  setActiveIdx: (arg: number | string) => void;
}> = ({ data, title = '', isHorizontal = false, activeIdx, setActiveIdx, currIdx }) => {
  const isTabScreen = useMediaQuery('(max-width:900px)');
  if (isTabScreen) isHorizontal = false;

  return (
    <GroupedAccordion
      activeIndex={activeIdx}
      currIndex={currIdx}
      setActiveIndex={setActiveIdx}
      title={title}
      isHorizontal={isHorizontal}
    >
      <Grid
        container
        flexWrap="wrap"
        alignItems="center"
        columnSpacing={1}
        rowGap={2}
        px={1}
        py={1.5}
      >
        {data.map(row => {
          return (
            <Grid item key={row.label} xs={row.fullWidth ? 12 : 6}>
              <Fieldset label={row.label ?? ''} variant="small">
                <Typography>
                  {row.value ? getValue(row.value, row.type, row.navigateTo) : '-'}
                </Typography>
              </Fieldset>
            </Grid>
          );
        })}
      </Grid>
    </GroupedAccordion>
  );
};

export default Details;
