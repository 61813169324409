import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import youkraftLogo from 'media/youkraftLogo.png';

import { getUserInfo } from 'utils/auth';

import theme from './theme.module.scss';

const SideNavForMobile = ({ setIsDrawerMinimized, tabs }) => {
  const [openDrawer, toggleDrawer] = useState(true);
  const navigate = useNavigate();

  const userInfo = getUserInfo();

  return (
    <div className={theme.container}>
      <MenuIcon
        onClick={() => {
          toggleDrawer(!openDrawer);
        }}
        className={theme.column}
      />

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => {
          toggleDrawer(!openDrawer);
          setIsDrawerMinimized(!openDrawer);
        }}
      >
        <div className={theme.drawerList}>
          <div className={theme.logoContainer}>
            {/* <img
              className={theme.logo}
              src={youkraftLogo}
              alt="Youkraft logo"
              onClick={() => navigate('/')}
            /> */}
            <div className={theme.name}>
              <Typography variant="subtitle1" fontWeight={500}>{userInfo.fname + ' ' + userInfo.lname}</Typography>
            </div>
          </div>
          {tabs.map(tab => {
            return (
              <NavLink to={tab.route} key={tab.key}>
                <div className={theme.menus}>
                  <img src={tab.icon} alt={tab.icon} />
                  <span>{tab.label}</span>
                </div>
              </NavLink>
            );
          })}
        </div>
      </Drawer>
    </div>
  );
};

export default SideNavForMobile;
