import { gql, useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { PROJECT_UPDATE_SUCCESS } from 'data/notificationsConst';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Customer, Project } from 'types/common';

import GroupedAccordion from 'components/Accordion/GroupedAccordion';
import AssociatedCustomers from 'components/AssociatedCustomers';
import { DetailsControlled } from 'components/Details';
import { getUpdatedFields } from 'components/FormPanel';
import GoBackButton from 'components/GoBackButton';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import TileList from 'components/TileList';

import ProjectAddress from './ProjectAddress';
import UpdateProjectForm from './ProjectForm';

const GET_PROJECT_QUERY = gql`
  query GetProjectById($id: ID!) {
    getProjectById(_id: $id) {
      _id
      referenceId
      name
      type
      description
      status
      constructionType
      noOfBedrooms
      noOfKitchens
      noOfBathrooms
      houseNo
      floorNo
      leads {
        _id
        customer {
          _id
          fname
          lname
          referenceId
          email
          mobile
        }
        productCategory
        status
        referenceId
        expectedClosure
        estimatedValue
        funnelState
        leadProducts {
          _id
          category
          product {
            ... on HfdProduct {
              _id
              subCategory
              group
              standard
              size
              unitOfMeasure
              price
              brand {
                _id
                name
              }
              status
              createdAt
              updatedAt
            }
            ... on HwtProduct {
              _id
              subCategory
              group
              size
              unitOfMeasure
              price
              weight
              standard
              type
              status
              createdAt
              updatedAt
            }
            ... on LhaProduct {
              _id
              subCategory
              group
              application
              wattage
              price
              brand {
                _id
                name
              }
              status
              createdAt
              updatedAt
            }
            ... on MkwProduct {
              _id
              subCategory
              propertyType
              propertyStatus
              usage
              criteria
              shapeOfKitchen
              typeOfWardrobe
              size
              price
              status
              createdAt
              updatedAt
            }
            ... on TflProduct {
              _id
              subCategory
              group
              size
              unitOfMeasure
              price
              brand {
                _id
                name
              }
              finish
              status
              createdAt
              updatedAt
            }
            ... on WmtProduct {
              _id
              subCategory
              group
              product
              gradeOrFinish
              size
              unitOfMeasure
              brand {
                _id
                referenceId
                name
              }
              price
              status
              createdAt
              updatedAt
            }
          }
        }
      }
      customer {
        _id
        referenceId
        fname
        lname
        prefferedLanguage
        status
        customerType {
          name
          _id
        }
        gender
      }
      site {
        _id
        referenceId
        name
        siteStatus
        expectedCompletion
        siteSize
        siteType
        area {
          _id
          name
        }
        address {
          _id
          addressLine1
          addressLine2
          area {
            _id
            name
          }
          landmark
          pincode
          city
          state
          latitude
          longitude
        }
      }
      associatedCustomers {
        _id
        customerType {
          _id
          name
        }
        fname
        lname
        mobile
        referenceId
        email
        gender
        isVerified
        prefferedLanguage
        status
      }
    }
  }
`;

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      _id
      referenceId
      name
      type
      description
      status
      constructionType
      noOfBedrooms
      noOfKitchens
      noOfBathrooms
      houseNo
      floorNo
      leads {
        _id
        customer {
          _id
          fname
          lname
          referenceId
          email
          mobile
        }
        productCategory
        status
        referenceId
        expectedClosure
        estimatedValue
        funnelState
        leadProducts {
          _id
          category
          product {
            ... on HfdProduct {
              _id
              subCategory
              group
              standard
              size
              unitOfMeasure
              price
              brand {
                _id
                name
              }
              status
              createdAt
              updatedAt
            }
            ... on HwtProduct {
              _id
              subCategory
              group
              size
              unitOfMeasure
              price
              weight
              standard
              type
              status
              createdAt
              updatedAt
            }
            ... on LhaProduct {
              _id
              subCategory
              group
              application
              wattage
              price
              brand {
                _id
                name
              }
              status
              createdAt
              updatedAt
            }
            ... on MkwProduct {
              _id
              subCategory
              propertyType
              propertyStatus
              usage
              criteria
              shapeOfKitchen
              typeOfWardrobe
              size
              price
              status
              createdAt
              updatedAt
            }
            ... on TflProduct {
              _id
              subCategory
              group
              size
              unitOfMeasure
              price
              brand {
                _id
                name
              }
              finish
              status
              createdAt
              updatedAt
            }
            ... on WmtProduct {
              _id
              subCategory
              group
              product
              gradeOrFinish
              size
              unitOfMeasure
              brand {
                _id
                referenceId
                name
              }
              price
              status
              createdAt
              updatedAt
            }
          }
        }
      }
      customer {
        _id
        referenceId
        fname
        lname
        prefferedLanguage
        status
        customerType {
          name
          _id
        }
        gender
      }
      site {
        _id
        referenceId
        name
        siteStatus
        expectedCompletion
        siteSize
        siteType
        area {
          _id
          name
        }
        address {
          _id
          addressLine1
          addressLine2
          area {
            _id
            name
          }
          landmark
          pincode
          city
          state
          latitude
          longitude
        }
      }
      associatedCustomers {
        _id
        customerType {
          _id
          name
        }
        fname
        lname
        mobile
        referenceId
        email
        gender
        isVerified
        prefferedLanguage
        status
      }
    }
  }
`;

type GetProjectQueryResponse = {
  getProjectById: Project;
};

type GetProjectQueryVariables = {
  id: string;
};

type UpdateProjectMutationResponse = {
  updateProject: Project;
};

type UpdateProjectMutationVariables = {
  input: any;
};

const ProjectPage = () => {
  const { projectId = '' } = useParams<{ projectId: string }>();
  const [activeProductIndex, setActiveProductIndex] = useState<number | string>(0);
  const [currProjectAddrs, setCurrProjectAddrs] = useState({
    floorNo: '',
    houseNo: '',
  });

  const navigate = useNavigate();

  const { data: project, loading: loadingProject } = useQuery<
    GetProjectQueryResponse,
    GetProjectQueryVariables
  >(GET_PROJECT_QUERY, {
    variables: {
      id: projectId,
    },
  });

  const [updateProject, { loading: updating }] = useMutation<
    UpdateProjectMutationResponse,
    UpdateProjectMutationVariables
  >(UPDATE_PROJECT_MUTATION);

  const handleProjectSubmit = (data: any) => {
    delete data.referenceId;
    delete data.__typename;

    if (Array.isArray(data.associatedCustomers) && data.associatedCustomers.length > 0) {
      const cusIds: string[] = [];
      data.associatedCustomers.forEach((cus: Customer) => {
        cusIds.push(cus._id);
      });
      data.associatedCustomers = cusIds;
    }

    updateProject({
      variables: {
        input: data,
      },
      onCompleted: _ => {
        toast.success(PROJECT_UPDATE_SUCCESS);
      },
    });
  };

  return (
    <Navbar>
      <GoBackButton title="Project Details" />
      {loadingProject || !project ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid container flexDirection="column" pb={4}>
          <Grid item xs={12}>
            <GroupedAccordion
              title="Project"
              activeIndex={activeProductIndex}
              setActiveIndex={setActiveProductIndex}
              currIndex={0}
            >
              <Grid px={1} py={1}>
                <UpdateProjectForm
                  onSubmit={handleProjectSubmit}
                  loading={loadingProject || updating}
                  initialData={{
                    ...project.getProjectById,
                    houseNo: undefined,
                    floorNo: undefined,
                  }}
                  variant="UDPATE"
                />
              </Grid>
            </GroupedAccordion>
          </Grid>
          <Grid item xs={12}>
            <GroupedAccordion
              title="Project Address"
              activeIndex={activeProductIndex}
              setActiveIndex={setActiveProductIndex}
              currIndex={1}
            >
              <Grid container px={1} py={2}>
                <ProjectAddress
                  onChange={data => {
                    setCurrProjectAddrs({ ...data });
                  }}
                  siteAddress={project.getProjectById.site.address}
                  initialData={{
                    houseNo: project.getProjectById.houseNo,
                    floorNo: project.getProjectById.floorNo,
                  }}
                />
                <Grid item xs={12} md={6} mt={2}>
                  <LoadingButton
                    loading={updating || loadingProject}
                    variant="outlined"
                    disabled={
                      !!!currProjectAddrs.floorNo ||
                      !!!currProjectAddrs.houseNo ||
                      !!!Object.keys(
                        getUpdatedFields(
                          {
                            floorNo: project.getProjectById.floorNo,
                            houseNo: project.getProjectById.houseNo,
                          },
                          currProjectAddrs
                        )
                      ).length
                    }
                    onClick={() =>
                      handleProjectSubmit({
                        _id: projectId,
                        ...currProjectAddrs,
                      })
                    }
                  >
                    Update Address
                  </LoadingButton>
                </Grid>
              </Grid>
            </GroupedAccordion>
          </Grid>
          <Grid item xs={12}>
            <DetailsControlled
              activeIdx={activeProductIndex}
              currIdx={2}
              setActiveIdx={setActiveProductIndex}
              title="Site"
              data={[
                {
                  label: 'Site Name',
                  type: 'STRING',
                  value: project.getProjectById.site?.name,
                  fullWidth: true,
                },
                {
                  label: 'Site Status',
                  type: 'STRING',
                  value: project.getProjectById.site?.siteStatus,
                },
                {
                  label: 'Area Name',
                  type: 'STRING',
                  value: project.getProjectById.site?.area?.name,
                },
                {
                  label: 'Site Size',
                  type: 'STRING',
                  value: project.getProjectById.site?.siteSize,
                },
                {
                  label: 'Expected Completion',
                  type: 'DATE',
                  value: project.getProjectById.site?.expectedCompletion,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <GroupedAccordion
              activeIndex={activeProductIndex}
              currIndex={3}
              setActiveIndex={setActiveProductIndex}
              title="Leads"
            >
              <Grid px={1.5} py={1.5}>
                {!!project.getProjectById.leads.length && (
                  <Grid item xs={12} mb={2}>
                    <TileList type="lead" list={project.getProjectById.leads} />
                  </Grid>
                )}
                <Grid item xs={12} container>
                  <Button
                    fullWidth
                    variant={'outlined'}
                    size="small"
                    sx={{ fontSize: '0.8rem' }}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      const data = {
                        customer: {
                          _id: project.getProjectById.customer._id,
                          referenceId: project.getProjectById.customer.referenceId,
                          name:
                            project.getProjectById.customer.fname +
                            ' ' +
                            (project.getProjectById.customer.lname ?? ''),
                        },
                        project: {
                          _id: project.getProjectById._id,
                          name: project.getProjectById.name,
                          referenceId: project.getProjectById.referenceId,
                        },
                      };
                      navigate(`/leads/create-lead?data=${JSON.stringify(data)}`);
                    }}
                  >
                    Create New Lead
                  </Button>
                </Grid>
              </Grid>
            </GroupedAccordion>
          </Grid>
          <Grid item xs={12}>
            <DetailsControlled
              activeIdx={activeProductIndex}
              currIdx={4}
              setActiveIdx={setActiveProductIndex}
              title="Owner"
              data={[
                {
                  label: 'First Name',
                  type: 'STRING',
                  value: project.getProjectById.customer.fname,
                },
                {
                  label: 'Last Name',
                  type: 'STRING',
                  value: project.getProjectById.customer.lname,
                },
                {
                  label: 'Preffered Language',
                  type: 'STRING',
                  value: project.getProjectById.customer.prefferedLanguage,
                },
                {
                  label: 'Status',
                  type: 'STRING',
                  value: project.getProjectById.customer.status,
                },
                {
                  label: 'Gender',
                  type: 'STRING',
                  value: project.getProjectById.customer.gender,
                },
                {
                  label: 'Customer_Type',
                  type: 'STRING',
                  value: project.getProjectById.customer.customerType?.name ?? '-', // why not loading properly
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <GroupedAccordion
              activeIndex={activeProductIndex}
              currIndex={5}
              setActiveIndex={setActiveProductIndex}
              title="Associated Customers"
            >
              <Grid px={1.5} pb={1.5} pt={0.5}>
                <AssociatedCustomers
                  id={project.getProjectById._id}
                  associatedCustomers={project.getProjectById.associatedCustomers ?? []}
                  updateMutation={updateProject}
                  updating={updating}
                />
              </Grid>
            </GroupedAccordion>
          </Grid>
        </Grid>
      )}
    </Navbar>
  );
};

export default ProjectPage;
