import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { FC } from 'react';

import { VisuallyHiddenInput } from 'components/Inputs/VisuallyHiddenInput';

import { resizeImageFile } from 'utils/imageResizer';

const UploadInput: FC<{
  value: any[];
  onChange: (arg: any) => void;
  label?: string;
  multiple?: boolean;
  accept?: string;
  required?: boolean;
}> = ({ value = [], onChange, accept, label, multiple = false, required = false }) => {
  const isMobileScreen = useMediaQuery('(max-width:600px)');

  const handleFileChange = async (e: any) => {
    const localFiles = e.target.files;

    if (localFiles) {
      const filesPromises: Promise<File>[] = [];

      for (let i = 0; i < localFiles.length; i++) {
        const src = URL.createObjectURL(localFiles[i]);
        localFiles[i].src = src;
        filesPromises.push(resizeImageFile(localFiles[i], true));
      }
      const resolvedFiles = await Promise.all(filesPromises);

      onChange([...value, ...resolvedFiles]);
    }
  };

  const deleteImg = (img: File | string) => {
    let newImages = value.filter(item => item !== img);
    onChange(newImages);
  };

  return (
    <Grid container flexDirection="column" rowSpacing={0.7}>
      <Grid
        item
        xs={12}
        container
        gap={1}
        alignItems="center"
        overflow="scroll"
        maxHeight="210px"
        py={1}
      >
        {value.map((img: any, idx) => (
          <div
            style={{
              position: 'relative',
            }}
            key={idx}
          >
            <img
              src={typeof img === 'string' ? img : img['src']}
              alt="img"
              style={{
                height: '100%',
                maxHeight: '180px',
                width: 'auto',
              }}
            />
            <DeleteForeverIcon
              color="error"
              fontSize="medium"
              sx={{
                position: 'absolute',
                right: 6,
                top: 6,
                cursor: 'pointer',
              }}
              onClick={() => deleteImg(img)}
            />
          </div>
        ))}
      </Grid>
      <Grid item xs={12} mt={1.5}>
        <Button
          component="label"
          variant="outlined"
          size="small"
          fullWidth={isMobileScreen}
          startIcon={<CloudUploadIcon />}
        >
          {label ? label : 'Upload Image'}
          <VisuallyHiddenInput
            type="file"
            accept={accept ? accept : '.jpg,.jpeg,.png,.doc,.pdf'}
            multiple={multiple}
            name="ImageButton"
            required={required ? !(value.length > 0) : false}
            onChange={handleFileChange}
          />
        </Button>
      </Grid>
    </Grid>
  );
};

export default UploadInput;
