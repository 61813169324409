import { NetworkStatus, useQuery } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FIELD_VISITS_QUERY } from 'routes/MyFieldVisits';

import LoadingIndicator from 'components/LoadingIndicator';
import TileList from 'components/TileList';

const FieldVisitSection = () => {
  const { data: fieldVisits, networkStatus: fieldVisitsNetworkStatus } = useQuery(
    FIELD_VISITS_QUERY,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        filter: {
          fieldVisitDateRange: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        },
      },
    }
  );

  const loadingFieldVisits =
    fieldVisitsNetworkStatus === NetworkStatus.loading ||
    fieldVisitsNetworkStatus === NetworkStatus.refetch;

  return (
    <Grid container direction={'column'} mb={'6rem'}>
      {loadingFieldVisits || !!!fieldVisits ? (
        <Grid item xs={12} justifySelf={'center'}>
          <LoadingIndicator size="1.6rem" />
        </Grid>
      ) : (
        <>
          {!!fieldVisits.getFieldVisits.length ? (
            <TileList type="fieldVisit" list={fieldVisits.getFieldVisits} />
          ) : (
            <Box height="10vh" display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2">No Field Visits for today</Typography>
            </Box>
          )}
        </>
      )}
    </Grid>
  );
};

export default FieldVisitSection;
