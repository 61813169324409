import { FormControl, Grid, TextField } from '@mui/material';
import { FieldVisitStatusEnum, FieldVisitTypeEnum } from 'types/common';

import DateRange from 'components/Inputs/DateRange';
import { MultiSelect } from 'components/Inputs/Select';

const FieldVisitFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const handleChange = (fieldName: string, val: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            value={state.searchTerm ?? ''}
            label="Field Visit Search"
            variant="outlined"
            onChange={e => handleChange('searchTerm', e.target.value)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <DateRange
          value={state.fieldVisitDateRange ?? [null, null]}
          onChange={val => handleChange('fieldVisitDateRange', val)}
          title="Field Visit Date"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Type"
          handleChange={val => handleChange('fieldVisitType', val)}
          options={Object.values(FieldVisitTypeEnum).map(o => ({ label: o, value: o }))}
          value={state.fieldVisitType ?? []}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Status"
          handleChange={val => handleChange('status', val)}
          options={Object.values(FieldVisitStatusEnum).map(o => ({ label: o, value: o }))}
          value={state.status ?? []}
        />
      </Grid>
    </Grid>
  );
};

export default FieldVisitFilters;
