import { gql } from '@apollo/client';
import { AutoCompleteOption } from 'types/common';

export type WMTSubCategoriesQueryResponse = {
  getWmtProducts: {
    subCategory: string;
  }[];
};

export const WMT_SUB_CATEGORIES_QUERY = gql`
  query GetWmtProducts {
    getWmtProducts {
      subCategory
    }
  }
`;

export type WMTGroupNamesQueryResponse = {
  getWmtProducts: {
    group: string;
  }[];
};

export type WMTGroupNamesQueryVariables = {
  filter: {
    subCategory: string;
  };
};

export const WMT_GROUP_NAMES_QUERY = gql`
  query GetWmtProducts($filter: WmtProductFilter) {
    getWmtProducts(filter: $filter) {
      group
    }
  }
`;

export type WMTProductsQueryResponse = {
  getWmtProducts: {
    product: string;
  }[];
};

export type WMTProductsQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
  };
};

export const WMT_PRODUCTS_QUERY = gql`
  query GetWmtProducts($filter: WmtProductFilter) {
    getWmtProducts(filter: $filter) {
      product
    }
  }
`;

export type WMTGradeOrFinishesQueryResponse = {
  getWmtProducts: {
    gradeOrFinish: string;
  }[];
};

export type WMTGradeOrFinishesQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    product: string;
  };
};

export const WMT_GRADE_OR_FINISHES_QUERY = gql`
  query GetWmtProducts($filter: WmtProductFilter) {
    getWmtProducts(filter: $filter) {
      gradeOrFinish
    }
  }
`;

export type WMTSizesQueryResponse = {
  getWmtProducts: {
    size: string;
  }[];
};

export type WMTSizesQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    product: string;
    gradeOrFinish: string;
  };
};

export const WMT_SIZES_QUERY = gql`
  query GetWmtProducts($filter: WmtProductFilter) {
    getWmtProducts(filter: $filter) {
      size
    }
  }
`;

export type WMTUnitsOfMeasureQueryResponse = {
  getWmtProducts: {
    unitOfMeasure: string;
  }[];
};

export type WMTUnitsOfMeasureQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    size: string;
    product: string;
    gradeOrFinish: string;
  };
};

export const WMT_UNITS_OF_MEASURE_QUERY = gql`
  query GetWmtProducts($filter: WmtProductFilter) {
    getWmtProducts(filter: $filter) {
      unitOfMeasure
    }
  }
`;

export type WMTBrandNamesQueryResponse = {
  getWmtProducts: {
    _id: string;
    price: number;
    brand: AutoCompleteOption;
  }[];
};

export type WMTBrandNamesQueryVariables = {
  filter: {
    subCategory: string;
    group: string;
    product: string;
    gradeOrFinish: string;
    size: string;
    unitOfMeasure: string;
  };
};

export const WMT_BRAND_NAMES_QUERY = gql`
  query GetWmtProducts($filter: WmtProductFilter) {
    getWmtProducts(filter: $filter) {
      _id
      price
      brand {
        _id
        name
      }
    }
  }
`;
