import { gql } from '@apollo/client';

export type MKWSubCategoriesQueryResponse = {
  getMkwProducts: {
    subCategory: string;
  }[];
};

export const MKW_SUB_CATEGORIES_QUERY = gql`
  query GetMkwProducts {
    getMkwProducts {
      subCategory
    }
  }
`;

export type MKWPropertyTypesQueryResponse = {
  getMkwProducts: {
    propertyType: string;
  }[];
};

export type MKWPropertyTypesQueryVariables = {
  filter: {
    subCategory: string;
  };
};

export const MKW_PROPERTY_TYPES_QUERY = gql`
  query GetMkwProducts($filter: MkwProductFilter) {
    getMkwProducts(filter: $filter) {
      propertyType
    }
  }
`;

export type MKWPropertyStatusQueryResponse = {
  getMkwProducts: {
    propertyStatus: string;
  }[];
};

export type MKWPropertyStatusQueryVariables = {
  filter: {
    subCategory: string;
    propertyType: string;
  };
};

export const MKW_PROPERTY_STATUS_QUERY = gql`
  query GetMkwProducts($filter: MkwProductFilter) {
    getMkwProducts(filter: $filter) {
      propertyStatus
    }
  }
`;

export type MKWUsagesQueryResponse = {
  getMkwProducts: {
    usage: string;
  }[];
};

export type MKWUsagesQueryVariables = {
  filter: {
    subCategory: string;
    propertyType: string;
    propertyStatus: string;
  };
};

export const MKW_USAGES_QUERY = gql`
  query GetMkwProducts($filter: MkwProductFilter) {
    getMkwProducts(filter: $filter) {
      usage
    }
  }
`;

export type MKWCriteriasQueryResponse = {
  getMkwProducts: {
    criteria: string;
  }[];
};

export type MKWCriteriasQueryVariables = {
  filter: {
    subCategory: string;
    propertyType: string;
    propertyStatus: string;
    usage: string;
  };
};

export const MKW_CRITERIAS_QUERY = gql`
  query GetMkwProducts($filter: MkwProductFilter) {
    getMkwProducts(filter: $filter) {
      criteria
    }
  }
`;

export type MKWShapeOfKitchensQueryResponse = {
  getMkwProducts: {
    shapeOfKitchen: string;
  }[];
};

export type MKWShapeOfKitchensQueryVariables = {
  filter: {
    subCategory: string;
    propertyType: string;
    propertyStatus: string;
    usage: string;
    criteria: string;
  };
};

export const MKW_SHAPE_OF_KITCHENS_QUERY = gql`
  query GetMkwProducts($filter: MkwProductFilter) {
    getMkwProducts(filter: $filter) {
      shapeOfKitchen
    }
  }
`;

export type MKWTypesOfWardrobeQueryResponse = {
  getMkwProducts: {
    typeOfWardrobe: string;
  }[];
};

export type MKWTypesOfWardrobeQueryVariables = {
  filter: {
    subCategory: string;
    propertyType: string;
    propertyStatus: string;
    usage: string;
    criteria: string;
    shapeOfKitchen: string;
  };
};

export const MKW_TYPES_OF_WARDROBE_QUERY = gql`
  query GetMkwProducts($filter: MkwProductFilter) {
    getMkwProducts(filter: $filter) {
      typeOfWardrobe
    }
  }
`;

export type MKWSizesQueryResponse = {
  getMkwProducts: {
    _id: string;
    price: number;
    size: string;
  }[];
};

export type MKWSizesQueryVariables = {
  filter: {
    subCategory: string;
    propertyType: string;
    propertyStatus: string;
    usage: string;
    criteria: string;
    shapeOfKitchen: string;
    typeOfWardrobe: string;
  };
};

export const MKW_SIZES_QUERY = gql`
  query GetMkwProducts($filter: MkwProductFilter) {
    getMkwProducts(filter: $filter) {
      _id
      price
      size
    }
  }
`;
