import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, InputAdornment, TextField } from '@mui/material';
import {
  LHAApplicationsQueryResponse,
  LHAApplicationsQueryVariables,
  LHABrandNamesQueryResponse,
  LHABrandNamesQueryVariables,
  LHAGroupNamesQueryResponse,
  LHAGroupNamesQueryVariables,
  LHASubCategoriesQueryResponse,
  LHAWattagesQueryResponse,
  LHAWattagesQueryVariables,
  LHA_APPLICATIONS_QUERY,
  LHA_BRAND_NAMES_QUERY,
  LHA_GROUP_NAMES_QUERY,
  LHA_SUB_CATEGORIES_QUERY,
  LHA_WATTAGES_QUERY,
} from 'graphql/query/product/lhaProduct';
import React, { useState } from 'react';
import { AutoCompleteOption } from 'types/common';

import { getUpdatedFields } from 'components/FormPanel';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';

import { removeEmptyFields } from 'utils/common';
import { rankedFieldsChangeHandler } from 'utils/formHandlers';

const LHAProductForm: React.FC<{
  onSubmit: (arg: any) => void;
  loading: boolean;
  disabled?: boolean;
  initialData?: Record<string, any>;
  variant?: 'CREATE' | 'UPDATE';
}> = ({ onSubmit, loading, disabled = false, initialData = {}, variant = 'CREATE' }) => {
  const [formState, setFormState] = useState<Record<string, any>>(initialData);
  const [selectedProduct, setSelectedProduct] = useState<{
    _id: string;
    price: number;
    brand: AutoCompleteOption;
  }>({
    _id: '',
    brand: initialData.brand ?? { _id: '', name: '' },
    price: initialData.price ?? '',
  });
  const rankedFields = ['subCategory', 'group', 'application', 'wattage', 'brand', 'quantity'];

  const [getSubCategories, { data: subCategories, loading: loadingSubCategories }] =
    useLazyQuery<LHASubCategoriesQueryResponse>(LHA_SUB_CATEGORIES_QUERY);

  const [getGroupNames, { data: groups, loading: loadingGroupNames }] = useLazyQuery<
    LHAGroupNamesQueryResponse,
    LHAGroupNamesQueryVariables
  >(LHA_GROUP_NAMES_QUERY);

  const [getApplications, { data: applications, loading: loadingApplications }] = useLazyQuery<
    LHAApplicationsQueryResponse,
    LHAApplicationsQueryVariables
  >(LHA_APPLICATIONS_QUERY);

  const [getWattages, { data: wattages, loading: loadingWattages }] = useLazyQuery<
    LHAWattagesQueryResponse,
    LHAWattagesQueryVariables
  >(LHA_WATTAGES_QUERY);

  const [getBrands, { data: brands, loading: loadingBrands }] = useLazyQuery<
    LHABrandNamesQueryResponse,
    LHABrandNamesQueryVariables
  >(LHA_BRAND_NAMES_QUERY);

  const getPrice = (value: AutoCompleteOption) => {
    if (!!brands) {
      const currProduct = brands.getLhaProducts.find(p => p.brand._id === value._id) as any;
      setSelectedProduct(currProduct);
    }
  };

  const handleSubmit = () => {
    const newFields = removeEmptyFields({ ...formState });

    if (!!newFields.brand) {
      onSubmit({
        product: selectedProduct._id,
        quantity: formState.quantity,
      });
    }
  };

  return (
    <Grid container px={1} py={2} rowGap={2} columnSpacing={1.5}>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Sub Category"
          fetch={getSubCategories}
          handleChange={val =>
            rankedFieldsChangeHandler('subCategory', val, setFormState, rankedFields)
          }
          disabled={disabled}
          loading={loadingSubCategories}
          options={(subCategories?.getLhaProducts ?? []).map(p => p.subCategory)}
          value={formState.subCategory ?? { _id: '', name: '', referenceId: '' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Group"
          fetch={getGroupNames}
          handleChange={val => rankedFieldsChangeHandler('group', val, setFormState, rankedFields)}
          loading={loadingGroupNames}
          options={(groups?.getLhaProducts ?? []).map(p => p.group)}
          value={formState.group ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.subCategory || !!!formState.subCategory?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteWithFetch
          label="Application"
          fetch={getApplications}
          handleChange={val =>
            rankedFieldsChangeHandler('application', val, setFormState, rankedFields)
          }
          loading={loadingApplications}
          options={(applications?.getLhaProducts ?? []).map(p => p.application)}
          value={formState.application ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.group || !!!formState.group?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Wattage"
          fetch={getWattages}
          handleChange={val =>
            rankedFieldsChangeHandler('wattage', val, setFormState, rankedFields)
          }
          loading={loadingWattages}
          options={(wattages?.getLhaProducts ?? []).map(p => p.wattage)}
          value={formState.wattage ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.application || !!!formState.application?._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
              application: formState.application?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteWithFetch
          label="Brand"
          fetch={getBrands}
          handleChange={val => {
            rankedFieldsChangeHandler('brand', val, setFormState, rankedFields);
            getPrice(val);
          }}
          loading={loadingBrands}
          options={(brands?.getLhaProducts ?? []).map(product => product.brand)}
          value={formState.brand ?? { _id: '', name: '', referenceId: '' }}
          disabled={!!!formState.wattage || !!!formState.wattage._id || disabled}
          variables={{
            filter: {
              subCategory: formState.subCategory?._id,
              group: formState.group?._id,
              application: formState.application?._id,
              wattage: formState.wattage?._id,
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            label="Quantity"
            value={formState.quantity ?? ''}
            type="number"
            onChange={e =>
              rankedFieldsChangeHandler(
                'quantity',
                !!e.target.value ? +e.target.value : '',
                setFormState,
                rankedFields
              )
            }
            variant="outlined"
            required
            disabled={!!!formState.brand || !!!formState.brand._id || disabled}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            label="Estimated Value"
            type="number"
            variant="outlined"
            disabled
            value={formState.quantity ? +formState.quantity * selectedProduct.price : ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <b color="lightGray">₹</b>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Grid>
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={4} marginRight="auto">
          <LoadingButton
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            loading={loading}
            disabled={
              variant === 'CREATE'
                ? !!!formState.quantity
                : !(
                    !!formState.quantity &&
                    !!Object.keys(getUpdatedFields(initialData, formState)).length
                  )
            }
          >
            {variant === 'CREATE' ? 'Create' : 'Update'}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LHAProductForm;
