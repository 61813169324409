import { useLazyQuery } from '@apollo/client';
import { FormControl, Grid, TextField } from '@mui/material';
import { areasQuery } from 'graphql/query/area';
import { CustomerTypesResponse, GET_CUSTOMER_TYPES_QUERY } from 'graphql/query/customer';
import { CustomerStatusEnum, LanguageEnum } from 'types/common';

import { MultiSelect } from 'components/Inputs/Select';

const CustomerFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const [getCustomerTypes, { data: customerTypes, loading: customerTypesLoading }] =
    useLazyQuery<CustomerTypesResponse>(GET_CUSTOMER_TYPES_QUERY);

  const [getAreas, { data: areas, loading: areasLoading }] = useLazyQuery(areasQuery);

  const handleChange = (fieldName: string, val: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            value={state.searchTerm ?? ''}
            label="Customer Search"
            variant="outlined"
            onChange={e => handleChange('searchTerm', e.target.value)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Customer Type"
          handleChange={val => handleChange('customerType', val)}
          options={
            customerTypes?.getCustomerTypes.map(o => ({
              label: o.name,
              value: o._id,
            })) ?? []
          }
          value={state.customerType ?? []}
          fetchConfig={{
            fetchFn: getCustomerTypes,
            variables: {},
            loading: customerTypesLoading,
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Area"
          handleChange={val => handleChange('area', val)}
          options={
            areas?.getAreas.map(o => ({
              label: o.name,
              value: o._id,
            })) ?? []
          }
          value={state.area ?? []}
          fetchConfig={{
            fetchFn: getAreas,
            variables: {},
            loading: areasLoading,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <MultiSelect
          label="Preffered Language"
          handleChange={val => handleChange('prefferedLanguage', val)}
          options={Object.values(LanguageEnum).map(o => ({ label: o, value: o }))}
          value={state.prefferedLanguage ?? []}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Status"
          handleChange={val => handleChange('status', val)}
          options={Object.values(CustomerStatusEnum).map(o => ({ label: o, value: o }))}
          value={state.status ?? []}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Is Verified"
          handleChange={val => handleChange('isVerified', val)}
          options={[
            { label: 'YES', value: true },
            { label: 'NO', value: false },
          ]}
          value={state.isVerified ?? []}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerFilters;
