import { useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, Grid } from '@mui/material';
import {
  PROJECT_NAMES_QUERY,
  ProjectNamesQueryResponse,
  ProjectNamesQueryVariables,
} from 'graphql/query/project';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AutoCompleteOption } from 'types/common';

import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';

type ProjectAddProps = {
  customerInfo: {
    _id: string;
    referenceId: string;
    fname: string;
    lname?: string;
  };
  showExistingButton?: boolean;
  onSubmit?: (arg: any) => void;
  loading?: boolean;
};

const ProjectAdd: React.FC<ProjectAddProps> = ({
  customerInfo,
  showExistingButton = false,
  loading = false,
  onSubmit = () => {},
}) => {
  const [enableProjectActions, toggleProjectActions] = useState(true);
  const [selectedProject, setSelectedProject] = useState<AutoCompleteOption | null>(null);

  const [getProjects, { data: projects, loading: projectsLoading }] = useLazyQuery<
    ProjectNamesQueryResponse,
    ProjectNamesQueryVariables
  >(PROJECT_NAMES_QUERY);

  const navigate = useNavigate();

  const stringifiedCustomerInfo = JSON.stringify({
    customer: {
      _id: customerInfo._id,
      referenceId: customerInfo.referenceId,
      fname: customerInfo.fname,
      lname: customerInfo.lname,
    },
  });

  return (
    <Grid container rowGap={1} columnSpacing={1} alignItems={'center'}>
      {enableProjectActions && (
        <>
          {showExistingButton && (
            <Grid container item xs={7} my={0.5}>
              <Button
                fullWidth
                variant="outlined"
                size="small"
                sx={{ fontSize: '0.8rem' }}
                onClick={() => {
                  toggleProjectActions(false);
                }}
              >
                Add existing Project
              </Button>
            </Grid>
          )}
          <Grid container item xs={showExistingButton ? 5 : 12} my={0.5}>
            <Button
              fullWidth
              variant={showExistingButton ? 'contained' : 'outlined'}
              size="small"
              sx={{ fontSize: '0.8rem' }}
              startIcon={<AddIcon />}
              onClick={() => navigate(`/projects/create-project?data=${stringifiedCustomerInfo}`)}
            >
              Create New
            </Button>
          </Grid>
        </>
      )}
      {!enableProjectActions && (
        <Grid
          xs={12}
          item
          container
          my={0}
          direction={'row'}
          alignItems={'center'}
          justifyContent="flex-end"
          columnSpacing={1}
        >
          <Grid item xs={12} mb={1.5}>
            <FormControl fullWidth>
              <AutocompleteWithFetch
                fetch={getProjects}
                handleChange={val => setSelectedProject(val)}
                label="Project"
                loading={projectsLoading}
                options={projects?.getProjects ?? []}
                value={
                  selectedProject ?? {
                    _id: '',
                    name: '',
                    referenceId: '',
                  }
                }
                variables={{
                  filter: {
                    searchTerm: customerInfo.referenceId,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} md={4} lg={2} mb={1.5}>
            <LoadingButton
              size="small"
              fullWidth
              variant="contained"
              disabled={!!!selectedProject?._id}
              loading={loading}
              onClick={() => onSubmit({ project: selectedProject?._id })}
            >
              Add
            </LoadingButton>
          </Grid>
          <Grid item xs={6} md={4} mb={1.5} lg={2}>
            <Button
              fullWidth
              variant="text"
              size="small"
              onClick={() => {
                toggleProjectActions(true);
                setSelectedProject({
                  _id: '',
                  name: '',
                  referenceId: '',
                });
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectAdd;
