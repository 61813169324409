import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';

type TimelineProps = {
  currTimeline: string;
  handleTimeline: (arg: any) => void;
  timelines: { label: string; value: string }[];
};

const Timeline: React.FC<TimelineProps> = ({ currTimeline, handleTimeline, timelines }) => {
  return (
    <Box sx={{ width: '100%' }} mb={2} borderBottom={'1px solid lightgray'}>
      <Tabs
        value={currTimeline}
        variant="scrollable"
        sx={{
          '&.MuiTabs-root': {
            minHeight: 20,
          },
        }}
      >
        {timelines.map(tab => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            onClick={() => {
              handleTimeline(tab.value);
            }}
            sx={{
              fontSize: 12,
              pt: 0.8,
              pb: 1,
              '&.MuiButtonBase-root': {
                minHeight: 20,
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default Timeline;
