import dayjs from 'dayjs';
import React from 'react';
import {
  CommercialTypeEnum,
  Site,
  SiteSizeMeasurementEnum,
  SiteStatusEnum,
  SiteTypeEnum,
} from 'types/common';

import { FormInput, FormPanel } from 'components/FormPanel';

type UpdateSiteFormProps = {
  site: Site;
  handleSubmit: (data: any) => void;
  loading: boolean;
};

const UpdateSiteForm: React.FC<UpdateSiteFormProps> = ({ site, handleSubmit, loading }) => {
  return (
    <FormPanel
      error={null}
      loading={loading}
      onSubmit={handleSubmit}
      manualFormUpdate={
        !!site.siteImages.length && !loading
          ? () => ({ fieldName: 'siteImages', value: site.siteImages })
          : undefined
      }
    >
      <FormInput
        fieldName="referenceId"
        label="Site ID"
        type="string"
        disabled
        defaultValue={site?.referenceId}
      />
      <FormInput type="string" fieldName="name" label="Site Name" defaultValue={site?.name} />
      <FormInput
        type="select"
        fieldName="siteStatus"
        label="Site Status"
        defaultValue={site?.siteStatus}
        options={Object.values(SiteStatusEnum).map(o => ({
          label: o,
          value: o,
        }))}
        validators={{
          required: true,
        }}
      />
      <FormInput
        type="select"
        fieldName="siteType"
        label="Site Type"
        defaultValue={site?.siteType}
        options={Object.values(SiteTypeEnum).map(o => ({
          label: o,
          value: o,
        }))}
        disabled
        validators={{
          required: true,
        }}
      />
      {site.siteType === SiteTypeEnum.COMMERCIAL && (
        <FormInput
          type="select"
          fieldName="commercialType"
          label="Commercial Type"
          defaultValue={site?.commercialType}
          options={Object.values(CommercialTypeEnum).map(o => ({
            label: o,
            value: o,
          }))}
          validators={{
            required: true,
          }}
        />
      )}
      <FormInput
        fieldName="siteSize"
        type="number"
        label="Site Size"
        defaultValue={site?.siteSize}
        validators={{
          required: true,
        }}
      />
      <FormInput
        type="select"
        fieldName="siteSizeMeasurement"
        label="Measurement unit"
        defaultValue={site?.siteSizeMeasurement}
        options={Object.values(SiteSizeMeasurementEnum).map(o => ({
          label: o,
          value: o,
        }))}
        validators={{
          required: true,
        }}
      />
      <FormInput
        fieldName="builtUpArea"
        type="number"
        label="Built Up Area (sqft)"
        defaultValue={site?.builtUpArea}
        validators={{
          required: true,
        }}
      />
      {/* <FormInput
        fieldName="area"
        label="Area"
        type="auto_complete_with_fetch"
        defaultValue={site?.area}
        fullWidth
        autoCompleteConfig={{
          fetchOptionsFn: getAreas,
          loading: areasLoading,
          options: areas?.getAreas ?? [],
        }}
        validators={{
          required: true,
        }}
      /> */}
      <FormInput
        type="number"
        fieldName="noOfFloors"
        label="Number of Floors"
        defaultValue={site?.noOfFloors}
        validators={{
          required: true,
        }}
      />
      {site.siteType === SiteTypeEnum.RESIDENTIAL && (
        <FormInput
          fieldName="noOfFlats"
          type="number"
          label="Number of Flats"
          defaultValue={site?.noOfFlats}
          validators={{
            required: true,
          }}
        />
      )}
      <FormInput
        fieldName="expectedCompletion"
        label="Expected Completion"
        validators={{
          required: true,
        }}
        defaultValue={site?.expectedCompletion ? dayjs(site.expectedCompletion) : null}
        type="date"
      />
      <FormInput
        type="upload"
        fieldName="siteImages"
        multiple
        validators={{
          required: true,
        }}
        label="Site Images"
        defaultValue={site.siteImages ?? []}
        fullWidth
      />
    </FormPanel>
  );
};

export default UpdateSiteForm;
