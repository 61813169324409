import { gql, useLazyQuery } from '@apollo/client';
import { FormControl, Grid, TextField } from '@mui/material';
import { DepartmentEnum, RoleType, UserStatusEnum, UserTypeEnum } from 'types/common';

import { MultiSelect } from 'components/Inputs/Select';

const GET_ROLES = gql`
  query GetRoles {
    getRoles {
      _id
      referenceId
      name
    }
  }
`;

type GetRolesQueryResponse = {
  getRoles: RoleType[];
};

const UsersFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const [getRoles, { data: userRoles, loading: loadingRoles }] =
    useLazyQuery<GetRolesQueryResponse>(GET_ROLES);

  const handleChange = (fieldName: string, val: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            value={state.searchTerm ?? ''}
            label="Search User"
            variant="outlined"
            onChange={e => handleChange('searchTerm', e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Status"
          handleChange={val => handleChange('status', val)}
          options={Object.values(UserStatusEnum).map(o => ({ label: o, value: o }))}
          value={state.status ?? []}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Type"
          handleChange={val => handleChange('type', val)}
          options={Object.values(UserTypeEnum).map(o => ({ label: o, value: o }))}
          value={state.type ?? []}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Department"
          handleChange={val => handleChange('department', val)}
          options={Object.values(DepartmentEnum).map(o => ({ label: o, value: o }))}
          value={state.department ?? []}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelect
          label="Roles"
          handleChange={val => handleChange('roles', val)}
          options={
            userRoles?.getRoles.map(o => ({
              label: o.name,
              value: o.referenceId,
            })) ?? []
          }
          value={state.roles ?? []}
          fetchConfig={{
            fetchFn: getRoles,
            loading: loadingRoles,
            variables: {},
          }}
        />
      </Grid>
    </Grid>
  );
};

export default UsersFilters;
