import { FormControl, Grid, TextField } from '@mui/material';
import { StatusEnum } from 'types/common';

import { Select } from 'components/Inputs/Select';

const StoreAreasFilters: React.FC<{ state: Record<string, any>; setState: (arg: any) => void }> = ({
  setState,
  state,
}) => {
  const handleChange = (fieldName: string, val: any) => {
    setState(prev => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  return (
    <Grid container columnSpacing={2} alignItems={'center'} rowGap={2}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <TextField
            value={state.searchTerm ?? ''}
            label="Store Area Search"
            variant="outlined"
            onChange={e => handleChange('searchTerm', e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <Select
          label="Status"
          onChange={val => handleChange('status', val)}
          options={Object.values(StatusEnum).map(o => ({ label: o, value: o }))}
          value={state.status ?? ''}
        />
      </Grid>
    </Grid>
  );
};

export default StoreAreasFilters;
