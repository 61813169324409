import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import EastIcon from '@mui/icons-material/East';
import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, GridProps, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FOLLOWUP_UPDATE_SUCCESS } from 'data/notificationsConst';
import dayjs from 'dayjs';
import {
  GET_CRM_USERS_QUERY,
  GET_STORE_ASSOCIATE_USERS,
  GetCRMUsersQueryResponse,
  GetStoreAssociateUsersQueryResponse,
} from 'graphql/query/common';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CallStatusEnum,
  Followup,
  FollowupOutcomeEnum,
  FollowupStatusEnum,
  FollowupTypeEnum,
  Lead,
  PreferredSlotEnum,
  preferredSlotMap,
} from 'types/common';

import GroupedAccordion from 'components/Accordion/GroupedAccordion';
import { getUpdatedFields } from 'components/FormPanel';
import AutocompleteWithFetch from 'components/FormPanel/AutoCompleteWithFetch';
import GoBackButton from 'components/GoBackButton';
import { Select } from 'components/Inputs/Select';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';

import { deepEqual, getCallStatusMappedFollowupOutcomes } from 'utils/common';
import { convertLeadsToAutoCompleteOptions, transformCustomerNames } from 'utils/transformFn';

type GetFollowupQueryResponse = {
  getFollowupById: Followup;
};

type GetFollowupQueryVariables = {
  id: string;
};

const GET_FOLLOWUP_QUERY = gql`
  query GetFollowupById($id: ID!) {
    getFollowupById(_id: $id) {
      _id
      referenceId
      followupNotes
      callStatus
      followupOutcome
      preferredSlot
      status
      followupDate
      rescheduledCounter
      followupType
      assignedTo {
        _id
        empId
        fname
        lname
      }
      createdBy {
        _id
        empId
        fname
        lname
      }
      lead {
        _id
        referenceId
        customer {
          _id
          referenceId
          fname
          lname
        }
        project {
          _id
          referenceId
        }
      }
    }
  }
`;

type UpdateFollowupMutationResponse = {
  updateFollowup: Followup;
};

type UpdateFollowupMutationVariables = {
  input: {
    _id: string;
    lead?: string;
    callStatus?: CallStatusEnum;
    followupOutcome?: FollowupOutcomeEnum;
    followupNotes?: string;
    nextFollowupDate?: string;
    preferredSlot?: PreferredSlotEnum;
    status?: FollowupStatusEnum;
    followupDate?: string;
    followupType?: FollowupTypeEnum;
    assignedTo?: string;
    rescheduledCounter?: number;
  };
};

const UPDATE_FOLLOWUP_MUTATION = gql`
  mutation UpdateFollowup($input: UpdateFollowupInput!) {
    updateFollowup(input: $input) {
      _id
      referenceId
      followupNotes
      nextFollowupDate
      callStatus
      followupOutcome
      preferredSlot
      status
      followupDate
      rescheduledCounter
      followupType
      assignedTo {
        _id
        empId
        fname
        lname
      }
      createdBy {
        _id
        empId
        fname
        lname
      }
      lead {
        _id
        referenceId
        customer {
          _id
          referenceId
          fname
          lname
        }
        project {
          _id
          referenceId
        }
      }
    }
  }
`;

type GetLeadsQueryResponse = {
  getLeads: Lead[];
};

const GET_LEADS_QUERY = gql`
  query GetLeads($filter: LeadFilter) {
    getLeads(filter: $filter) {
      _id
      referenceId
      customer {
        _id
        fname
        lname
      }
    }
  }
`;

const FollowupPage = () => {
  const { followupId = '' } = useParams<{ followupId: string }>();
  const [activeIndex, setActiveIndex] = useState<number | string>(0);

  const { data: followup, loading: loadingFollowup } = useQuery<
    GetFollowupQueryResponse,
    GetFollowupQueryVariables
  >(GET_FOLLOWUP_QUERY, {
    variables: {
      id: followupId,
    },
  });
  return (
    <Navbar>
      <GoBackButton title="Followup Details " />
      {loadingFollowup || !!!followup ? (
        <LoadingIndicator size="1.6rem" />
      ) : (
        <Grid container flexDirection="column" pb={4}>
          <GroupedAccordion
            activeIndex={activeIndex}
            currIndex={0}
            setActiveIndex={setActiveIndex}
            title="Followup"
          >
            <Grid item xs={12} px={1} py={2}>
              <FollowupDetailsForm followupId={followupId} initialData={followup.getFollowupById} />
            </Grid>
          </GroupedAccordion>
          <Grid
            container
            flexDirection="column"
            rowGap={1.5}
            my={2}
            p={2}
            border={1}
            borderColor="#d0d0d0"
            borderRadius={2}
          >
            <NextPageLink
              title="Product Details"
              linkTo={`/leads/${followup.getFollowupById.lead._id}/products`}
            />
            <NextPageLink
              title="Lead Status"
              linkTo={`/leads/${followup.getFollowupById.lead._id}/lead-status`}
            />
            <NextPageLink
              title="Customer"
              linkTo={`/customers/${followup.getFollowupById.lead.customer._id}`}
            />
          </Grid>
        </Grid>
      )}
    </Navbar>
  );
};

const FollowupDetailsForm: React.FC<{ followupId: string; initialData: Followup }> = ({
  followupId,
  initialData,
}) => {
  const [formState, setFormState] = useState<Record<string, any>>({});

  useEffect(() => {
    if (!deepEqual(initialData, formState)) {
      setFormState(initialData);
    }
  }, [initialData]);

  const handleChange = (fieldName: string, value: any) => {
    setFormState(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const [getLeads, { loading: loadingLeads, data: leads }] =
    useLazyQuery<GetLeadsQueryResponse>(GET_LEADS_QUERY);

  const [getCRMUsers, { data: crmUsers, loading: loadingCrmUsers }] =
    useLazyQuery<GetCRMUsersQueryResponse>(GET_CRM_USERS_QUERY);

  const [getStoreAssociates, { data: storeAssociates, loading: loadingStoreAssociates }] =
    useLazyQuery<GetStoreAssociateUsersQueryResponse>(GET_STORE_ASSOCIATE_USERS);

  const isStoreVisit = formState.followupType === FollowupTypeEnum.STORE_VISIT;

  const [updateFollowup, { loading: updatingFollowup, error }] = useMutation<
    UpdateFollowupMutationResponse,
    UpdateFollowupMutationVariables
  >(UPDATE_FOLLOWUP_MUTATION);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const refinedData = getUpdatedFields(initialData, formState);

    delete refinedData.referenceId;
    delete refinedData.createdBy;

    refinedData.followupOutcome = formState.followupOutcome;
    refinedData.followupNotes = formState.followupNotes;
    refinedData._id = followupId;

    updateFollowup({
      variables: {
        input: {
          ...refinedData,
        },
      },
      onCompleted: _ => {
        toast.success(FOLLOWUP_UPDATE_SUCCESS);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container rowGap={1.5} columnSpacing={1.5}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <AutocompleteWithFetch
              fetch={getLeads}
              disabled
              value={convertLeadsToAutoCompleteOptions([initialData.lead])[0]}
              loading={loadingLeads}
              options={convertLeadsToAutoCompleteOptions(leads?.getLeads)}
              labelWithId
              label="Lead"
              required
              handleChange={val => handleChange('lead', val)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              value={formState.referenceId ?? ''}
              label="Followup ID"
              variant="outlined"
              disabled
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Select
            value={formState.followupType ?? ''}
            label="Followup Type"
            options={Object.values(FollowupTypeEnum).map(o => ({ label: o, value: o }))}
            disabled
            onChange={(val: any) => handleChange('followupType', val)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(formState.followupDate)}
                onChange={newValue => {
                  handleChange('followupDate', newValue);
                }}
                label="Followup Date"
                disabled
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                    required: true,
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Select
            value={formState.preferredSlot ?? ''}
            label="Preferred Slot"
            options={Object.values(PreferredSlotEnum).map(o => ({
              label: preferredSlotMap[o],
              value: o,
            }))}
            disabled
            onChange={(val: any) => handleChange('preferredSlot', val)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              value={formState.status ?? ''}
              label="Status"
              variant="outlined"
              disabled
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Select
            value={formState.callStatus ?? ''}
            label="Call Status"
            disabled={initialData.status === FollowupStatusEnum.CLOSED}
            fullWidth
            options={Object.values(CallStatusEnum).map(o => ({
              label: o,
              value: o,
            }))}
            onChange={(val: any) => {
              handleChange('callStatus', val);
              handleChange('followupOutcome', '');
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            label="Created By"
            value={transformCustomerNames([initialData.createdBy])[0]}
            fetch={() => {}}
            loading={false}
            handleChange={val => handleChange('createdBy', val)}
            options={[]}
            required
            disabled
            labelWithId
          />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteWithFetch
            label="Assigned to"
            disabled={initialData.status === FollowupStatusEnum.CLOSED}
            fetch={isStoreVisit ? getStoreAssociates : getCRMUsers}
            loading={isStoreVisit ? loadingStoreAssociates : loadingCrmUsers}
            handleChange={val => handleChange('assignedTo', val)}
            required
            labelWithId
            options={transformCustomerNames(
              isStoreVisit ? storeAssociates?.fetchStoreAssociateUsers : crmUsers?.fetchCRMUsers
            )}
            value={
              !!formState.assignedTo?.fname
                ? transformCustomerNames([formState.assignedTo])[0]
                : formState.assignedTo ?? {
                    _id: '',
                    name: '',
                    referenceId: '',
                  }
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            value={formState.followupOutcome ?? ''}
            label="Followup Outcome"
            fullWidth
            required={!!formState.callStatus}
            disabled={initialData.status === FollowupStatusEnum.CLOSED || !!!formState.callStatus}
            onChange={(val: any) => handleChange('followupOutcome', val)}
            options={Object.values(getCallStatusMappedFollowupOutcomes(formState.callStatus)).map(
              o => ({
                value: o,
                label: o,
              })
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              value={formState.followupNotes ?? ''}
              onChange={e => handleChange('followupNotes', e.target.value)}
              label="Followup Notes"
              placeholder="Followup Notes"
              type="text"
              multiline
              minRows={3}
              disabled={initialData.status === FollowupStatusEnum.CLOSED || !!!formState.callStatus}
              required={!!formState.callStatus}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={updatingFollowup}
            disabled={!!!Object.keys(getUpdatedFields(initialData, formState)).length}
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

type NextPageLinkProps = {
  title: string;
  linkTo: string;
} & GridProps;

const NextPageLink: React.FC<NextPageLinkProps> = ({ title, linkTo, ...props }) => {
  const navigate = useNavigate();
  return (
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(linkTo)}
      {...props}
    >
      <Typography variant="body2" fontSize={16} fontWeight={700}>
        {title}
      </Typography>
      <EastIcon color="primary" fontSize="medium" />
    </Grid>
  );
};

export default FollowupPage;
