import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { AdminCreateModuleEnum } from 'types/common';

import { downloadBinaryData } from 'utils/admin';

const DOWNLOAD_TEMPLATE_MUTATION = gql`
  mutation DownloadTemplate($type: FileUploadTypeEnum!, $fields: [String]) {
    downloadTemplate(type: $type, fields: $fields) {
      filename
      content
    }
  }
`;

type DownloadTemplateMutationResponse = {
  downloadTemplate: {
    filename: string;
    content: string;
  };
};

type DownloadTemplateMutationVariables = {
  type: AdminCreateModuleEnum;
  fields: string[];
};

type DownloadTemplateProps = {
  type: AdminCreateModuleEnum;

  createModalSchema: {
    required: boolean;
    field: string;
    label: string;
  }[];
  onClose: () => void;
};

const DownloadTemplate: React.FC<DownloadTemplateProps> = ({
  type,
  createModalSchema,
  onClose,
}) => {
  const [formState, setFormState] = useState(
    createModalSchema.reduce((acc, curr) => {
      if (curr.field == 'referenceId') return acc;
      acc[curr.field] = !!curr.required;
      return acc;
    }, {})
  );

  const disabledFields = useMemo(() => {
    return createModalSchema.reduce((acc, curr) => {
      acc[curr.field] = !!curr.required;
      return acc;
    }, {});
  }, [createModalSchema]);

  const labels = useMemo(() => {
    return createModalSchema.reduce((acc, curr) => {
      acc[curr.field] = curr.label;
      return acc;
    }, {});
  }, [createModalSchema]);

  const [downloadTemplate, { loading: downloadingTemplate }] = useMutation<
    DownloadTemplateMutationResponse,
    DownloadTemplateMutationVariables
  >(DOWNLOAD_TEMPLATE_MUTATION);

  const handleChange = (e: any) => {
    setFormState(prev => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleDownloadTemplate = () => {
    const selectedFields = Object.keys(formState).reduce((prev, curr) => {
      if (formState[curr]) prev.push(curr);
      return prev;
    }, [] as string[]);

    downloadTemplate({
      variables: {
        type,
        fields: selectedFields,
      },
      onCompleted: res => {
        downloadBinaryData(res.downloadTemplate);
        onClose();
      },
    });
  };

  return (
    <Box display="flex" flexDirection="column" rowGap={2.5} px={1.5} py={2} maxHeight={'85vh'}>
      <Typography variant="h6" fontWeight={600}>
        Select Fields
      </Typography>

      <Grid container width="100%" columnSpacing={2} alignItems={'center'}>
        {Object.keys(formState).map(field => (
          <Grid key={field} item xs={6} md={6} lg={4}>
            <FormControlLabel
              checked={formState[field]}
              required={disabledFields[field]}
              control={<Checkbox />}
              label={labels[field]}
              disabled={disabledFields[field]}
              name={field}
              onChange={handleChange}
            />
          </Grid>
        ))}
      </Grid>

      <Box display="flex" flexDirection="column" alignItems={'flex-end'} rowGap={0.8} mt={'auto'}>
        <Box display="flex" justifyContent="end">
          <Button
            variant="text"
            onClick={onClose}
            sx={{
              mr: 1.5,
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={downloadingTemplate}
            onClick={handleDownloadTemplate}
          >
            Download
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default DownloadTemplate;
