import { gql, useMutation } from '@apollo/client';
import { PROJECT_CREATE_SUCCESS } from 'data/notificationsConst';
import { updateCachedList } from 'graphql/cacheUpdate';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProjectForm from 'routes/Project/ProjectForm';
import { GET_PROJECTS_QUERY } from 'routes/Projects';
import { Project } from 'types/common';

import GoBackButton from 'components/GoBackButton';
import Navbar from 'components/Navbar';

const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      _id
    }
  }
`;

type CreateProjectResponse = {
  createProject: Project;
};

const CreateNewProject = () => {
  const [createProject, { loading: submitting }] =
    useMutation<CreateProjectResponse>(CREATE_PROJECT_MUTATION);

  const navigate = useNavigate();
  const handleSubmit = data => {
    createProject({
      variables: {
        input: data,
      },
      onCompleted: res => {
        updateCachedList(GET_PROJECTS_QUERY, 'getProjects', res.createProject);
        toast.success(PROJECT_CREATE_SUCCESS);
        navigate(`/projects/${res.createProject._id}`);
      },
    });
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dataToPrePopulate = JSON.parse(queryParams.get('data') ?? '{}') ?? {};

  return (
    <Navbar>
      <GoBackButton title="Create Project" />
      <ProjectForm
        loading={submitting}
        onSubmit={handleSubmit}
        variant="CREATE"
        renderAddress
        initialData={dataToPrePopulate}
      />
    </Navbar>
  );
};

export default CreateNewProject;
