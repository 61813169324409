import { isAuthenticated, logout } from 'utils/auth';

interface UserState {
  isAuthenticated: boolean;
}

export const userInitialState: UserState = {
  isAuthenticated: isAuthenticated(),
};

export interface ActionType {
  type: 'USER_AUTHENTICATED' | 'USER_UNAUTHENTICATED';
  payload: {
    isAuthenticated: boolean;
  };
}

const userReducer = (state: UserState, action: ActionType) => {
  if (action.type === 'USER_AUTHENTICATED') {
    return {
      ...state,
      isAuthenticated: action.payload.isAuthenticated,
    };
  } else if (action.type === 'USER_UNAUTHENTICATED') {
    logout();
    return {
      ...state,
      isAuthenticated: action.payload.isAuthenticated,
    };
  }
  return state;
};

export default userReducer;
