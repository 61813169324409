import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { AdminUpdateModuleEnum } from 'types/common';

const UPDATE_BRANDS_MUTATION = gql`
  mutation UpdateBrands($input: GraphQLUpload!) {
    updateBrands(input: $input)
  }
`;

type UpdateBrandsMutationResponse = {
  updateBrands: any;
};

type UpdateBrandsMutationVariables = {
  input: any;
};

const UPDATE_ROLES_MUTATION = gql`
  mutation UpdateRoles($input: GraphQLUpload!) {
    updateRoles(input: $input)
  }
`;

type UpdateRolesMutationResponse = {
  updateRoles: any;
};

type UpdateRolesMutationVariables = {
  input: any;
};

const UPDATE_LEAD_SOURCES_MUTATION = gql`
  mutation UpdateLeadSources($input: GraphQLUpload!) {
    updateLeadSources(input: $input)
  }
`;

type UpdateLeadSourcesMutationResponse = {
  updateLeadSources: any;
};
type UpdateLeadSourcesMutationVariables = {
  input: any;
};

const UPDATE_USERS_MUTATION = gql`
  mutation UpdateUsers($input: GraphQLUpload!) {
    updateUsers(input: $input)
  }
`;

type UpdateUsersMutationResponse = {
  updateUsers: any;
};

type UpdateUsersMutationVariables = {
  input: any;
};

const UPDATE_FOLLOW_UPS_MUTATION = gql`
  mutation UpdateFollowups($input: GraphQLUpload!) {
    updateFollowups(input: $input)
  }
`;

type UpdateFollowupsMutationResponse = {
  updateFollowups: any;
};

type UpdateFollowupsMutationVariables = {
  input: any;
};

const UPDATE_FIELD_VISITS_MUTATION = gql`
  mutation UpdateFieldVisits($input: GraphQLUpload!) {
    updateFieldVisits(input: $input)
  }
`;

type UpdateFieldVisitsMutationResponse = {
  updateFieldVisits: any;
};

type UpdateFieldVisitsMutationVariables = {
  input: any;
};

const UPDATE_HFD_PRODUCTS_MUTATION = gql`
  mutation UpdateHfdProducts($input: GraphQLUpload!) {
    updateHfdProducts(input: $input)
  }
`;

type UpdateHfdProductsMutationResponse = {
  updateHfdProducts: any;
};

type UpdateHfdProductsMutationVariables = {
  input: any;
};

const UPDATE_PINCODES_MUTATION = gql`
  mutation UpdatePincodes($input: GraphQLUpload!) {
    updatePincodes(input: $input)
  }
`;

type UpdatePincodesMutationResponse = {
  updatePincodes: any;
};

type UpdatePincodesMutationVariables = {
  input: any;
};

const UPDATE_STORES_MUTATION = gql`
  mutation UpdateStores($input: GraphQLUpload!) {
    updateStores(input: $input)
  }
`;

const UPDATE_PROJECTS_MUTATION = gql`
  mutation UpdateProjects($input: GraphQLUpload!) {
    updateProjects(input: $input)
  }
`;

const UPDATE_SITES_MUTATION = gql`
  mutation UpdateSites($input: GraphQLUpload!) {
    updateSites(input: $input)
  }
`;

const UPDATE_CUSTOMERS_MUTATION = gql`
  mutation UpdateCustomers($input: GraphQLUpload!) {
    updateCustomers(input: $input)
  }
`;

const UPDATE_HWT_PRODUCTS_MUTATION = gql`
  mutation UpdateHwtProducts($input: GraphQLUpload!) {
    updateHwtProducts(input: $input)
  }
`;

const UPDATE_LHA_PRODUCTS_MUTATION = gql`
  mutation UpdateLhaProducts($input: GraphQLUpload!) {
    updateLhaProducts(input: $input)
  }
`;

const UPDATE_MKW_PRODUCTS_MUTATION = gql`
  mutation UpdateMkwProducts($input: GraphQLUpload!) {
    updateMkwProducts(input: $input)
  }
`;

const UPDATE_TFL_PRODUCTS_MUTATION = gql`
  mutation UpdateTflProducts($input: GraphQLUpload!) {
    updateTflProducts(input: $input)
  }
`;

const UPDATE_WMT_PRODUCTS_MUTATION = gql`
  mutation UpdateWmtProducts($input: GraphQLUpload!) {
    updateWmtProducts(input: $input)
  }
`;

const REASSIGN_LEADS_MUTATION = gql`
  mutation ReassignLeads($input: GraphQLUpload!) {
    reassignLeads(input: $input)
  }
`;

const UPDATE_LEAD_ORDER_MAPPING_MUTATION = gql`
  mutation UpdateOrderIdInLeads($input: GraphQLUpload!) {
    updateOrderIdInLeads(input: $input)
  }
`;

const UPDATE_STORE_AREA_MAPPING_MUTATION = gql`
  mutation UpdateStoreAreas($input: GraphQLUpload!) {
    updateStoreAreas(input: $input)
  }
`;

const UPDATE_AREAS_MUTATION = gql`
  mutation UpdateAreas($input: GraphQLUpload!) {
    updateAreas(input: $input)
  }
`;

const UPDATE_TARGETS_MUTATION = gql`
  mutation UpdateTargets($input: GraphQLUpload!) {
    updateTargets(input: $input)
  }
`;

const useBulkUpdateFn = ({ type }: { type: AdminUpdateModuleEnum }) => {
  const [bulkUpdateParams, setBulkUpdateFuncParams] = useState<
    [(arg: any) => void, { loading: boolean; error: any }]
  >([() => {}, { loading: false, error: null }]);

  const [updateBrands, { loading: updatingBrands, error: updateBrandsError }] = useMutation<
    UpdateBrandsMutationResponse,
    UpdateBrandsMutationVariables
  >(UPDATE_BRANDS_MUTATION);

  const [updateRoles, { loading: updatingRoles, error: updateRolesError }] = useMutation<
    UpdateRolesMutationResponse,
    UpdateRolesMutationVariables
  >(UPDATE_ROLES_MUTATION);

  const [updateLeadSources, { loading: updatingLeadSources, error: updateLeadSourcesError }] =
    useMutation<UpdateLeadSourcesMutationResponse, UpdateLeadSourcesMutationVariables>(
      UPDATE_LEAD_SOURCES_MUTATION
    );

  const [updateUsers, { loading: updatingUsers, error: updateUsersError }] = useMutation<
    UpdateUsersMutationResponse,
    UpdateUsersMutationVariables
  >(UPDATE_USERS_MUTATION);

  const [updateFollowups, { loading: updatingFollowups, error: updateFollowupsError }] =
    useMutation<UpdateFollowupsMutationResponse, UpdateFollowupsMutationVariables>(
      UPDATE_FOLLOW_UPS_MUTATION
    );

  const [updateFieldVisits, { loading: updatingFieldVisits, error: updateFieldVisitsError }] =
    useMutation<UpdateFieldVisitsMutationResponse, UpdateFieldVisitsMutationVariables>(
      UPDATE_FIELD_VISITS_MUTATION
    );

  const [updateHfdProducts, { loading: updatingHfdProducts, error: updateHfdProductsError }] =
    useMutation<UpdateHfdProductsMutationResponse, UpdateHfdProductsMutationVariables>(
      UPDATE_HFD_PRODUCTS_MUTATION
    );

  const [updatePincodes, { loading: updatingPincodes, error: updatePincodesError }] = useMutation<
    UpdatePincodesMutationResponse,
    UpdatePincodesMutationVariables
  >(UPDATE_PINCODES_MUTATION);

  const [updateSites, { loading: updatingSites, error: updateSitesError }] =
    useMutation(UPDATE_SITES_MUTATION);

  const [updateProjects, { loading: updatingProjects, error: updateProjectsError }] =
    useMutation(UPDATE_PROJECTS_MUTATION);

  const [updateStores, { loading: updatingStores, error: updateStoresError }] =
    useMutation(UPDATE_STORES_MUTATION);

  const [updateCustomers, { loading: updatingCustomers, error: updateCustomersError }] =
    useMutation(UPDATE_CUSTOMERS_MUTATION);

  const [updateLhaProducts, { loading: updatingLhaProducts, error: updateLhaProductsError }] =
    useMutation(UPDATE_LHA_PRODUCTS_MUTATION);

  const [updateMkwProducts, { loading: updatingMkwProducts, error: updateMkwProductsError }] =
    useMutation(UPDATE_MKW_PRODUCTS_MUTATION);

  const [updateTflProducts, { loading: updatingTflProducts, error: updateTflProductsError }] =
    useMutation(UPDATE_TFL_PRODUCTS_MUTATION);

  const [updateWmtProducts, { loading: updatingWmtProducts, error: updateWmtProductsError }] =
    useMutation(UPDATE_WMT_PRODUCTS_MUTATION);

  const [updateHwtProducts, { loading: updatingHwtProducts, error: updateHwtProductsError }] =
    useMutation(UPDATE_HWT_PRODUCTS_MUTATION);

  const [updateLeads, { loading: updatingLeads, error: updateLeadsError }] =
    useMutation(REASSIGN_LEADS_MUTATION);

  const [
    updateLeadOrderMappings,
    { loading: updatingLeadOrderMappings, error: updateLeadOrderMappingsError },
  ] = useMutation(UPDATE_LEAD_ORDER_MAPPING_MUTATION);

  const [
    updateStoreAreaMappings,
    { loading: updatingStoreAreaMappings, error: updateStoreAreaMappingsError },
  ] = useMutation(UPDATE_STORE_AREA_MAPPING_MUTATION);

  const [updateAreas, { loading: updatingAreas, error: updateAreasError }] =
    useMutation(UPDATE_AREAS_MUTATION);

  const [updateTargets, { loading: updatingTargets, error: updateTargetsError }] =
    useMutation(UPDATE_TARGETS_MUTATION);

  useEffect(() => {
    switch (type) {
      case AdminUpdateModuleEnum.BRAND:
        setBulkUpdateFuncParams([
          updateBrands,
          { loading: updatingBrands, error: updateBrandsError },
        ]);
        break;
      case AdminUpdateModuleEnum.ROLE:
        setBulkUpdateFuncParams([updateRoles, { loading: updatingRoles, error: updateRolesError }]);
        break;
      case AdminUpdateModuleEnum.LEADSOURCE:
        setBulkUpdateFuncParams([
          updateLeadSources,
          { loading: updatingLeadSources, error: updateLeadSourcesError },
        ]);
        break;
      case AdminUpdateModuleEnum.USER:
        setBulkUpdateFuncParams([updateUsers, { loading: updatingUsers, error: updateUsersError }]);
        break;
      case AdminUpdateModuleEnum.FOLLOWUP:
        setBulkUpdateFuncParams([
          updateFollowups,
          { loading: updatingFollowups, error: updateFollowupsError },
        ]);
        break;
      case AdminUpdateModuleEnum.FIELDVISIT:
        setBulkUpdateFuncParams([
          updateFieldVisits,
          { loading: updatingFieldVisits, error: updateFieldVisitsError },
        ]);
        break;
      case AdminUpdateModuleEnum.HFDPRODUCT:
        setBulkUpdateFuncParams([
          updateHfdProducts,
          { loading: updatingHfdProducts, error: updateHfdProductsError },
        ]);
        break;
      case AdminUpdateModuleEnum.PINCODE:
        setBulkUpdateFuncParams([
          updatePincodes,
          { loading: updatingPincodes, error: updatePincodesError },
        ]);
        break;
      case AdminUpdateModuleEnum.STORE:
        setBulkUpdateFuncParams([
          updateStores,
          { loading: updatingStores, error: updateStoresError },
        ]);
        break;
      case AdminUpdateModuleEnum.SITE:
        setBulkUpdateFuncParams([updateSites, { loading: updatingSites, error: updateSitesError }]);
        break;
      case AdminUpdateModuleEnum.PROJECT:
        setBulkUpdateFuncParams([
          updateProjects,
          { loading: updatingProjects, error: updateProjectsError },
        ]);
        break;
      case AdminUpdateModuleEnum.CUSTOMER:
        setBulkUpdateFuncParams([
          updateCustomers,
          { loading: updatingCustomers, error: updateCustomersError },
        ]);
        break;
      case AdminUpdateModuleEnum.HWTPRODUCT:
        setBulkUpdateFuncParams([
          updateHwtProducts,
          { loading: updatingHwtProducts, error: updateHwtProductsError },
        ]);
        break;
      case AdminUpdateModuleEnum.LHAPRODUCT:
        setBulkUpdateFuncParams([
          updateLhaProducts,
          { loading: updatingLhaProducts, error: updateLhaProductsError },
        ]);
        break;
      case AdminUpdateModuleEnum.MKWPRODUCT:
        setBulkUpdateFuncParams([
          updateMkwProducts,
          { loading: updatingMkwProducts, error: updateMkwProductsError },
        ]);
        break;
      case AdminUpdateModuleEnum.TFLPRODUCT:
        setBulkUpdateFuncParams([
          updateTflProducts,
          { loading: updatingTflProducts, error: updateTflProductsError },
        ]);
        break;
      case AdminUpdateModuleEnum.WMTPRODUCT:
        setBulkUpdateFuncParams([
          updateWmtProducts,
          { loading: updatingWmtProducts, error: updateWmtProductsError },
        ]);
        break;
      case AdminUpdateModuleEnum.LEAD:
        setBulkUpdateFuncParams([updateLeads, { loading: updatingLeads, error: updateLeadsError }]);
        break;
      case AdminUpdateModuleEnum.LEAD_ORDER_MAPPING:
        setBulkUpdateFuncParams([
          updateLeadOrderMappings,
          { loading: updatingLeadOrderMappings, error: updateLeadOrderMappingsError },
        ]);
        break;
      case AdminUpdateModuleEnum.STOREAREA:
        setBulkUpdateFuncParams([
          updateStoreAreaMappings,
          { loading: updatingStoreAreaMappings, error: updateStoreAreaMappingsError },
        ]);
        break;
      case AdminUpdateModuleEnum.AREA:
        setBulkUpdateFuncParams([updateAreas, { loading: updatingAreas, error: updateAreasError }]);
        break;
      case AdminUpdateModuleEnum.TARGET:
        setBulkUpdateFuncParams([
          updateTargets,
          { loading: updatingTargets, error: updateTargetsError },
        ]);
        break;
      default:
        break;
    }
  }, [
    type,
    updatingBrands,
    updateBrandsError,
    updatingRoles,
    updatingUsers,
    updatingFollowups,
    updatingFieldVisits,
    updatingHfdProducts,
    updatingHwtProducts,
    updatingLhaProducts,
    updatingMkwProducts,
    updatingTflProducts,
    updatingWmtProducts,
    updatingPincodes,
    updatingProjects,
    updatingSites,
    updatingStores,
    updatingCustomers,
    updatingLeads,
    updatingLeadOrderMappings,
    updatingStoreAreaMappings,
    updatingTargets,
    updateRolesError,
    updateLeadSourcesError,
    updatingLeadSources,
    updatingAreas,
    updateUsersError,
    updateFollowupsError,
    updateFieldVisitsError,
    updateHfdProductsError,
    updateHwtProductsError,
    updateLhaProductsError,
    updateMkwProductsError,
    updateTflProductsError,
    updateWmtProductsError,
    updatePincodesError,
    updateStoresError,
    updateSitesError,
    updateProjectsError,
    updateCustomersError,
    updateLeadsError,
    updateLeadOrderMappingsError,
    updateStoreAreaMappingsError,
    updateAreasError,
    updateTargetsError,
  ]);

  return bulkUpdateParams;
};

export default useBulkUpdateFn;
